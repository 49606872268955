import { PARAMETER_TIME_FORMAT } from "@gymflow/common";
import {
  DATE_ONLY_FORMAT,
  iso8601WithoutTz,
  utcToZonedTime,
} from "@gymflow/helpers";
import {
  ApiListResponse,
  EmailSendingHistoryDTO,
  EventRsvpHistoryDTO,
  LeadHistoryDTO,
  MembershipSubscriptionHistoryTimelineDTO,
  NoteDashboardDTO,
  TaskDTO,
  TimelineDTO,
} from "@gymflow/types";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import dateFnsParse from "date-fns/parse";
import { format, utcToZonedTime as dateFnsUtcToZonedTime } from "date-fns-tz";

import { memberQueryKeys } from "./memberQueryKeys";

export function useInfiniteQueryMemberTimeline(
  {
    api,
    opts,
    tz,
  }: {
    api: {
      timelineApi: {
        find: (arg0: {
          [k: string]: any;
        }) => Promise<{ data: ApiListResponse<TimelineNode> }>;
      };
    };
    opts: MemberTimelineFilter;
    tz: string;
  },
  queryOpts?: UseInfiniteQueryOptions<ApiListResponse<TimelineNode>>,
) {
  const result = useInfiniteQuery({
    queryKey: memberQueryKeys.timeline(opts.extraParams.userMemberId, opts),
    queryFn: async ({ pageParam }) => {
      const result = await api.timelineApi.find({
        page: pageParam,
        limit: 10,
        ...opts,
      });
      const parsed = { ...result.data };
      parsed.content = result.data.content.map((node) => {
        return convertDatesToZoned(node, tz) as unknown as TimelineNode;
      });
      return parsed;
    },
    getNextPageParam: (lastPage) => lastPage.number + 1,
    ...queryOpts,
  });
  return result;
}

const convertDatesToZoned = (
  {
    sendTimestamp,
    createdDate,
    eventStartDate,
    deadlineDate,
    deadlineTime,
    transactionDate,
    ...rest
  }: {
    sendTimestamp?: string;
    createdDate?: string;
    eventStartDate?: string;
    deadlineDate?: string;
    deadlineTime?: string;
    transactionDate?: string;
  },
  tz: string,
) => {
  const deadline =
    deadlineDate &&
    deadlineTime &&
    dateFnsParse(
      utcToZonedTime(`${deadlineDate}T${deadlineTime}Z`, tz),
      iso8601WithoutTz,
      new Date(),
    );

  return {
    ...rest,
    sendTimestamp,
    sendEmailDate:
      sendTimestamp &&
      dateFnsUtcToZonedTime(Math.trunc(+sendTimestamp) * 1000, tz),
    createdDate: createdDate && utcToZonedTime(createdDate, tz),
    eventStartDate: eventStartDate && utcToZonedTime(eventStartDate, tz),
    deadlineDate: deadline && format(deadline, DATE_ONLY_FORMAT),
    deadlineTime: deadline && format(deadline, PARAMETER_TIME_FORMAT),
    transactionDate: transactionDate && utcToZonedTime(transactionDate, tz),
  };
};

export type TimelineNode =
  | MembershipSubscriptionHistoryTimelineDTO
  | TaskDTO
  | TimelineDTO
  | LeadHistoryDTO
  | EmailSendingHistoryDTO
  | EventRsvpHistoryDTO
  | NoteDashboardDTO;

interface MemberTimelineFilter {
  readonly extraParams: {
    readonly userMemberId: string;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { noteQueryKeys } from "./noteQueryKeys";

export function useMutationNoteCreateForLead({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      newNote,
      file,
    }: {
      newNote: Parameters<ApiType["noteApi"]["createLeadNote"]>[0];
      file?: Parameters<ApiType["noteApi"]["createLeadNote"]>[1];
    }) => {
      const response = await api.noteApi.createLeadNote(newNote, file);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: noteQueryKeys.all() });
    },
  });

  return mutation;
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationFacilityAvailabilityOverrideCreate({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      facilityId,
      newOverride,
    }: {
      facilityId: Parameters<
        ApiType["availabilityApi"]["createFacilityOverride"]
      >[0];
      newOverride: Parameters<
        ApiType["availabilityApi"]["createFacilityOverride"]
      >[1];
    }) => {
      const response = await api.availabilityApi.createFacilityOverride(
        facilityId,
        newOverride,
      );
      return response.data;
    },
    onSuccess: (_, { facilityId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allFacilityOverrides(facilityId),
      });
    },
  });

  return mutation;
}

import { pluralize } from "@gymflow/helpers";
import { formattedScheduleDate } from "apps/portal/src/helpers/date";
import { useSendSMS } from "apps/portal/src/hooks";
import { useClubSettings } from "apps/portal/src/providers";
import { useCallback } from "react";

import { SendSMSSidebar } from "../../organisms";
import { SCHEDULE_TIME_FORMAT } from "../../organisms/SendSMSSidebar/constants";
import { SendSMSFormValues } from "../../organisms/SendSMSSidebar/types";
import { generateLeadFiltersForEmailAndBulkStatus } from "./leadFilterHelper";
import { LeadFilters } from "./LeadPipelineFilterSidebar";

export type SendSMSToLeadsSidebarProps = {
  isVisible: boolean;
  onClose: () => void;
  recipients: number;
  areAllSelected: boolean;
  selected: number[];
  excluded: number[];
  listFilters: LeadFilters;
};

export const SendSMSToLeadsSidebar = ({
  onClose,
  recipients,
  areAllSelected,
  selected,
  excluded,
  listFilters,
  ...props
}: SendSMSToLeadsSidebarProps) => {
  const { timezone } = useClubSettings();
  const { sendSMSToLeads, sendSMSToLeadsFilter } = useSendSMS();

  const onSubmit = useCallback(
    async ({
      marketing,
      message,
      isSchedule,
      date,
      time,
    }: SendSMSFormValues) => {
      const schedule =
        isSchedule && date && time && timezone
          ? formattedScheduleDate(date, time, timezone, SCHEDULE_TIME_FORMAT)
          : undefined;
      const smsPayload = {
        purpose: marketing,
        body: message,
        schedule,
      };

      if (areAllSelected) {
        await sendSMSToLeadsFilter({
          smsPayload,
          leadIdsToExclude: excluded,
          ...generateLeadFiltersForEmailAndBulkStatus({
            filters: listFilters,
          }),
        });
      } else {
        await sendSMSToLeads({
          smsPayload,
          leadIds: selected,
        });
      }

      onClose();
    },
    [
      timezone,
      areAllSelected,
      onClose,
      sendSMSToLeadsFilter,
      excluded,
      listFilters,
      sendSMSToLeads,
      selected,
    ],
  );

  return (
    <SendSMSSidebar
      {...props}
      onClose={onClose}
      onSubmit={onSubmit}
      recipient={`${recipients} ${pluralize("lead", "leads", recipients)}`}
      description="Send a SMS to the selected leads."
    />
  );
};

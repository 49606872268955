import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { leadSourceAsPublicQueryKeys } from "./leadSourceAsPublicQueryKeys";

export async function leadSourceListAsPublicQueryFn({
  api,
}: {
  api: ApiType;
}) {
  const result = await api.public.leadApi.findLeadSources();
  return result.data;
}

export function useLeadSourceListAsPublic({
  api,
}: {
  api: ApiType;
}) {
  const result = useQuery({
    queryKey: leadSourceAsPublicQueryKeys.list(),
    queryFn: () => leadSourceListAsPublicQueryFn({ api }),
    initialData: [],
  });
  return result;
}

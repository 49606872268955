import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  facilityListQueryFn,
  useMutationKisiGenerateSignatureKey,
  useQueryKisiGroupList,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { useContext, useEffect, useMemo, useState } from "react";

import { copyToClipboard } from "../../../helpers";
import { useApiResolverContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  Button,
  eagerSelectAll,
  LookingGlassIcon,
  MembershipSelect,
  PaginatedSelect,
  PlusCircleIcon,
  renderMultiSelectPlaceholder,
  Switch,
  TextInputWithButton,
} from "../../atoms";
import { WizardContext, WizardState } from "../StepWizard";
import {
  KisiGroupToFacilityPair,
  KisiGroupToMembershipPair,
} from "./KisiGroupToMembershipPair";

export function KisiIntegrationStep2() {
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { api } = useGymflowModels();
  const { data: kisiGroups } = useQueryKisiGroupList({ api, clubId });
  const generateSignatureKey = useMutationKisiGenerateSignatureKey({
    api,
  });
  const [signatureKey, setSignatureKey] = useState("");
  const { wizardState, setWizardState } = useContext(WizardContext);
  const [validationError, setValidationError] = useState("");
  const [isMembershipEnabled, setIsMembershipEnabled] = useState(false);
  const [isFacilityEnabled, setIsFacilityEnabled] = useState(false);

  useEffect(() => {
    if (typeof wizardState["membershipToGroupPairs"] === "undefined") {
      setWizardState((prev: WizardState) => ({
        ...prev,
        membershipToGroupPairs: [],
      }));
    }
    if (typeof wizardState["facilityToGroupPairs"] === "undefined") {
      setWizardState((prev: WizardState) => ({
        ...prev,
        facilityToGroupPairs: [],
      }));
    }
  }, []);

  const membershipsAssigned = (wizardState["membershipToGroupPairs"] ??
    []) as KisiGroupToMembershipPair[];
  const isMembershipMapValid = membershipsAssigned.every((pair) => {
    return pair.kisiGroupId && pair.memberships.every((m) => m.membershipId);
  });

  const getDisabledMembershipIdsForGroupId = (
    kisiGroupId: string | null | undefined,
  ) => {
    return membershipsAssigned
      .filter((pair) => {
        return pair.kisiGroupId !== kisiGroupId;
      })
      .reduce(
        (acc, value) =>
          acc.concat(
            value.memberships
              .filter((m) => m.membershipId !== null)
              .map((m) => m.membershipId) as number[],
          ),
        [] as number[],
      );
  };

  const facilitiesAssigned = (wizardState["facilityToGroupPairs"] ??
    []) as KisiGroupToFacilityPair[];
  const isFacilityMapValid = facilitiesAssigned.every((pair) => {
    return pair.kisiGroupId && pair.facilities.every((m) => m.facilityId);
  });

  useEffect(() => {
    if (wizardState.isWizardStepValid === false && !isMembershipMapValid) {
      setValidationError(
        "There is a problem with the chosen configuration. Make sure all memberships have access groups selected.",
      );
      return;
    }
    setValidationError("");
  }, [wizardState.isWizardStepValid]);

  const apiResolver = useApiResolverContext();

  const webhookUrl = useMemo(() => {
    return `${apiResolver.urls.api}/webhook/kisi/access/check-in`;
  }, [apiResolver.urls.api]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div>
          <div className="font-semibold">Grant access to members</div>
          <div className="text-sm text-gray-500">
            We'll automatically grant access to memberships with an ACTIVE
            status only.
          </div>
        </div>
        <div>
          <Switch
            value={isMembershipEnabled}
            onChange={(checked) => {
              setIsMembershipEnabled(checked);
              if (checked) {
                setWizardState((prev: WizardState) => ({
                  ...prev,
                  membershipToGroupPairs: [
                    {
                      memberships: [],
                      kisiGroupId: null,
                      kisiGroupName: null,
                    },
                  ],
                }));
              } else {
                setWizardState((prev: WizardState) => ({
                  ...prev,
                  membershipToGroupPairs: [],
                }));
              }
            }}
          />
        </div>
      </div>
      <div className={cn("flex gap-4", { hidden: !isMembershipEnabled })}>
        <div className="flex flex-1 flex-col">
          <div className="text-sm font-semibold text-gray-700">Membership</div>
          <div className="text-sm text-gray-600">
            Select the membership you want associated with an access group.
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="text-sm font-semibold text-gray-700">
            Access Group
          </div>
          <div className="text-sm text-gray-600">
            Select the access group you want associated with the membership.
          </div>
        </div>
        <div>{/* Intentionally blank */}</div>
      </div>

      {membershipsAssigned.map((kisiLine, idx) => {
        return (
          <div
            className={cn("flex items-center gap-4", {
              hidden: !isMembershipEnabled,
            })}
            key={kisiLine.kisiGroupId}
          >
            <div className="flex flex-1 flex-col">
              <MembershipSelect
                value={
                  kisiLine.memberships.length > 0
                    ? kisiLine.memberships.map((membership) => {
                        return {
                          label: membership.membershipName,
                          value: membership.membershipId,
                        };
                      })
                    : null
                }
                isMulti
                showSelectAll
                selectAllClick={eagerSelectAll}
                onChange={(option) => {
                  const newAssigned = membershipsAssigned.slice(0);
                  newAssigned[idx].memberships = option.map(
                    ({ value, label }) => {
                      return {
                        membershipName: label,
                        membershipId: value,
                      };
                    },
                  );
                  setWizardState({
                    ...wizardState,
                    membershipToGroupPairs: newAssigned,
                  });
                }}
                disableMembershipIds={getDisabledMembershipIdsForGroupId(
                  kisiLine.kisiGroupId,
                )}
                isSearchable
                placeholder={renderMultiSelectPlaceholder({
                  value: kisiLine.memberships.map((membership) => {
                    return {
                      label: membership.membershipName ?? "",
                    };
                  }),
                  noValuePlaceholder: "Select Membership",
                })}
              />
            </div>
            <div className="flex flex-1 flex-col">
              <div>
                <PaginatedSelect
                  loadOptions={() =>
                    Promise.resolve({
                      options:
                        kisiGroups?.map(({ name, id }) => ({
                          label: name,
                          value: id,
                          isDisabled: !!facilitiesAssigned.find(
                            (f) => f.kisiGroupId === id,
                          ),
                        })) || [],
                    })
                  }
                  onChange={({
                    value,
                    label,
                  }: {
                    value: string;
                    label: string;
                  }) => {
                    const newAssigned = membershipsAssigned.slice(0);
                    newAssigned[idx].kisiGroupName = label;
                    newAssigned[idx].kisiGroupId = value;
                    setWizardState({
                      ...wizardState,
                      membershipToGroupPairs: newAssigned,
                    });
                  }}
                  value={
                    kisiLine.kisiGroupId
                      ? {
                          value: { id: kisiLine.kisiGroupId },
                          label: kisiLine.kisiGroupName,
                        }
                      : null
                  }
                  placeholder="Select Kisi Group"
                />
              </div>
            </div>
            <div>
              <FontAwesomeIcon
                onClick={() => {
                  const newAssigned = membershipsAssigned.slice(0);
                  newAssigned.splice(idx, 1);
                  setWizardState({
                    ...wizardState,
                    membershipToGroupPairs: newAssigned,
                  });
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
          </div>
        );
      })}
      <div
        className={cn("mx-2 flex flex-row-reverse", {
          hidden: !isMembershipEnabled,
        })}
      >
        <Button
          className="mt-2"
          onClick={() => {
            const newAssigned = membershipsAssigned.slice(0);
            newAssigned.push({
              memberships: [],
              kisiGroupId: null,
              kisiGroupName: null,
            });
            setWizardState({
              ...wizardState,
              membershipToGroupPairs: newAssigned,
            });
          }}
          disabled={!isMembershipMapValid}
        >
          <PlusCircleIcon
            className="mr-2 self-center"
            pathClassName="stroke-gray-500"
          />
          <div>Add New</div>
        </Button>
      </div>
      <div className="text-error-500 mt-2">{validationError}</div>

      <div className="flex items-center justify-between">
        <div>
          <div className="font-semibold">
            Grant access for appointment bookings
          </div>
          <div className="text-sm text-gray-500">
            We'll automatically give access 15 mins before a booked appointments
            to the facility that was booked.
          </div>
        </div>
        <div>
          <Switch
            value={isFacilityEnabled}
            onChange={(checked) => {
              setIsFacilityEnabled(checked);
              if (checked) {
                setWizardState((prev: WizardState) => ({
                  ...prev,
                  facilityToGroupPairs: [
                    {
                      facilities: [],
                      kisiGroupId: null,
                      kisiGroupName: null,
                    },
                  ],
                }));
              } else {
                setWizardState((prev: WizardState) => ({
                  ...prev,
                  facilityToGroupPairs: [],
                }));
              }
            }}
          />
        </div>
      </div>
      <div className={cn("flex gap-4", { hidden: !isFacilityEnabled })}>
        <div className="flex flex-1 flex-col">
          <div className="text-sm font-semibold text-gray-700">Facility</div>
          <div className="text-sm text-gray-600">
            Select the facility you want associated with an access group.
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="text-sm font-semibold text-gray-700">
            Access Group
          </div>
          <div className="text-sm text-gray-600">
            Select the access group you want associated with the facility.
          </div>
        </div>
        <div>{/* Intentionally blank */}</div>
      </div>

      {facilitiesAssigned.map((kisiLine, idx) => {
        return (
          <div
            className={cn("flex items-center gap-4", {
              hidden: !isFacilityEnabled,
            })}
            key={kisiLine.kisiGroupId}
          >
            <div className="flex flex-1 flex-col">
              <PaginatedSelect
                value={
                  kisiLine.facilities.length > 0
                    ? kisiLine.facilities.map((facility) => {
                        return {
                          label: facility.facilityName,
                          value: facility.facilityId,
                        };
                      })
                    : null
                }
                loadOptions={async (term, __, additional) => {
                  const facilityList = await facilityListQueryFn({
                    api,
                    opts: {
                      page: additional.page,
                      extraParams: { status: "ACTIVE", name: term },
                    },
                  });
                  return {
                    options: facilityList.content.map((facility) => {
                      return {
                        label: facility.name,
                        value: facility.id,
                      };
                    }),
                    hasMore: !facilityList.last,
                    additional: {
                      page: additional.page + 1,
                    },
                  };
                }}
                isMulti
                showSelectAll
                selectAllClick={eagerSelectAll}
                onChange={(option) => {
                  const newAssigned = facilitiesAssigned.slice(0);
                  newAssigned[idx].facilities = option.map(
                    ({ value, label }: { value: number; label: string }) => {
                      return {
                        facilityName: label,
                        facilityId: value,
                      };
                    },
                  );
                  setWizardState({
                    ...wizardState,
                    facilityToGroupPairs: newAssigned,
                  });
                }}
                isSearchable
                placeholder={renderMultiSelectPlaceholder({
                  value: kisiLine.facilities.map((facility) => {
                    return {
                      label: facility.facilityName ?? "",
                    };
                  }),
                  noValuePlaceholder: "Select Facility",
                })}
                icon={
                  <LookingGlassIcon
                    className="h-9 w-9"
                    pathClassName="stroke-grey-600"
                  />
                }
              />
            </div>
            <div className="flex flex-1 flex-col">
              <div>
                <PaginatedSelect
                  loadOptions={() =>
                    Promise.resolve({
                      options:
                        kisiGroups?.map(({ name, id }) => ({
                          label: name,
                          value: id,
                          isDisabled: !!membershipsAssigned.find(
                            (m) => m.kisiGroupId === id,
                          ),
                        })) || [],
                    })
                  }
                  onChange={({
                    value,
                    label,
                  }: {
                    value: string;
                    label: string;
                  }) => {
                    const newAssigned = facilitiesAssigned.slice(0);
                    newAssigned[idx].kisiGroupName = label;
                    newAssigned[idx].kisiGroupId = value;
                    setWizardState({
                      ...wizardState,
                      facilityToGroupPairs: newAssigned,
                    });
                  }}
                  value={
                    kisiLine.kisiGroupId
                      ? {
                          value: { id: kisiLine.kisiGroupId },
                          label: kisiLine.kisiGroupName,
                        }
                      : null
                  }
                  placeholder="Select Kisi Group"
                />
              </div>
            </div>
            <div>
              <FontAwesomeIcon
                onClick={() => {
                  const newAssigned = facilitiesAssigned.slice(0);
                  newAssigned.splice(idx, 1);
                  setWizardState({
                    ...wizardState,
                    facilityToGroupPairs: newAssigned,
                  });
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
          </div>
        );
      })}
      <div
        className={cn("mx-2 flex flex-row-reverse", {
          hidden: !isFacilityEnabled,
        })}
      >
        <Button
          className="mt-2"
          onClick={() => {
            const newAssigned = facilitiesAssigned.slice(0);
            newAssigned.push({
              facilities: [],
              kisiGroupId: null,
              kisiGroupName: null,
            });
            setWizardState({
              ...wizardState,
              facilityToGroupPairs: newAssigned,
            });
          }}
          disabled={!isFacilityMapValid}
        >
          <PlusCircleIcon
            className="mr-2 self-center"
            pathClassName="stroke-gray-500"
          />
          <div>Add New</div>
        </Button>
      </div>
      <div className="text-error-500 mt-2">{validationError}</div>
      <div className="flex items-center justify-between">
        <div>
          <div className="font-semibold">Automatic Check Ins</div>
          <div className="text-sm text-gray-500">
            Check members into the club, classes, appointments and get access
            logs in Gymflow every time a member enters using Kisi.
          </div>
        </div>
        <div>
          <Switch
            value={!!signatureKey}
            onChange={async (checked) => {
              if (checked) {
                const key = await generateSignatureKey.mutateAsync({ clubId });
                setSignatureKey(key.secretKey);
              } else {
                setSignatureKey("");
              }
            }}
          />
        </div>
      </div>
      <div className={cn("flex flex-col gap-2", { hidden: !signatureKey })}>
        <div className="text-sm font-semibold">Webhook URL</div>
        <div>
          <TextInputWithButton
            onButtonClick={() => {
              copyToClipboard(webhookUrl);
            }}
            buttonText="Copy URL"
            reactiveContent="Copied"
            value={webhookUrl}
          />
        </div>
      </div>
      <div className={cn("flex flex-col gap-2", { hidden: !signatureKey })}>
        <div className="text-sm font-semibold">Signature Key</div>
        <div>
          <TextInputWithButton
            onButtonClick={() => {
              copyToClipboard(signatureKey);
            }}
            buttonText="Copy Key"
            reactiveContent="Copied"
            value={signatureKey}
          />
        </div>
      </div>
    </div>
  );
}

import {
  ApiListResponse,
  AvailabilityByHostCalculationResponse,
  AvailabilityCalculationResult,
  AvailabilityOverrideDTO,
  AvailabilityOverridePostDTO,
  AvailabilitySlot,
  FacilityAvailabilityDTO,
  FacilityAvailabilitySlot,
  HostAvailabilityDTO,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

export type AvailabilityApi = ReturnType<typeof availabilityApi>;

const availabilityApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  return {
    hostAvailability(staffId: string) {
      return axiosInstance.get<HostAvailabilityDTO>(
        `${clubPrefix}availability/host/${staffId}`,
      );
    },
    saveHostAvailability(
      staffId: string,
      params: {
        availableForAppointments: boolean;
        availabilitySlotList: AvailabilitySlot[];
      },
    ) {
      return axiosInstance.post<HostAvailabilityDTO>(
        `${clubPrefix}availability/host/${staffId}`,
        params,
      );
    },
    listHostOverride(
      staffId: string,
      params: {
        readonly page?: number;
        readonly size?: number;
        readonly sort?: string;
        readonly overrideDate?: string;
        readonly unpaged?: boolean;
        readonly isUnavailableAllDay?: boolean;
        readonly startTime?: string;
        readonly endTime?: string;
        readonly id?: number[];
      },
    ) {
      return axiosInstance.get<ApiListResponse<AvailabilityOverrideDTO>>(
        `${clubPrefix}availability/host/${staffId}/override`,
        { params },
      );
    },
    createHostOverride(
      staffId: string,
      params: {
        isUnavailableAllDay: boolean;
        startTime: string;
        endTime: string;
        overrideDate: string;
      },
    ) {
      return axiosInstance.post<AvailabilityOverrideDTO>(
        `${clubPrefix}availability/host/${staffId}/override`,
        params,
      );
    },
    hostOverride(staffId: string, overrideId: number) {
      return axiosInstance.get<AvailabilityOverrideDTO>(
        `${clubPrefix}availability/host/${staffId}/override/${overrideId}`,
      );
    },
    deleteHostOverride(staffId: string, overrideId: number) {
      return axiosInstance.delete(
        `${clubPrefix}availability/host/${staffId}/override/${overrideId}`,
      );
    },
    updateHostOverride(
      staffId: string,
      overrideId: number,
      patchedFields: Partial<AvailabilityOverridePostDTO>,
    ) {
      return axiosInstance.patch<AvailabilityOverrideDTO>(
        `${clubPrefix}availability/host/${staffId}/override/${overrideId}`,
        patchedFields,
      );
    },
    hostCalculateAvailabilityMultipleDays(params: {
      appointableId?: number;
      dateFrom: string;
      appointableHostId?: string;
      numberOfDays?: number;
      ignoreAppointments?: boolean;
    }) {
      return axiosInstance.post<AvailabilityCalculationResult[]>(
        `${clubPrefix}availability/host/calculate-multiple-days`,
        params,
      );
    },
    hostCalculateAvailabilityMappedByHost(params: {
      dateFrom: string;
      dateTo: string;
      appointableHostsIdList?: string[];
    }) {
      return axiosInstance.post<AvailabilityByHostCalculationResponse>(
        `${clubPrefix}availability/host/calculate-by-host`,
        params,
      );
    },
    facilityAvailability(facilityId: number) {
      return axiosInstance.get<FacilityAvailabilityDTO>(
        `${clubPrefix}availability/facility/${facilityId}`,
      );
    },
    saveFacilityAvailability(
      facilityId: number,
      params: {
        availableForAppointments: boolean;
        availabilitySlotList: FacilityAvailabilitySlot[];
      },
    ) {
      return axiosInstance.post<FacilityAvailabilityDTO>(
        `${clubPrefix}availability/facility/${facilityId}`,
        params,
      );
    },
    facilityOverride(facilityId: number, overrideId: number) {
      return axiosInstance.get<AvailabilityOverrideDTO>(
        `${clubPrefix}availability/facility/${facilityId}/override/${overrideId}`,
      );
    },
    createFacilityOverride(
      facilityId: number,
      params: {
        isUnavailableAllDay: boolean;
        startTime: string;
        endTime: string;
        overrideDate: string;
      },
    ) {
      return axiosInstance.post<AvailabilityOverrideDTO>(
        `${clubPrefix}availability/facility/${facilityId}/override`,
        params,
      );
    },
    deleteFacilityOverride(facilityId: number, overrideId: number) {
      return axiosInstance.delete(
        `${clubPrefix}availability/facility/${facilityId}/override/${overrideId}`,
      );
    },
    updateFacilityOverride(
      facilityId: number,
      overrideId: number,
      patchedFields: Partial<AvailabilityOverridePostDTO>,
    ) {
      return axiosInstance.patch<AvailabilityOverrideDTO>(
        `${clubPrefix}availability/facility/${facilityId}/override/${overrideId}`,
        patchedFields,
      );
    },
    listFacilityOverride(
      facilityId: number,
      params: {
        page?: number;
        size?: number;
        sort?: string;
        overrideDate?: string;
        unpaged?: boolean;
        isUnavailableAllDay?: boolean;
        startTime?: string;
        endTime?: string;
        id?: string;
      },
    ) {
      return axiosInstance.get<ApiListResponse<AvailabilityOverrideDTO>>(
        `${clubPrefix}availability/facility/${facilityId}/override`,
        { params },
      );
    },
    facilityCalculateAvailability(params: {
      appointableId: number;
      dateFrom: string;
      dateTo: string;
      appointableHostsIdList?: string[];
      appointableFacilityIdList?: number[];
    }) {
      return axiosInstance.post<AvailabilityCalculationResult[]>(
        `${clubPrefix}availability/facility/calculate`,
        params,
      );
    },
    facilityCalculateAvailabilityMappedByFacility(params: {
      dateFrom: string;
      dateTo: string;
      appointableHostsIdList?: string[];
      appointableFacilityIdList?: number[];
    }) {
      return axiosInstance.post<AvailabilityByHostCalculationResponse>(
        `${clubPrefix}availability/facility/calculate-by-facility`,
        params,
      );
    },
  };
};

export default availabilityApi;

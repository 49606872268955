import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationHostAvailabilityOverrideCreate({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      staffId,
      newOverride,
    }: {
      staffId: Parameters<ApiType["availabilityApi"]["createHostOverride"]>[0];
      newOverride: Parameters<
        ApiType["availabilityApi"]["createHostOverride"]
      >[1];
    }) => {
      const response = await api.availabilityApi.createHostOverride(
        staffId,
        newOverride,
      );
      return response.data;
    },
    onSuccess: (_, { staffId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allHostOverrides(staffId),
      });
    },
  });

  return mutation;
}

import { useAutoAnimate } from "@formkit/auto-animate/react";
import { cn } from "@gymflow/helpers";
import { useClubSettings } from "apps/portal/src/providers";
import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ToggleModeButton } from "../../pages/Leads/ToggleModeButton";
import HostedPagesClassesDayCalendar from "./HostedPagesClassesDayCalendar";
import HostedPagesClassesDayList from "./HostedPagesClassesDayList";
import {
  HostedPagesClassesFilterPopover,
  HostedPagesClassesFilterPopoverProps,
} from "./HostedPagesClassesFilterPopover";
import WeekPicker from "./WeekPicker";

export type HostedPagesClassesProps = {};

const HostedPagesClasses = ({}: HostedPagesClassesProps) => {
  const settings = useClubSettings();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<
    HostedPagesClassesFilterPopoverProps["filter"]
  >({
    activityCategoryId: [],
    activityId: [],
    eventHostId: [],
    facilityId: [],
  });
  const [displayType, setDisplayType] = useState<"CALENDAR" | "LIST">(
    "CALENDAR",
  );
  const [selectedStartOfWeek, setSelectedStartOfWeek] = useState(
    DateTime.now().setZone(settings.timezone).startOf("week"),
  );

  const [parent] = useAutoAnimate();
  return (
    <div
      ref={parent}
      className="flex h-full max-h-full flex-col bg-gray-50 dark:bg-gray-800"
    >
      <div className="track-height flex flex-col gap-4 border-b border-gray-300 bg-[#ffffff] p-4 dark:border-gray-700 dark:bg-gray-950 lg:px-28">
        <div className="flex flex-row flex-wrap items-center justify-between gap-2">
          <div className="flex flex-row items-center gap-4">
            <div className="text-xl font-medium text-gray-950 dark:text-white">
              {t("page.hostedPagesClasses.title")}
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-2">
            <WeekPicker
              className={cn("max-lg:hidden transition-opacity", {
                "opacity-0": displayType === "LIST",
              })}
              selectedStartOfWeek={selectedStartOfWeek}
              onChange={setSelectedStartOfWeek}
            />
            <ToggleModeButton
              value={displayType === "LIST" ? "RIGHT" : "LEFT"}
              onChange={(newValue) =>
                setDisplayType(newValue === "RIGHT" ? "LIST" : "CALENDAR")
              }
            />
            <HostedPagesClassesFilterPopover
              filter={filter}
              onChange={setFilter}
            />
          </div>
          {displayType === "CALENDAR" && (
            <WeekPicker
              className={cn("lg:hidden")}
              selectedStartOfWeek={selectedStartOfWeek}
              onChange={setSelectedStartOfWeek}
            />
          )}
        </div>
      </div>
      {displayType === "CALENDAR" && (
        <HostedPagesClassesDayCalendar
          setFilter={setFilter}
          filter={filter}
          selectedStartOfWeek={selectedStartOfWeek}
        />
      )}
      {displayType === "LIST" && (
        <HostedPagesClassesDayList setFilter={setFilter} filter={filter} />
      )}
    </div>
  );
};

export default HostedPagesClasses;

import "react-image-crop/dist/ReactCrop.css";

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactCrop, { Crop } from "react-image-crop";

import { ModalContext, ModalWrapper } from "../../providers";
import { Button } from "./Button";
import {
  DangerIcon,
  EditIcon,
  PlusIcon,
  TrashIcon,
  UploadCloudIcon,
} from "./icons";

const MAX_SIZE_IN_BYTES = 2000000; // 2MB
const MAX_VERTICE_SIZE_IN_PX = 400;

export function ImageInputWithModal({
  accept,
  allowedExtensions,
  onChange,
  onDelete,
  value,
  modalTitle,
}: {
  accept?: string;
  allowedExtensions?: string[];
  onChange: (newValue: Blob) => void;
  onDelete: () => void;
  value?: { url?: string; blob?: Blob };
  modalTitle: string;
}) {
  const { setModal, hide: hideModal } = useContext(ModalContext);
  const canvasRef = createRef<HTMLCanvasElement>();

  useEffect(() => {
    if (!canvasRef.current || !value) {
      return;
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = function () {
      if (ctx && canvasRef.current) {
        ctx.reset();
        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          canvas.width,
          canvas.height,
        );
      }
    };
    if (value.blob) {
      img.src = URL.createObjectURL(value.blob);
    } else if (value.url) {
      img.src = value.url;
    }
  }, [canvasRef, value]);

  const showModal = useCallback(() => {
    setModal(
      <CropModal
        onCancel={hideModal}
        onConfirm={(v) => {
          hideModal();
          onChange(v);
        }}
        title={modalTitle}
        accept={accept}
        allowedExtensions={allowedExtensions}
      />,
    );
  }, [accept, allowedExtensions, hideModal, modalTitle, setModal]);

  if (value) {
    return (
      <div className="flex items-end gap-3">
        <div className="flex h-[7.688rem] w-[7.688rem] overflow-hidden rounded-xl border border-gray-200">
          <canvas ref={canvasRef} className="h-[7.688rem] w-[7.688rem]" />
        </div>
        <Button
          className="w-[6.25rem] bg-white"
          size="small"
          onClick={showModal}
        >
          <div className="flex gap-1">
            <div>
              <EditIcon className="h-5 w-5" pathClassName="stroke-black" />
            </div>
            <div className="hidden text-black sm:block">Edit</div>
          </div>
        </Button>
        <Button
          className="ring-error-300 w-[6.25rem] bg-white"
          size="small"
          onClick={() => onDelete()}
        >
          <div className="flex gap-1">
            <div>
              <TrashIcon className="h-5 w-5" pathClassName="stroke-error-700" />
            </div>
            <div className="text-error-700 hidden sm:block">Delete</div>
          </div>
        </Button>
      </div>
    );
  }
  return (
    <div
      className="border-secondary-500 flex h-[7.688rem] w-[7.688rem] cursor-pointer items-center justify-center rounded border-2 border-dashed"
      onClick={showModal}
    >
      <div className="bg-secondary-25 flex h-11 w-11 items-center justify-center rounded-3xl">
        <PlusIcon
          className="h-[1.375rem] w-[1.375rem]"
          pathClassName="stroke-secondary-500"
        />
      </div>
    </div>
  );
}

function CropModal({
  onCancel,
  onConfirm,
  title,
  accept = "image/*",
  allowedExtensions = ["jpg", "jpeg", "png"],
}: {
  onCancel: () => void;
  onConfirm: (blob: Blob) => void;
  title: string;
  accept?: string;
  allowedExtensions?: string[];
}) {
  const fileInputRef = createRef<HTMLInputElement>();
  const [error, setError] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>(false);

  const [src, setSrc] = useState<{
    blobUrl: string;
    type: string;
    name: string;
  }>();
  const [crop, setCrop] = useState<Crop>({
    unit: "px",
    height: 150,
    width: 150,
    x: 0,
    y: 0,
    aspect: 1,
  });

  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 150,
    height: 150,
  });
  const [imageLoaded, setImageLoaded] = useState<HTMLImageElement>();
  const maxVerticeSizeInPx = useMemo(() => {
    if (!imageLoaded) {
      return MAX_VERTICE_SIZE_IN_PX;
    }
    if (imageLoaded.width < imageLoaded.height) {
      return imageLoaded.width > MAX_VERTICE_SIZE_IN_PX
        ? MAX_VERTICE_SIZE_IN_PX
        : imageLoaded.width;
    } else {
      return imageLoaded.height > MAX_VERTICE_SIZE_IN_PX
        ? MAX_VERTICE_SIZE_IN_PX
        : imageLoaded.height;
    }
  }, [imageLoaded]);

  const onChooseFile = useCallback(
    (file: File) => {
      const isExtensionValid = allowedExtensions.some((ext) =>
        file.name.toLowerCase().endsWith(ext),
      );
      if (!isExtensionValid) {
        setError("The image format is incorrect, choose another image.");
        setIsValid(false);
        return;
      }
      if (file.size > MAX_SIZE_IN_BYTES) {
        setError("Size of image is too big, please select another image.");
        setIsValid(false);
        return;
      }
      setIsValid(true);
      setError("");
      setSrc({
        blobUrl: window.URL.createObjectURL(file),
        type: file.type,
        name: file.name,
      });
    },
    [allowedExtensions],
  );
  return (
    <ModalWrapper
      className="w-[25rem] max-w-[25rem] sm:w-[25rem] sm:max-w-[25rem]"
      onCancel={onCancel}
    >
      <div className="flex justify-between">
        <div className="text-lg font-semibold">{title}</div>
        <div>
          <FontAwesomeIcon
            onClick={() => {
              onCancel();
            }}
            className="cursor-pointer text-xl text-gray-600"
            icon={faClose}
          />
        </div>
      </div>
      <div
        className="mt-6 flex min-h-[15.375rem] w-[22rem] items-center justify-center rounded-xl border border-gray-200"
        onDrop={(e) => {
          e.preventDefault();
          if (!fileInputRef.current || !!src) {
            return;
          }
          fileInputRef.current.files = e.dataTransfer.files;
          onChooseFile(e.dataTransfer.files[0]);
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        <div
          className="flex cursor-pointer flex-col items-center justify-center"
          onClick={
            !src
              ? () => {
                  fileInputRef.current?.click();
                }
              : undefined
          }
        >
          {!src && (
            <>
              <div className="bg-secondary-25 flex h-8 w-8 flex-col items-center justify-center rounded-3xl">
                <UploadCloudIcon pathClassName="stroke-secondary-700" />
              </div>

              <div className="mt-3 text-sm font-normal text-gray-500">
                <div className="text-secondary-500 inline-block font-semibold">
                  Click to upload
                </div>{" "}
                or drag and drop here
              </div>
              <div className="text-sm font-normal text-gray-500">
                PNG, JPG (max. 2MB)
              </div>
            </>
          )}
        </div>
        {src && (
          <ReactCrop
            className="max-w-[22rem]"
            src={src.blobUrl}
            onChange={(newCrop) => {
              setCrop(newCrop);
            }}
            onImageLoaded={(img) => {
              setImageLoaded(img);
              setIsValid(true);
            }}
            crop={crop}
            maxHeight={size.height}
            maxWidth={size.width}
            minHeight={size.height}
            minWidth={size.width}
            disabled={false}
            locked={true}
          />
        )}
      </div>
      <div className={cn("mt-2 flex flex-col", { hidden: !src })}>
        <div>
          <input
            className="w-100"
            type="range"
            min="50"
            max={maxVerticeSizeInPx}
            onChange={(e) => {
              const width = Number(e.currentTarget.value);
              const height = Number(e.currentTarget.value);
              setSize({
                width,
                height,
              });
              setCrop((s) => ({ ...s, width, height }));
            }}
          />
        </div>
        <div className="flex justify-between text-[0.75rem] font-medium text-gray-500">
          <div>Min</div>
          <div>Max</div>
        </div>
        <div
          className="mt-4 flex cursor-pointer items-center justify-center gap-2"
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          <UploadCloudIcon
            pathClassName="stroke-primary-600"
            className="h-5 w-5"
          />
          <div className="text-primary-600 text-sm font-semibold">
            Browse another image
          </div>
        </div>
      </div>
      <div
        className={cn("mt-3 flex items-center gap-2", {
          hidden: !error,
        })}
      >
        <div>
          <DangerIcon className="h-4 w-4" pathClassName="stroke-error-600" />
        </div>
        <div className="text-error-600 text-sm font-normal ">{error}</div>
      </div>
      <div className="mt-5 flex gap-3">
        <Button onClick={onCancel} className="flex-1">
          Cancel
        </Button>
        <Button
          intent="primary"
          className="flex-1"
          disabled={!isValid}
          onClick={async () => {
            if (imageLoaded) {
              const blob = await getCroppedImg(
                imageLoaded,
                crop,
                src!.name,
                src!.type,
              );
              onConfirm(blob);
            }
          }}
        >
          Add
        </Button>
      </div>
      <input
        type="file"
        className="hidden"
        ref={fileInputRef}
        accept={accept}
        onChange={(e) => {
          if (
            e.target.files &&
            e.target.files !== null &&
            e.target.files?.[0]
          ) {
            const file = e.target.files[0];
            onChooseFile(file);
          }
        }}
      />
    </ModalWrapper>
  );
}

function getCroppedImg(
  image: HTMLImageElement,
  crop: Crop,
  fileName: string,
  type: string,
): Promise<Blob> {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx!.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject();
          return;
        }
        // @ts-ignore
        blob.name = fileName;
        resolve(blob);
      },
      type,
      1,
    );
  });
}

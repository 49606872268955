import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useAppointableCategoriesAsPublic,
  useQueryActivityCategoryListAsPublic,
  useQueryCreditPackListAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { SessionPackDTO } from "@gymflow/types";
import { useApiResolverContext } from "apps/portal/src/providers";
import React, { useEffect, useMemo, useState } from "react";

import useGymflowModels from "../../../store";
import { Button, Spinner } from "../../atoms";
import { EmptyPage } from "../../atoms/EmptyPage";
import { SortPopover, SortType } from "../SortPopover";
import { HostedPagesCreditPackCard } from "./HostedPagesCreditPackCard";
import {
  CreditPackType,
  HostedPagesCreditPackFilterPopover,
  HostedPagesCreditPackFilterPopoverProps,
} from "./HostedPagesCreditPackFilterPopover";

export type HostedPagesCreditPackListProps = {};

export const HostedPagesCreditPackList: React.FC<
  HostedPagesCreditPackListProps
> = ({}) => {
  const { reinitializeWithAuth } = useApiResolverContext();
  useEffect(() => {
    reinitializeWithAuth();
  }, [reinitializeWithAuth]);
  const { api } = useGymflowModels();
  const [filter, setFilter] = useState<
    HostedPagesCreditPackFilterPopoverProps["filter"]
  >({
    appointableCategories: [],
    classCategories: [],
  });

  const [sort, setSort] = useState<SortType | undefined>("ASC");
  const [currentPacks, setCurrentPacks] = useState<CreditPackType>("CLASSES");
  const { data } = useQueryCreditPackListAsPublic({
    api,
    opts: {
      ...(currentPacks === "CLASSES"
        ? {
            hasActivityCategory: true,
          }
        : {}),
      ...(currentPacks === "APPOINTMENTS"
        ? {
            hasAppointableCategory: true,
          }
        : {}),
      status: "ACTIVE",
      size: 200,
      page: 0,
    },
  });
  const { data: appointableCategories } = useAppointableCategoriesAsPublic({
    api,
    paginationOption: { page: 0, size: 200 },
    filter: {
      statusList: ["ACTIVE"],
    },
  });
  const { data: classCategories } = useQueryActivityCategoryListAsPublic({
    api,
    filter: {
      size: 200,
      statusList: ["ACTIVE"],
    },
  });
  const filteredSortedPacks = useMemo(() => {
    return data?.content
      .filter((e) => {
        if (currentPacks === "APPOINTMENTS") {
          return (
            filter.appointableCategories.length === 0 ||
            e.appointableCategoryIdList.some((id) =>
              filter.appointableCategories.includes(id),
            )
          );
        } else {
          return (
            filter.classCategories.length === 0 ||
            e.activityCategoryIdList.some((id) =>
              filter.classCategories.includes(id),
            )
          );
        }
      })
      .sort((a, b) => {
        if (!sort) return 0;
        if (sort === "ASC") {
          return a.price - b.price;
        } else {
          return b.price - a.price;
        }
      });
  }, [
    currentPacks,
    data?.content,
    filter.appointableCategories,
    filter.classCategories,
    sort,
  ]);
  const creditPacksToShow = useMemo(() => {
    const categories =
      currentPacks === "APPOINTMENTS"
        ? appointableCategories.content
        : classCategories.content;
    const idToCategoryName = categories.reduce(
      (acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
      },
      {} as Record<number, string>,
    );
    const groups = filteredSortedPacks?.reduce(
      (acc, curr) => {
        const packCategories =
          currentPacks === "APPOINTMENTS"
            ? curr.appointableCategoryIdList
            : curr.activityCategoryIdList;
        for (const key of packCategories) {
          acc[key] ??= {
            name: idToCategoryName[key],
            packs: [] as SessionPackDTO[],
          };
          acc[key].packs.push(curr);
        }
        return acc;
      },
      {} as Record<string, { name: string; packs: SessionPackDTO[] }>,
    );
    return groups;
  }, [
    appointableCategories.content,
    classCategories.content,
    currentPacks,
    filteredSortedPacks,
  ]);
  const [parent] = useAutoAnimate();
  return (
    <div className="flex h-full max-h-full flex-col bg-gray-50 dark:bg-gray-800">
      <div className="track-height flex flex-col gap-4 border-b border-gray-300 bg-[#ffffff] p-4 dark:border-gray-700 dark:bg-gray-950 lg:px-28">
        <div className="flex flex-row flex-wrap items-center justify-between gap-2">
          <div className="flex flex-row items-center gap-4">
            <div className="text-xl font-medium text-gray-950 dark:text-white">
              Credit Packs
            </div>
            <div className="hidden flex-row items-center gap-2 lg:flex">
              <Button
                size="small"
                className={cn("text-sm transition-all", {
                  "!border-secondary-500 !text-secondary-500":
                    currentPacks === "CLASSES",
                })}
                onClick={() => setCurrentPacks("CLASSES")}
              >
                Classes
              </Button>
              <Button
                size="small"
                className={cn("text-sm transition-all", {
                  "!border-secondary-500 !text-secondary-500":
                    currentPacks === "APPOINTMENTS",
                })}
                onClick={() => setCurrentPacks("APPOINTMENTS")}
              >
                Appointments
              </Button>
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <SortPopover onChange={setSort} value={sort} />
            <HostedPagesCreditPackFilterPopover
              filter={filter}
              onChange={setFilter}
              type={currentPacks}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2 lg:hidden">
          <Button
            size="small"
            className={cn("text-sm transition-all flex-1", {
              "!border-secondary-500 !text-secondary-500":
                currentPacks === "CLASSES",
            })}
            onClick={() => setCurrentPacks("CLASSES")}
          >
            Classes
          </Button>
          <Button
            size="small"
            className={cn("text-sm transition-all flex-1", {
              "!border-secondary-500 !text-secondary-500":
                currentPacks === "APPOINTMENTS",
            })}
            onClick={() => setCurrentPacks("APPOINTMENTS")}
          >
            Appointments
          </Button>
        </div>
      </div>

      <div
        ref={parent}
        className="track-height flex min-h-[16rem] flex-col gap-4 overflow-y-auto overflow-x-hidden bg-gray-50 p-4 dark:bg-gray-800 lg:px-28 lg:pt-6"
      >
        {creditPacksToShow === undefined && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        )}
        {filteredSortedPacks?.length === 0 && (
          <div className="absolute inset-0 flex items-center justify-center">
            <EmptyPage
              action={
                (currentPacks === "APPOINTMENTS" &&
                  filter.appointableCategories.length > 0) ||
                (currentPacks === "CLASSES" &&
                  filter.classCategories.length > 0) ? (
                  <Button
                    onClick={() => {
                      setFilter({
                        appointableCategories: [],
                        classCategories: [],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                ) : undefined
              }
            />
          </div>
        )}
        {creditPacksToShow &&
          Object.entries(creditPacksToShow).map(([key, value]) => (
            <CreditPackRow key={key} name={value.name} packs={value.packs} />
          ))}
      </div>
    </div>
  );
};

const CreditPackRow = ({
  name,
  packs,
}: {
  name: string;
  packs: SessionPackDTO[];
}) => {
  const [parent] = useAutoAnimate();
  return (
    <div className="flex flex-col gap-3">
      <div className="font-semibold text-gray-500">{name}</div>
      <div
        className="relative grid content-start gap-3 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-8"
        ref={parent}
      >
        {packs?.map((e) => (
          <HostedPagesCreditPackCard key={e.id} creditPack={e} />
        ))}
      </div>
    </div>
  );
};

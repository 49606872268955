import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memberQueryFn, useMember } from "@gymflow/api";
import { useMutationMemberSale } from "@gymflow/api/lib/hooks/member/useMutationMemberSale";
import {
  PaymentConfirmationStatus,
  useParseErrors,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import { LUXON_DATE_FORMAT } from "@gymflow/helpers";
import { InvoiceDTO, UserMemberBean } from "@gymflow/types";
import { Formik, useFormikContext } from "formik";
import { DateTime } from "luxon";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { usePortalRoutes, useValidateMemberDetails } from "../../../hooks";
import { useClubSettings } from "../../../providers";
import { ToastContext } from "../../../providers/ToastProvider/context";
import useGymflowModels from "../../../store";
import { UserMemberPage } from "../../../views/UserMember";
import {
  Button,
  SlideSideBar,
  Spinner,
  UsersSearchInput,
  UsersSearchInputOptionsType,
  UsersSearchInputOptionType,
} from "../../atoms";
import { CollapsibleSections, SideBarUserForm } from "../../molecules";
import { ShopCheckout } from "../ShopCheckout";
import { ShopItemPicker } from "../ShopItemPicker";
import { ShopCartType } from "../ShopTypes";
import { ShopSidebarContext } from "./ShopSidebarContext";
import { defaultValuesNormalizer } from "./utils/defaultValuesNormalizer";

export const ShopSidebarSidebarProvider: React.FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const { t } = useTranslation();
  const [sidebarState, setSidebarState] = useState<
    | {
        isVisible: boolean;
        currentShopTab?: number;
      }
    | undefined
  >();
  const [areFurtherDetailsNeeded, setAreFurtherDetailsNeeded] = useState(false);
  const [isUserFormShown, setIsUserFormShown] = useState(false);
  const { api } = useGymflowModels();
  const clubSettings = useClubSettings();
  const hide = useCallback(() => {
    setSidebarState(undefined);
    setIsUserFormShown(false);
    setSelectedUser(undefined);
    setCart(undefined);
    setAreFurtherDetailsNeeded(false);
    setCurrentPaymentMethodId(undefined);
    setPromotionCode(undefined);
    setStartDate(
      DateTime.now()
        .setZone(clubSettings.timezone)
        .startOf("day")
        .toFormat(LUXON_DATE_FORMAT),
    );
    setAllowChangingStartDate(true);
    setOpenSectionIdx(0);
  }, [clubSettings.timezone]);
  const [selectedUser, setSelectedUser] =
    useState<UsersSearchInputOptionType>();
  const selectedUserId = selectedUser?.value.id;
  const selectedUserProfileType = selectedUser?.value.profileType;
  const [cart, setCart] = useState<ShopCartType>();

  const cartHasSomething =
    cart?.type === "MEMBERSHIP" ||
    cart?.type === "CREDIT_PACK" ||
    cart?.payload?.some((e) => e.quantity > 0);
  const [currentPaymentMethodId, setCurrentPaymentMethodId] =
    useState<string>();
  const [promotionCode, setPromotionCode] = useState<string>();
  const [startDate, setStartDate] = useState<string>(
    DateTime.now()
      .setZone(clubSettings.timezone)
      .startOf("day")
      .toFormat(LUXON_DATE_FORMAT),
  );
  const [allowChangingStartDate, setAllowChangingStartDate] =
    useState<boolean>(true);
  const { data: userMember, isFetching: isFetchingUserMember } = useMember({
    api,
    memberId: selectedUserProfileType === "USER" ? selectedUserId : undefined,
    tz: clubSettings.timezone,
  });
  const userHasActiveMembershipSubscription =
    userMember?.user.subscriptions.some(
      (e) =>
        !!e.membershipBean &&
        (e.active || e.status === "OVERDUE" || e.status === "PENDING"),
    );

  const { validateMemberDetails, requiredFields, isLoadingRequiredFields } =
    useValidateMemberDetails({
      sellerUserType: "STAFF",
    });

  // just "copy-past"; will change it with new design
  const onMemberCreated = useCallback(
    async (newMemberId: string) => {
      if (!newMemberId) return;

      const newMember = await memberQueryFn({
        memberId: newMemberId,
        api,
      });
      setSelectedUser({
        id: newMember.id,
        label: `${newMember.firstName} ${newMember.lastName}`,
        subLabel: newMember.email,
        value: newMember,
        imageUrl: newMember.picture,
      });

      setOpenSectionIdx(1);
      setCart(undefined);
      setIsUserFormShown(false);
      setAreFurtherDetailsNeeded(false);
    },
    [api],
  );

  const userSelected = useCallback(
    async (selected: UserMemberBean) => {
      const needsFurtherDetails = !(await validateMemberDetails(selected));

      setAreFurtherDetailsNeeded(needsFurtherDetails);
      if (!needsFurtherDetails) {
        setOpenSectionIdx(1);
      }
    },
    [validateMemberDetails],
  );

  const onChangeUsersSearchInput = useCallback(
    async (selected: UsersSearchInputOptionsType) => {
      const selectedLocal = selected[0];
      setSelectedUser(selectedLocal);
      setCart(undefined);
      setOpenSectionIdx(0);
      setIsUserFormShown(false);
      setAreFurtherDetailsNeeded(false);

      if (selectedLocal?.value.profileType === "LEAD") {
        return;
      } else if (
        typeof selectedLocal?.value.id === "string" &&
        selectedLocal?.value.profileType === "USER"
      ) {
        // if user id is string type -> it's UserMemberBean (we check it above)
        userSelected(selectedLocal?.value as UserMemberBean);
      }
    },
    [userSelected],
  );

  const buttonLabel = useMemo(() => {
    if (selectedUserProfileType === "LEAD") {
      return t("components.shopSidebarProvider.button.setupAccount");
    } else if (areFurtherDetailsNeeded) {
      return t("components.shopSidebarProvider.button.updateInfo");
    } else {
      return t("components.shopSidebarProvider.button.newUser");
    }
  }, [areFurtherDetailsNeeded, selectedUserProfileType, t]);

  const infoLabel = useMemo(() => {
    if (selectedUserProfileType === "LEAD") {
      return t("components.shopSidebarProvider.info.lead");
    } else if (areFurtherDetailsNeeded) {
      return t("components.shopSidebarProvider.info.needMoreDetails");
    } else {
      return "";
    }
  }, [areFurtherDetailsNeeded, selectedUserProfileType, t]);

  const sections = [
    {
      title: "Select User",
      body: (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center gap-2">
            <div className="flex-1">
              <UsersSearchInput
                withImageByDefault
                value={selectedUser ? [selectedUser] : []}
                onChange={onChangeUsersSearchInput}
                isMulti={false}
              />
            </div>
            <Button
              className="min-w-fit"
              onClick={() => setIsUserFormShown(true)}
            >
              {buttonLabel}
            </Button>
          </div>
          <div className="text-sm text-gray-600">{infoLabel}</div>
          {/* Temporary solution (just have the same form as in NewUserSideBar) before a new design */}
          {isUserFormShown && !isLoadingRequiredFields && (
            <SideBarUserForm
              className="px-0"
              hide={() => setIsUserFormShown(false)}
              onMemberCreated={onMemberCreated}
              edit={areFurtherDetailsNeeded}
              userBeforeUpdate={
                selectedUserProfileType === "USER" &&
                typeof selectedUserId === "string"
                  ? (selectedUser?.value as UserMemberBean) // if user id is string type -> it's UserMemberBean (we check it above)
                  : undefined
              }
              onChange={() => {
                // nothing
              }}
              defaultValues={defaultValuesNormalizer(
                selectedUser,
                selectedUserProfileType,
              )}
              requiredFields={requiredFields}
            />
          )}
        </div>
      ),
    },
    {
      title: "Select Item",
      body: (
        <div className="flex w-full flex-row items-center gap-2">
          {isFetchingUserMember && <Spinner />}
          {!isFetchingUserMember && (
            <ShopItemPicker
              userHasActiveMembershipSubscription={
                !!userHasActiveMembershipSubscription
              }
              cart={cart}
              setCart={(newCart, stayInSection) => {
                setCart(newCart);
                if (!stayInSection) {
                  setOpenSectionIdx(2);
                }
                if (
                  newCart.type === "MEMBERSHIP" &&
                  newCart.payload.fixedStartDate
                ) {
                  setStartDate(
                    DateTime.fromISO(newCart.payload.fixedStartDate, {
                      setZone: true,
                    })
                      .plus({ seconds: 1 })
                      .setZone(clubSettings.timezone)
                      .toFormat(LUXON_DATE_FORMAT),
                  );
                  setAllowChangingStartDate(false);
                } else {
                  setStartDate(
                    DateTime.now()
                      .setZone(clubSettings.timezone)
                      .startOf("day")
                      .toFormat(LUXON_DATE_FORMAT),
                  );
                  setAllowChangingStartDate(true);
                }
              }}
              hideShopSidebar={() => {
                setSidebarState((e) => ({
                  ...e,
                  isVisible: false,
                }));
              }}
              showShopSidebar={() => {
                setSidebarState((e) => ({
                  ...e,
                  isVisible: true,
                }));
              }}
              sidebarState={sidebarState}
              setSidebarState={setSidebarState}
            />
          )}{" "}
        </div>
      ),
      className: "p-0",
      disabled:
        !selectedUser ||
        areFurtherDetailsNeeded ||
        selectedUserProfileType === "LEAD",
    },
    {
      title: "Checkout",
      body: !!cart &&
        selectedUser &&
        typeof selectedUserId === "string" &&
        selectedUserProfileType === "USER" && (
          <ShopCheckout
            cart={cart}
            // if user id is string type -> it's UserMemberBean (we check it above)
            selectedUser={selectedUser.value as UserMemberBean}
            setSidebarState={setSidebarState}
            currentPaymentMethodId={currentPaymentMethodId}
            setCurrentPaymentMethodId={setCurrentPaymentMethodId}
            promotionCode={promotionCode}
            setPromotionCode={setPromotionCode}
            startDate={startDate}
            setStartDate={setStartDate}
            allowChangingStartDate={allowChangingStartDate}
          />
        ),
      disabled: !cart || !cartHasSomething,
    },
  ];
  const [openSectionIdx, setOpenSectionIdx] = useState<number>(0);

  const history = useHistory();
  const { createMemberLink } = usePortalRoutes();
  const { mutateAsync: checkout } = useMutationMemberSale({
    api,
    memberId: typeof selectedUserId === "string" ? selectedUserId : "",
    clubId: clubSettings.clubId,
    tz: clubSettings.timezone,
  });
  const parseError = useParseErrors();
  const { show: showPaymentConfirmationAlert } = usePaymentAuthorizationAlert();
  const { toast } = useContext(ToastContext);

  return (
    <ShopSidebarContext.Provider
      value={{
        showShop: async (user) => {
          if (user) {
            setSelectedUser({
              id: user.id,
              label: `${user.firstName} ${user.lastName}`,
              subLabel: user.email,
              value: user,
            });
            if (user.profileType === "USER" && typeof user.id === "string") {
              // if user id is string type -> it's UserMemberBean (we check it above)
              await userSelected(user as UserMemberBean);
            }
          }
          setSidebarState({
            isVisible: true,
          });
        },
      }}
    >
      {children}
      <SlideSideBar
        isOpen={!!sidebarState?.isVisible}
        hide={hide}
        className="!w-full lg:!w-1/2"
        isLoading={false}
      >
        <Formik
          initialValues={{ priceOverride: undefined }}
          validationSchema={toFormikValidationSchema(
            z.object({
              priceOverride: z.coerce.number({
                invalid_type_error: "Must include price",
                required_error: "Must include price",
              }),
            }),
          )}
          onSubmit={async (values) => {
            if (!cart) return;
            try {
              const priceOverride = values.priceOverride
                ? +values.priceOverride
                : undefined;
              let result: { invoice: InvoiceDTO };
              if (cart.type === "MEMBERSHIP") {
                result = await checkout({
                  paymentMethod: currentPaymentMethodId,
                  promotionCode: promotionCode,
                  membershipId: cart.payload.id,
                  startDate: startDate,
                  priceOverride,
                });
              } else if (cart.type === "CREDIT_PACK") {
                result = await checkout({
                  paymentMethod: currentPaymentMethodId,
                  promotionCode: promotionCode,
                  sessionPackId: cart.payload.id,
                  startDate: startDate,
                  priceOverride,
                });
              } else {
                result = await checkout({
                  paymentMethod: currentPaymentMethodId,
                  promotionCode: promotionCode,
                  products: cart.payload.map((e) => ({
                    productId: e.product.id,
                    quantity: e.quantity,
                  })),
                  priceOverride,
                });
              }
              hide();
              if (typeof selectedUserId === "string") {
                history.push(
                  createMemberLink(
                    selectedUserId,
                    cart.type === "MEMBERSHIP"
                      ? UserMemberPage.Account
                      : cart.type === "CREDIT_PACK"
                      ? UserMemberPage.Sessions
                      : UserMemberPage.Payments,
                  ),
                );
              }
              if (result.invoice.status === "AWAITING_AUTHORIZATION") {
                const paymentConfirmationResult =
                  await showPaymentConfirmationAlert({
                    paymentIntentIdForAuthorization:
                      result.invoice.paymentIntentIdForAuthorization,
                    confirmPayment:
                      api.strongCustomerAuthorizationApi.confirmPayment,
                    messageText: (
                      <>
                        The user must authorize this payment before it will be
                        processed.
                        <br />
                        Please ask the user to authorize the payment by clicking
                        the link sent to their email.
                      </>
                    ),
                  });
                if (
                  paymentConfirmationResult.status ===
                  PaymentConfirmationStatus.Waiting
                ) {
                  toast({
                    message: "Awaiting payment, check later.",
                    intent: "warning",
                  });
                } else if (
                  paymentConfirmationResult.status ===
                  PaymentConfirmationStatus.Failed
                ) {
                  toast({
                    message: "Payment Failed.",
                    intent: "error",
                  });
                }
              }
            } catch (e: any) {
              setSidebarState((e) => ({ ...e, isVisible: false }));
              await parseError(e?.response);
              setSidebarState((e) => ({ ...e, isVisible: true }));
            }
          }}
        >
          <div className="flex h-full max-h-full flex-col overflow-hidden">
            <div className="flex flex-col justify-between border-b border-gray-200 p-8">
              <div className="mb-1 flex flex-row items-center justify-between">
                <div className="text-xl font-semibold text-gray-900">
                  Sell Something
                </div>

                <FontAwesomeIcon
                  onClick={() => {
                    hide();
                  }}
                  className="cursor-pointer text-xl text-gray-600"
                  icon={faClose}
                />
              </div>
              <div className="text-sm font-medium text-gray-600">
                Sell a membership, credit pack or products.
              </div>
              {selectedUser && (
                <div className="text-sm font-medium text-gray-600">
                  Sale For:{" "}
                  <b>
                    {selectedUser.value.firstName} {selectedUser.value.lastName}
                  </b>
                  {cartHasSomething && cart
                    ? `, ${
                        cart.type === "MEMBERSHIP"
                          ? cart.payload.name
                          : cart.type === "CREDIT_PACK"
                          ? cart.payload.name
                          : cart.payload
                              .filter((e) => e.quantity > 0)
                              .map(
                                (e) =>
                                  `${e.quantity > 1 ? `${e.quantity} x ` : ""}${
                                    e.product.name
                                  }`,
                              )
                              .join(", ")
                      }`
                    : ""}
                </div>
              )}
            </div>
            <CollapsibleSections
              sections={sections}
              onChangeSection={(_, sectionIdx) => {
                setOpenSectionIdx(sectionIdx);
              }}
              openSectionIndex={openSectionIdx}
            />
            <div className="mt-auto flex h-20 w-full flex-row items-center justify-end gap-x-2 border-t border-gray-200 bg-gray-50 px-6 py-4">
              <Button
                className="flex-1"
                onClick={() => {
                  hide();
                }}
              >
                Cancel
              </Button>
              {cart && openSectionIdx === 2 && <TakePaymentButton />}
            </div>
          </div>
        </Formik>
      </SlideSideBar>
    </ShopSidebarContext.Provider>
  );
};
const TakePaymentButton: React.FC = () => {
  const formik = useFormikContext();
  return (
    <Button
      disabled={!formik.isValid}
      className="flex-1"
      intent="primary"
      onClick={async () => await formik.submitForm()}
    >
      Take Payment
    </Button>
  );
};

import { LeadDTO, UserMemberBean, UserProfileType } from "@gymflow/types";

import { UsersSearchInputOptionType } from "../../../atoms";

export const defaultValuesNormalizer = (
  selectedUser?: UsersSearchInputOptionType,
  selectedUserProfileType?: UserProfileType,
) => {
  if (!selectedUser) {
    return {};
  }

  const { value } = selectedUser;

  const isUserMemberBean = (
    obj: UserMemberBean | LeadDTO,
  ): obj is UserMemberBean =>
    "email" in obj && "firstName" in obj && typeof obj.id === "string";

  const isLeadDTO = (obj: UserMemberBean | LeadDTO): obj is LeadDTO =>
    "source" in obj && typeof obj.id === "number";

  const getSource = () => {
    if (selectedUserProfileType === "LEAD" && isLeadDTO(value)) {
      return {
        name: value.source?.name,
        id: value.source?.id,
      };
    }
    if (isUserMemberBean(value) && value.lead?.source) {
      return {
        name: value.lead.source?.name,
        id: value.lead.source?.id,
      };
    }
    return undefined;
  };

  if (isUserMemberBean(value)) {
    return {
      email: value.email,
      "first-name": value.firstName,
      "last-name": value.lastName,
      "mobile-number": value.mobileNumber,
      dateBirth: value.dateBirth,
      "post-code": value.postCode,
      addressLine1: value.addressLine1,
      addressLine2: value.addressLine2,
      city: value.city,
      country: value.country,
      gender: value.gender,
      "email-communication": value.emailCommunication,
      "sms-communication": value.smsCommunication,
      "push-communication": value.pushCommunication,
      "emergency-contact-name": value.emergencyContactName,
      "emergency-contact": value.emergencyContact,
      source: getSource(),
      "personal-number": value.personalNumber,
    };
  }

  if (isLeadDTO(value)) {
    return {
      email: value.email,
      "first-name": value.firstName,
      "last-name": value.lastName,
      "mobile-number": value.mobileNumber,
      "email-communication": value.emailCommunication,
      "sms-communication": value.smsCommunication,
      source: getSource(),
    };
  }

  // If nothing (it's not real but for unexpected case)
  return {};
};

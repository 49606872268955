import { useInfiniteQueryMemberTimeline } from "@gymflow/api";
import { useCallback, useContext, useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import usersIcon from "../../assets/img/users-icon.svg";
import PillTabsLayout from "../components/molecules/PillTabsLayout";
import { ActionMenuButtons } from "../components/SearchGlobal/ActionMenuButtons";
import { ShopSidebarContext } from "../components/Shop/ShopSidebar/ShopSidebarContext";
import LeadOverview from "../components/UserMember/LeadOverview";
import { UserMemberNotes } from "../components/UserMember/Notes/UserMemberNotes";
import Timeline from "../components/UserMember/Timeline/Timeline";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { PageTitleProviderContext, useClubSettings } from "../providers";
import useGymflowModels from "../store";

export const LeadProfilePage = {
  Profile: "/profile",
  Account: "/account",
  Sessions: "/sessions",
  Payments: "/payments",
  Notes: "/notes",
  Timeline: "/timeline",
};
function LeadProfile() {
  const { LeadStore, settingsStore, TaskStore, api } = useGymflowModels();
  const { createLeadLink } = usePortalRoutes();
  const match = useRouteMatch();
  const { id } = match.params;
  const settings = useClubSettings();

  const {
    clearEditingRecord,
    update: updateUser,
    create: createUser,
    changeLeadColumn,
    fetchById,
  } = LeadStore.useStoreActions((actions) => actions);
  const { editing, loadingRecord } = LeadStore.useStoreState((state) => state);
  const { setPageTitle } = useContext(PageTitleProviderContext);

  useEffect(() => {
    if (editing?.firstName && editing?.lastName) {
      setPageTitle({
        title: `${editing.firstName} ${editing.lastName}`,
        iconUrl: usersIcon,
      });
    }
  }, [setPageTitle, editing?.firstName, editing?.lastName]);

  const [timelineOpts, setTimelineOpts] = useState({
    nodeType: ["EMAIL", "LEADSTATUS", "SUBSCRIPTION", "TASK", "TRANSACTION"],
  });
  const {
    data: timeline,
    isFetching: isTimelineLoading,
    hasNextPage: hasMoreNodes,
    fetchNextPage: fetchMoreNodes,
  } = useInfiniteQueryMemberTimeline({
    api,
    tz: settings.timezone,
    opts: {
      extraParams: { leadId: id, nodeType: timelineOpts?.nodeType },
    },
  });
  const nodes = timeline?.pages.flatMap((p) => p.content) ?? [];

  const refetchTimeline = useCallback(({ nodeType }) => {
    setTimelineOpts({
      nodeType,
    });
  }, []);

  const { fetchById: fetchTask } = TaskStore.useStoreActions(
    (actions) => actions,
  );

  const clubId = settings.clubId;

  useEffect(() => {
    fetchById(id);
    return () => clearEditingRecord();
  }, [id, fetchById, clearEditingRecord]);

  const createUserAndRefresh = useCallback(
    async (newUser) => {
      await createUser({ clubId, ...newUser });
      return fetchById(id);
    },
    [createUser, fetchById, clubId, id],
  );

  const updateUserAndRefresh = useCallback(
    async (updatedUser) => {
      await updateUser({ clubId, ...updatedUser });
      return fetchById(id);
    },
    [updateUser, fetchById, clubId, id],
  );

  const updateLeadStatusAndRefresh = useCallback(
    async (updatedLeadStatus) => {
      await changeLeadColumn(updatedLeadStatus);
      return fetchById(id);
    },
    [changeLeadColumn, fetchById, id],
  );

  const layoutConfig = [
    {
      text: "Profile",
      path: createLeadLink(id, LeadProfilePage.Profile),
      component: LeadOverview,
      props: {
        user: editing,
        isLoading: loadingRecord,
        updateUser: updateUserAndRefresh,
        updateLeadStatus: updateLeadStatusAndRefresh,
        upgradeToFullUser: createUserAndRefresh,
      },
    },
    {
      text: "Notes",
      path: createLeadLink(id, LeadProfilePage.Notes),
      component: UserMemberNotes,
      props: {
        leadId: id,
        name: `${editing?.firstName} ${editing?.lastName}`,
      },
    },
    {
      text: "Timeline",
      path: createLeadLink(id, LeadProfilePage.Timeline),
      component: Timeline,
      props: {
        isLoading: isTimelineLoading,
        fetchNodes: fetchMoreNodes,
        value: nodes,
        isLast: !hasMoreNodes,
        refreshNodes: refetchTimeline,
      },
    },
  ];
  const { showShop } = useContext(ShopSidebarContext);
  if (!editing) {
    return null;
  }

  return (
    <Switch>
      <Route path={createLeadLink(id)}>
        <PillTabsLayout
          className="p-4 lg:p-8"
          tabs={layoutConfig}
          moreActions={
            <ActionMenuButtons
              leadId={id}
              showShop={showShop}
              showSMSSidebar={() => {
                setIsSMSSidebarVisible(true);
              }}
            />
          }
        />
      </Route>
    </Switch>
  );
}

const LeadProfileWithProvider = () => {
  const { LeadStore, TaskStore } = useGymflowModels();
  return (
    <LeadStore.Provider>
      <TaskStore.Provider>
        <LeadProfile />
      </TaskStore.Provider>
    </LeadStore.Provider>
  );
};

export default LeadProfileWithProvider;

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { DateTime, Interval } from "luxon";

import { Button } from "../../atoms";

export type WeekPickerProps = {
  className?: string;
  selectedStartOfWeek: DateTime;
  onChange: (newStartOfWeek: DateTime) => void;
};

const WeekPicker = ({
  onChange,
  selectedStartOfWeek,
  className,
}: WeekPickerProps) => {
  const currentInterval = Interval.fromDateTimes(
    selectedStartOfWeek,
    selectedStartOfWeek.plus({ weeks: 1 }),
  );
  return (
    <div className={cn("flex flex-row gap-2 max-lg:w-full", className)}>
      <Button
        disabled={selectedStartOfWeek < DateTime.now().startOf("day")}
        onClick={() => {
          onChange(selectedStartOfWeek.minus({ weeks: 1 }));
        }}
        className={cn("aspect-square p-0 transition-opacity", {
          "opacity-0": selectedStartOfWeek < DateTime.now().startOf("day"),
        })}
      >
        <FontAwesomeIcon icon={faAngleLeft} className="h-4 w-4 text-gray-500" />
      </Button>
      <div className="dark:border-darkGray-700 flex items-center justify-center rounded-[.625rem] border border-gray-300 px-4 text-gray-950 shadow-sm dark:text-[#ffffff] max-lg:w-full lg:min-w-[28rem]">
        {currentInterval.toLocaleString(DateTime.DATE_FULL)}
      </div>
      <Button
        onClick={() => {
          onChange(selectedStartOfWeek.plus({ weeks: 1 }));
        }}
        className="aspect-square p-0"
      >
        <FontAwesomeIcon
          icon={faAngleRight}
          className="h-4 w-4 text-gray-500"
        />
      </Button>
    </div>
  );
};

export default WeekPicker;

import { useInfiniteQueryLeadList, useQueryLeadStatus } from "@gymflow/api";
import { isMobile, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { utcToZonedTime } from "@gymflow/helpers";
import { LeadPipelineItem } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment-timezone";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";

import { usePageSize, usePortalRoutes, useSelected } from "../../../../hooks";
import { useClubSettings } from "../../../../providers";
import { RouteFeature, RouteLayout } from "../../../../routes";
import useGymflowModels from "../../../../store";
import { Button, Checkbox } from "../../../atoms";
import { NewUserSideBarProviderContext } from "../../../molecules";
import { PaginatedTable } from "../../../organisms";
import { LeadActionsDropdown } from "../LeadActionsDropdown";
import { LeadBadge } from "../LeadBadge";
import { generateLeadFilters } from "../leadFilterHelper";
import { LeadFilters } from "../LeadPipelineFilterSidebar";

export function LeadList({
  filters,
  selection,
}: {
  filters: LeadFilters;
  selection: ReturnType<typeof useSelected<number>>;
}) {
  const { api } = useGymflowModels();

  const [currentPage, setCurrentPage] = useState(0);
  const [sort, setSort] = useState<any>([]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filters]);

  const { timezone, date_format } = useClubSettings();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });

  const { data: leadStatusList, isLoading: isLoadingLeadStatus } =
    useQueryLeadStatus({ api });
  const dealWonStatusId = leadStatusList?.find(
    (status) => status.presetType === "DEAL_CLOSED",
  )?.id;
  const dealLostStatusId = leadStatusList?.find(
    (status) => status.presetType === "DEAL_LOST",
  )?.id;
  const {
    data,
    fetchNextPage,
    isLoading: isLoadingLeads,
  } = useInfiniteQueryLeadList(
    {
      api,
      opts: {
        limit: pageSize,
        ...generateLeadFilters({
          filters,
        }),
      },
      tz: timezone,
    },
    { enabled: !!leadStatusList },
  );

  const windowSize = useWindowSize({ debounceDelay: 100 });

  const columnHelper = createColumnHelper<LeadPipelineItem>();
  const { createMemberLink, createLeadLink } = usePortalRoutes();
  const currentPageData = data?.pages[currentPage]?.content ?? [];
  const selectColumn = columnHelper.accessor("leadId", {
    id: "selected",
    enableSorting: false,
    header: () => (
      <Checkbox
        className="pr-4"
        value={selection.areAllSelected}
        onChange={function (checked) {
          selection.selectAll(checked);
        }}
      />
    ),
    cell: (cell) => {
      return (
        <Checkbox
          className="pr-4"
          value={selection.isSelected(cell.getValue())}
          onChange={function (_, e) {
            if (e.shiftKey) {
              e.preventDefault();
              e.stopPropagation();
              const previousSelectedId =
                selection.selected[selection.selected.length - 1];
              if (previousSelectedId !== undefined) {
                const previousSelectedIdx = currentPageData.findIndex(
                  (row) => row.leadId === previousSelectedId,
                );
                if (previousSelectedIdx !== -1) {
                  const a =
                    previousSelectedIdx <= cell.row.index
                      ? previousSelectedIdx
                      : cell.row.index;
                  const b =
                    previousSelectedIdx > cell.row.index
                      ? previousSelectedIdx
                      : cell.row.index;

                  const slice = currentPageData
                    .slice(a, b + 1)
                    .map((r) => r.leadId);
                  selection.selectMultiple(slice);
                  return;
                }
              }
            }
            selection.toggle(cell.getValue());
          }}
        />
      );
    },
  });

  const history = useHistory();
  const { open: openNewUserSidebar } = useContext(
    NewUserSideBarProviderContext,
  );
  const { createClubLink } = usePortalRoutes();
  const actionsColumn = columnHelper.accessor("leadId", {
    id: "actions",
    header: "Actions",
    enableSorting: false,
    cell: (cell) => {
      return (
        <LeadActionsDropdown
          dealLostStatusId={dealLostStatusId!}
          dealWonStatusId={dealWonStatusId!}
          lead={cell.row.original}
          showCreateNote
          showCreateTask
        />
      );
    },
  });
  const desktopColumns = [
    selectColumn,
    columnHelper.accessor("leadId", {
      id: "leadId",
      header: "Lead",
      enableSorting: false,
      cell: (cell) => {
        return (
          <Link
            to={
              cell.row.original.userMemberId
                ? createMemberLink(cell.row.original.userMemberId)
                : createLeadLink(cell.getValue())
            }
            className="flex gap-2"
          >
            <div className="flex flex-col justify-center">
              <div className="text-primary-600 text-sm">{`${cell.row.original.firstName} ${cell.row.original.lastName}`}</div>
              <div className="line-clamp-1 max-w-[8rem] text-xs font-medium text-gray-500 sm:max-w-none">
                {cell.row.original.email}
              </div>
            </div>
          </Link>
        );
      },
    }),
    columnHelper.accessor("leadStatusName", {
      id: "leadStatusName",
      header: "Status",
      enableSorting: false,
      cell: (cell) => {
        return <LeadBadge statusName={cell.getValue()} />;
      },
    }),
    columnHelper.accessor("createdDate", {
      id: "createdDate",
      header: "Created Date",
      enableSorting: false,
      cell: (cell) => {
        return (
          <div className="text-sm font-normal text-gray-600">
            {moment(
              utcToZonedTime(cell.getValue(), timezone),
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            ).format(date_format)}
          </div>
        );
      },
    }),
    columnHelper.accessor("leadSourceName", {
      id: "leadSourceName",
      header: "Source",
      enableSorting: false,
      cell: (cell) => {
        return (
          <div className="text-sm font-normal text-gray-600">
            {cell.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor("leadId", {
      id: "account",
      header: "Account",
      enableSorting: false,
      cell: (cell) => {
        const lead = cell.row.original;
        if (lead.userMemberId !== undefined) {
          return (
            <Button
              size="small"
              className="flex-1"
              onClick={() => {
                history.push(
                  createClubLink(
                    RouteLayout.Staff,
                    RouteFeature.UserMember.replace(":id", lead.userMemberId!),
                  ),
                );
              }}
            >
              View Profile
            </Button>
          );
        }
        return (
          <Button
            intent="secondary-outline"
            size="small"
            className="flex-1"
            onClick={() => {
              openNewUserSidebar({
                creationMode: "MEMBER",
                defaultFormValues: {
                  email: lead.email,
                  "mobile-number": lead.mobileNumber,
                  "first-name": lead.firstName,
                  "last-name": lead.lastName,
                  source: { name: lead.leadSourceName, id: lead.leadSourceId },
                  "sms-communication": lead.smsCommunication,
                  "email-communication": lead.emailCommunication,
                },
                onClose: async (arg) => {
                  if (arg?.userType === "MEMBER") {
                    history.push(
                      createClubLink(
                        RouteLayout.Staff,
                        RouteFeature.UserMember.replace(
                          ":id",
                          arg.userMemberId,
                        ),
                      ),
                    );
                  }
                },
              });
            }}
          >
            Setup Account
          </Button>
        );
      },
    }),
    actionsColumn,
  ];

  const mobileColumns = [
    selectColumn,
    columnHelper.accessor("leadId", {
      id: "leadId",
      header: "Lead",
      enableSorting: false,
      cell: (cell) => {
        return (
          <Link
            to={
              cell.row.original.userMemberId
                ? createMemberLink(cell.row.original.userMemberId)
                : createLeadLink(cell.getValue())
            }
            className="flex gap-2"
          >
            <div className="flex flex-col justify-center">
              <div className="text-primary-600 text-sm">{`${cell.row.original.firstName} ${cell.row.original.lastName}`}</div>
              <div className="line-clamp-1 max-w-[8rem] text-xs font-medium text-gray-500 sm:max-w-none">
                {cell.row.original.email}
              </div>
            </div>
          </Link>
        );
      },
    }),
    columnHelper.accessor("leadStatusName", {
      id: "leadStatusName",
      header: "Status",
      enableSorting: false,
      cell: (cell) => {
        return <LeadBadge statusName={cell.getValue()} />;
      },
    }),
    actionsColumn,
  ];

  return (
    <PaginatedTable
      tableProps={{
        data: currentPageData,
        columns: !isMobile(windowSize.width) ? desktopColumns : mobileColumns,
        onSortingChange: setSort,
        sort: sort,
        isFetching: isLoadingLeadStatus || isLoadingLeads,
        pageSize: pageSize,
        tableContainerRef,
      }}
      hasPreviousPage={!!data && currentPage > 0}
      hasNextPage={!!data && !!data.pages[currentPage]?.nextPageToken}
      goToNextPage={() => {
        setCurrentPage((e) => e + 1);
        if (!data?.pages[currentPage + 1]) {
          fetchNextPage();
        }
      }}
      goToPreviousPage={() => {
        setCurrentPage((e) => e - 1);
      }}
    />
  );
}

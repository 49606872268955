import { cn } from "@gymflow/helpers";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import noop from "lodash/noop";
import range from "lodash/range";
import { Fragment, ReactNode, RefObject, useCallback } from "react";

export interface ColumnMeta {
  suspense: () => ReactNode;
}

export type TableProps<T> = {
  data: T[];
  columns: ColumnDef<any, any>[];
  pageCount?: number;
  pageIndex?: number;
  onSortingChange?: OnChangeFn<SortingState>;
  isFetching: boolean;
  sort?: SortingState;
  pageSize?: number;
  className?: string;
  theadClassName?: string;
  manualSorting?: boolean;
  rowClassName?: string;
  tableContainerRef?: RefObject<HTMLDivElement>;
};

export function Table<T>({
  data,
  columns,
  pageCount,
  pageIndex,
  onSortingChange = noop,
  isFetching,
  sort = [],
  pageSize,
  className,
  theadClassName,
  manualSorting,
  rowClassName,
  tableContainerRef,
}: TableProps<T>) {
  const table = useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: columns as ColumnDef<unknown, any>[],
    manualPagination: true,
    pageCount,
    onSortingChange,
    manualSorting,
    state: {
      sorting: sort,
      pagination: {
        pageIndex: pageIndex ?? 0,
        pageSize: pageSize ?? 0,
      },
    },
  });

  const renderFooter = function () {
    let footerHasValues = false;
    const nodes = table.getFooterGroups().map((footerGroup) => {
      return (
        <tr key={footerGroup.id}>
          {footerGroup.headers.map((header, idx) => {
            const footerValue = header.column.columnDef.footer;
            if (footerValue) {
              footerHasValues = true;
            }
            return (
              <th
                key={footerGroup.id + header.id}
                scope="col"
                className={classNames(
                  "relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-600",
                  { "pl-3": idx === 0 },
                )}
              >
                {flexRender(footerValue, header.getContext())}
              </th>
            );
          })}
        </tr>
      );
    });
    return (
      <tfoot className={classNames("bg-gray-50", { hidden: !footerHasValues })}>
        {nodes}
      </tfoot>
    );
  };

  const renderProgressRows = useCallback(() => {
    if (!isFetching) return null;
    return range(0, pageSize).map((i) => (
      <tr
        className={cn("border-y border-gray-200", rowClassName)}
        key={`progress-tr-${i}`}
      >
        {table.getHeaderGroups().map((headerGroup) => {
          return headerGroup.headers.map((header) => {
            const suspense = (header.column.columnDef.meta as ColumnMeta)
              ?.suspense;
            if (suspense) {
              return suspense();
            }
            return (
              <td
                key={`progress-td-${headerGroup.id + header.id}`}
                className="relative animate-pulse text-sm font-medium text-gray-900 first:pl-4"
              >
                <div className="mr-8 h-2 rounded bg-gray-400"></div>
              </td>
            );
          });
        })}
      </tr>
    ));
  }, [isFetching, pageSize, rowClassName, table]);

  const renderEmptySet = useCallback(() => {
    return (
      !isFetching &&
      data.length === 0 && (
        <tr className={cn("border-b border-gray-200", rowClassName)}>
          {table.getHeaderGroups().map((headerGroup) => {
            return headerGroup.headers.map((header, headerIdx) => {
              return (
                <td
                  className={cn(
                    "relative text-sm font-medium text-gray-900 first:pl-4",
                  )}
                  key={`empty-td-${headerGroup.id + header.id}`}
                >
                  <div>{headerIdx === 0 && "No results."}</div>
                </td>
              );
            });
          })}
        </tr>
      )
    );
  }, [isFetching, data.length, rowClassName, table]);

  return (
    <div className="relative flex h-full max-h-full w-full max-w-full">
      <div
        ref={tableContainerRef}
        className={classNames(
          "absolute inset-0 flow-root overflow-y-auto",
          className,
        )}
      >
        <table className="sticky top-0 z-[3] w-full text-left">
          <thead
            className={classNames(
              "sticky top-0 z-[3] w-full bg-gray-50",
              theadClassName,
            )}
          >
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Fragment key={headerGroup.id}>
                  <tr className="h-11 w-full items-center">
                    {headerGroup.headers.map((header) => {
                      const columnSort = sort.find(
                        (s: { id: string }) => s.id === header.id,
                      );
                      const canSort = header.column.getCanSort();

                      return (
                        <th
                          key={headerGroup.id + header.id}
                          scope="col"
                          className={cn(
                            "relative isolate text-left text-sm font-semibold text-gray-600 first:pl-4",
                          )}
                        >
                          <div
                            className={cn(
                              "group inline-flex gap-x-2 capitalize",
                              {
                                "cursor-pointer hover:!text-gray-600": canSort,
                              },
                            )}
                            onClick={(e) => {
                              e.preventDefault();
                              const handler =
                                header.column.getToggleSortingHandler();
                              if (handler) {
                                handler(e);
                              }
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                            {canSort && (
                              <span
                                className={cn(
                                  "flex-none rounded text-gray-400",
                                  {
                                    invisible: !columnSort,
                                    "group-hover:!visible": canSort,
                                  },
                                )}
                              >
                                {sort.length && columnSort?.desc ? (
                                  <ChevronDownIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronUpIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                  <tr>
                    <th
                      className="h-px bg-gray-200 p-0"
                      colSpan={headerGroup.headers.length}
                    />
                  </tr>
                </Fragment>
              );
            })}
          </thead>
          <tbody>
            {renderProgressRows()}
            {renderEmptySet()}

            {table.getRowModel().rows.map((row, idx) => {
              return (
                <tr
                  className={classNames(
                    "pulse border-y-[1px] border-gray-200 first:border-t-0",
                    {
                      hidden: isFetching,
                    },
                    rowClassName,
                  )}
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        className={cn(
                          "relative text-sm font-medium text-gray-900 first:pl-4",
                        )}
                        key={`${row.id}_${cell.id}`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {renderFooter()}
        </table>
      </div>
    </div>
  );
}

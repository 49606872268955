import {
  clubStaleTime,
  leadQueryFn,
  leadStatusListQueryFn,
  useClub,
  useClubFeatureFlags,
  useMemberDelete,
  useMutationDeleteLead,
  useMutationLeadStatus,
} from "@gymflow/api";
import { AlertContext } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { LeadPipelineItem } from "@gymflow/types";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { useCallback, useContext, useState } from "react";

import { useSendEmailsNew } from "../../../hooks";
import { Can, Subject, Verb } from "../../../permissions";
import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  buttonVariants,
  CheckDoneIcon,
  DotsVerticalIcon,
  FilePlusIcon,
  MailIcon,
  PaginatedSelect,
  SMSIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  TrashIcon,
} from "../../atoms";
import { Popover, PopoverContent, PopoverTrigger } from "../../atoms/Popover";
import {
  CreateEditTaskSidebarProviderContext,
  SendEmailSidebarProviderContext,
  SendSMSToLeadSidebarContext,
} from "../../organisms";
import { ConfirmModal } from "../../templates";
import { NoteFormSideBarProviderContext } from "../../UserMember/Notes/NoteFormSideBarProvider";
import SendEmailAlertWithProvider from "../../UserMember/SendEmails/SendEmailAlert";

export function LeadActionsDropdown({
  className,
  dealLostStatusId,
  dealWonStatusId,
  lead,
  showCreateTask,
  showCreateNote,
  showCreateTaskAndNoteResponsive,
}: {
  className?: string;
  dealLostStatusId: number;
  dealWonStatusId: number;
  lead: LeadPipelineItem;
  showCreateTask?: boolean;
  showCreateNote?: boolean;
  showCreateTaskAndNoteResponsive?: boolean;
}) {
  const { api } = useGymflowModels();
  const {
    changeLeadStatusMutation: { mutateAsync: changeStatus },
  } = useMutationLeadStatus({ api });

  const { toast, notifyDanger } = useContext(ToastContext);

  const { setAlert, hide } = useContext(AlertContext);
  const { setModal, hide: hideModal } = useContext(ModalContext);

  const { clubId } = useClubSettings();
  const { data: club } = useClub({ clubId, api }, { staleTime: clubStaleTime });

  const { sendEmailToLeads } = useSendEmailsNew();
  const { mutateAsync: deleteLeadMutate } = useMutationDeleteLead({ api });
  const { mutateAsync: deleteMemberMutate } = useMemberDelete({ api });
  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  const { openCreateTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );
  const { openSendEmailSidebar } = useContext(SendEmailSidebarProviderContext);
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onClickCreateTask = useCallback(async () => {
    setIsPopoverOpen(false);

    const dataLead = await leadQueryFn({ api, leadId: lead.leadId });

    const { id: leadId, userMemberId, firstName, lastName } = dataLead;

    openCreateTaskSidebar({
      initialValues: {
        relatedUsers: [
          {
            id: userMemberId ?? leadId,
            label: `${firstName} ${lastName}`,
            value: dataLead,
            imageUrl: undefined, // lead does't have picture
          },
        ],
      },
    });
  }, [api, lead, openCreateTaskSidebar]);

  const { open: openSendSMSSidebar } = useContext(SendSMSToLeadSidebarContext);
  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(e) => {
        setIsPopoverOpen(e);
      }}
    >
      <PopoverTrigger
        className={cn(
          buttonVariants({ size: "small", intent: "default" }),
          "mt-0 h-11 w-11 bg-white text-gray-700 hover:bg-gray-50",
          className,
        )}
      >
        <div className="flex items-center justify-center">
          <DotsVerticalIcon
            className="h-6 w-6"
            pathClassName="stroke-gray-700"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        side="bottom"
        asChild
        className="flex w-fit flex-col overflow-hidden rounded-lg"
        hideWhenDetached
      >
        <div>
          {featureFlags?.featureFlags.PORTAL_SEND_SMS && (
            <Can I={Verb.Create} a={Subject.SMS}>
              <div
                className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                onClick={async () => {
                  setIsPopoverOpen(false);
                  openSendSMSSidebar({
                    leadId: lead.leadId,
                    leadName: `${lead.firstName} ${lead.lastName}`,
                  });
                }}
              >
                <SMSIcon className="h-4 w-4" pathClassName="stroke-gray-700" />
                <div>Send SMS</div>
              </div>
            </Can>
          )}
          <Can I={Verb.Create} a={Subject.Email}>
            <div
              className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              onClick={async () => {
                setIsPopoverOpen(false);
                const recipient = `${lead.firstName} ${lead.lastName}`;
                if (featureFlags?.featureFlags.PORTAL_NEW_SEND_EMAIL_SIDEBAR) {
                  openSendEmailSidebar({
                    recipient,
                    allowMarketing: lead.emailCommunication,
                    requestType: "LEAD",
                    listOfIds: [lead.leadId],
                  });
                  return;
                }
                setAlert(
                  <SendEmailAlertWithProvider
                    allowMarketing={lead.emailCommunication}
                    from={club?.email!}
                    to={recipient}
                    onSubmit={(values: any) => {
                      const bcc = values.bcc ? values.bcc.split(",") : [];
                      return sendEmailToLeads({
                        emailPayload: {
                          marketing: values.marketing,
                          body: values.body,
                          subject: values.subject,
                          bccList: bcc,
                        },
                        leadIds: [lead.leadId],
                      });
                    }}
                    onCancel={hide}
                  />,
                );
              }}
            >
              <MailIcon className="h-4 w-4" pathClassName="stroke-gray-700" />
              <div>Send Email</div>
            </div>
          </Can>
          <div className="border-t border-t-gray-300" />
          {showCreateTaskAndNoteResponsive && (
            <div className="flex flex-col justify-center gap-1 px-4 py-2 text-sm text-gray-700 lg:hidden">
              <div>Change status to:</div>
              <PaginatedSelect
                value={{ label: lead.leadStatusName }}
                onChange={async (newValue) => {
                  await changeStatus({
                    leadId: lead.leadId,
                    newColumn: newValue.value,
                  });
                }}
                loadOptions={async () => {
                  const response = await leadStatusListQueryFn({ api });
                  return {
                    options: response
                      .sort((a, b) => {
                        return a.statusOrder - b.statusOrder;
                      })
                      .filter((e) => e.presetType !== "DEAL_CLOSED")
                      .filter((e) => e.presetType !== "DEAL_LOST")
                      .filter((e) => e.id !== lead.leadStatusId)
                      .map((source) => ({
                        value: source.id,
                        label: source.name,
                      })),
                    hasMore: false,
                  };
                }}
              />
            </div>
          )}
          {(showCreateTask || showCreateTaskAndNoteResponsive) && (
            <div
              className={cn(
                "flex cursor-pointer items-center gap-2 py-2 px-4 text-sm text-gray-700 hover:bg-gray-50",
                {
                  "flex lg:hidden": showCreateTaskAndNoteResponsive,
                },
              )}
              onClick={onClickCreateTask}
            >
              <CheckDoneIcon
                className="h-4 w-4"
                pathClassName="stroke-gray-700"
              />
              <div>Add Task</div>
            </div>
          )}

          {(showCreateNote || showCreateTaskAndNoteResponsive) && (
            <div
              className={cn(
                "flex cursor-pointer items-center gap-2 py-2 px-4 text-sm text-gray-700 hover:bg-gray-50",
                {
                  "flex lg:hidden": showCreateTaskAndNoteResponsive,
                },
              )}
              onClick={async () => {
                setIsPopoverOpen(false);
                triggerNewNote({
                  ...(lead?.userMemberId
                    ? {
                        userMemberId: lead.userMemberId,
                      }
                    : {
                        leadId: lead.leadId,
                      }),
                  name: `${lead.firstName} ${lead.lastName}`,
                });
              }}
            >
              <FilePlusIcon
                className="h-4 w-4"
                pathClassName="stroke-gray-700"
              />
              <div>Add Note</div>
            </div>
          )}
          <div
            className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
            onClick={async () => {
              setIsPopoverOpen(false);
              try {
                await changeStatus({
                  leadId: lead.leadId,
                  newColumn: dealWonStatusId,
                });
              } catch (e) {
                notifyDanger(e as any);
              }
            }}
          >
            <div>
              <ThumbsUpIcon pathClassName="stroke-success-600" />
            </div>
            <div>Deal Won</div>
          </div>
          <div
            className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
            onClick={async () => {
              setIsPopoverOpen(false);
              try {
                await changeStatus({
                  leadId: lead.leadId,
                  newColumn: dealLostStatusId,
                });
              } catch (e) {
                notifyDanger(e as any);
              }
            }}
          >
            <ThumbsDownIcon pathClassName="stroke-[#F04438]" />
            <div>Deal Lost</div>
          </div>
          <div
            className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
            onClick={async () => {
              setIsPopoverOpen(false);
              if (lead.userMemberId) {
                setModal(
                  <ConfirmModal
                    title="Delete Member?"
                    onCancel={hideModal}
                    onConfirm={async () => {
                      try {
                        await deleteMemberMutate({
                          clubId,
                          memberId: lead.userMemberId!,
                        });

                        toast({ message: "Lead deleted." });
                      } catch (e) {
                        notifyDanger(e as any);
                      }
                      hideModal();
                    }}
                    confirmText="Delete"
                  >
                    This lead has a member associated with it. Deleting it will
                    also delete the member. Are you sure?
                  </ConfirmModal>,
                );
              } else {
                try {
                  await deleteLeadMutate({
                    leadId: lead.leadId,
                  });
                } catch (e) {
                  notifyDanger(e as any);
                }
                toast({ message: "Lead deleted." });
              }
            }}
          >
            <TrashIcon className="h-4 w-4" pathClassName="stroke-[#F04438]" />
            <div>Delete</div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

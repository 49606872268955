import { FormMapper } from "@gymflow/common";

export default class ActivityFormMapper extends FormMapper {
  constructor() {
    super({
      inValue: [],
      outValue: [
        {
          key: "activity-category-id",
          transform: (v: { label: string; value: number }) => {
            return v.value;
          },
        },
      ],
    });
  }

  override to(record: Record<string, any>) {
    const transformed = super.from(record) as any;
    const activityCategory = transformed["activityCategory"];
    transformed["activity-category-id"] = {
      label: activityCategory.name,
      value: activityCategory.id,
    };
    return transformed;
  }
}

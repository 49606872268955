import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { NoteDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { noteQueryKeys } from "./noteQueryKeys";

export function useQueryNote(
  {
    api,
    noteId,
  }: {
    api: ApiType;
    noteId?: number;
  },
  opts?: UseQueryOptions<NoteDTO | null>,
) {
  const result = useQuery({
    queryKey: noteQueryKeys.details(noteId),
    queryFn: async () => {
      const result = await api.noteApi.findById(noteId as number);
      return result.data as NoteDTO;
    },
    enabled: !!noteId,
    initialData: null,
    ...opts,
  });

  return result;
}

import "moment-timezone";

import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { canFind, canFindById, canTrimFields } from "./features/apiResource";
import GuestStatus from "./GuestStatus.ts";

const eventEndpoints = {
  rsvp: `${endpoints.eventOccurrence}/rsvp`,
  /**
   * @deprecated
   */
  activityCategories: "catalog/activity/category",
  userMemberAgenda: `${endpoints.eventOccurrence}/user/member/:memberId`,
  calendarEventOccurrences: `calendar/event-occurrences`,
  calendarAppointments: `calendar/appointments`,
};

const eventApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: [
      "name",
      "userEventHostId",
      "startDate",
      "endDate",
      "startTime",
      "endTime",
      "startRecurringDate",
      "endRecurringDate",
      "recurrenceType",
    ],
    baseUrl: `${clubPrefix}${endpoints.eventOccurrence}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    {
      create(newResource, ignoreAvailabilityValidation) {
        const trimmedData = state.trimFields(newResource);

        return state.http.post(`${clubPrefix}${endpoints.eventOccurrence}`, {
          event: { ...trimmedData, clubId },
          ignoreAvailabilityValidation,
        });
      },

      update(id, patchedFields, isUpdateAll, ignoreAvailabilityValidation) {
        assert(
          check.any([check.string(id), check.number(id)]),
          "id must be number or string",
        );
        return state.http.put(`${state.baseUrl}/${id}`, {
          event: { ...state.trimFields(patchedFields), clubId },
          isAll: isUpdateAll,
          ignoreAvailabilityValidation,
        });
      },

      addAttendeeToRsvp(userMemberId, occurrenceId) {
        assert(check.string(userMemberId), "userMemberId must be a string");
        assert(check.number(occurrenceId), "occurrenceId must be a number");
        return state.http.post(`${clubPrefix}${eventEndpoints.rsvp}`, {
          userMemberId,
          occurrenceId,
        });
      },

      updateRsvp(rsvpId, status) {
        assert(check.number(rsvpId), "rsvpId must be a number");
        assert(
          check.in(status, GuestStatus),
          "status needs to be a valid GuestStatus.",
        );
        return state.http.patch(
          `${clubPrefix}${eventEndpoints.rsvp}/${rsvpId}`,
          { status },
        );
      },

      delete(occurrenceId, deleteAll) {
        assert(check.number(occurrenceId), "occurrenceId must be number");
        assert(check.boolean(deleteAll), "deleteAll must be a boolean");

        return state.http.delete(`${state.baseUrl}/${occurrenceId}`, {
          params: { isAll: deleteAll },
        });
      },

      checkInAll(occurrenceId) {
        assert(check.number(occurrenceId), "occurrenceId must be a number");
        return state.http.patch(`${clubPrefix}${eventEndpoints.rsvp}`, {
          status: GuestStatus.Attended,
          occurrenceId,
        });
      },

      /**
       * @deprecated
       */
      findActivityCategories() {
        return state.http.get(
          `${clubPrefix}${eventEndpoints.activityCategories}`,
        );
      },

      findByUserMemberId({
        memberId,
        page = 0,
        limit = 10,
        extraParams = {},
        sort,
      }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");
        assert.string(memberId, "memberId must be string");

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        const url = `${clubPrefix}${eventEndpoints.userMemberAgenda.replace(
          ":memberId",
          memberId,
        )}`;
        return state.http.get(url, config);
      },
      upcomingByUserMemberId({ memberId, limit, nextPageToken }) {
        const config = {
          params: {
            limit,
            nextPageToken,
            dateFrom: new Date().toISOString().split(".")[0] + "Z",
          },
        };

        const url = `${clubPrefix}user-member-booking/${memberId}/upcoming`;
        return state.http.get(url, config);
      },
      pastByUserMemberId({ memberId, limit, nextPageToken }) {
        const config = {
          params: {
            limit,
            nextPageToken,
            dateTo: new Date().toISOString().split(".")[0] + "Z",
          },
        };

        const url = `${clubPrefix}user-member-booking/${memberId}/past`;
        return state.http.get(url, config);
      },

      calendarEventOccurrences({ filters }) {
        return state.http.get(
          `${clubPrefix}${eventEndpoints.calendarEventOccurrences}`,
          { params: filters },
        );
      },

      calendarAppointments({ filters }) {
        return state.http.get(
          `${clubPrefix}${eventEndpoints.calendarAppointments}`,
          { params: filters },
        );
      },
    },
  );
};

export const PARAMETER_DATE_ONLY_FORMAT = "YYYY-MM-DD";
export const PARAMETER_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";
export const PARAMETER_DATE_FORMAT_WITHOUT_TZ = "YYYY-MM-DDTHH:mm:ss";
export const PARAMETER_TIME_FORMAT = "HH:mm:ss";

export default eventApi;

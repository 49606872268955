import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { AvailabilityOverrideDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryHostAvailabilityOverrideList(
  {
    api,
    staffId,
    opts,
  }: {
    api: ApiType;
    staffId: Parameters<ApiType["availabilityApi"]["listHostOverride"]>[0];
    opts?: Parameters<ApiType["availabilityApi"]["listHostOverride"]>[1];
  },
  queryOpts?: UseQueryOptions<
    Awaited<ReturnType<ApiType["availabilityApi"]["listHostOverride"]>>["data"]
  >,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.listHostOverrides(staffId as string, opts),
    queryFn: async () => {
      const result = await api.availabilityApi.listHostOverride(
        staffId as string,
        opts!,
      );
      return result.data;
    },
    enabled: !!staffId,
    initialData: defaultPage<AvailabilityOverrideDTO>,
    ...queryOpts,
  });

  return result;
}

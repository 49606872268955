import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useInfiniteQueryEventOccurrencesAsMember,
  useInfiniteQueryEventOccurrencesAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import scrollEndReached from "apps/portal/src/helpers/scrollEndReached";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Spinner } from "../../atoms";
import { EmptyPage } from "../../atoms/EmptyPage";
import HostedPagesClassCard from "./HostedPagesClassCard";
import { HostedPagesClassesFilterPopoverProps } from "./HostedPagesClassesFilterPopover";

export type HostedPagesClassesDayListProps = {
  filter: HostedPagesClassesFilterPopoverProps["filter"];
  setFilter: (filter: HostedPagesClassesFilterPopoverProps["filter"]) => void;
};

const HostedPagesClassesDayList = ({
  filter,
  setFilter,
}: HostedPagesClassesDayListProps) => {
  const { isEmbed } = usePortalRoutes();
  const { t } = useTranslation();
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const auth = useAuthenticatedUser();
  const publicQuery = useInfiniteQueryEventOccurrencesAsPublic({
    api,
    filters: {
      dateFrom: DateTime.now()
        .startOf("hour")
        .toUTC()
        .toISO({ suppressMilliseconds: true })!,
      dateTo: DateTime.now()
        .plus({ years: 1 })
        .startOf("day")
        .toUTC()
        .toISO({ suppressMilliseconds: true })!,
      includeWaitingCounts: true,
      includeBookedCounts: true,
      ...filter,
    },
    enabled: !auth.id,
  });
  const memberQuery = useInfiniteQueryEventOccurrencesAsMember({
    api,
    filters: {
      dateFrom: DateTime.now()
        .startOf("hour")
        .toUTC()
        .toISO({ suppressMilliseconds: true })!,
      dateTo: DateTime.now()
        .plus({ years: 1 })
        .startOf("day")
        .toUTC()
        .toISO({ suppressMilliseconds: true })!,
      includeWaitingCounts: true,
      includeBookedCounts: true,
      ...filter,
    },
    enabled: !!auth.id,
  });
  const {
    data: listClasses,
    isLoading,
    fetchNextPage,
    isFetching,
  } = auth.id ? memberQuery : publicQuery;
  const classes = listClasses?.pages.flatMap((e) => e.content);
  const emptyClasses =
    listClasses?.pages.reduce((acc, next) => acc + next.content.length, 0) ===
    0;
  const hasFiltersApplied =
    filter.activityCategoryId.length > 0 ||
    filter.activityId.length > 0 ||
    filter.eventHostId.length > 0 ||
    filter.facilityId.length > 0;
  const todayInClubTimezone = DateTime.now().setZone(settings.timezone);
  const [parent] = useAutoAnimate();
  return (
    <div
      className={cn(
        "track-height flex h-full w-full flex-col items-center self-center overflow-y-auto p-4",
        {
          "h-fit": isEmbed,
        },
      )}
      onScroll={scrollEndReached(100, () => {
        if (!isFetching) {
          fetchNextPage();
        }
      })}
    >
      <div
        ref={parent}
        className={cn(
          "flex h-fit w-full max-w-[min(calc(100vw-2rem),40rem)] flex-col gap-4 overflow-y-visible",
          {
            "h-full items-center justify-center": !isLoading && emptyClasses,
          },
        )}
      >
        {isLoading && <Spinner />}
        {!isLoading && emptyClasses && (
          <EmptyPage
            action={
              hasFiltersApplied ? (
                <Button
                  onClick={() => {
                    setFilter({
                      activityCategoryId: [],
                      activityId: [],
                      eventHostId: [],
                      facilityId: [],
                    });
                  }}
                >
                  Clear Filters
                </Button>
              ) : undefined
            }
          />
        )}
        {!isLoading &&
          classes?.map((eventOccurrence, i) => {
            const eventStartDate = DateTime.fromISO(
              eventOccurrence.startDate,
            ).setZone(settings.timezone);
            const hasDateGap =
              i === 0 ||
              !eventStartDate.hasSame(
                DateTime.fromISO(classes[i - 1].startDate).setZone(
                  settings.timezone,
                ),
                "day",
              );

            return (
              <React.Fragment key={i}>
                {hasDateGap && (
                  <div className="text-sm font-semibold text-gray-500">
                    {todayInClubTimezone.hasSame(eventStartDate, "day")
                      ? t("common.today")
                      : todayInClubTimezone
                          .plus({ days: 1 })
                          .hasSame(eventStartDate, "day")
                      ? t("common.tomorrow")
                      : eventStartDate.toLocaleString(
                          DateTime.DATE_MED_WITH_WEEKDAY,
                        )}
                  </div>
                )}
                <HostedPagesClassCard
                  key={eventOccurrence.eventOccurrenceId}
                  calendarEventOccurrence={eventOccurrence}
                />
              </React.Fragment>
            );
          })}
        {!isEmbed && !isLoading && isFetching && <Spinner />}
        {!isLoading && isEmbed && (
          <Button
            showSpinner={isFetching}
            disabled={isFetching}
            onClick={() => {
              fetchNextPage();
            }}
          >
            {t("pages.hostedPagesClasses.loadMore")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default HostedPagesClassesDayList;

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationFacilityAvailabilityEdit({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      facilityId,
      params,
    }: {
      facilityId: Parameters<
        ApiType["availabilityApi"]["saveFacilityAvailability"]
      >[0];
      params: Parameters<
        ApiType["availabilityApi"]["saveFacilityAvailability"]
      >[1];
    }) => {
      const response = await api.availabilityApi.saveFacilityAvailability(
        facilityId,
        params,
      );
      return response.data;
    },
    onSuccess: (_, { facilityId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.facilityAvailability(facilityId),
      });
    },
  });

  return mutation;
}

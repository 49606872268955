import { QueryKey } from "@tanstack/react-query";

export const emailTemplateQueryKeys = {
  all: () => ["emailTemplate"] as QueryKey,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...emailTemplateQueryKeys.all(), { filters }] as QueryKey,
  listInfinityQuery: () => ["listInfinityQuery"] as QueryKey,
  details: (emailTemplateId?: number) =>
    [...emailTemplateQueryKeys.all(), emailTemplateId] as QueryKey,
};

import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { useContext } from "react";

import { Button, CardIcon, CheckCircleIcon, LockIcon } from "../../atoms";

export function MigrationOverview({
  isTokenValid,
  needsPasswordSet,
  needsPaymentMethod,
  onNextButtonClick,
  onProcessAlreadyFinished,
}: {
  isTokenValid: boolean;
  needsPasswordSet: boolean;
  needsPaymentMethod: boolean;
  onNextButtonClick: () => void;
  onProcessAlreadyFinished: () => void;
}) {
  const steps = [];
  if (needsPasswordSet) {
    steps.push({
      icon: <LockIcon className="h-6 w-6" pathClassName="stroke-gray-500" />,
      title: "Password",
      subtitle: "Create a password to access your account",
    });
  }
  if (needsPaymentMethod) {
    steps.push({
      icon: <CardIcon className="h-6 w-6" pathClassName="stroke-gray-500" />,
      title: "Card details",
      subtitle: "Add card details in your account",
    });
  }

  const { toast } = useContext(ToastContext);
  if (!isTokenValid) {
    toast({ message: "This invite link is not valid.", intent: "error" });
    return null;
  }

  if (!needsPasswordSet && !needsPaymentMethod) {
    onProcessAlreadyFinished();
    return null;
  }

  return (
    <div className="mt-16 flex justify-center">
      <div className="flex max-w-[20.5rem] flex-col gap-8">
        <div>
          <div className="flex flex-col items-center gap-4">
            <div className="flex h-14 w-14 items-center justify-center rounded-[32rem] border-8 border-[#ECFDF3] bg-[#DCFAE6] dark:border-[#053321] dark:bg-[#074D31]">
              <CheckCircleIcon
                className="h-7 w-7"
                pathClassName="stroke-success-600 dark:stroke-[#17B26A]"
              />
            </div>
            <div className="flex flex-col items-center gap-2">
              <div className="dark:text-gray-25 text-center text-xl font-bold text-gray-950">
                Your Email is Verified
              </div>
              <div className="text-center text-base font-medium text-gray-500 dark:text-gray-400">
                Please follow the steps below to complete the transfer.
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="rounded-2xl border border-gray-200 bg-white p-4 dark:border-gray-600 dark:bg-gray-950">
            <div className="flow-root pb-7">
              <ul className="-mb-8">
                {steps.map((step, stepIdx) => (
                  <li key={step.title}>
                    <div className="relative pb-1">
                      {stepIdx !== steps.length - 1 ? (
                        <span
                          aria-hidden="true"
                          className="absolute left-6 top-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-600"
                        />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div className="flex h-12 w-12 items-center justify-center rounded-xl border border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-950">
                          {step.icon}
                        </div>
                        <div className="flex min-w-0 flex-1 flex-col justify-center">
                          <div className="dark:text-gray-25 text-sm font-semibold text-gray-950">
                            {step.title}
                          </div>
                          <div className="text-xs font-normal text-gray-500 dark:text-gray-400">
                            {step.subtitle}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex">
            <Button
              intent="secondary"
              className="mt-2 flex-1"
              onClick={onNextButtonClick}
            >
              Follow Steps
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

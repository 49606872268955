import "quill/dist/quill.snow.css";
import "./EmailEditor.css";

import { cn } from "@gymflow/helpers";
import Quill, { Range } from "quill";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { QUILL_OPTIONS } from "./constants";
import { EmailEditorProps, EmailEditorRef, ToolbarModule } from "./types";
import { cleanHTMLForEmail } from "./utils/cleanHTMLForEmail";
import { makePlaceholdersForBackend } from "./utils/makePlaceholdersForBackend";
import { makePlaceholdersForFrontend } from "./utils/makePlaceholdersForFrontend";

export const EmailEditor = forwardRef<EmailEditorRef, EmailEditorProps>(
  ({ value, onChange, placeholders, className }, ref) => {
    const [initDone, setInitDone] = useState(false);
    const quillRef = useRef<HTMLDivElement>(null);
    const [savedRange, setSavedRange] = useState<Range | null>(null);
    const quillInstance = useRef<Quill | null>(null);

    const { t } = useTranslation();

    // Expose methods to parent
    useImperativeHandle(ref, () => ({
      overrideHTML: (html) => {
        const updatedHTML = makePlaceholdersForFrontend(html, placeholders);
        quillInstance.current?.clipboard.dangerouslyPasteHTML(updatedHTML);
      },
      insertText: (text) => {
        const range = quillInstance.current?.getSelection() ||
          savedRange || { index: 0, length: 0 };

        const { index, length } = range;

        // Remove the selected text
        if (length > 0) {
          quillInstance.current?.deleteText(index, length);
        }

        // Insert the new text at the current index
        quillInstance.current?.insertText(index, text);

        // Update the selection range
        const newRange = {
          index: index + text.length,
          length: 0,
        };
        quillInstance.current?.setSelection(newRange);
        setSavedRange(newRange);
      },
    }));

    // Initialize the Quill editor
    useEffect(() => {
      if (quillRef.current) {
        // Create a new Quill instance with configuration
        quillInstance.current = new Quill(quillRef.current, QUILL_OPTIONS);
      }

      // Cleanup Quill instance when the component unmounts
      return () => {
        quillInstance.current = null;
      };
    }, []);

    // Handlers
    useEffect(() => {
      if (quillInstance.current) {
        // Handle text change events in the editor
        quillInstance.current.on(Quill.events.TEXT_CHANGE, () => {
          const html = makePlaceholdersForBackend(
            quillInstance.current?.root.innerHTML || "",
            placeholders,
          );
          const cleanedHTML = cleanHTMLForEmail(html);
          onChange(cleanedHTML);

          const currentRange = quillInstance.current?.getSelection();
          if (currentRange) {
            setSavedRange(currentRange);
          }
        });

        // Save cursor position when the editor loses focus
        quillInstance.current.on(Quill.events.SELECTION_CHANGE, (range) => {
          if (range) {
            setSavedRange(range);
          }
        });

        // Override the image handler (change to image URL flow)
        const toolbar = quillInstance.current.getModule(
          "toolbar",
        ) as ToolbarModule;
        toolbar.addHandler("image", () => {
          const imageUrl = prompt(
            t("components.emailEditor.imageHandler.prompt"),
          );
          if (imageUrl) {
            const range = quillInstance.current?.getSelection();
            if (range) {
              quillInstance.current?.insertEmbed(
                range.index,
                "image",
                imageUrl,
              );
            }
          }
        });
      }

      // Cleanup Quill listeners when the component unmounts
      return () => {
        quillInstance.current?.off(Quill.events.TEXT_CHANGE);
        quillInstance.current?.off(Quill.events.SELECTION_CHANGE);
      };
    }, [onChange, placeholders, t]);

    // Set the initial value of the editor
    useEffect(() => {
      if (quillInstance.current && value && !initDone) {
        const html = makePlaceholdersForFrontend(value, placeholders);
        quillInstance.current.clipboard.dangerouslyPasteHTML(html);
      }
      setInitDone(true);
    }, [value, initDone, placeholders]);

    return (
      <div className="flex w-full flex-wrap">
        {/* Additional wrapper for borders and sizes */}
        <div
          className={cn(
            "min-h-[20rem] w-full rounded-xl border border-gray-200 text-left",
            className,
          )}
        >
          {/* Container for the Quill editor */}
          <div ref={quillRef} />
        </div>
      </div>
    );
  },
);

EmailEditor.displayName = "EmailEditor";

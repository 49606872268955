import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { EventRsvpStatus } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { calendarAsMemberQueryKeys } from "../calendarAsMember";
import { eventOccurrenceAsMemberQueryKeys } from "./eventOccurrenceAsMemberQueryKeys";

export function useEventOccurrenceRsvpAsMember({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const updateRsvpMutation = useMutation({
    mutationFn: ({
      rsvpId,
      status,
    }: {
      rsvpId: number;
      status: EventRsvpStatus;
    }) => api.customerOccurrenceApi.updateRsvp(rsvpId, status),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceAsMemberQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.eventOccurrences(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.userMemberBookingAgenda(),
      });
    },
  });

  const addAttendeeToRsvpMutation = useMutation({
    mutationFn: ({
      userMemberId,
      occurrenceId,
    }: {
      userMemberId: string;
      occurrenceId: number;
    }) =>
      api.customerOccurrenceApi.addAttendeeToRsvp(userMemberId, occurrenceId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceAsMemberQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.eventOccurrences(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.userMemberBookingAgenda(),
      });
    },
  });

  return {
    updateRsvp: updateRsvpMutation,
    addAttendeeToRsvp: addAttendeeToRsvpMutation,
  };
}

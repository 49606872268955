import { TemplateDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { emailTemplateQueryKeys } from "./emailTemplateQueryKeys";

export function useEmailTemplate(
  {
    api,
    emailTemplateId,
  }: {
    api: {
      emailTemplateApi: {
        findById: (arg0: number) => Promise<{ data: TemplateDTO }>;
      };
    };
    emailTemplateId?: number;
  },
  opts?: UseQueryOptions<TemplateDTO | null>,
) {
  const result = useQuery({
    queryKey: emailTemplateQueryKeys.details(emailTemplateId),
    queryFn: async () => {
      const { data } = await api.emailTemplateApi.findById(emailTemplateId!);
      return data as TemplateDTO;
    },
    enabled: !!emailTemplateId,
    initialData: null,
    ...opts,
  });

  return result;
}

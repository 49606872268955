import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMutationHostAvailabilityEdit,
  useRoutesByBrand,
  useStaff,
  useStaffChangeRole,
  useStaffCreate,
  useStaffDisable,
  useStaffEdit,
  useStaffEnable,
  useStaffList,
  useStaffSetClubAccessible,
} from "@gymflow/api";
import { toLowerCaseExceptFirstChar } from "@gymflow/helpers";
import { createColumnHelper } from "@tanstack/react-table";
import classNames from "classnames";
import { useStoreActions, useStoreState } from "easy-peasy";
import { capitalize } from "lodash";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import environment from "../../../environment";
import { usePageSize } from "../../../hooks/usePageSize";
import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { Can, Subject, Verb } from "../../../permissions";
import { ModalContext, useClubSettings } from "../../../providers";
import { ToastContext } from "../../../providers/ToastProvider/context";
import { RouteFeature } from "../../../routes/feature";
import { RouteLayout } from "../../../routes/layout";
import useGymflowModels from "../../../store";
import { Avatar, Badge, Button } from "../../atoms";
import DropdownFilter from "../../DropdownFilter";
import { PaginatedTable } from "../../organisms/PaginatedTable";
import { ConfirmModal, StaffFormModal } from "../../templates";
import { SettingsContainer } from "../SettingsContainer";

export function StaffTable() {
  const settings = useClubSettings();
  const history = useHistory();
  const { api, authStore } = useGymflowModels();
  const { brand, createClubLink } = usePortalRoutes();
  const { notifyDanger } = useContext(ToastContext);
  const { setModal, hide } = useContext(ModalContext);
  const [editingStaffId, setEditingStaffId] = useState(null);
  const [isActiveOption, setIsActiveOption] = useState({
    label: "Enabled",
    value: true,
  });
  const { id: loggedInId } = useStoreState(authStore);
  const { logout } = useStoreActions(authStore);

  const [selectedPage, setSelectedPage] = useState(0);
  const [sort, setSort] = useState([]);
  const tableContainerRef = useRef(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const { data: editing, isFetching: isFetchingStaff } = useStaff({
    api,
    staffId: editingStaffId,
  });
  const changeRoleMutation = useStaffChangeRole({ api });

  const { data, isLoading } = useStaffList({
    api,
    opts: {
      page: selectedPage,
      limit: pageSize,
      sort: sort?.[0]
        ? {
            field: sort[0].id,
            desc: sort[0].desc,
          }
        : undefined,
      extraParams: { activeUser: isActiveOption.value },
    },
  });
  const editStaff = useStaffEdit({ api });
  const createStaff = useStaffCreate({ api });
  const enableStaff = useStaffEnable({ api });
  const disableStaff = useStaffDisable({ api });
  const editAvailabilityMutation = useMutationHostAvailabilityEdit({ api });
  const setClubsAccessibleStaff = useStaffSetClubAccessible({ api });
  const serverUrl = environment.get("API_RESOLVER_URL");
  const {
    data: { results: clubRoutes },
    isFetching: isFetchingRoutes,
  } = useRoutesByBrand({
    serverUrl,
    brand,
    page: 0,
    limit: 1000000,
  });

  const handleSubmit = useCallback(
    async (values) => {
      try {
        if (editing) {
          const { clubAccessibleList, role, ...staffFields } =
            values.patchedFields;
          if (Object.keys(staffFields)) {
            await editStaff.mutateAsync({
              staffId: values.id,
              patchedFields: staffFields,
            });
          }

          if (clubAccessibleList) {
            setClubsAccessibleStaff.mutateAsync({
              staffId: values.id,
              accessibleClubs: clubAccessibleList,
            });
          }
          if (role && role !== editing.role) {
            await changeRoleMutation.mutateAsync({
              clubId: settings.clubId,
              staffId: values.id,
              role,
            });

            if (values.id === loggedInId) {
              logout();
            }
          }
        } else {
          const { clubAccessibleList, role, ...staffFields } = values;
          const {
            data: { id },
          } = await createStaff.mutateAsync(staffFields);
          setClubsAccessibleStaff.mutateAsync({
            staffId: id,
            accessibleClubs: clubAccessibleList,
          });
          if (role !== "MANAGER") {
            await changeRoleMutation.mutateAsync({
              clubId: settings.clubId,
              staffId: id,
              role,
            });
          }
        }

        setEditingStaffId(null);
        hide();
      } catch (e) {
        notifyDanger(e);
      }
    },
    [createStaff, editStaff, editing, hide, notifyDanger],
  );

  const showFormModal = useCallback(() => {
    let existingValues = null;
    if (editing) {
      existingValues = {
        ...editing,
        clubAccessibleList: editing.clubAccessibleList.map(
          ({ id, ...props }) => {
            const clubName = clubRoutes.find(
              ({ clubId }) => id === clubId,
            )?.displayName;
            return { ...props, id, name: clubName };
          },
        ),
      };
    }
    setModal(
      <StaffFormModal
        existingValues={existingValues}
        onSubmit={(submittedValues) => {
          return handleSubmit(submittedValues);
        }}
        onCancel={() => {
          setEditingStaffId(null);
          setModal(null);
        }}
        showEditingSelfRoleWarning={loggedInId === existingValues?.id}
      />,
    );
  }, [editing]);

  useEffect(() => {
    if (editingStaffId && editing) {
      showFormModal();
    }
  }, [editingStaffId, setModal, editing, showFormModal]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor(
      (row) => {
        return {
          firstName: row.firstName,
          lastName: row.lastName,
          picture: row.picture,
          email: row.email,
        };
      },
      {
        id: "firstName",
        cell: (info) => {
          const { firstName, lastName, picture, email } = info.getValue();
          return (
            <div className="flex gap-x-4">
              <Avatar url={picture} />
              <div className="flex flex-col">
                <div
                  className={classNames({
                    "text-gray-300": !isActiveOption.value,
                  })}
                >{`${firstName} ${lastName}`}</div>
                <div
                  className={classNames({
                    "text-gray-600": isActiveOption.value,
                    "text-gray-300": !isActiveOption.value,
                  })}
                >
                  {email}
                </div>
              </div>
            </div>
          );
        },
        header: () => <span>Name</span>,
        meta: {
          suspense: () => (
            <td className="relative animate-pulse py-4 pr-3 text-sm font-medium text-gray-900">
              <div className="ml-4 flex space-x-4">
                <div>
                  <div className="h-10 w-10 rounded-full bg-gray-400"></div>
                </div>
                <div className="flex w-2/5 flex-col space-y-4">
                  <div className="h-2 rounded bg-gray-400"></div>
                  <div className="h-2 rounded bg-gray-400"></div>
                </div>
              </div>
            </td>
          ),
        },
        sortDescFirst: false,
      },
    ),
    columnHelper.accessor("role", {
      cell: (cell) => {
        return <Badge>{capitalize(cell.getValue())}</Badge>;
      },
      enableSorting: false,
    }),
    columnHelper.accessor("mobileNumber", {
      header: "Phone",
    }),
    columnHelper.display({
      id: "locations",
      header: "Locations",
      cell: (props) => {
        return props.row.original.clubAccessibleList.map((club) => {
          const clubName = clubRoutes.find(
            ({ clubId }) => club.id === clubId,
          )?.displayName;
          return (
            <Badge className="ml-1" key={club.id}>
              {clubName}
            </Badge>
          );
        });
      },
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (props) => {
        const isEnabled = props.row.original.activeUser;
        const id = props.row.original.id;
        const renderBody = () => {
          let text_part1 = "";
          let text_part2 = "";

          if (isEnabled) {
            text_part1 = "Are you sure you want to disable this staff member?";
            text_part2 = "Access will be immediately revoked.";
          } else {
            text_part1 = "Are you sure you want to enable this staff member?";
            text_part2 = "Access will be immediately restored.";
          }
          return (
            <>
              <div className="mt-2 text-sm text-gray-600">{text_part1}</div>
              <div className="text-sm text-gray-600">{text_part2}</div>
            </>
          );
        };

        return (
          <div className="flex gap-2">
            <Button
              className="min-w-0"
              intent="link"
              showSpinner={
                props.row.original.id === editingStaffId && isFetchingStaff
              }
              disabled={isFetchingStaff}
              onClick={() => {
                setEditingStaffId(props.row.original.id);
              }}
            >
              Edit
            </Button>
            <Button
              intent="link-warning"
              onClick={() => {
                setModal(
                  <ConfirmModal
                    type={isEnabled ? "danger" : "default"}
                    title={
                      isEnabled ? "Disable Staff Member" : "Enable Staff Member"
                    }
                    onConfirm={async () => {
                      if (isEnabled) {
                        await disableStaff.mutateAsync(id);

                        await editAvailabilityMutation.mutateAsync({
                          staffId: id,
                          availableForAppointments: false,
                          availabilitySlotList: [],
                        });
                      } else {
                        await enableStaff.mutateAsync(id);
                      }
                      hide();
                    }}
                    onCancel={() => {
                      hide();
                    }}
                  >
                    {renderBody()}
                  </ConfirmModal>,
                );
              }}
            >
              {isEnabled ? "Disable" : "Enable"}
            </Button>

            {isEnabled && (
              <Can I={Verb.View} a={Subject.Appointments}>
                <Button
                  intent="link"
                  onClick={() => {
                    history.push(
                      createClubLink(
                        RouteLayout.Staff,
                        RouteFeature.StaffAvailability.replace(
                          ":staffId",
                          props.row.original.id,
                        ),
                      ),
                    );
                  }}
                >
                  Availability
                </Button>
              </Can>
            )}
          </div>
        );
      },
    }),
  ];

  return (
    <SettingsContainer
      title="Team Members"
      subTitle="Manage your team members and their account permissions here."
      actions={
        <>
          <DropdownFilter
            className="mt-0"
            options={[
              {
                label: "Enabled",
                value: true,
              },
              {
                label: "Disabled",
                value: false,
              },
            ]}
            value={isActiveOption}
            onChange={(v) => setIsActiveOption(v)}
          />
          <Button intent="secondary" onClick={showFormModal}>
            <div className="flex flex-row items-center gap-x-2">
              <FontAwesomeIcon icon={faPlus} />
              Staff
            </div>
          </Button>
        </>
      }
    >
      <PaginatedTable
        tableProps={{
          data: data.content,
          columns: columns,
          pageCount: data.totalPages,
          pageIndex: data.number,
          onSortingChange: setSort,
          sort: sort,
          isFetching: isLoading,
          pageSize: pageSize,
          tableContainerRef,
        }}
        hasNextPage={!!data && data?.number < data?.totalPages - 1}
        hasPreviousPage={!!data && data?.number > 0}
        goToNextPage={() => {
          setSelectedPage((e) => e + 1);
        }}
        goToPreviousPage={() => {
          setSelectedPage((e) => e - 1);
        }}
      />
    </SettingsContainer>
  );
}

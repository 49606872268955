import { ApiListResponse, NoteDTO, NotePostDTO } from "@gymflow/types";
import { AxiosInstance } from "axios";

import { transformIntoFormData } from "./features/transformIntoFormData";

export type NoteApiType = ReturnType<typeof noteApi>;

const noteApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;

  return {
    findById(id: number) {
      return axiosInstance.get<NoteDTO>(`${clubPrefix}note/${id}`);
    },
    search(params: {
      readonly page: number;
      readonly size: number;
      readonly userMemberId?: string;
      readonly leadId?: number;
      readonly appointmentId?: number;
      readonly dateFrom?: string;
      readonly dateTo?: string;
    }) {
      return axiosInstance.put<ApiListResponse<NoteDTO>>(
        `${clubPrefix}note/search`,
        params,
      );
    },
    create(newNote: NotePostDTO, file?: File) {
      const formData = transformIntoFormData({
        file: {
          name: "file",
          contents: file,
        },
        fields: {
          name: "note",
          content: newNote,
        },
      });

      return axiosInstance.post(`${clubPrefix}note`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    update(
      id: number,
      {
        patchedFields,
        file,
      }: { patchedFields: { content: string }; file: File },
    ) {
      const formData = transformIntoFormData({
        file: {
          name: "file",
          contents: file,
        },
        fields: {
          name: "note",
          content: patchedFields,
        },
      });

      return axiosInstance.patch(`${clubPrefix}note/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    remove(id: number) {
      return axiosInstance.delete(`${clubPrefix}note/${id}`);
    },

    removeAttachment(id: number) {
      return axiosInstance.delete(`${clubPrefix}note/${id}/attachment`);
    },

    createLeadNote(newNote: NotePostDTO, file?: File) {
      const formData = transformIntoFormData({
        file: {
          name: "file",
          contents: file,
        },
        fields: {
          name: "note",
          content: newNote,
        },
      });

      return axiosInstance.post<NoteDTO>(
        `${clubPrefix}note/lead-note`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
    },

    createAppointmentNote(newNote: NotePostDTO, file?: File) {
      const formData = transformIntoFormData({
        file: {
          name: "file",
          contents: file,
        },
        fields: {
          name: "note",
          content: newNote,
        },
      });

      return axiosInstance.post(
        `${clubPrefix}note/appointment-note`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
    },
  };
};

export default noteApi;

import { Transition } from "@headlessui/react";
import { VariantProps } from "class-variance-authority";
import { MouseEvent, ReactNode, useState } from "react";

import { Button, buttonVariants } from "./Button";

export interface ReactiveButtonProps
  extends VariantProps<typeof buttonVariants> {
  className?: string;
  children: ReactNode;
  onClick?: (
    e: MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => Promise<void> | void;
  reactiveContent: ReactNode;
}

export function ReactiveButton({
  children,
  className,
  intent,
  size,
  reactiveContent,
  onClick,
}: ReactiveButtonProps) {
  const [showReactionText, setShowReactionText] = useState(false);
  const [showOriginal, setShowOriginal] = useState(true);
  return (
    <Button
      className={className}
      intent={intent}
      size={size}
      onClick={async (e) => {
        if (showReactionText) {
          return;
        }
        setShowReactionText(true);
        setShowOriginal(false);
        setTimeout(() => {
          setShowReactionText(false);
        }, 2000);
        if (!onClick) {
          return;
        }
        await onClick(e);
      }}
    >
      <Transition
        className="transition-all duration-200 ease-in-out"
        show={showReactionText}
        enter="transition-all ease-in duration-300"
        enterFrom="opacity-0 translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-all ease-out duration-300"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-2"
        afterLeave={() => {
          setShowOriginal(true);
        }}
      >
        {reactiveContent}
      </Transition>
      {showOriginal && children}
    </Button>
  );
}

import { UIEventHandler } from "react";

export default (offset: number, callBack: () => void) => {
  const result: UIEventHandler = (ev) => {
    const tracker = ev.target as HTMLDivElement;
    if (
      tracker.scrollHeight - tracker.clientHeight - tracker.scrollTop <
      offset
    ) {
      callBack();
    }
  };
  return result;
};

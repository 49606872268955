import { useMemberSearch } from "@gymflow/api";
import { capitalize } from "lodash";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useDebounceValue, useOnClickOutside } from "usehooks-ts";

import searchIcon from "../../../assets/img/search-lg.svg";
import { SidebarToggleContext } from "../../providers/SidebarToggleContext";
import useGymflowModels from "../../store";
import { Badge, Spinner } from "../atoms";
import { LeadActionsDropdown, UserActionsDropdown } from "../molecules";
import { UserMemberAvatar } from "../molecules/UserMemberAvatar";

export interface SearchGlobalProps {}

export const SearchGlobal: React.FC<SearchGlobalProps> = () => {
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounceValue(query, 200);
  const { api } = useGymflowModels();

  const { data, isLoading } = useMemberSearch({
    api,
    term: debouncedQuery,
    size: 5,
  });

  const ref = useRef(null);

  useOnClickOutside(
    ref,
    () => {
      setTimeout(() => {
        setQuery("");
      }, 100);
    },
    "mouseup",
  );

  const { hideSidebar } = useContext(SidebarToggleContext);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }, []);

  return (
    <div
      className="relative flex h-full w-full flex-row items-center gap-x-2 bg-white px-8"
      ref={ref}
    >
      <img src={searchIcon} alt="search-icon" className="h-5" />
      <input
        value={query}
        onChange={handleInputChange}
        placeholder="Search..."
        type="search"
        className="placeholder:text-primary-700 flex h-full w-full bg-white outline-none placeholder:text-base"
      />
      {!!query && isLoading && (
        <div className="absolute left-0 top-[calc(100%+.25rem)] z-[1000] flex w-full flex-row items-center justify-between gap-x-2 rounded-lg border border-gray-300 bg-white p-4 shadow">
          <Spinner className="" />
        </div>
      )}

      {!!query && !isLoading && data && data?.length === 0 && (
        <div className="absolute left-0 top-[calc(100%+.25rem)] z-[1000] flex w-full flex-row items-center justify-between gap-x-2 rounded-lg border border-gray-300 bg-white p-4 shadow">
          No results found
        </div>
      )}
      {!!query && !isLoading && data && data.length > 0 && (
        <div
          onClick={() => {
            setQuery("");
          }}
          className="absolute left-0 top-[calc(100%+.25rem)] z-[1000] flex w-fit min-w-full max-w-[100vw] flex-col rounded-lg border border-gray-300 bg-white shadow"
        >
          {data.map((user) => {
            let badgeVariant: "default" | "error" | "primary" | "secondary" =
              "default";
            if (user.userType === "LEAD") {
              badgeVariant = "primary";
            } else if (
              user.userType === "MEMBER" ||
              user.userType === "CUSTOMER"
            ) {
              badgeVariant = "secondary";
            } else if (user.userType === "DELETED") {
              badgeVariant = "error";
            }
            return (
              <div
                key={user.id}
                className="flex flex-row items-center justify-between gap-x-2 p-4"
                onClick={() => {
                  hideSidebar();
                }}
              >
                <UserMemberAvatar className="w-full" {...user} />
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="flex flex-row items-center justify-end gap-x-2"
                >
                  <Badge intent={badgeVariant}>
                    {capitalize(user.userType)}
                  </Badge>
                  {typeof user.id === "number" ? (
                    <LeadActionsDropdown
                      className="h-9 w-9"
                      leadId={user.id}
                      leadName={`${user.firstName} ${user.lastName}`}
                    />
                  ) : (
                    <UserActionsDropdown
                      className="h-9 w-9"
                      memberId={user.id}
                      memberName={`${user.firstName} ${user.lastName}`}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

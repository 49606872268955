import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { activityQueryKeys } from "./activityQueryKeys";

export function useMutationActivityCreate({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (
      newActivity: Parameters<ApiType["activityApi"]["create"]>[0],
    ) => {
      const response = await api.activityApi.create(newActivity);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: activityQueryKeys.all() });
    },
  });

  return mutation;
}

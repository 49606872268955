import {
  activityCategoryListAsMemberQueryFn,
  activityListAsMemberQueryFn,
  facilityListAsMemberQueryFn,
  useEventOccurrenceRsvpAsMember,
  useInfiniteQueryCalendarEventOccurrencesAsMember,
} from "@gymflow/api";
import { DATE_FORMAT, DATE_FORMAT_WITH_SECONDS } from "@gymflow/common";
import moment from "moment-timezone";
import qs from "qs";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

import { EventGrid } from "../components/molecules";
import RsvpButton from "../components/RsvpButton.tsx";
import { useAuthenticatedUser, useClubSettings } from "../providers";
import { ToastContext } from "../providers/ToastProvider/context";
import useGymflowModels from "../store";

function buildFilterKey(userId) {
  return `calendar-previous-filter-${userId}`;
}

function UserCalendar() {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const { id: loggedInId } = useAuthenticatedUser();
  const { notifyDanger } = useContext(ToastContext);
  const { date } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const [selectedDate, setSelectedDate] = useState(
    moment(date, DATE_FORMAT).isValid()
      ? moment(date, DATE_FORMAT).format(DATE_FORMAT_WITH_SECONDS)
      : moment().format(DATE_FORMAT_WITH_SECONDS),
  );

  useEffect(() => {
    if (date) {
      setSelectedDate(
        moment(date, DATE_FORMAT).format(DATE_FORMAT_WITH_SECONDS),
      );
    }
  }, [date]);

  const [filter, setFilter] = useState(() => {
    const prevFilter = localStorage.getItem(buildFilterKey(loggedInId));
    if (prevFilter) {
      return JSON.parse(prevFilter);
    }
    return { category: null };
  });

  const { addAttendeeToRsvp, updateRsvp } = useEventOccurrenceRsvpAsMember({
    api,
  });

  const {
    data: calendarEvents,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQueryCalendarEventOccurrencesAsMember({
    api,
    tz: settings.timezone,
    filters: {
      dateFrom: moment(selectedDate, DATE_FORMAT_WITH_SECONDS)
        .startOf("day")
        .format(DATE_FORMAT_WITH_SECONDS),
      dateTo: moment(selectedDate, DATE_FORMAT_WITH_SECONDS)
        .add(6, "days")
        .endOf("day")
        .format(DATE_FORMAT_WITH_SECONDS),
      activityCategoryId: filter?.category ? filter?.category.value : undefined,
      facilityId: filter?.facility ? filter.facility.value : undefined,
      activityId: filter?.activity ? filter.activity.value : undefined,
      includeBookedCounts: true,
      includeWaitingCounts: true,
    },
  });
  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, calendarEvents?.pageParams.length]);

  const renderRsvpButton = (event) => {
    const {
      eventOccurrenceId: eventId,
      capacity,
      waitListCapacity,
      startDate,
      bookedCount,
      waitingCount,
      eventRsvpId,
      eventRsvpStatus,
    } = event;

    return (
      <RsvpButton
        activityId={event.activityId}
        eventCapacity={capacity}
        userMemberId={loggedInId}
        onClick={async ({ status }) => {
          if (eventRsvpId) {
            return await updateRsvp
              .mutateAsync({
                status,
                rsvpId: eventRsvpId,
              })
              .catch(notifyDanger);
          }

          return await addAttendeeToRsvp
            .mutateAsync({
              userMemberId: loggedInId,
              occurrenceId: eventId,
            })
            .catch(notifyDanger);
        }}
        startDate={startDate}
        bookedCount={bookedCount}
        waitingCount={waitingCount}
        waitListCapacity={waitListCapacity}
        rsvpStatus={eventRsvpStatus}
      />
    );
  };

  return (
    <div className="content h-full overflow-y-auto p-8">
      <Row>
        <Col>
          <EventGrid
            initialDate={selectedDate}
            events={calendarEvents?.pages.flatMap((p) => p)}
            dateFormat={dateFormat}
            renderRsvpButton={renderRsvpButton}
            onSelectedDateChange={setSelectedDate}
            allowFilter={true}
            filter={filter}
            onCategoryFilterChange={(category) => {
              setFilter((filters) => ({
                ...filters,
                category: category.value === null ? null : category,
              }));
              localStorage.setItem(
                buildFilterKey(loggedInId),
                JSON.stringify({ ...filter, category }),
              );
            }}
            onFacilityFilterChange={(facility) => {
              setFilter((filters) => ({ ...filters, facility }));
              localStorage.setItem(
                buildFilterKey(loggedInId),
                JSON.stringify({ ...filter, facility: facility }),
              );
            }}
            onActivityFilterChange={(activity) => {
              setFilter((filters) => ({ ...filters, activity }));
              localStorage.setItem(
                buildFilterKey(loggedInId),
                JSON.stringify({ ...filter, activity: activity }),
              );
            }}
            fetchCategories={async (filter) => {
              const categories = activityCategoryListAsMemberQueryFn({
                api,
                filter: {
                  ...filter,
                  statusList: ["ACTIVE"],
                },
              });
              return categories;
            }}
            fetchFacilities={async ({ page }) => {
              const facilities = await facilityListAsMemberQueryFn({
                api,
                filter: { extraParams: { status: "ACTIVE" }, page },
              });
              return facilities;
            }}
            fetchActivities={async ({ page }) => {
              const activities = await activityListAsMemberQueryFn({
                api,
                filter: { status: "ACTIVE", page },
              });
              return activities;
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default UserCalendar;

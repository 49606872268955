import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { activityQueryKeys } from "./activityQueryKeys";

export function useMutationActivityCategoryEditStatus({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      activityCategoryId,
      newStatus,
    }: {
      activityCategoryId: Parameters<
        ApiType["activityApi"]["updateCategoryStatus"]
      >[0];
      newStatus: Parameters<ApiType["activityApi"]["updateCategoryStatus"]>[1];
    }) => {
      const response = await api.activityApi.updateCategoryStatus(
        activityCategoryId,
        newStatus,
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: activityQueryKeys.categories(),
      });
    },
  });
  return mutation;
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useInfiniteQuery } from "@tanstack/react-query";

import { calendarAsPublicQueryKeys } from "./calendarAsPublicQueryKeys";

export function useInfiniteQueryEventOccurrencesAsPublic({
  api,
  filters,
  enabled = true,
}: {
  api: ApiType;
  filters: Omit<
    Parameters<
      ApiType["public"]["eventApi"]["calendarEventOccurrences"]
    >[0]["filters"],
    "nextPageToken" | "limit"
  >;
  enabled?: boolean;
}) {
  return useInfiniteQuery({
    queryKey: calendarAsPublicQueryKeys.eventOccurrencesInfiniteScroll({
      filters,
    }),
    queryFn: async ({ pageParam }) => {
      return (
        await api.public.eventApi.calendarEventOccurrences({
          filters: {
            ...filters,
            limit: 15,
            nextPageToken: pageParam,
            isPublicEvent: true,
          },
        })
      ).data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageToken;
    },
    enabled,
  });
}

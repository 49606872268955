import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { activityQueryKeys } from "./activityQueryKeys";

export function useMutationActivityEnable({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (activityId: number) => {
      await api.activityApi.activate(activityId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: activityQueryKeys.all() });
    },
  });

  return mutation;
}

import {
  clubStaleTime,
  useClub,
  useClubFeatureFlags,
  useMember,
} from "@gymflow/api";
import { AlertContext } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { useContext, useState } from "react";

import { useAccessToggleButton, useSendEmailsNew } from "../../hooks";
import { Can, Subject, Verb } from "../../permissions";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import {
  buttonVariants,
  CheckDoneIcon,
  CheckInIcon,
  CheckOutIcon,
  DotsVerticalIcon,
  MailIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ShopBagIcon,
  SMSIcon,
  StickerSquareIcon,
} from "../atoms";
import {
  CreateEditTaskSidebarProviderContext,
  SendEmailSidebarProviderContext,
  SendSMSToUserSidebarContext,
} from "../organisms";
import { ShopSidebarContext } from "../Shop/ShopSidebar/ShopSidebarContext";
import { NoteFormSideBarProviderContext } from "../UserMember/Notes/NoteFormSideBarProvider";
import SendEmailAlertWithProvider from "../UserMember/SendEmails/SendEmailAlert";

export function UserActionsDropdown({
  className,
  memberId,
  memberName,
}: {
  className?: string;
  memberId: string;
  memberName: string;
}) {
  const { api } = useGymflowModels();

  const { clubId, timezone } = useClubSettings();
  const { data: club } = useClub({ clubId, api }, { staleTime: clubStaleTime });
  const { setAlert, hide } = useContext(AlertContext);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { showShop } = useContext(ShopSidebarContext);

  const { open: openSendSMSSidebar } = useContext(SendSMSToUserSidebarContext);
  const {
    toggle,
    isReady: isAccessReady,
    isCheckedIn,
  } = useAccessToggleButton({ userMemberId: memberId });
  const { data: user, isLoading: isUserLoading } = useMember({
    memberId,
    api,
    tz: timezone,
  });
  const { sendEmailToMembers } = useSendEmailsNew();
  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  const { openSendEmailSidebar } = useContext(SendEmailSidebarProviderContext);
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });

  const { openCreateTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(e) => {
        setIsPopoverOpen(e);
      }}
    >
      <PopoverTrigger
        className={cn(
          buttonVariants({ size: "small", intent: "default" }),
          "mt-0 h-11 w-11 bg-white text-gray-700 hover:bg-gray-50",
          className,
        )}
      >
        <div className="flex items-center justify-center">
          <DotsVerticalIcon
            className="h-6 w-6"
            pathClassName="stroke-gray-700"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        side="bottom"
        asChild
        className="flex w-fit flex-col overflow-hidden rounded-lg"
        hideWhenDetached
      >
        {isAccessReady && !isUserLoading && user && (
          <div>
            <Can I={Verb.View} a={Subject.Sales}>
              <div
                className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                onClick={() => {
                  setIsPopoverOpen(false);
                  showShop(user.user);
                }}
              >
                <ShopBagIcon
                  className="h-4 w-4"
                  pathClassName="stroke-gray-700"
                />
                <div>Sell Something</div>
              </div>
            </Can>
            <Can I={Verb.View} a={Subject.Access}>
              <div
                className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                onClick={async () => {
                  setIsPopoverOpen(false);
                  await toggle();
                }}
              >
                {isCheckedIn ? (
                  <CheckOutIcon
                    className="h-4 w-4"
                    pathClassName="stroke-gray-700"
                  />
                ) : (
                  <CheckInIcon
                    className="h-4 w-4"
                    pathClassName="stroke-gray-700"
                  />
                )}
                <div>{isCheckedIn ? "Check out" : "Check in"}</div>
              </div>
            </Can>

            <div className="border-t border-t-gray-300" />

            <Can I={Verb.Create} a={Subject.Email}>
              <div
                className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                onClick={async () => {
                  setIsPopoverOpen(false);
                  const recipient = memberName;
                  if (
                    featureFlags?.featureFlags.PORTAL_NEW_SEND_EMAIL_SIDEBAR
                  ) {
                    openSendEmailSidebar({
                      recipient,
                      allowMarketing: user.user.emailCommunication,
                      requestType: "USER",
                      listOfIds: [memberId],
                    });
                    return;
                  }
                  setAlert(
                    <SendEmailAlertWithProvider
                      allowMarketing={user.user.emailCommunication}
                      from={club?.email!}
                      to={recipient}
                      onSubmit={async (values: any) => {
                        const bcc = values.bcc ? values.bcc.split(",") : [];
                        await sendEmailToMembers({
                          emailPayload: {
                            marketing: values.marketing,
                            body: values.body,
                            subject: values.subject,
                            bccList: bcc,
                          },
                          userMemberIds: [memberId],
                        });
                      }}
                      onCancel={hide}
                    />,
                  );
                }}
              >
                <MailIcon className="h-4 w-4" pathClassName="stroke-gray-700" />
                <div>Send Email</div>
              </div>
            </Can>
            {featureFlags?.featureFlags.PORTAL_SEND_SMS && (
              <Can I={Verb.Create} a={Subject.SMS}>
                <div
                  className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={async () => {
                    setIsPopoverOpen(false);
                    openSendSMSSidebar({
                      memberId,
                      memberName,
                    });
                  }}
                >
                  <SMSIcon
                    className="h-4 w-4"
                    pathClassName="stroke-gray-700"
                  />
                  <div>Send SMS</div>
                </div>
              </Can>
            )}
            <div className="border-t border-t-gray-300" />
            <div
              className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              onClick={async () => {
                setIsPopoverOpen(false);
                triggerNewNote({
                  userMemberId: memberId,
                  name: memberName,
                });
              }}
            >
              <StickerSquareIcon
                className="h-4 w-4"
                pathClassName="stroke-gray-700"
              />
              <div>Add Note</div>
            </div>
            <Can I={Verb.View} a={Subject.Task}>
              <div
                className="flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                onClick={async () => {
                  setIsPopoverOpen(false);
                  openCreateTaskSidebar({
                    initialValues: {
                      relatedUsers: [
                        {
                          id: memberId,
                          label: memberName,
                          value: user.user,
                          imageUrl: user.user.picture,
                        },
                      ],
                    },
                  });
                }}
              >
                <CheckDoneIcon
                  className="h-4 w-4"
                  pathClassName="stroke-gray-700"
                />
                <div>Create Task</div>
              </div>
            </Can>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { ActivityDTO, ApiListResponse } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityQueryKeys } from "./activityQueryKeys";

export async function activityListQueryFn({
  api,
  opts,
}: {
  api: ApiType;
  opts: Parameters<ApiType["activityApi"]["find"]>[0];
}) {
  const result = await api.activityApi.find(opts);
  return result.data as ApiListResponse<ActivityDTO>;
}

export function useQueryActivityList({
  api,
  opts,
}: {
  api: ApiType;
  opts?: Parameters<ApiType["activityApi"]["find"]>[0];
}) {
  const result = useQuery({
    queryKey: activityQueryKeys.list(opts),
    queryFn: () => activityListQueryFn({ api, opts: opts! }),
    initialData: defaultPage<ActivityDTO>,
    enabled: !!opts,
  });
  return result;
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime } from "@gymflow/helpers";
import { ApiTokenListResponse, BookingAgendaResult } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";

import { calendarAsMemberQueryKeys } from "./calendarAsMemberQueryKeys";

export function useInfiniteQueryUserMemberBookingAgenda({
  api,
  tz,
  filters,
}: {
  api: ApiType;
  tz: string;
  filters: Parameters<
    ApiType["customerOccurrenceApi"]["userMemberBookingAgenda"]
  >[0]["filters"];
}) {
  return useInfiniteQuery({
    queryKey: calendarAsMemberQueryKeys.eventOccurrences({ tz, filters }),
    queryFn: async ({ pageParam }) => {
      const result = await api.customerOccurrenceApi.userMemberBookingAgenda({
        filters: {
          ...filters,
          nextPageToken: pageParam,
        },
      });
      return result.data;
    },
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    select: (data) => userMemberBookingAgendaSelectFn(data, tz),
    enabled: !!tz,
  });
}

export function userMemberBookingAgendaSelectFn(
  data: {
    pages: ApiTokenListResponse<BookingAgendaResult>[];
    pageParams: unknown[];
  },
  tz: string,
) {
  return {
    ...data,
    pages: data.pages.map((page) =>
      page.content.map((eventOccurrence) => ({
        ...eventOccurrence,
        startDate: utcToZonedTime(eventOccurrence.startDate, tz),
        endDate: utcToZonedTime(eventOccurrence.endDate, tz),
      })),
    ),
  };
}

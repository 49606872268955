import {
  TaskListFilter,
  useInfiniteQueryTaskList,
  useTaskEdit,
} from "@gymflow/api";
import {
  isMobile,
  PARAMETER_DATE_ONLY_FORMAT,
  UserStatus,
} from "@gymflow/common";
import { LUXON_DATE_FORMAT } from "@gymflow/helpers";
import { TaskDTO } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import { isAxiosError } from "axios";
import { DateTime } from "luxon";
import moment from "moment";
import { useContext, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLocalStorage, useWindowSize } from "usehooks-ts";

import {
  StaffSelectInputOptionsType,
  Tabs,
  TabType,
  TagsPopover,
} from "../components/atoms";
import { CheckboxInput } from "../components/atoms/base/CheckboxInput";
import {
  CreateEditTaskSidebarProviderContext,
  PaginatedTable,
  TaskActionsColumn,
  TaskActionsSettingsContainer,
  TaskFilterSidebar,
} from "../components/organisms";
import { SettingsContainer } from "../components/Settings/SettingsContainer";
import { getFilterAppliedCount } from "../helpers";
import { usePageSize, usePortalRoutes } from "../hooks";
import { useAuthenticatedUser, useClubSettings } from "../providers";
import { ToastContext } from "../providers/ToastProvider/context";
import useGymflowModels from "../store";
import { NonRecursive } from "../types/nonRecursive";
import { LeadProfilePage } from "./LeadProfile";
import { UserMemberPage } from "./UserMember";

type TabsType = "TODAY" | "TOMORROW" | "ALL" | "OVERDUE" | "COMPLETED";

type ExtraDateParamsType = { dateTo?: string; dateFrom?: string };

export type TaskFilterLocalStorageType = {
  dueDate?: string;
  taskOwners?: StaffSelectInputOptionsType;
};

type NonRecursiveTaskDTO = NonRecursive<TaskDTO>;

const DUE_DATE_FORMAT = "ddd, MMM DD, YYYY";

const TABS: TabType<TabsType>[] = [
  { id: "TODAY", label: <Trans i18nKey="page.tasks.tabs.today" /> },
  { id: "TOMORROW", label: <Trans i18nKey="page.tasks.tabs.tomorrow" /> },
  { id: "ALL", label: <Trans i18nKey="page.tasks.tabs.allTasks" /> },
  {
    id: "OVERDUE",
    label: <Trans i18nKey="page.tasks.tabs.overdue" />,
    labelClassName: "text-warning-600",
  },
  {
    id: "COMPLETED",
    label: <Trans i18nKey="page.tasks.tabs.completed" />,
  },
];

export const Tasks = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [activeTabId, _setActiveTabId] = useState<TabsType>(TABS[0].id);

  const history = useHistory();
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { timezone: tz } = useClubSettings();
  const { openCreateTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );
  const { toast, notifyDanger } = useContext(ToastContext);
  const columnHelper = createColumnHelper<NonRecursiveTaskDTO>();
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const { id: loggedInId } = useAuthenticatedUser();
  const { routeId, createMemberLink, createLeadLink } = usePortalRoutes();
  const [filters, setFilters] = useLocalStorage<TaskFilterLocalStorageType>(
    `tasks-list-${loggedInId}-${routeId}`,
    {},
  );

  // Rules (by design): the "Due Date" filter doesn't work for the Today, Tomorrow, and Overdue tabs/tables;
  // Show completed tasks only for the "Completed" tab/table;
  const opts: TaskListFilter = useMemo(() => {
    const extraParams: ExtraDateParamsType = {};
    const { taskOwners, dueDate } = filters;
    const now = DateTime.now().setZone(tz);

    if (dueDate) {
      const dueDateZoned = DateTime.fromFormat(dueDate, LUXON_DATE_FORMAT, {
        zone: tz,
      });

      extraParams.dateFrom = dueDateZoned.startOf("day").toUTC().toISO() ?? "";
      extraParams.dateTo = dueDateZoned.endOf("day").toUTC().toISO() ?? "";
    }

    if (activeTabId === "OVERDUE") {
      extraParams.dateFrom = undefined;
      extraParams.dateTo = now.endOf("minute").toUTC().toISO() ?? "";
    }

    if (activeTabId === "TODAY") {
      extraParams.dateFrom = now.startOf("day").toUTC().toISO() ?? "";
      extraParams.dateTo = now.endOf("day").toUTC().toISO() ?? "";
    }

    if (activeTabId === "TOMORROW") {
      const tomorrow = now.plus({ days: 1 });
      extraParams.dateFrom = tomorrow.startOf("day").toUTC().toISO() ?? "";
      extraParams.dateTo = tomorrow.endOf("day").toUTC().toISO() ?? "";
    }

    return {
      limit: pageSize,
      extraParams: {
        ...extraParams,
        "taskOwners.id": taskOwners?.map(({ id }) => id.toString()),
        complete: activeTabId === "COMPLETED",
      },
    };
  }, [activeTabId, filters, pageSize, tz]);

  const {
    data: tasks,
    isFetching,
    fetchNextPage,
  } = useInfiniteQueryTaskList({
    api,
    tz,
    opts,
  });
  const { mutateAsync: editTask } = useTaskEdit({ api, tz });

  const defaultColumns = [
    columnHelper.accessor(
      ({ id, complete }) => ({
        taskId: id,
        complete,
      }),
      {
        id: "done",
        header: () => t("page.tasks.table.header.done"),
        enableSorting: false,
        cell: (cell) => (
          <CheckboxInput
            containerClassName="ml-1.5"
            onChange={async () => {
              const complete = !cell.getValue().complete;
              try {
                await editTask({
                  taskId: cell.getValue().taskId,
                  patchedFields: { complete },
                });
                toast({
                  message: complete
                    ? t("page.tasks.notify.title.taskCompleted")
                    : t("page.tasks.notify.title.taskIncomplete"),
                  description: complete
                    ? t("page.tasks.notify.description.taskCompleted")
                    : t("page.tasks.notify.description.taskIncomplete"),
                });
              } catch (e) {
                if (isAxiosError(e)) {
                  notifyDanger(e);
                }
              }
            }}
            checked={cell.getValue().complete}
          />
        ),
      },
    ),
    columnHelper.accessor("name", {
      id: "name",
      header: () => t("page.tasks.table.header.task"),
      enableSorting: false,
      cell: (cell) => (
        <div className="text-sm font-semibold text-gray-900">
          {cell.getValue()}
        </div>
      ),
    }),
  ];

  const actionsColumn = columnHelper.accessor("id", {
    id: "actions",
    header: () => t("page.tasks.table.header.actions"),
    enableSorting: false,
    cell: (cell) => <TaskActionsColumn taskId={cell.getValue()} />,
  });

  const desktopColumns = [
    ...defaultColumns,
    columnHelper.accessor("deadlineDate", {
      id: "dueDate",
      header: () => t("page.tasks.table.header.dueDate"),
      enableSorting: false,
      cell: (cell) => (
        <div className="text-sm font-normal text-gray-600">
          {cell.getValue()
            ? moment
                .tz(cell.getValue(), PARAMETER_DATE_ONLY_FORMAT, tz)
                .format(DUE_DATE_FORMAT)
                .toLocaleString()
            : "-"}
        </div>
      ),
    }),
    columnHelper.accessor(
      ({ taskRelatedLeads, taskRelatedUsers }) => ({
        taskRelatedLeads,
        taskRelatedUsers,
      }),
      {
        id: "usersLeads",
        header: () => t("page.tasks.table.header.usersLeads"),
        enableSorting: false,
        cell: (cell) => (
          <TagsPopover
            tags={[
              ...cell.getValue().taskRelatedLeads,
              ...cell.getValue().taskRelatedUsers,
            ].map((item) => ({
              label: `${item.firstName} ${item.lastName}`,
              item,
            }))}
            onClickItem={({ item }) => {
              if (!item) return;
              const page =
                item.profileType === UserStatus.Lead
                  ? createLeadLink(item.id, LeadProfilePage.Profile)
                  : createMemberLink(item.id, UserMemberPage.Profile);
              history.push(page);
            }}
          />
        ),
      },
    ),
    columnHelper.accessor("taskOwners", {
      id: "owners",
      header: () => t("page.tasks.table.header.owners"),
      enableSorting: false,
      cell: (cell) => (
        <TagsPopover
          tags={cell.getValue().map(({ firstName, lastName }) => ({
            label: `${firstName} ${lastName}`,
          }))}
        />
      ),
    }),
    ...(activeTabId !== "COMPLETED" ? [actionsColumn] : []),
  ];

  const setActiveTabId = (id: TabsType) => {
    _setActiveTabId(id);
    setCurrentPage(0);
  };

  const mobileColumns = [
    ...defaultColumns,
    ...(activeTabId !== "COMPLETED" ? [actionsColumn] : []),
  ];

  const currentPageData = tasks?.pages[currentPage]?.content ?? [];

  const windowWidth = useWindowSize({ debounceDelay: 100 }).width;

  return (
    <>
      <TaskFilterSidebar
        isVisible={isFilterVisible}
        onClose={() => setIsFilterVisible(false)}
        value={filters}
        onChange={(newFilters) => {
          setCurrentPage(0);
          setFilters(newFilters);
        }}
      />
      <div className="flex h-full max-h-full w-full p-4 lg:p-8">
        <SettingsContainer
          title={t("page.tasks.title")}
          subTitle={t("page.tasks.subTitle")}
          actions={
            <TaskActionsSettingsContainer
              setIsFilterVisible={setIsFilterVisible}
              openCreateTaskSidebar={openCreateTaskSidebar}
              appliedFiltersCount={getFilterAppliedCount(filters)}
            />
          }
        >
          <Tabs
            activeTabId={activeTabId}
            setActiveTabId={setActiveTabId}
            tabs={TABS}
          />
          <PaginatedTable
            tableProps={{
              data: currentPageData,
              columns: !isMobile(windowWidth) ? desktopColumns : mobileColumns,
              isFetching,
              pageSize,
              tableContainerRef,
            }}
            hasPreviousPage={!!tasks && currentPage > 0}
            hasNextPage={!!tasks && !tasks.pages[currentPage]?.last}
            goToNextPage={() => {
              setCurrentPage((e) => e + 1);
              if (!tasks?.pages[currentPage + 1]) {
                fetchNextPage();
              }
            }}
            goToPreviousPage={() => {
              setCurrentPage((e) => e - 1);
            }}
          />
        </SettingsContainer>
      </div>
    </>
  );
};

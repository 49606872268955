import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { AvailabilityOverrideDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryFacilityAvailabilityOverrideList(
  {
    api,
    facilityId,
    opts,
  }: {
    api: ApiType;
    facilityId?: number;
    opts: Parameters<ApiType["availabilityApi"]["listFacilityOverride"]>[1];
  },
  queryOpts?: UseQueryOptions<
    Awaited<
      ReturnType<ApiType["availabilityApi"]["listFacilityOverride"]>
    >["data"]
  >,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.listFacilityOverrides(
      facilityId as number,
      opts,
    ),
    queryFn: async () => {
      const result = await api.availabilityApi.listFacilityOverride(
        facilityId!,
        opts,
      );
      return result.data;
    },
    enabled: !!facilityId,
    initialData: defaultPage<AvailabilityOverrideDTO>,
    ...queryOpts,
  });

  return result;
}

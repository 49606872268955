import {
  clubStaleTime,
  useClubHostedPagesSettingsAsPublic,
  useQueryClubFeatureFlagsAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { Transition } from "@headlessui/react";
import { useContext, useState } from "react";

import { usePortalRoutes } from "../../hooks";
import { useAuthenticatedUser, useClubSettings } from "../../providers";
import { LoginOverlayContext } from "../../providers/authentication/loginOverlay/context";
import { generateSiteWithAuthRoutes, RouteFeature } from "../../routes";
import useGymflowModels from "../../store";
import { Button, MenuIcon, NavLink, navLinkVariants } from "../atoms";
import { BuyMembership as BuyMembershipSite } from "../site/BuyMembership";
import { BuySessionPack as BuySessionPackSite } from "../site/BuySessionPack";
import { Enquiry } from "../site/Enquiry";
import { Timetable } from "../site/Timetable";

export function SiteNavHeader() {
  const settings = useClubSettings();
  const auth = useAuthenticatedUser();
  const { showLoginOverlay, showSignUpOverlay } =
    useContext(LoginOverlayContext);

  const { api } = useGymflowModels();
  const { data: featureFlags } = useQueryClubFeatureFlagsAsPublic({
    api,
    clubId: settings.clubId,
  });

  const { data: hostedPagesSettings } = useClubHostedPagesSettingsAsPublic(
    { api, clubId: settings.clubId },
    { staleTime: clubStaleTime },
  );
  const routesWithAuth = generateSiteWithAuthRoutes(
    featureFlags,
    hostedPagesSettings?.settings,
  );

  const [isNavDropdownOpen, setIsNavDropdownOpen] = useState(false);
  const { createSiteOrEmbedLink } = usePortalRoutes();
  const routesOrder = [
    "Memberships",
    "Credit Packs",
    "Products",
    "Classes",
    "Appointments",
    "Contact Us",
  ];
  const routesForLinks = routesWithAuth
    .concat([
      ...(featureFlags?.featureFlags.HOSTED_CREDIT_REDESIGN
        ? []
        : [
            {
              path: RouteFeature.SiteBuyMembership,
              name: "Memberships",
              component: BuyMembershipSite,
              invisible: !hostedPagesSettings?.settings?.membershipsNavEnabled,
            },
            {
              path: RouteFeature.SiteBuySessionPack,
              name: "Credit Packs",
              component: BuySessionPackSite,
              invisible: !hostedPagesSettings?.settings?.creditPacksNavEnabled,
            },
          ]),
      ...(featureFlags?.featureFlags.HOSTED_PAGES_CLASSES_REDESIGN
        ? []
        : [
            {
              path: RouteFeature.SiteTimetable,
              name: "Classes",
              component: Timetable,
              invisible: !hostedPagesSettings?.settings?.timetableNavEnabled,
            },
          ]),

      {
        path: RouteFeature.SiteEnquiry,
        name: "Contact Us",
        component: Enquiry,
        invisible: !hostedPagesSettings?.settings?.leadCaptureNavEnabled,
      },
    ])
    .sort((a, b) => routesOrder.indexOf(a.name) - routesOrder.indexOf(b.name));
  return (
    <>
      <div className="flex items-center justify-between border-b border-b-gray-300 dark:border-gray-700 dark:bg-gray-950 lg:hidden">
        <div className="flex w-full items-center justify-between px-3 py-4">
          <a
            className="flex justify-center"
            href={hostedPagesSettings?.settings.yourWebsiteUrl}
          >
            <img
              src={hostedPagesSettings?.settings.logo}
              alt="logo"
              className="h-8"
            />
          </a>
          <div
            className="cursor-pointer p-2"
            onClick={() => {
              setIsNavDropdownOpen(!isNavDropdownOpen);
            }}
          >
            <MenuIcon />
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          setIsNavDropdownOpen(false);
        }}
        className={cn(
          "pointer-events-none absolute inset-0 bottom-14 top-[calc(4.5rem+1px)] flex transition-all duration-200 lg:hidden",
          {
            "pointer-events-auto z-10 bg-gray-900/50 bg-opacity-50 backdrop-blur-sm":
              isNavDropdownOpen,
          },
        )}
      >
        <Transition
          show={isNavDropdownOpen}
          className="absolute inset-y-0 right-0 w-72 bg-white px-6 py-3 dark:bg-gray-950"
          enter="transition ease duration-300 transform"
          enterFrom="opacity-0 translate-x-12"
          enterTo="opacity-100 -translate-x-0"
          leave="transition ease duration-300 transform"
          leaveFrom="opacity-100 -translate-x-0"
          leaveTo="opacity-0 translate-x-12"
        >
          <div className="flex flex-col gap-2">
            {hostedPagesSettings?.settings?.yourWebsiteUrl && (
              <a
                className={cn(navLinkVariants(), "p-3")}
                href={hostedPagesSettings.settings.yourWebsiteUrl}
              >
                Home
              </a>
            )}
            {routesForLinks.map((r) => {
              if (r.invisible) {
                return null;
              }
              return (
                <NavLink
                  className="p-3"
                  key={r.path}
                  to={createSiteOrEmbedLink(r.path)}
                >
                  {r.name}
                </NavLink>
              );
            })}
          </div>
          <div className="my-2 flex w-full border-b border-gray-300" />
          <div className="flex w-full flex-row gap-2 pt-2">
            {auth.id ? (
              <Button
                className="flex-1"
                onClick={() => {
                  auth.logout();
                }}
              >
                Logout
              </Button>
            ) : (
              <>
                <Button
                  className="flex-1"
                  onClick={async () => {
                    await showLoginOverlay();
                    setIsNavDropdownOpen(false);
                  }}
                >
                  <div className="flex-nowrap">Sign In</div>
                </Button>
                <Button
                  intent="secondary"
                  className="flex-1"
                  onClick={async () => {
                    await showSignUpOverlay();
                    setIsNavDropdownOpen(false);
                  }}
                >
                  <div className="flex-nowrap">Sign Up</div>
                </Button>
              </>
            )}
          </div>
        </Transition>
      </div>
      <div className="hidden h-[4.75rem] items-center justify-between border-b border-b-gray-300 px-4 dark:border-gray-700 dark:bg-gray-950 lg:flex  lg:px-28">
        {hostedPagesSettings?.settings?.logo ? (
          <div className="flex h-10 justify-start">
            {hostedPagesSettings?.settings?.yourWebsiteUrl ? (
              <a
                className="flex justify-center"
                href={hostedPagesSettings.settings.yourWebsiteUrl}
              >
                <img src={hostedPagesSettings.settings.logo} alt="logo" />
              </a>
            ) : (
              <img src={hostedPagesSettings.settings.logo} alt="logo" />
            )}
          </div>
        ) : (
          <div className="bg-gymflow-logo h-10 w-48 bg-contain bg-center bg-no-repeat"></div>
        )}
        <div className="flex items-center justify-self-center">
          {hostedPagesSettings?.settings?.yourWebsiteUrl && (
            <a
              className={cn(navLinkVariants(), "p-3")}
              href={hostedPagesSettings?.settings?.yourWebsiteUrl}
            >
              Home
            </a>
          )}
          {routesForLinks.map((r) => {
            if (r.invisible) {
              return null;
            }
            return (
              <NavLink
                className="p-3"
                key={r.path}
                to={createSiteOrEmbedLink(r.path)}
              >
                {r.name}
              </NavLink>
            );
          })}
        </div>
        <div className="flex items-center gap-4">
          {auth.id ? (
            <Button intent="secondary" onClick={() => auth.logout()}>
              Logout
            </Button>
          ) : (
            <>
              <Button
                onClick={async () => {
                  showLoginOverlay();
                }}
              >
                Sign In
              </Button>
              <Button
                intent="secondary"
                onClick={() => {
                  showSignUpOverlay();
                }}
              >
                Sign Up
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

import { useClubFeatureFlags } from "@gymflow/api";
import {
  FormikInput,
  FormMapper,
  renderErrorRowOnTouch,
  RequiredFieldsLabel,
  TemplateType,
  useRecordForm,
} from "@gymflow/common";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { EmailEditor } from "../atoms";
import Editor from "./Editor";
import useEditorPlugins from "./useEditorPlugins";

const TEMPLATE_NAME = "template-name";
const SUBJECT = "subject";
const BODY = "body";
const TEMPLATE_TYPE = "template-type";

const EmailTemplateSchema = Yup.object().shape({
  [TEMPLATE_NAME]: Yup.string().required().min(3),
  [SUBJECT]: Yup.string().required().min(3),
  [BODY]: Yup.string()
    .transform((value) => {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(value, "text/html");
      return htmlDoc.documentElement.innerText;
    })
    .required()
    .min(3),
  [TEMPLATE_TYPE]: Yup.string()
    .oneOf(Object.values(TemplateType))
    .required()
    .default(TemplateType.Email),
});

function AddTemplateAlert({
  template,
  fetchPlaceholders,
  onCancel,
  onSubmit,
  readOnlyTitle,
}) {
  const { clubId } = useClubSettings();
  const { api } = useGymflowModels();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const [placeholders, setPlaceholders] = useState();
  const emailEditorRef = useRef();
  const { convertEditorNodesToHTML, convertHTMLToEditorNodes } =
    useEditorPlugins({ placeholders });
  const [editorValue, setEditorValue] = useState(
    convertHTMLToEditorNodes("<p></p>"),
  );
  const [editorValueLoaded, setEditorValueLoaded] = useState(false);

  useEffect(() => {
    if (editorValueLoaded || !placeholders) {
      return;
    }

    const body = template?.body || "<p></p>";
    const newEditorValue = featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD ? body : convertHTMLToEditorNodes(body);

    setEditorValue(newEditorValue);
    setEditorValueLoaded(true);
  }, [convertHTMLToEditorNodes, placeholders, template?.body, featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD]);

  const { initialValues, getPatchedValues, getValues } = useRecordForm({
    record: template,
    fields: EmailTemplateSchema.default(),
    mapper: new FormMapper(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async () => {
      if (template) {
        await onSubmit(getPatchedValues(formik.values));
      } else {
        await onSubmit(getValues(formik.values));
      }
    },
    validationSchema: EmailTemplateSchema,
  });
  const { touched, errors, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    async function loadPlaceholders() {
      const result = await fetchPlaceholders();
      setPlaceholders(result);
    }
    loadPlaceholders();
  }, [fetchPlaceholders]);

  useEffect(() => {
    if (!placeholders) {
      return;
    }
    let html = featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD ? editorValue : convertEditorNodesToHTML(editorValue);
    setFieldValue(BODY, html);
  }, [editorValue, convertEditorNodesToHTML, placeholders, setFieldValue, featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD]);

  return (
    !!placeholders &&
    editorValueLoaded && (
      <ReactBSAlert
        title={(template ? "Edit" : "Add") + " Template"}
        showCancel
        closeOnClickOutside={false}
        onCancel={onCancel}
        onConfirm={handleSubmit}
        style={{
          width: "80em",
        }}
      >
        <Form className="form-horizontal" role="form">
          <Row>
            <Col className="text-left">
              <Label tag="h3" htmlFor={TEMPLATE_NAME}>
                Template Name *
              </Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <FormikInput
                  autoComplete="off"
                  data-testid={TEMPLATE_NAME}
                  maxLength="128"
                  name={TEMPLATE_NAME}
                  id={TEMPLATE_NAME}
                  type="text"
                  formikProps={formik}
                  readOnly={readOnlyTitle}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="text-left">
              <Label tag="h3" htmlFor={SUBJECT}>
                Subject *
              </Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <FormikInput
                  autoComplete="off"
                  data-testid={SUBJECT}
                  maxLength="128"
                  name={SUBJECT}
                  id={SUBJECT}
                  type="text"
                  formikProps={formik}
                />
              </FormGroup>
            </Col>
          </Row>
          {/* This is a temporary solution. The entire file will be removed after testing the new email composer */}
          {featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD && (
            <Row>
              <Col>
              <Select
                className="float-left w-52"
                options={Object.values(placeholders).map((p) => ({
                  label: p,
                  value: p,
                }))}
                onChange={(selected) => {
                  const text = "${" + selected.value + "}";
                  emailEditorRef.current?.insertText(text);
                }}
                value={null}
                placeholder="Placeholders"
                isSearchable={false}
              />
              </Col>
            </Row>
          )}
          <Row className="mt-2" style={{ padding: "10px" }}>
            {featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD ? (
              <>
                <EmailEditor
                  ref={emailEditorRef}
                  onChange={setEditorValue}
                  value={editorValue}
                  placeholders={placeholders}
                />
                <FormGroup>
                  {renderErrorRowOnTouch(BODY, touched, errors)}
                </FormGroup>
              </>
            ) : (
              <Col
                className="text-left"
                style={{
                  border: "1px solid rgba(58, 58, 63, 0.5)",
                  borderRadius: "0.4285rem",
                  minHeight: "250px",
                }}
              >
                <FormGroup>
                  <Editor
                    onChange={setEditorValue}
                    value={editorValue}
                    placeholders={placeholders}
                  />
                </FormGroup>
                <FormGroup>
                  {renderErrorRowOnTouch(BODY, touched, errors)}
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="text-left">
              <RequiredFieldsLabel />
            </Col>
          </Row>
        </Form>
      </ReactBSAlert>
    )
  );
}

AddTemplateAlert.defaultProps = {
  readOnlyTitle: false,
};

AddTemplateAlert.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  template: PropTypes.object,
  readOnlyTitle: PropTypes.bool,
  fetchPlaceholders: PropTypes.func.isRequired,
};

export default AddTemplateAlert;

import {
  activityListQueryFn,
  useMutationRuleGroupEdit,
  useMutationRuleGroupNew,
} from "@gymflow/api";
import { FormikInput, onlyNumbersProps } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { RuleGroup, RuleType } from "@gymflow/types";
import { useFormik } from "formik";
import range from "lodash/range";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toFormikValidationSchema } from "zod-formik-adapter";

import useGymflowModels from "../../../store";
import { PaginatedSelect } from "../../atoms";
import { ClassRuleFormType, ClassRuleMapper } from "./ClassRuleMapper";
import { RuleFormWrapper } from "./RuleFormWrapper";
import { RuleMapper } from "./RuleMapper";
import { RulesComponents } from "./RulesComponents";
import { YesNoRadioButtons } from "./YesNoRadioButtons";

export function ClassRuleForm() {
  const { t } = useTranslation();
  const { ruleGroupId } = useParams<{ ruleGroupId: string }>();
  const isEditing = ruleGroupId !== undefined && ruleGroupId !== "";
  return (
    <RuleFormWrapper
      title={
        isEditing
          ? t("page.rules.classForm.headerEdit")
          : t("page.rules.classForm.headerNew")
      }
      description={
        isEditing
          ? t("page.rules.classForm.explanationEdit")
          : t("page.rules.classForm.explanationNew")
      }
      Form={Form}
    />
  );
}

const Form = forwardRef<
  { submitForm: () => Promise<any> },
  | {
      isEditing: true;
      ruleTypeToIdMap: Record<RuleType, number>;
      initialValues: RuleGroup;
    }
  | {
      isEditing: false;
    }
>(function Form(props, ref) {
  const initialValues = useMemo(() => {
    return "initialValues" in props
      ? ClassRuleMapper.mapBEtoFE(props.initialValues)
      : ClassRuleMapper.defaultValues;
  }, [props]);
  const { ruleGroupId } = useParams<{ ruleGroupId: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { mutateAsync: newGroup } = useMutationRuleGroupNew({ api });
  const { mutateAsync: editGroup } = useMutationRuleGroupEdit({ api });

  const formik = useFormik<ClassRuleFormType>({
    initialValues,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: toFormikValidationSchema(ClassRuleMapper.schema),
    onSubmit: async (values) => {
      if (props.isEditing) {
        await editGroup({
          ruleGroupId: +ruleGroupId,
          patchedFields: RuleMapper.mapPostToPatch(
            ClassRuleMapper.mapFEtoBE(values),
            props.ruleTypeToIdMap,
          ),
        });
      } else {
        await newGroup({
          fields: ClassRuleMapper.mapFEtoBE(values),
        });
      }
      history.goBack();
    },
  });
  const { values, setFieldValue, submitForm } = formik;
  useImperativeHandle(ref, () => ({
    submitForm,
  }));
  return (
    <>
      {!values.isDefault && (
        <RulesComponents.FormRow
          label={t("page.rules.commonFields.ruleNameLabel")}
        >
          <FormikInput
            name="name"
            placeholder={t("page.rules.commonFields.ruleNamePlaceholder")}
            formikProps={formik}
          />
        </RulesComponents.FormRow>
      )}
      <RulesComponents.FormRow
        label={t("page.rules.classForm.advancedBookingsLabel")}
        isDisabled={!values.advancedBookings.enabled}
        onChange={(e) => {
          const value: typeof values.advancedBookings = e
            ? {
                enabled: true,
                amount: 1,
                type: "HOURS",
              }
            : {
                enabled: false,
              };
          setFieldValue("advancedBookings", value);
        }}
      >
        {values.advancedBookings.enabled && (
          <RulesComponents.WindowPicker
            label={t("page.rules.classForm.advancedBookingsExplanation")}
            onChange={(newValue) => {
              setFieldValue("advancedBookings", { enabled: true, ...newValue });
            }}
            value={values.advancedBookings}
            availableUnits={["HOURS", "DAYS", "MONTHS"]}
          />
        )}
      </RulesComponents.FormRow>
      <RulesComponents.FormRow
        label={t("page.rules.classForm.bookingNoticeLabel")}
        isDisabled={!values.bookingNoticePeriod.enabled}
        onChange={(e) => {
          const value: typeof values.bookingNoticePeriod = e
            ? {
                enabled: true,
                amount: 1,
                type: "HOURS",
              }
            : {
                enabled: false,
              };
          setFieldValue("bookingNoticePeriod", value);
        }}
      >
        {values.bookingNoticePeriod.enabled && (
          <RulesComponents.WindowPicker
            label={t("page.rules.classForm.bookingNoticeExplanation")}
            onChange={(newValue) => {
              setFieldValue("bookingNoticePeriod", {
                enabled: true,
                ...newValue,
              });
            }}
            value={values.bookingNoticePeriod}
            availableUnits={["MINUTES", "HOURS", "DAYS"]}
          />
        )}
      </RulesComponents.FormRow>

      <RulesComponents.FormRow
        label={t("page.rules.classForm.concurrentBookingsLabel")}
      >
        <RulesComponents.CardLabel
          label={t("page.rules.classForm.concurrentBookingsExplanation")}
        >
          <YesNoRadioButtons
            value={!values.concurrentBookings}
            onChange={(newValue) => {
              setFieldValue("concurrentBookings", !newValue);
            }}
          />
        </RulesComponents.CardLabel>
      </RulesComponents.FormRow>

      <RulesComponents.FormRow
        label={t("page.rules.classForm.bookingLimitsLabel")}
        isDisabled={!values.bookingLimits.enabled}
        onChange={(e) => {
          const value: typeof values.bookingLimits = e
            ? {
                enabled: true,
                amount: 1,
              }
            : {
                enabled: false,
              };
          setFieldValue("bookingLimits", value);
        }}
      >
        <RulesComponents.CardLabel
          label={t("page.rules.classForm.bookingLimitsExplanation")}
        >
          {values.bookingLimits.enabled && (
            <PaginatedSelect
              value={{ label: values.bookingLimits.amount }}
              onChange={(newValue) => {
                setFieldValue("bookingLimits.amount", newValue.value);
              }}
              loadOptions={async () => {
                return {
                  options: range(1, 15).map((e) => ({
                    label: `${e}`,
                    value: e,
                  })),
                };
              }}
            />
          )}
        </RulesComponents.CardLabel>
      </RulesComponents.FormRow>

      <RulesComponents.FormRow
        label={t("page.rules.classForm.cancellationsLabel")}
        tooltip={t("page.rules.classForm.cancellationsTooltip")}
        isDisabled={!values.cancelation.enabled}
        onChange={(e) => {
          const value: typeof values.cancelation = e
            ? {
                enabled: true,
                amount: 1,
                type: "HOURS",
                cancellationFee: {
                  enabled: false,
                },
              }
            : {
                enabled: false,
              };
          setFieldValue("cancelation", value);
        }}
      >
        {values.cancelation?.enabled && (
          <div className="flex w-full flex-col gap-2">
            <RulesComponents.WindowPicker
              label={t("page.rules.classForm.cancellationsExplanation")}
              onChange={(newValue) => {
                setFieldValue("cancelation", {
                  ...values.cancelation,
                  enabled: true,
                  ...newValue,
                });
              }}
              value={values.cancelation}
              availableUnits={["HOURS", "DAYS", "MONTHS"]}
            />

            <RulesComponents.CardLabel
              label={t("page.rules.classForm.cancellationsFeeExplanation")}
            >
              <YesNoRadioButtons
                value={values.cancelation.cancellationFee?.enabled}
                onChange={(newValue) => {
                  const value: typeof values.cancelation.cancellationFee =
                    newValue
                      ? {
                          enabled: true,
                          amount: 1,
                        }
                      : {
                          enabled: false,
                        };
                  setFieldValue("cancelation.cancellationFee", value);
                }}
              />
              <FormikInput
                className={cn("transition-opacity w-16", {
                  "opacity-0": !values.cancelation.cancellationFee?.enabled,
                })}
                name="cancelation.cancellationFee.amount"
                formikProps={formik}
                {...onlyNumbersProps}
              />
            </RulesComponents.CardLabel>
          </div>
        )}
      </RulesComponents.FormRow>

      <RulesComponents.FormRow
        label={t("page.rules.classForm.noShowsLabel")}
        tooltip={t("page.rules.classForm.noShowsTooltip")}
      >
        <RulesComponents.CardLabel
          label={t("page.rules.classForm.noShowsExplanation")}
        >
          <YesNoRadioButtons
            value={values.noShowFee.enabled}
            onChange={(newValue) => {
              const value: typeof values.noShowFee = newValue
                ? {
                    enabled: true,
                    amount: 1,
                  }
                : {
                    enabled: false,
                  };
              setFieldValue("noShowFee", value);
            }}
          />
          <FormikInput
            className={cn("transition-opacity w-16", {
              "opacity-0": !values.noShowFee.enabled,
            })}
            name="noShowFee.amount"
            formikProps={formik}
            {...onlyNumbersProps}
          />
        </RulesComponents.CardLabel>
      </RulesComponents.FormRow>

      {!values.isDefault && (
        <RulesComponents.FormRow
          label={t("page.rules.classForm.applyToClassesLabel")}
        >
          <div className="flex w-full flex-col gap-2">
            <div>{t("page.rules.classForm.applyToClassesExplanation")}</div>

            <PaginatedSelect
              menuPlacement="top"
              value={values.classList}
              isMulti
              placeholder="Select..."
              onChange={(newValue) => {
                setFieldValue("classList", newValue);
              }}
              loadOptions={async (_, __, additional) => {
                const data = await activityListQueryFn({
                  api,
                  opts: {
                    page: additional.page,
                    size: 10,
                    status: "ACTIVE",
                  },
                });

                return {
                  options: data.content.map((classDTO) => ({
                    label: classDTO.name,
                    value: classDTO.id,
                  })),
                  hasMore: !data.last,
                  additional: {
                    page: additional.page + 1,
                  },
                };
              }}
              badgeClearIndicator
            />
          </div>
        </RulesComponents.FormRow>
      )}
    </>
  );
});

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMutationActivityCategoryCreate,
  useMutationActivityCategoryEditStatus,
  useQueryActivityCategoryList,
} from "@gymflow/api";
import useGymflowModels from "apps/portal/src/store";
import React, { useContext, useState } from "react";

import { ModalWrapper } from "../../../providers";
import { Button, TextInput } from "../../atoms";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";

export interface ActivityCategoryModalProps {
  onCancel: () => void;
}

export const ActivityCategoryModal: React.FC<ActivityCategoryModalProps> = ({
  onCancel,
}) => {
  const { api } = useGymflowModels();
  const { data: categories } = useQueryActivityCategoryList({
    api,
    filter: {
      statusList: ["ACTIVE"],
      size: 100,
      page: 0,
    },
  });
  const createCategoryMutation = useMutationActivityCategoryCreate({ api });
  const editStatusMutation = useMutationActivityCategoryEditStatus({ api });
  const [newCategoryName, setNewCategoryName] = useState("");
  const { notifyDanger } = useContext(ToastContext);
  return (
    <ModalWrapper
      className="flex flex-col gap-y-4 sm:!max-w-xl"
      onCancel={onCancel}
    >
      <div className="flex w-full flex-row items-start justify-between">
        <div className="flex flex-col">
          <div className="flex text-lg font-semibold text-gray-900">
            Class Categories
          </div>
          <div className="flex text-sm text-gray-600">
            Add or remove class categories
          </div>
        </div>
        <FontAwesomeIcon
          icon={faClose}
          onClick={onCancel}
          className="h-5 w-5 cursor-pointer text-gray-500"
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <div className="text-sm font-medium text-gray-700">Category</div>
        <div className="flex flex-row items-center gap-x-4">
          <TextInput
            placeholder="Enter new category"
            type="text"
            value={newCategoryName}
            onChange={(e) => {
              setNewCategoryName(e.currentTarget.value);
            }}
          />
          <Button
            onClick={async () => {
              if (newCategoryName.length > 0) {
                await createCategoryMutation
                  .mutateAsync({
                    name: newCategoryName,
                    color: "#ffffff",
                  })
                  .catch((e) => {
                    notifyDanger(e);
                  });
                setNewCategoryName("");
              }
            }}
          >
            Add
          </Button>
        </div>
      </div>

      {categories.content.map((e) => {
        return (
          <div key={e.id} className="flex flex-row justify-between">
            <div className="text-sm text-gray-700">{e.name}</div>
            <FontAwesomeIcon
              icon={faClose}
              onClick={() => {
                editStatusMutation.mutateAsync({
                  activityCategoryId: e.id,
                  newStatus: "DISABLED",
                });
              }}
              className="h-5 w-5 cursor-pointer text-gray-500"
            />
          </div>
        );
      })}

      <div className="flex space-x-2">
        <Button onClick={onCancel} className="flex-1">
          Close
        </Button>
      </div>
    </ModalWrapper>
  );
};

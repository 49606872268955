import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { migrationQueryKeys } from "./migrationQueryKeys";

export function useMutationMigrationPaymentMethodSetupIntentAuthorized({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      params: Parameters<
        ApiType["public"]["hostedClubApi"]["invitationPaymentMethodSetupIntentAuthorized"]
      >[0],
    ) => {
      await api.public.hostedClubApi.invitationPaymentMethodSetupIntentAuthorized(
        params,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(migrationQueryKeys.all());
    },
  });
}

export function LocationMarkerIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        className={pathClassName}
        d="M8.00033 8.66665C9.10489 8.66665 10.0003 7.77122 10.0003 6.66665C10.0003 5.56208 9.10489 4.66665 8.00033 4.66665C6.89576 4.66665 6.00033 5.56208 6.00033 6.66665C6.00033 7.77122 6.89576 8.66665 8.00033 8.66665Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M8.00033 14.6666C10.667 12 13.3337 9.61217 13.3337 6.66665C13.3337 3.72113 10.9458 1.33331 8.00033 1.33331C5.05481 1.33331 2.66699 3.72113 2.66699 6.66665C2.66699 9.61217 5.33366 12 8.00033 14.6666Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import {
  SendEmailToLeadFilterProps,
  SendEmailToMemberFilterProps,
} from "apps/portal/src/hooks";
import { createContext } from "react";

export type RequestType = "LEAD" | "USER";

// For Leads: listOfIds = array of numbers OR filter = SendEmailToLeadFilterProps
// OR
// For Users (members): listOfIds = array of strings OR filter = SendEmailToMemberFilterProps
export type OpenSendEmailSidebarOptions = {
  recipient: string;
  allowMarketing: boolean;
  requestType: RequestType;
  onSuccess?: () => void;
} & (
  | { listOfIds: number[]; filter?: never }
  | { filter: SendEmailToLeadFilterProps; listOfIds?: never }
  | { listOfIds: string[]; filter?: never }
  | { filter: SendEmailToMemberFilterProps; listOfIds?: never }
);

interface SendEmailSidebarProviderContextType {
  openSendEmailSidebar: (values: OpenSendEmailSidebarOptions) => void;
}

const defaultContext: SendEmailSidebarProviderContextType = {
  openSendEmailSidebar: () => {
    throw new Error(
      "openSendEmailSidebar function must be used within SendEmailSidebarProvider",
    );
  },
};

export const SendEmailSidebarProviderContext =
  createContext<SendEmailSidebarProviderContextType>(defaultContext);

const smsApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, {
    sendSMSToMember({ body, userMemberId }) {
      return state.http.post(
        `${clubPrefix}user-member-send-sms/${userMemberId}`,
        { body },
      );
    },
    sendSMSToMembers({ smsPayload, userMemberIds }) {
      return state.http.post(`${clubPrefix}user-member-send-sms/no-filter`, {
        smsPayload,
        userMemberIds,
      });
    },
    sendSMSToMembersFilter({
      smsPayload,
      userMemberIdsToExclude,
      createdFrom,
      createdTo,
      smsCommunication,
      emailCommunication,
      membershipIdList,
      membershipTypeList,
      membershipStartFrom,
      membershipStartTo,
      membershipCancellationFrom,
      membershipCancellationTo,
      membershipExpireFrom,
      membershipExpireTo,
      assignedStaffIdList,
      creditsRemainingFrom,
      creditsRemainingTo,
      membershipStatusList,
    }) {
      return state.http.post(`${clubPrefix}user-member-send-sms/filter`, {
        smsPayload,
        userMemberIdsToExclude,
        createdFrom,
        createdTo,
        smsCommunication,
        emailCommunication,
        membershipIdList,
        membershipTypeList,
        membershipStartFrom,
        membershipStartTo,
        membershipCancellationFrom,
        membershipCancellationTo,
        membershipExpireFrom,
        membershipExpireTo,
        assignedStaffIdList,
        creditsRemainingFrom,
        creditsRemainingTo,
        membershipStatusList,
      });
    },
    sendSMSToLead({ leadId, body }) {
      return state.http.post(`${clubPrefix}lead-send-sms/${leadId}`, {
        body,
      });
    },
    sendSMSToLeads({ smsPayload, leadIds }) {
      return state.http.post(`${clubPrefix}lead-send-sms/no-filter`, {
        smsPayload,
        leadIds,
      });
    },
    sendSMSToLeadsFilter({
      smsPayload,
      leadIdsToExclude,
      createdFrom,
      createdTo,
      smsCommunication,
      emailCommunication,
      leadStatusIdList,
      leadSourceIdList,
    }) {
      return state.http.post(`${clubPrefix}lead-send-sms/filter`, {
        smsPayload,
        leadIdsToExclude,
        createdFrom,
        createdTo,
        smsCommunication,
        emailCommunication,
        leadStatusIdList,
        leadSourceIdList,
      });
    },
    getCreditsHistoryTopUps({ limit, nextPageToken }) {
      return state.http.get(`${clubPrefix}messaging-history/credits/top-ups`, {
        params: { limit, nextPageToken },
      });
    },
    getCreditsHistorySpent({ limit, nextPageToken }) {
      return state.http.get(`${clubPrefix}messaging-history/credits/spent`, {
        params: { limit, nextPageToken },
      });
    },
    getStripeProducts() {
      return state.http.get(`${clubPrefix}stripe-product`);
    },
  });
};

export default smsApi;

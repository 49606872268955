import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutationClubEditSettings } from "@gymflow/api";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import useGymflowModels from "../../../store";
import { Spinner, Switch, TextInput } from "../../atoms";

interface PageMenuProps {
  readonly value?: {
    readonly membershipsNavEnabled: boolean;
    readonly creditPacksNavEnabled: boolean;
    readonly productNavEnabled: boolean;
    readonly appointmentsNavEnabled: boolean;
    readonly timetableNavEnabled: boolean;
    readonly leadCaptureNavEnabled: boolean;
    readonly yourWebsiteUrl: string;
    readonly redirectUrl: string;
  };
}

export function PageMenu({
  value = {
    membershipsNavEnabled: false,
    creditPacksNavEnabled: false,
    productNavEnabled: false,
    appointmentsNavEnabled: false,
    timetableNavEnabled: false,
    leadCaptureNavEnabled: false,
    yourWebsiteUrl: "",
    redirectUrl: "",
  },
}: PageMenuProps) {
  const { api } = useGymflowModels();
  const editSettingsMutation = useMutationClubEditSettings({ api });

  const [yourWebsiteUrl, setYourWebsiteUrl] = useState(value.yourWebsiteUrl);
  const [isSavingWebsiteUrl, setIsSavingWebsiteUrl] = useState(false);
  const [yourWebsiteUrlError, setYourWebsiteUrlError] = useState("");
  const [redirectUrl, setRedirectUrl] = useState(value.redirectUrl);
  const [isSavingRedirectUrl, setIsSavingRedirectUrl] = useState(false);
  const [redirectUrlError, setRedirectUrlError] = useState("");

  useEffect(() => {
    if (value.yourWebsiteUrl) {
      setYourWebsiteUrl(value.yourWebsiteUrl);
    }
  }, [value.yourWebsiteUrl]);

  useEffect(() => {
    if (value.redirectUrl) {
      setRedirectUrl(value.redirectUrl);
    }
  }, [value.redirectUrl]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-8">
      <div className="md:grid-cols-subgrid md:col-span-3 md:grid">
        <div className="md:col-start-1 md:col-end-3">
          <div className="text-base font-semibold">Page Menu</div>
          <div className="font-normal text-gray-600">
            Customize your navigation menu for your web pages and include links
            back to your site.
          </div>
        </div>
      </div>
      <div
        className="flex flex-col gap-4 rounded-xl border border-gray-200 bg-white p-6 shadow-sm md:col-span-5
        "
      >
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Memberships</div>
          <div className="flex gap-2">
            <Switch
              value={value.membershipsNavEnabled}
              onChange={async (checked) => {
                await editSettingsMutation.mutateAsync({
                  fields: {
                    membershipsNavEnabled: checked,
                  },
                });
              }}
              label="Memberships"
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Credit Packs</div>
          <div className="flex gap-2">
            <Switch
              value={value.creditPacksNavEnabled}
              onChange={async (checked) => {
                await editSettingsMutation.mutateAsync({
                  fields: {
                    creditPacksNavEnabled: checked,
                  },
                });
              }}
              label="Credit Packs"
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Appointments</div>
          <div className="flex gap-2">
            <Switch
              value={value.appointmentsNavEnabled}
              onChange={async (checked) => {
                await editSettingsMutation.mutateAsync({
                  fields: {
                    appointmentsNavEnabled: checked,
                  },
                });
              }}
              label="Appointments"
            />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Schedule</div>
          <div className="flex gap-2">
            <Switch
              value={value.timetableNavEnabled}
              onChange={async (checked) => {
                await editSettingsMutation.mutateAsync({
                  fields: {
                    timetableNavEnabled: checked,
                  },
                });
              }}
              label="Schedule"
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">
            Contact Us (Lead Capture)
          </div>
          <div className="flex gap-2">
            <Switch
              value={value.leadCaptureNavEnabled}
              onChange={async (checked) => {
                await editSettingsMutation.mutateAsync({
                  fields: {
                    leadCaptureNavEnabled: checked,
                  },
                });
              }}
              label="Contact Us (Lead Capture)"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-sm font-semibold">
            <div className="inline-block">Your Website URL</div>
            <FontAwesomeIcon
              data-tooltip-id="website-url"
              data-tooltip-content={
                'We will send users back to your website URL when they click "Home" or your logo.'
              }
              className="ml-2 cursor-pointer"
              icon={faQuestionCircle}
            />
            <Tooltip
              className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
              id="website-url"
            />
          </div>
          <div>
            {isSavingWebsiteUrl ? (
              <Spinner />
            ) : (
              <TextInput
                value={yourWebsiteUrl}
                onChange={({ currentTarget: { value } }) => {
                  setYourWebsiteUrlError("");
                  setYourWebsiteUrl(value);
                }}
                onBlur={async ({ currentTarget: { value } }) => {
                  if (value === "" || isValidUrl(value)) {
                    setIsSavingWebsiteUrl(true);
                    await editSettingsMutation.mutateAsync({
                      fields: {
                        yourWebsiteUrl: value,
                      },
                    });
                    setIsSavingWebsiteUrl(false);
                  } else {
                    setYourWebsiteUrlError(
                      "URL is not valid. Make sure it starts with http or https.",
                    );
                  }
                }}
              />
            )}
          </div>

          {yourWebsiteUrlError && (
            <div className="text-error-700 text-sm">{yourWebsiteUrlError}</div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-sm font-semibold">
            <div className="inline-block">Redirect URL</div>
            <FontAwesomeIcon
              data-tooltip-id="redirect-url"
              data-tooltip-content="We will redirect users back to this URL when they complete a purchase, booking or submission."
              className="ml-2 cursor-pointer"
              icon={faQuestionCircle}
            />
            <Tooltip
              className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
              id="redirect-url"
            />
          </div>
          <div>
            {isSavingRedirectUrl ? (
              <Spinner />
            ) : (
              <TextInput
                value={redirectUrl}
                onChange={({ currentTarget: { value } }) => {
                  setRedirectUrlError("");
                  setRedirectUrl(value);
                }}
                onBlur={async ({ currentTarget: { value } }) => {
                  if (value === "" || isValidUrl(value)) {
                    setIsSavingRedirectUrl(true);
                    await editSettingsMutation.mutateAsync({
                      fields: {
                        redirectUrl: value,
                      },
                    });
                    setIsSavingRedirectUrl(false);
                  } else {
                    setRedirectUrlError(
                      "URL is not valid. Make sure it starts with http or https.",
                    );
                  }
                }}
              />
            )}
          </div>
          {redirectUrlError && (
            <div className="text-error-700 text-sm">{redirectUrlError}</div>
          )}
        </div>
      </div>
    </div>
  );
}

function isValidUrl(urlString: string) {
  let urlObject;
  try {
    urlObject = new URL(urlString);
  } catch {
    return false;
  }
  return urlObject.protocol === "https:" || urlObject.protocol === "http:";
}

import { useLead, useMember } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { ReactNode, useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { copyToClipboard } from "../../helpers";
import { usePortalRoutes } from "../../hooks";
import { useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import { RouteFeature, RouteLayout } from "../../routes";
import useGymflowModels from "../../store";
import { Button, CopyIcon, Spinner } from "../atoms";
import { Popover, PopoverContent, PopoverTrigger } from "../atoms/Popover";
import { NewUserSideBarProviderContext } from "../molecules";

const timeoutDuration = 120;
export function MemberCard({
  isOpen,
  leadId,
  memberId,
  children,
}: {
  isOpen: boolean;
  leadId: number;
  memberId?: string;
  children: ReactNode;
}) {
  const timeOutRef = useRef<any>();

  const [innerIsOpen, setInnerIsOpen] = useState(isOpen);
  const handleEnter = () => {
    clearTimeout(timeOutRef.current);
    setInnerIsOpen(true);
  };

  const handleLeave = () => {
    timeOutRef.current = setTimeout(() => {
      setInnerIsOpen(false);
    }, timeoutDuration);
  };

  return (
    <Popover open={isOpen || innerIsOpen}>
      <PopoverTrigger
        asChild
        onMouseEnter={() => handleEnter()}
        onMouseLeave={() => handleLeave()}
      >
        {children}
      </PopoverTrigger>
      <PopoverContent>
        <div className="flex w-full flex-col gap-2 p-4">
          {memberId ? (
            <MemberContents memberId={memberId} />
          ) : (
            <LeadContents leadId={leadId} />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}

function LeadContents({ leadId }: { leadId: number }) {
  const { api } = useGymflowModels();
  const { data, isFetching } = useLead({ api, leadId });
  const { open } = useContext(NewUserSideBarProviderContext);
  const { createClubLink } = usePortalRoutes();
  const history = useHistory();
  const { toast } = useContext(ToastContext);

  if (isFetching || !data) {
    return <Spinner />;
  }
  return (
    <>
      <div className="flex gap-4 text-sm font-normal text-gray-700">
        <div>{data.email}</div>
        <div
          className="cursor-pointer stroke-gray-400 hover:stroke-gray-500 active:stroke-gray-300"
          onClick={async () => {
            const result = await copyToClipboard(data.email);
            if (result) {
              toast({ message: "Copied email to clipboard." });
            } else {
              toast({
                message: "Couldn't copy email to clipboard: " + data.email,
                intent: "error",
              });
            }
          }}
        >
          <CopyIcon />
        </div>
      </div>
      <div
        className={cn("flex gap-4 text-sm font-normal text-gray-700", {
          hidden: !data.mobileNumber,
        })}
      >
        <div>{data.mobileNumber}</div>
        <div
          className="cursor-pointer stroke-gray-400 hover:stroke-gray-500 active:stroke-gray-300"
          onClick={async () => {
            const result = await copyToClipboard(data.mobileNumber);
            if (result) {
              toast({ message: "Copied mobileNumber to clipboard." });
            } else {
              toast({
                message:
                  "Couldn't copy mobileNumber to clipboard: " +
                  data.mobileNumber,
                intent: "error",
              });
            }
          }}
        >
          <CopyIcon />
        </div>
      </div>
      <div className="text-sm font-normal text-gray-700">
        {data.source.name}
      </div>
      <div className="flex">
        <Button
          intent="secondary-outline"
          className="flex-1"
          onClick={() => {
            open({
              creationMode: "MEMBER",
              defaultFormValues: {
                email: data.email,
                "mobile-number": data.mobileNumber,
                "first-name": data.firstName,
                "last-name": data.lastName,
                source: { name: data.source.name, id: data.source.id },
                "sms-communication": data.smsCommunication,
                "email-communication": data.emailCommunication,
              },
              onClose: async (arg) => {
                if (arg?.userType === "MEMBER") {
                  history.push(
                    createClubLink(
                      RouteLayout.Staff,
                      RouteFeature.UserMember.replace(":id", arg.userMemberId),
                    ),
                  );
                }
              },
            });
          }}
        >
          Setup Account
        </Button>
      </div>
    </>
  );
}

function MemberContents({ memberId }: { memberId: string }) {
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();
  const { data, isFetching } = useMember({ api, memberId, tz: timezone });
  const { createClubLink } = usePortalRoutes();
  const history = useHistory();
  const { toast } = useContext(ToastContext);

  if (isFetching || !data) {
    return <Spinner />;
  }
  return (
    <>
      <div className="text-lg font-bold text-gray-900">
        {data.user.firstName} {data.user.lastName}
      </div>
      <div className="flex gap-4 text-sm font-normal text-gray-700">
        <div>{data.user.email}</div>
        <div
          className="cursor-pointer stroke-gray-400 hover:stroke-gray-500 active:stroke-gray-300"
          onClick={async () => {
            const result = await copyToClipboard(data.user.email);
            if (result) {
              toast({ message: "Copied email to clipboard." });
            } else {
              toast({
                message: "Couldn't copy email to clipboard: " + data.user.email,
                intent: "error",
              });
            }
          }}
        >
          <CopyIcon />
        </div>
      </div>
      <div
        className={cn("flex gap-4 text-sm font-normal text-gray-700", {
          hidden: !data.user.mobileNumber,
        })}
      >
        <div>{data.user.mobileNumber}</div>
        <div
          className="cursor-pointer stroke-gray-400 hover:stroke-gray-500 active:stroke-gray-300"
          onClick={async () => {
            const result = await copyToClipboard(data.user.mobileNumber);
            if (result) {
              toast({ message: "Copied mobile number to clipboard." });
            } else {
              toast({
                message:
                  "Couldn't copy mobile number to clipboard: " +
                  data.user.mobileNumber,
                intent: "error",
              });
            }
          }}
        >
          <CopyIcon />
        </div>
      </div>
      <div className="text-sm font-normal text-gray-700">
        {data.user.lead.source.name}
      </div>
      <div className="flex">
        <Button
          className="flex-1"
          onClick={() => {
            history.push(
              createClubLink(
                RouteLayout.Staff,
                RouteFeature.UserMember.replace(":id", memberId),
              ),
            );
          }}
        >
          View Profile
        </Button>
      </div>
    </>
  );
}

import {
  Button,
  CloseIcon,
  FormInput,
  FormRadioButtonGroup,
  FormStaffSelectInput,
  FormTextarea,
  FormTimeSelect,
  FormUsersSearchInput,
  Trash2Icon,
} from "apps/portal/src/components/atoms";
import { FormikProvider, useFormik } from "formik";

import { FormSingleDatePicker } from "../../../../molecules";
import {
  INITIAL_VALUES_TASK_FORM,
  STATUS_OPTIONS,
  TASK_DETAILS_ROWS,
  taskFormSchema,
} from "./constants";
import { TaskFormProps, TaskFormValues, TaskStatus } from "./types";

export const TaskForm = ({
  initialValues = INITIAL_VALUES_TASK_FORM,
  title,
  description,
  onClose,
  submitButtonText,
  onSubmit,
  onDelete,
}: TaskFormProps) => {
  const formik = useFormik<TaskFormValues>({
    initialValues: { ...INITIAL_VALUES_TASK_FORM, ...initialValues },
    validationSchema: taskFormSchema,
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col justify-between border-b border-b-gray-200 p-8">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-lg font-semibold text-gray-900">{title}</div>
              <div className="cursor-pointer" onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
            <div className="pr-16 text-base text-gray-600">{description}</div>
          </div>
          <div className="flex flex-col gap-4 px-8 py-4">
            <FormInput<TaskFormValues>
              name="taskName"
              label="Task Name"
              placeholder="Enter a name"
              isRequired
            />
            <FormTextarea<TaskFormValues>
              name="taskDetails"
              label="Task Details"
              placeholder="Enter a name"
              rows={TASK_DETAILS_ROWS}
            />
            <FormSingleDatePicker<TaskFormValues>
              name="dueDate"
              labelUnderPicker="Due Date"
            />
            <FormTimeSelect<TaskFormValues>
              name="dueTime"
              label="Due Time"
              fullWidth
            />
            <FormUsersSearchInput<TaskFormValues>
              name="relatedUsers"
              label="Related Users"
              dropPosition="top"
              withImageByDefault
              isMulti
            />
            <FormStaffSelectInput<TaskFormValues>
              name="taskOwners"
              label="Task Owners"
              isMulti
              dropPosition="top"
              withImageByDefault
            />
            <FormRadioButtonGroup<TaskFormValues, TaskStatus>
              name="status"
              label="Status"
              options={STATUS_OPTIONS}
            />
          </div>
          {!!onDelete && (
            <div className="my-4 flex justify-center">
              <Button
                intent="error-outline"
                size="small"
                className="gap-1.5"
                onClick={onDelete}
              >
                <Trash2Icon />
                Delete Task
              </Button>
            </div>
          )}
        </div>
        <div className="flex items-center justify-between gap-4 border-t border-t-gray-200 bg-gray-50 p-4">
          <Button intent="default" className="w-full" onClick={onClose}>
            Cancel
          </Button>
          <Button
            intent="secondary"
            className="w-full"
            onClick={async () => await formik.submitForm()}
          >
            {submitButtonText}
          </Button>
        </div>
      </div>
    </FormikProvider>
  );
};

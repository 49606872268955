import { cn } from "@gymflow/helpers";

import { OptionImage } from "./OptionImage";

type SelectedOptionItemProps = {
  className?: string;
  disabled?: boolean;
  show: boolean;
  imageUrl?: string;
  label: string;
  subLabel?: string;
  withImageByDefault: boolean;
};

export const SelectedOptionItem = ({
  className,
  disabled = false,
  show,
  withImageByDefault,
  imageUrl,
  label,
  subLabel,
}: SelectedOptionItemProps) =>
  show ? (
    <div
      className={cn(
        "pointer-events-none absolute inset-y-0.5 left-3.5 right-14 flex cursor-pointer items-center gap-2",
        { "opacity-50 cursor-not-allowed": disabled },
        className,
      )}
    >
      <OptionImage
        imageUrl={imageUrl}
        label={label}
        withImageByDefault={withImageByDefault}
      />
      <div
        className={cn("truncate text-base font-medium text-gray-950", {
          "text-gray-500": disabled,
        })}
      >
        {label}
      </div>
      <div className="truncate text-base text-gray-500">{subLabel}</div>
    </div>
  ) : null;

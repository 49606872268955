import { QueryKey } from "@tanstack/react-query";

export const calendarAsMemberQueryKeys = {
  all: () => ["calendarAsMember"] as QueryKey,
  /*** @deprecated */
  eventOccurrencesOld: (filters?: Readonly<{ [k: string]: any }>) => {
    if (filters) {
      return [
        ...calendarAsMemberQueryKeys.all(),
        "eventOccurrencesOld",
        { filters },
      ] as QueryKey;
    } else {
      return [
        ...calendarAsMemberQueryKeys.all(),
        "eventOccurrencesOld",
      ] as QueryKey;
    }
  },
  eventOccurrences: (filters?: Readonly<{ [k: string]: any }>) => {
    if (filters) {
      return [
        ...calendarAsMemberQueryKeys.all(),
        "eventOccurrences",
        { filters },
      ] as QueryKey;
    } else {
      return [
        ...calendarAsMemberQueryKeys.all(),
        "eventOccurrences",
      ] as QueryKey;
    }
  },
  appointments: (filters?: Readonly<{ [k: string]: any }>) => {
    if (filters) {
      return [
        ...calendarAsMemberQueryKeys.all(),
        "appointments",
        { filters },
      ] as QueryKey;
    } else {
      return [...calendarAsMemberQueryKeys.all(), "appointments"] as QueryKey;
    }
  },
  userMemberBookingAgenda: (filters?: Readonly<{ [k: string]: any }>) => {
    if (filters) {
      return [
        ...calendarAsMemberQueryKeys.all(),
        "userMemberBookingAgenda",
        { filters },
      ] as QueryKey;
    } else {
      return [
        ...calendarAsMemberQueryKeys.all(),
        "userMemberBookingAgenda",
      ] as QueryKey;
    }
  },
};

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationHostAvailabilityOverrideEdit({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (params: {
      staffId: Parameters<ApiType["availabilityApi"]["updateHostOverride"]>[0];
      availabilityOverrideId: Parameters<
        ApiType["availabilityApi"]["updateHostOverride"]
      >[1];
      patchedFields: Parameters<
        ApiType["availabilityApi"]["updateHostOverride"]
      >[2];
    }) => {
      const response = await api.availabilityApi.updateHostOverride(
        params.staffId,
        params.availabilityOverrideId,
        params.patchedFields,
      );
      return response.data;
    },
    onSuccess: (_, { staffId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allHostOverrides(staffId),
      });
    },
  });

  return mutation;
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { ActivityCategoryDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityQueryKeys } from "./activityQueryKeys";

export async function activityCategoryListQueryFn({
  api,
  filter,
}: {
  api: ApiType;
  filter: Parameters<ApiType["activityApi"]["findCategories"]>[0];
}) {
  const result = await api.activityApi.findCategories(filter);
  return result.data;
}

export function useQueryActivityCategoryList({
  api,
  filter,
}: {
  api: ApiType;
  filter: Parameters<ApiType["activityApi"]["findCategories"]>[0];
}) {
  const result = useQuery({
    queryKey: activityQueryKeys.categories(filter),
    queryFn: () => activityCategoryListQueryFn({ api, filter }),
    initialData: defaultPage<ActivityCategoryDTO>,
  });
  return result;
}

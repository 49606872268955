import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryHostAvailabilityCalculateMappedByHost(
  {
    api,
    tz,
    params,
  }: {
    api: ApiType;
    tz: string;
    params?: Parameters<
      ApiType["availabilityApi"]["hostCalculateAvailabilityMappedByHost"]
    >[0];
  },
  opts?: UseQueryOptions<
    | Awaited<
        ReturnType<
          ApiType["availabilityApi"]["hostCalculateAvailabilityMappedByHost"]
        >
      >["data"]
    | undefined
    | null
  >,
) {
  const result = useQuery({
    queryKey:
      availabilityQueryKeys.hostCalculateAvailabilityMappedByHost(params),
    queryFn: async () => {
      const utcDateFrom = zonedTimeToUtc(params!.dateFrom, tz);
      const utcDateTo = zonedTimeToUtc(params!.dateTo, tz);

      const result =
        await api.availabilityApi.hostCalculateAvailabilityMappedByHost({
          ...params,
          dateFrom: utcDateFrom,
          dateTo: utcDateTo,
        });
      return result.data;
    },
    select: (data) =>
      hostCalculateAvailabilityMappedByHostSelectFn({ data, tz }),
    enabled: !!params,
    initialData: null,
    ...opts,
  });

  return result;
}

export function hostCalculateAvailabilityMappedByHostSelectFn({
  data,
  tz,
}: {
  data?:
    | Awaited<
        ReturnType<
          ApiType["availabilityApi"]["hostCalculateAvailabilityMappedByHost"]
        >
      >["data"]
    | null;
  tz: string;
}) {
  if (!data) {
    return data;
  }
  return Object.keys(data).reduce(
    (acc, hostId) => {
      const timezoneParsedStaffAvailability = data[hostId]
        .slice(0)
        .map((availabilityLine) => ({
          ...availabilityLine,
          startTime: utcToZonedTime(availabilityLine.startTime, tz),
          endTime: utcToZonedTime(availabilityLine.endTime, tz),
        }));
      acc[hostId] = timezoneParsedStaffAvailability;
      return acc;
    },
    {} as Awaited<
      ReturnType<
        ApiType["availabilityApi"]["hostCalculateAvailabilityMappedByHost"]
      >
    >["data"],
  );
}

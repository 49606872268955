import {
  ActivityCategoryDTO,
  ActivityDTO,
  ActivityStatus,
  ApiListResponse,
} from "@gymflow/types";

import { createPublicAxiosInstance } from "./publicAxiosInstance";
import { ApiPageableRequestParams } from "./types";

export type PublicActivityApiType = ReturnType<typeof publicActivityApi>;

const publicActivityApi = (apiUrl: string, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  const axiosInstance = createPublicAxiosInstance({
    apiUrl,
  });

  return {
    find(
      params: ApiPageableRequestParams & {
        status?: ActivityStatus;
        id?: number[];
      },
    ) {
      return axiosInstance.get<ApiListResponse<ActivityDTO>>(`${clubPrefix}hosted/activity`, { params });
    },
    async fetchCategories() {
      const result = await axiosInstance.put(
        `${clubPrefix}hosted/activity/category/search`,
        {
          page: 0,
          size: 1000,
        },
      );
      result.data = result.data.content;
      return result;
    },
    findCategories(
      params: ApiPageableRequestParams & {
        statusList?: ActivityStatus[];
      },
    ) {
      return axiosInstance.put<ApiListResponse<ActivityCategoryDTO>>(
        `${clubPrefix}hosted/activity/category/search`,
        params,
      );
    },
    findCategoryById(id: number) {
      return axiosInstance.get<ActivityCategoryDTO>(
        `${clubPrefix}hosted/activity/category/${id}`,
      );
    },
  };
};

export default publicActivityApi;

import { ReactNode, useCallback, useState } from "react";

import { SlideSideBar } from "../../atoms";
import { SendEmailForm } from "./components/SendEmailForm/SendEmailForm";
import {
  OpenSendEmailSidebarOptions,
  SendEmailSidebarProviderContext,
} from "./SendEmailSidebarProviderContext";

export type SendEmailSidebarProviderProps = {
  children: ReactNode;
};

export const SendEmailSidebarProvider = ({
  children,
}: SendEmailSidebarProviderProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [sidebarState, setSidebarState] = useState<OpenSendEmailSidebarOptions>(
    {
      recipient: "",
      allowMarketing: false,
      requestType: "LEAD",
      listOfIds: [],
    },
  );

  const closeSidebar = useCallback(() => {
    setIsVisible(false);
  }, []);

  const openSendEmailSidebar = useCallback(
    (options: OpenSendEmailSidebarOptions) => {
      setSidebarState(options);
      setIsVisible(true);
    },
    [],
  );

  return (
    <SendEmailSidebarProviderContext.Provider value={{ openSendEmailSidebar }}>
      <SlideSideBar
        isOpen={isVisible}
        hide={closeSidebar}
        className="!w-full lg:!w-1/2"
      >
        <SendEmailForm onClose={closeSidebar} {...sidebarState} />
      </SlideSideBar>
      {children}
    </SendEmailSidebarProviderContext.Provider>
  );
};

import { EventOccurrenceDTO } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { calendarQueryKeys } from "../calendar";
import { eventOccurrenceQueryKeys } from "./eventOccurrenceQueryKeys";
import { eventOccurrenceToUtc } from "./eventOccurrenceToUtc";

// remove any (just "copy-past" from previous variant); We have it because of SideBarOccurrenceForm
export type EventOccurrenceCreateVariables = {
  fields: Record<string, any>;
  ignoreAvailabilityValidation?: boolean;
};

// remove unknown in eventApi.create (just "copy-past" from previous variant); We have it because of SideBarOccurrenceForm
export function useEventOccurrenceCreate(
  {
    api,
    tz,
  }: {
    api: {
      eventApi: {
        create: (
          fields: unknown,
          ignoreAvailabilityValidation?: boolean,
        ) => Promise<{ data: EventOccurrenceDTO }>;
      };
    };
    tz: string;
  },
  mutationOpts?: UseMutationOptions<
    EventOccurrenceDTO,
    unknown,
    EventOccurrenceCreateVariables
  >,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      ignoreAvailabilityValidation,
      fields,
    }: EventOccurrenceCreateVariables) => {
      const { data } = await api.eventApi.create(
        eventOccurrenceToUtc(fields, tz),
        ignoreAvailabilityValidation,
      );
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.eventOccurrences(),
      });
    },
    ...mutationOpts,
  });

  return mutation;
}

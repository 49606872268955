import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { NoteDTO } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { noteQueryKeys } from "./noteQueryKeys";

export function useMutationNoteCreate(
  {
    api,
  }: {
    api: ApiType;
  },

  mutationOpts?: UseMutationOptions<
    NoteDTO,
    unknown,
    {
      fields: Parameters<ApiType["noteApi"]["createAppointmentNote"]>[0];
      file?: Parameters<ApiType["noteApi"]["createAppointmentNote"]>[1];
    }
  >,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      fields,
      file,
    }: {
      fields: Parameters<ApiType["noteApi"]["create"]>[0];
      file?: Parameters<ApiType["noteApi"]["create"]>[1];
    }) => {
      const response = await api.noteApi.create(fields, file);
      return response.data as NoteDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: noteQueryKeys.all() });
    },
    ...mutationOpts,
  });

  return mutation;
}

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { Templates } from "../components/Marketing/Templates";
import { Promotions as PromotionsNew } from "../components/pages";

export const marketingRoute = {
  Automations: `/automations`,
  Promotions: `/promotions`,
  Templates: `/templates`,
};

export function Marketing() {
  const { url } = useRouteMatch();

  return (
    <div className="flex h-full max-h-full w-full flex-col gap-y-4 p-4 lg:p-8">
      <Switch>
        <Route path={`${url}${marketingRoute.Templates}`}>
          <Templates />
        </Route>
        <Route path={`${url}${marketingRoute.Promotions}`}>
          <PromotionsNew />
        </Route>
        <Route>
          <Redirect to={`${url}${marketingRoute.Templates}`} />
        </Route>
      </Switch>
    </div>
  );
}

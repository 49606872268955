import {
  AppointmentDTO,
  AppointmentPostDTO,
  AppointmentPurchaseSummaryDTO,
  AppointmentPurchaseSummaryPostDTO,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

export type CustomerAppointmentApiType = ReturnType<
  typeof customerAppointmentApi
>;

const customerAppointmentApi = (
  axiosInstance: AxiosInstance,
  clubId: number,
) => {
  const clubPrefix = `club/${clubId}/`;

  return {
    create(params: AppointmentPostDTO) {
      return axiosInstance.post<AppointmentDTO>(
        `${clubPrefix}customer/appointment`,
        params,
      );
    },
    summary(params: AppointmentPurchaseSummaryPostDTO) {
      return axiosInstance.post<AppointmentPurchaseSummaryDTO>(
        `${clubPrefix}customer/appointment/summary`,
        params,
      );
    },
  };
};

export default customerAppointmentApi;

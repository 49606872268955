import {
  ApiTokenListResponse,
  BookingAgendaResult,
  CalendarEventOccurrenceAsMember,
  EventOccurrenceDTO,
  EventRsvpStatus,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

export type CustomerOccurrenceApiType = ReturnType<typeof customerOccurrence>;

const customerOccurrence = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;

  return {
    findById(id: number) {
      return axiosInstance.get<EventOccurrenceDTO>(
        `${clubPrefix}customer/event/occurrence/${id}`,
      );
    },
    addAttendeeToRsvp(userMemberId: string, occurrenceId: number) {
      return axiosInstance.post(`${clubPrefix}customer/event/occurrence/rsvp`, {
        userMemberId,
        occurrenceId,
      });
    },
    updateRsvp(rsvpId: number, status: EventRsvpStatus) {
      return axiosInstance.patch(
        `${clubPrefix}customer/event/occurrence/rsvp/${rsvpId}`,
        {
          status,
        },
      );
    },
    calendarEventOccurrences({
      filters,
    }: {
      filters: {
        dateFrom: string;
        dateTo: string;
        limit: number;
        nextPageToken?: string;
        includeBookedCounts?: boolean;
        includeWaitingCounts?: boolean;
        eventHostId?: string[];
        facilityId?: number[];
        activityId?: number[];
        isPublicEvent?: boolean;
        activityCategoryId?: number[];
      };
    }) {
      return axiosInstance.get<
        ApiTokenListResponse<CalendarEventOccurrenceAsMember>
      >(`${clubPrefix}customer/calendar/event-occurrences`, {
        params: filters,
      });
    },

    userMemberBookingAgenda({
      filters,
    }: {
      filters: {
        limit: number;
        nextPageToken?: string;
        includeBookedCounts?: boolean;
        includeWaitingCounts?: boolean;
      };
    }) {
      return axiosInstance.get<ApiTokenListResponse<BookingAgendaResult>>(
        `${clubPrefix}customer/user-member-booking-agenda`,
        {
          params: filters,
        },
      );
    },
  };
};

export default customerOccurrence;

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { ReactNode } from "react";

import { ModalWrapper } from "../../providers";
import {
  Button,
  CheckCircleBrokenIcon,
  ExclamationTriangleIcon,
} from "../atoms";

export function MessageModal({
  title,
  children,
  type,
  onHide,
  onConfirm,
}: {
  title: ReactNode;
  children: ReactNode;
  type: "danger" | "warning" | "success";
  onHide: () => void;
  onConfirm: () => void;
}) {
  const renderIcon = () => {
    return (
      <div
        className={cn(
          "flex h-11 w-11 items-center justify-center  self-center rounded-full",
          {
            success: "bg-success-50",
            danger: "bg-error-50",
            warning: "bg-warning-50",
          }[type],
        )}
      >
        <div
          className={cn(
            "flex h-8 w-8 items-center justify-center self-center rounded-full",
            {
              success: "bg-success-100",
              danger: "bg-error-100",
              warning: "bg-warning-100",
            }[type],
          )}
        >
          {type === "success" ? (
            <CheckCircleBrokenIcon
              className="h-5 w-5"
              pathClassName={
                {
                  success: "stroke-success-600",
                  danger: "stroke-error-600",
                  warning: "stroke-warning-600",
                }[type]
              }
            />
          ) : (
            <ExclamationTriangleIcon
              className="h-5 w-5"
              pathClassName={
                {
                  success: "stroke-success-600",
                  danger: "stroke-error-600",
                  warning: "stroke-warning-600",
                }[type]
              }
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <ModalWrapper onCancel={onHide}>
      <div className="flex justify-between">
        {renderIcon()}
        <FontAwesomeIcon
          onClick={onHide}
          className="cursor-pointer text-xl text-gray-600"
          icon={faClose}
        />
      </div>
      <div className="text-lg font-semibold text-gray-900">{title}</div>
      <div className="mt-2 text-sm text-gray-600">{children}</div>
      <div className="mt-5 flex flex-row-reverse">
        <Button
          intent="secondary"
          size="small"
          onClick={onConfirm}
          className="!w-24"
        >
          Ok
        </Button>
      </div>
    </ModalWrapper>
  );
}

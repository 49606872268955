import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationFacilityAvailabilityOverrideEdit({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      facilityId,
      availabilityOverrideId,
      patchedFields,
    }: {
      facilityId: Parameters<
        ApiType["availabilityApi"]["updateFacilityOverride"]
      >[0];
      availabilityOverrideId: Parameters<
        ApiType["availabilityApi"]["updateFacilityOverride"]
      >[1];
      patchedFields: Parameters<
        ApiType["availabilityApi"]["updateFacilityOverride"]
      >[2];
    }) => {
      const response = await api.availabilityApi.updateFacilityOverride(
        facilityId,
        availabilityOverrideId,
        patchedFields,
      );
      return response.data;
    },
    onSuccess: (_, { facilityId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allFacilityOverrides(facilityId),
      });
    },
  });

  return mutation;
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryFacilityAvailabilityOverride(
  {
    api,
    availabilityOverrideId,
    facilityId,
  }: {
    api: ApiType;
    availabilityOverrideId?: number;
    facilityId?: number;
  },
  opts?: UseQueryOptions<
    | Awaited<
        ReturnType<ApiType["availabilityApi"]["facilityOverride"]>
      >["data"]
    | null
  >,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.facilityOverride(
      facilityId,
      availabilityOverrideId,
    ),
    queryFn: async () => {
      const result = await api.availabilityApi.facilityOverride(
        facilityId!,
        availabilityOverrideId!,
      );
      return result.data;
    },
    enabled: !!availabilityOverrideId && !!facilityId,
    initialData: null,
    ...opts,
  });

  return result;
}

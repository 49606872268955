/*** @deprecated, fields should be trimmed by zod */
export function trimFields(record: any, fieldsToTrim: string[]) {
  const trimmedRecord = {
    ...record,
  };
  fieldsToTrim.forEach((field) => {
    const fieldValue = trimmedRecord[field];
    if (typeof fieldValue === "string") {
      trimmedRecord[field] = fieldValue.trim();
    }
  });
  return trimmedRecord;
}

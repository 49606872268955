import {
  useAppointableCategoriesAsPublic,
  useQueryActivityCategoryListAsPublic,
} from "@gymflow/api";
import useGymflowModels from "apps/portal/src/store";
import React from "react";

import { LabeledForm } from "../../atoms";
import { BadgePicker } from "../../atoms/BadgePicker";
import { HostedPagesFilterPopover } from "../HostedPagesFilterPopover";

export type CreditPackType = "CLASSES" | "APPOINTMENTS";
export type HostedPagesCreditPackFilterPopoverProps = {
  filter: {
    appointableCategories: number[];
    classCategories: number[];
  };
  onChange: (
    newFilter: HostedPagesCreditPackFilterPopoverProps["filter"],
  ) => void;
  type: CreditPackType;
};

export const HostedPagesCreditPackFilterPopover: React.FC<
  HostedPagesCreditPackFilterPopoverProps
> = ({ filter, onChange, type }) => {
  const { api } = useGymflowModels();
  const { data: appointableCategories } = useAppointableCategoriesAsPublic({
    api,
    paginationOption: { page: 0, size: 200 },
    filter: {
      statusList: ["ACTIVE"],
    },
  });
  const { data: classCategories } = useQueryActivityCategoryListAsPublic({
    api,
    filter: {
      size: 200,
      statusList: ["ACTIVE"],
    },
  });
  return (
    <HostedPagesFilterPopover.Container>
      <HostedPagesFilterPopover.Trigger
        isFilterEmpty={
          type === "CLASSES"
            ? filter.classCategories.length === 0
            : filter.appointableCategories.length === 0
        }
      />
      <HostedPagesFilterPopover.Content>
        <HostedPagesFilterPopover.ContentHeader
          isFilterEmpty={
            type === "CLASSES"
              ? filter.classCategories.length === 0
              : filter.appointableCategories.length === 0
          }
          onReset={() => {
            onChange({
              appointableCategories: [],
              classCategories: [],
            });
          }}
        />
        <div className="flex max-w-full p-4 pt-0">
          {type === "APPOINTMENTS" ? (
            <LabeledForm label="Appointable Category" className="gap-y-2">
              <BadgePicker
                className="max-w-[80vw] lg:max-w-full"
                isMulti
                hasAllOption
                options={appointableCategories.content.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))}
                onChange={(newValue) => {
                  onChange({
                    ...filter,
                    appointableCategories: newValue,
                  });
                }}
                value={filter.appointableCategories}
              />
            </LabeledForm>
          ) : (
            <LabeledForm label="Class Category" className="gap-y-2">
              <BadgePicker
                className="max-w-[80vw] lg:max-w-full"
                isMulti
                hasAllOption
                options={classCategories.content.map((e) => ({
                  label: e.name,
                  value: e.id,
                }))}
                onChange={(newValue) => {
                  onChange({
                    ...filter,
                    classCategories: newValue,
                  });
                }}
                value={filter.classCategories}
              />
            </LabeledForm>
          )}
        </div>
      </HostedPagesFilterPopover.Content>
    </HostedPagesFilterPopover.Container>
  );
};

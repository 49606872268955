import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { HostAvailabilityDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryHostAvailability(
  {
    api,
    staffId,
  }: {
    api: ApiType;
    staffId: string | null;
  },
  opts?: UseQueryOptions<HostAvailabilityDTO | null>,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.hostAvailability(staffId as string),
    queryFn: async () => {
      const result = await api.availabilityApi.hostAvailability(
        staffId as string,
      );
      return result.data;
    },
    enabled: !!staffId,
    initialData: null,
    ...opts,
  });

  return result;
}

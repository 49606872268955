import {
  SendSMSToLeadArgs,
  SendSMSToLeadsArgs,
  SendSMSToLeadsFilterArgs,
  SendSMSToMemberArgs,
  SendSMSToMembersArgs,
  SendSMSToMembersFilterArgs,
  useMutationSendSMSToLead,
  useMutationSendSMSToLeads,
  useMutationSendSMSToLeadsFilter,
  useMutationSendSMSToMember,
  useMutationSendSMSToMembers,
  useMutationSendSMSToMembersFilter,
} from "@gymflow/api";
import { SMSSendingResponse } from "@gymflow/types";
import { AxiosError } from "axios";
import { useCallback, useContext } from "react";

import { MessageModal } from "../components/molecules";
import { ModalContext, useClubSettings } from "../providers";
import { ToastContext } from "../providers/ToastProvider/context";
import useGymflowModels from "../store";

export const useSendSMS = () => {
  const { api } = useGymflowModels();
  const { timezone: tz } = useClubSettings();
  const toast = useContext(ToastContext);
  const { mutateAsync: sendSMSToMemberMutate } = useMutationSendSMSToMember({
    api,
  });
  const { mutateAsync: sendSMSToMembersMutate } = useMutationSendSMSToMembers({
    api,
  });
  const { mutateAsync: sendSMSToMembersFilterMutate } =
    useMutationSendSMSToMembersFilter({ api, tz });
  const { mutateAsync: sendSMSToLeadMutate } = useMutationSendSMSToLead({
    api,
  });
  const { mutateAsync: sendSMSToLeadsMutate } = useMutationSendSMSToLeads({
    api,
  });
  const { mutateAsync: sendSMSToLeadsFilterMutate } =
    useMutationSendSMSToLeadsFilter({ api, tz });
  const { setModal, hide } = useContext(ModalContext);

  const showWarning = useCallback(
    ({
      failedMobileNumbers,
      sentSms,
      sentAttempts,
      failedAttemptsWithNoMobileNumber,
    }: SMSSendingResponse) => {
      const failedDueToMarketingRestrictionsCount = failedMobileNumbers
        ? failedMobileNumbers.length
        : 0;
      const failedDueToInvalidNumbers = failedAttemptsWithNoMobileNumber
        ? failedAttemptsWithNoMobileNumber.length
        : 0;

      const title = (
        <>
          Sent {sentSms} out of {sentAttempts} SMS.
          <br />
        </>
      );
      const nodes = [];

      if (failedDueToMarketingRestrictionsCount > 0) {
        nodes.push(
          <>
            SMS not sent due to marketing permissions:{" "}
            {failedMobileNumbers.join(", ")}
            <br />
          </>,
        );
      }

      if (failedDueToInvalidNumbers > 0) {
        nodes.push(
          <>
            SMS not sent due to missing or invalid phone number:{" "}
            {failedAttemptsWithNoMobileNumber
              .map(({ firstName, lastName }) => firstName + " " + lastName)
              .join(", ")}
          </>,
        );
      }

      setModal(
        <MessageModal
          title={title}
          type="warning"
          onHide={hide}
          onConfirm={hide}
        >
          {nodes}
        </MessageModal>,
      );
    },
    [hide, setModal],
  );

  const showSuccessful = useCallback(
    (response: SMSSendingResponse) => {
      setModal(
        <MessageModal
          title="SMS sent"
          type="success"
          onHide={hide}
          onConfirm={hide}
        >{`${response.sentSms} SMS successfully sent.`}</MessageModal>,
      );
    },
    [hide, setModal],
  );

  const showToastError = useCallback(
    (error: Error | AxiosError) => {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data?.error_message;

        if (errorMessage) {
          toast.toast({
            message: errorMessage,
            intent: "error",
          });
          return;
        }
      }

      toast.toast({
        message: "Error with SMS Sending",
        intent: "error",
      });
    },
    [toast],
  );

  const sendSMSToMember = useCallback(
    async (arg: SendSMSToMemberArgs) => {
      try {
        await sendSMSToMemberMutate(arg);
        setModal(
          <MessageModal
            title="SMS sent"
            type="success"
            onHide={hide}
            onConfirm={hide}
          >
            SMS successfully sent.
          </MessageModal>,
        );
      } catch (error) {
        showToastError(error as Error | AxiosError);
      }
    },
    [hide, sendSMSToMemberMutate, setModal, showToastError],
  );

  const sendSMSToMembers = useCallback(
    async (arg: SendSMSToMembersArgs) => {
      try {
        const response = await sendSMSToMembersMutate(arg);

        if (response.sentSms === response.sentAttempts) {
          showSuccessful(response);
        } else {
          showWarning(response);
        }
      } catch (error) {
        showToastError(error as Error | AxiosError);
      }
    },
    [sendSMSToMembersMutate, showSuccessful, showToastError, showWarning],
  );

  const sendSMSToMembersFilter = useCallback(
    async (arg: SendSMSToMembersFilterArgs) => {
      try {
        const response = await sendSMSToMembersFilterMutate(arg);

        if (response.sentSms === response.sentAttempts) {
          showSuccessful(response);
        } else {
          showWarning(response);
        }
      } catch (error) {
        showToastError(error as Error | AxiosError);
      }
    },
    [sendSMSToMembersFilterMutate, showSuccessful, showToastError, showWarning],
  );

  const sendSMSToLead = useCallback(
    async (arg: SendSMSToLeadArgs) => {
      try {
        await sendSMSToLeadMutate(arg);
        setModal(
          <MessageModal
            title="SMS sent"
            type="success"
            onHide={hide}
            onConfirm={hide}
          >
            SMS successfully sent.
          </MessageModal>,
        );
      } catch (error) {
        showToastError(error as Error | AxiosError);
      }
    },
    [hide, sendSMSToLeadMutate, setModal, showToastError],
  );

  const sendSMSToLeads = useCallback(
    async (arg: SendSMSToLeadsArgs) => {
      try {
        const response = await sendSMSToLeadsMutate(arg);

        if (response.sentSms === response.sentAttempts) {
          showSuccessful(response);
        } else {
          showWarning(response);
        }
      } catch (error) {
        showToastError(error as Error | AxiosError);
      }
    },
    [sendSMSToLeadsMutate, showSuccessful, showToastError, showWarning],
  );

  const sendSMSToLeadsFilter = useCallback(
    async (arg: SendSMSToLeadsFilterArgs) => {
      try {
        const response = await sendSMSToLeadsFilterMutate(arg);

        if (response.sentSms === response.sentAttempts) {
          showSuccessful(response);
        } else {
          showWarning(response);
        }
      } catch (error) {
        showToastError(error as Error | AxiosError);
      }
    },
    [sendSMSToLeadsFilterMutate, showSuccessful, showToastError, showWarning],
  );

  return {
    sendSMSToMember,
    sendSMSToMembers,
    sendSMSToMembersFilter,
    sendSMSToLead,
    sendSMSToLeads,
    sendSMSToLeadsFilter,
  };
};

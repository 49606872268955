import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime } from "@gymflow/helpers";
import { NoteDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { noteQueryKeys } from "./noteQueryKeys";

export function useQueryNoteSearch({
  api,
  opts,
  tz,
}: {
  api: ApiType;
  opts: Parameters<ApiType["noteApi"]["search"]>[0];
  tz: string;
}) {
  const result = useQuery({
    queryKey: noteQueryKeys.search(opts),
    queryFn: async () => {
      const result = await api.noteApi.search({ ...opts });
      return result.data;
    },
    initialData: defaultPage<NoteDTO>,
    select: (data) => {
      const notes = data.content.slice(0);
      const utcNotes = notes.map((note) => {
        return merge({}, note, {
          createdDate: utcToZonedTime(note.createdDate, tz),
        });
      });

      return merge({}, data, { content: utcNotes });
    },
  });
  return result;
}

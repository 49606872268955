import {
  clubStaleTime,
  useClub,
  useMutationSendEmailToLeadFilter,
  useMutationSendEmailToLeads,
  useMutationSendEmailToMemberFilter,
  useMutationSendEmailToMembers,
} from "@gymflow/api";
import { AlertContext } from "@gymflow/common";
import { pluralize } from "@gymflow/helpers";
import {
  EmailSendingResult,
  MembershipStatus,
  MembershipType,
} from "@gymflow/types";
import { useCallback, useContext } from "react";

import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

export type SendEmailToLeadFilterProps = {
  leadIdsToExclude?: number[];
  createdFrom?: string;
  createdTo?: string;
  smsCommunication?: boolean;
  emailCommunication?: boolean;
  leadStatusId?: number[];
  leadSourceId?: number[];
};

export type SendEmailToMemberFilterProps = {
  userMemberIdsToExclude?: string[];
  createdFrom?: string;
  createdTo?: string;
  smsCommunication?: boolean;
  emailCommunication?: boolean;
  membershipIdList?: number[];
  membershipStatusList?: MembershipStatus[];
  membershipTypeList?: MembershipType[];
  membershipStartFrom?: string;
  membershipStartTo?: string;
  membershipCancellationFrom?: string;
  membershipCancellationTo?: string;
  membershipExpireFrom?: string;
  membershipExpireTo?: string;
  assignedStaffIdList?: string[];
  creditsRemainingFrom?: string;
  creditsRemainingTo?: string;
};

export function useSendEmailsNew() {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });
  const { show, showError } = useContext(AlertContext);

  const showWarnings = useCallback(
    ({
      failedEmails,
      sentEmails,
      sentAttempts,
      failedAttemptsWithNoEmail,
    }: EmailSendingResult) => {
      const failedEmailsCount = failedEmails ? failedEmails.length : 0;
      const noEmailCount = failedAttemptsWithNoEmail
        ? failedAttemptsWithNoEmail.length
        : 0;
      const marketingErrors =
        sentAttempts - sentEmails - failedEmailsCount - noEmailCount;
      let isDanger = false;

      const nodes = [
        <>
          Sent {sentEmails} out of {sentAttempts}{" "}
          {pluralize("email", "emails", sentAttempts)}.
        </>,
      ];

      if (marketingErrors > 0) {
        nodes.push(
          <>
            <br />
            {marketingErrors} emails not sent due to marketing permissions.
            <br />
          </>,
        );
      }

      if (failedEmailsCount > 0) {
        isDanger = true;
        nodes.push(<>Failed emails: {failedEmails.join(", ")}</>);
      }

      if (failedAttemptsWithNoEmail && failedAttemptsWithNoEmail.length > 0) {
        isDanger = true;
        nodes.push(
          <>
            Users with no emails:
            {failedAttemptsWithNoEmail
              .map(({ firstName, lastName }) => firstName + " " + lastName)
              .join(", ")}
          </>,
        );
      }

      showError(nodes, {
        warning: !isDanger,
        danger: isDanger,
        title: isDanger ? "Error" : "Warning",
      });
    },
    [showError],
  );

  const { mutateAsync: sendEmailToLeadsMutate } = useMutationSendEmailToLeads({
    api,
  });
  const sendEmailToLeads = useCallback(
    async (arg: {
      emailPayload: {
        marketing: boolean;
        body: string;
        subject: string;
        bccList: string[];
      };
      leadIds: number[];
    }) => {
      const response = await sendEmailToLeadsMutate({
        emailPayload: {
          ...arg.emailPayload,
          fromEmail: club?.email!,
        },
        leadIds: arg.leadIds,
      });

      if (response.sentEmails === response.sentAttempts) {
        show(
          `${response.sentEmails} ${pluralize(
            "email",
            "emails",
            response.sentEmails,
          )} successfully sent.`,
          { showCancel: false },
        );
      } else {
        showWarnings(response);
      }
    },
    [club?.email, sendEmailToLeadsMutate, show, showWarnings],
  );

  const { mutateAsync: sendEmailToLeadFilterMutate } =
    useMutationSendEmailToLeadFilter({
      api,
      tz: settings.timezone,
    });
  const sendEmailToLeadFilter = useCallback(
    async (
      arg: {
        emailPayload: {
          marketing: boolean;
          body: string;
          subject: string;
          bccList: string[];
        };
      } & SendEmailToLeadFilterProps,
    ) => {
      const response = await sendEmailToLeadFilterMutate({
        ...arg,
        emailPayload: {
          ...arg.emailPayload,
          fromEmail: club?.email!,
        },
      });

      if (response.sentEmails === response.sentAttempts) {
        show(
          `${response.sentEmails} ${pluralize(
            "email",
            "emails",
            response.sentEmails,
          )} successfully sent.`,
          { showCancel: false },
        );
      } else {
        showWarnings(response);
      }
    },
    [club?.email, sendEmailToLeadFilterMutate, show, showWarnings],
  );

  const { mutateAsync: sendEmailToMembersMutate } =
    useMutationSendEmailToMembers({ api });
  const sendEmailToMembers = useCallback(
    async (arg: {
      emailPayload: {
        marketing: boolean;
        body: string;
        subject: string;
        bccList: string[];
      };
      userMemberIds: string[];
    }) => {
      const response = await sendEmailToMembersMutate({
        emailPayload: {
          ...arg.emailPayload,
          fromEmail: club?.email!,
        },
        userMemberIds: arg.userMemberIds,
      });

      if (response.sentEmails === response.sentAttempts) {
        show(
          `${response.sentEmails} ${pluralize(
            "email",
            "emails",
            response.sentEmails,
          )} successfully sent.`,
          { showCancel: false },
        );
      } else {
        showWarnings(response);
      }
    },
    [club?.email, sendEmailToMembersMutate, show, showWarnings],
  );

  const { mutateAsync: sendEmailToMemberFilterMutate } =
    useMutationSendEmailToMemberFilter({ api, tz: settings.timezone });
  const sendEmailToMemberFilter = useCallback(
    async (
      arg: {
        emailPayload: {
          marketing: boolean;
          body: string;
          subject: string;
          bccList: string[];
        };
      } & SendEmailToMemberFilterProps,
    ) => {
      const response = await sendEmailToMemberFilterMutate({
        ...arg,
        emailPayload: {
          ...arg.emailPayload,
          fromEmail: club?.email!,
        },
      });

      if (response.sentEmails === response.sentAttempts) {
        show(
          `${response.sentEmails} ${pluralize(
            "email",
            "emails",
            response.sentEmails,
          )} successfully sent.`,
          { showCancel: false },
        );
      } else {
        showWarnings(response);
      }
    },
    [club?.email, sendEmailToMemberFilterMutate, show, showWarnings],
  );

  return {
    sendEmailToLeads,
    sendEmailToLeadFilter,
    sendEmailToMembers,
    sendEmailToMemberFilter,
  };
}

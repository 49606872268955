import {
  useMutationNoteCreate,
  useMutationNoteCreateForLead,
  useMutationNoteDeleteAttachment,
  useMutationNoteEdit,
} from "@gymflow/api";
import React, { createContext, useState } from "react";

import useGymflowModels from "../../../store";
import { NoteFormSideBar } from "./NoteFormSideBar";

export type MemberMeta = {
  name: string;
  leadId?: number;
  userMemberId?: string;
  appointmentId?: number;
};
export interface NoteFormSideBarProviderProps {
  children: React.ReactNode;
}

interface NoteFormSideBarProviderContextType {
  triggerEditNote: (
    member: MemberMeta,
    nodeId: number,
    onClose?: () => Promise<void>,
  ) => void;
  triggerNewNote: (member: MemberMeta, onClose?: () => Promise<void>) => void;
}

export const NoteFormSideBarProviderContext =
  createContext<NoteFormSideBarProviderContextType>({} as any);

export const NoteFormSideBarProvider: React.FC<
  NoteFormSideBarProviderProps
> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [noteModalParams, setNoteModalParams] = useState<{
    member: MemberMeta;
    noteId?: number;
    onClose?: () => void;
  }>();
  const { api } = useGymflowModels();
  const createNote = useMutationNoteCreate({ api });
  const createNoteForLead = useMutationNoteCreateForLead({ api });
  const updateNote = useMutationNoteEdit({ api });
  const deleteNoteAttachment = useMutationNoteDeleteAttachment({ api });
  return (
    <NoteFormSideBarProviderContext.Provider
      value={{
        triggerEditNote(member, noteId, onCloseParam) {
          setIsVisible(true);

          setNoteModalParams({
            noteId,
            member,
            onClose: onCloseParam,
          });
        },
        triggerNewNote(member, onCloseParam) {
          setIsVisible(true);
          setNoteModalParams({
            member,
            onClose: onCloseParam,
          });
        },
      }}
    >
      <NoteFormSideBar
        name={noteModalParams?.member?.name}
        isOpen={isVisible}
        noteId={noteModalParams?.noteId}
        onClose={() => {
          setIsVisible(false);
          if (noteModalParams?.onClose) {
            noteModalParams.onClose();
          }
          setNoteModalParams(undefined);
        }}
        onChange={async ({ fields, id }) => {
          if (id) {
            if (fields?.file?.removed) {
              delete fields.file;
              await deleteNoteAttachment.mutateAsync({ noteId: id });
            }
            const { file, content } = fields;
            await updateNote.mutateAsync({
              noteId: id,
              patchedFields: { content },
              file: file?.file,
            });
          } else {
            const { file, content } = fields;
            if (noteModalParams?.member.userMemberId) {
              await createNote.mutateAsync({
                fields: {
                  content,
                  userMemberId: noteModalParams.member?.userMemberId,
                },
                file: file?.file,
              });
            } else if (noteModalParams?.member?.leadId) {
              await createNoteForLead.mutateAsync({
                newNote: { content, leadId: noteModalParams.member.leadId },
                file: file?.file,
              });
            }
          }

          if (noteModalParams?.onClose) {
            noteModalParams.onClose();
          }
        }}
      />
      {children}
    </NoteFormSideBarProviderContext.Provider>
  );
};

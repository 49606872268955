import { useTranslation } from "react-i18next";

import { ConfirmModal } from "../templates";

type ModalType = "CLASS" | "APPOINTMENT";

type HostNotAvailableModalProps = {
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  modalType: ModalType;
};

export const HostNotAvailableModal = ({
  onConfirm,
  onCancel,
  modalType,
}: HostNotAvailableModalProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      title={t("components.hostNotAvailableModal.title")}
    >
      {modalType === "APPOINTMENT" &&
        t("components.hostNotAvailableModal.appointment.description")}
      {modalType === "CLASS" &&
        t("components.hostNotAvailableModal.class.description")}
    </ConfirmModal>
  );
};

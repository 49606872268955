import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { FacilityAvailabilityDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryFacilityAvailability(
  {
    api,
    facilityId,
  }: {
    api: ApiType;
    facilityId?: number;
  },
  opts?: UseQueryOptions<FacilityAvailabilityDTO | null>,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.facilityAvailability(facilityId as number),
    queryFn: async () => {
      const result = await api.availabilityApi.facilityAvailability(
        facilityId as number,
      );
      return result.data;
    },
    enabled: !!facilityId,
    initialData: null,
    ...opts,
  });

  return result;
}

import { createContext, ReactNode, useCallback, useState } from "react";

import { useSendSMS } from "../../hooks";
import { SendSMSSidebar } from "./SendSMSSidebar/SendSMSSidebar";
import { SendSMSFormValues } from "./SendSMSSidebar/types";

interface SendSMSToUserSidebarContextType {
  open: (arg: { memberId: string; memberName: string }) => void;
}

export const SendSMSToUserSidebarContext =
  createContext<SendSMSToUserSidebarContextType>({
    open: () => {
      throw new Error("Called outside of context");
    },
  });

export const SendSMSToUserSidebarProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [userDetails, setUserDetails] = useState<{
    userMemberId: string;
    userName: string;
  }>();
  const open = useCallback(
    ({ memberId, memberName }: { memberId: string; memberName: string }) => {
      setUserDetails({ userMemberId: memberId, userName: memberName });
      setIsVisible(true);
    },
    [],
  );

  return (
    <SendSMSToUserSidebarContext.Provider
      value={{
        open,
      }}
    >
      <SendSMSToUserSidebar
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(false);
        }}
        userMemberId={userDetails?.userMemberId!}
        userName={userDetails?.userName!}
      />
      {children}
    </SendSMSToUserSidebarContext.Provider>
  );
};

export type SendSMSToUserSidebarProps = {
  isVisible: boolean;
  onClose: () => void;
  userMemberId: string;
  userName: string;
};

const SendSMSToUserSidebar = ({
  onClose,
  userMemberId,
  userName,
  isVisible,
}: SendSMSToUserSidebarProps) => {
  const { sendSMSToMember } = useSendSMS();

  const onSubmit = useCallback(
    async (values: SendSMSFormValues) => {
      await sendSMSToMember({
        userMemberId,
        body: values.message,
      });

      onClose();
    },
    [onClose, sendSMSToMember, userMemberId],
  );

  return (
    <SendSMSSidebar
      onClose={onClose}
      onSubmit={onSubmit}
      recipient={userName}
      description={`Send a SMS to ${userName}.`}
      isVisible={isVisible}
      hideMarketingOption
      hideScheduleOption
    />
  );
};

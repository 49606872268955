import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { ActivityCategoryDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityAsMemberQueryKeys } from "./activityAsMemberQueryKeys";

export async function activityCategoryListAsMemberQueryFn({
  api,
  filter,
}: {
  api: ApiType;
  filter: Parameters<ApiType["customerActivityApi"]["findCategories"]>[0];
}) {
  const result = await api.customerActivityApi.findCategories(filter);
  return result.data;
}

export function useQueryActivityCategoryListAsMember({
  api,
  filter,
}: {
  api: ApiType;
  filter: Parameters<ApiType["customerActivityApi"]["findCategories"]>[0];
}) {
  const result = useQuery({
    queryKey: activityAsMemberQueryKeys.categories(filter),
    queryFn: () => activityCategoryListAsMemberQueryFn({ api, filter }),
    initialData: defaultPage<ActivityCategoryDTO>,
  });
  return result;
}

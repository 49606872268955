import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryHostAvailabilityOverride(
  {
    api,
    availabilityOverrideId,
    staffId,
  }: {
    api: ApiType;
    staffId?: Parameters<ApiType["availabilityApi"]["hostOverride"]>[0];
    availabilityOverrideId?: Parameters<
      ApiType["availabilityApi"]["hostOverride"]
    >[1];
  },
  opts?: UseQueryOptions<
    Awaited<ReturnType<ApiType["availabilityApi"]["hostOverride"]>>["data"]
  >,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.hostOverride(
      staffId,
      availabilityOverrideId,
    ),
    queryFn: async () => {
      const result = await api.availabilityApi.hostOverride(
        staffId!,
        availabilityOverrideId!,
      );
      return result.data;
    },
    enabled: !!availabilityOverrideId && !!staffId,
    ...opts,
  });

  return result;
}

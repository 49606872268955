import { EventOccurrenceDTO } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { calendarQueryKeys } from "../calendar";
import { eventOccurrenceQueryKeys } from "./eventOccurrenceQueryKeys";
import { eventOccurrenceToUtc } from "./eventOccurrenceToUtc";

// remove any (just "copy-past" from previous variant); We have it because of SideBarOccurrenceForm
export type EventOccurrenceEditVariables = {
  eventId: number;
  patchedFields: Record<string, any>;
  isUpdateAll?: boolean;
  ignoreAvailabilityValidation?: boolean;
};

// remove unknown (eventApi.update) (just "copy-past" from previous variant); We have it because of SideBarOccurrenceForm
export function useEventOccurrenceEdit(
  {
    api,
    tz,
  }: {
    api: {
      eventApi: {
        update: (
          id: number,
          patchedFields: unknown,
          isUpdateAll?: boolean,
          ignoreAvailabilityValidation?: boolean,
        ) => Promise<{ data: EventOccurrenceDTO }>;
      };
    };
    tz: string;
  },
  mutationOpts?: UseMutationOptions<
    EventOccurrenceDTO,
    unknown,
    EventOccurrenceEditVariables
  >,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      eventId,
      patchedFields,
      isUpdateAll,
      ignoreAvailabilityValidation,
    }: EventOccurrenceEditVariables) => {
      const { data } = await api.eventApi.update(
        eventId,
        eventOccurrenceToUtc(patchedFields, tz),
        isUpdateAll,
        ignoreAvailabilityValidation,
      );
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.eventOccurrences(),
      });
    },
    ...mutationOpts,
  });
  return mutation;
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { ActivityDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { activityQueryKeys } from "./activityQueryKeys";

export async function activityQueryFn({
  api,
  activityId,
}: {
  api: ApiType;
  activityId: number;
}) {
  const result = await api.activityApi.findById(activityId);
  return result.data as ActivityDTO;
}

export function useQueryActivity(
  {
    api,
    activityId,
  }: {
    api: ApiType;
    activityId?: number;
  },
  opts?: UseQueryOptions<ActivityDTO | null>,
) {
  const result = useQuery({
    queryKey: activityQueryKeys.details(activityId),
    queryFn: () => activityQueryFn({ api, activityId: activityId! }),
    enabled: !!activityId,
    initialData: null,
    ...opts,
  });

  return result;
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { zonedTimeToUtc } from "@gymflow/helpers";

/*** @deprecated - This should be removed together with Timetable.jsx*/
export async function calendarEventOccurrencesAsPublicQueryFn({
  api,
  tz,
  filters,
}: {
  api: ApiType;
  tz: string;
  filters: Parameters<
    ApiType["public"]["eventApi"]["calendarEventOccurrences"]
  >[0]["filters"];
}) {
  const parsed: { dateFrom?: string; dateTo?: string } = {};

  if (filters?.["dateFrom"]) {
    parsed["dateFrom"] = zonedTimeToUtc(filters["dateFrom"], tz);
  }

  if (filters?.["dateTo"]) {
    parsed["dateTo"] = zonedTimeToUtc(filters["dateTo"], tz);
  }

  const params = {
    ...filters,
    ...parsed,
  };
  const result = await api.public.eventApi.calendarEventOccurrences({
    filters: params,
  });
  return result.data;
}

import { AlertContext } from "@gymflow/common";
import { useContext } from "react";

import { copyToClipboard } from "../../../helpers/copyToClipboard";
import { useIframeEmbed } from "../../../hooks/useIframeEmbed";
import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import {
  RouteFeature,
  RouteFeatureMigration,
  RouteFeatureSite,
} from "../../../routes/feature";
import { RouteLayout } from "../../../routes/layout";
import {
  Button,
  CodeIcon,
  LinkIcon,
  PlusCircleIcon,
  ReactiveButton,
} from "../../atoms";
import LinkGeneratorWizard from "../../Settings/Hosted/LinkGenerator/LinkGeneratorWizard";

export function Links() {
  const { createAbsoluteClubLink } = usePortalRoutes();
  const iframeEmbed = useIframeEmbed();

  const { setAlert, hide } = useContext(AlertContext);
  return (
    <div className="grid grid-cols-1 md:grid-cols-8">
      <div className="md:grid-cols-subgrid md:col-span-3 md:grid">
        <div className="md:col-start-1 md:col-end-3">
          <div className="text-base font-semibold">Links & Embeds</div>
          <div className="font-normal text-gray-600">
            Copy links and code to link or embed your pages to your own website.
          </div>
        </div>
      </div>
      <div
        className="flex flex-col gap-2 rounded-xl border border-gray-200 bg-white p-6 shadow-sm md:col-span-5
        "
      >
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Memberships</div>
          <div className="flex flex-wrap gap-2">
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  createAbsoluteClubLink(
                    RouteLayout.Link,
                    RouteFeature.LinkBuyMembership,
                  ),
                );
              }}
              reactiveContent={<div className="w-[5.28rem]">Link Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Link</div>
                <LinkIcon />
              </div>
            </ReactiveButton>
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  iframeEmbed(RouteFeature.SiteBuyMembership),
                );
              }}
              reactiveContent={<div className="w-[5.82rem]">Code Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Code</div>
                <CodeIcon />
              </div>
            </ReactiveButton>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Credit Packs</div>
          <div className="flex flex-wrap gap-2">
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  createAbsoluteClubLink(
                    RouteLayout.Link,
                    RouteFeature.LinkBuySessionPack,
                  ),
                );
              }}
              reactiveContent={<div className="w-[5.28rem]">Link Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Link</div>
                <LinkIcon />
              </div>
            </ReactiveButton>
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  iframeEmbed(RouteFeature.SiteBuySessionPack),
                );
              }}
              reactiveContent={<div className="w-[5.82rem]">Code Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Code</div>
                <CodeIcon />
              </div>
            </ReactiveButton>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Appointments</div>
          <div className="flex flex-wrap gap-2">
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  createAbsoluteClubLink(
                    RouteLayout.Link,
                    RouteFeatureSite.Appointments,
                  ),
                );
              }}
              reactiveContent={<div className="w-[5.28rem]">Link Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Link</div>
                <LinkIcon />
              </div>
            </ReactiveButton>
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  iframeEmbed(RouteFeatureSite.Appointments),
                );
              }}
              reactiveContent={<div className="w-[5.82rem]">Code Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Code</div>
                <CodeIcon />
              </div>
            </ReactiveButton>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Schedule</div>
          <div className="flex flex-wrap gap-2">
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  createAbsoluteClubLink(
                    RouteLayout.Link,
                    RouteFeature.LinkTimetable,
                  ),
                );
              }}
              reactiveContent={<div className="w-[5.28rem]">Link Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Link</div>
                <LinkIcon />
              </div>
            </ReactiveButton>
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(iframeEmbed(RouteFeature.LinkTimetable));
              }}
              reactiveContent={<div className="w-[5.82rem]">Code Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Code</div>
                <CodeIcon />
              </div>
            </ReactiveButton>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Contact Us</div>
          <div className="flex flex-wrap gap-2">
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  createAbsoluteClubLink(
                    RouteLayout.Link,
                    RouteFeature.LinkEnquiry,
                  ),
                );
              }}
              reactiveContent={<div className="w-[5.28rem]">Link Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Link</div>
                <LinkIcon />
              </div>
            </ReactiveButton>
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(iframeEmbed(RouteFeature.LinkEnquiry));
              }}
              reactiveContent={<div className="w-[5.82rem]">Code Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Code</div>
                <CodeIcon />
              </div>
            </ReactiveButton>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Registration</div>
          <div className="flex flex-wrap gap-2">
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  createAbsoluteClubLink(
                    RouteLayout.Link,
                    RouteFeature.LinkGuestRegistration,
                  ),
                );
              }}
              reactiveContent={<div className="w-[5.28rem]">Link Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Link</div>
                <LinkIcon />
              </div>
            </ReactiveButton>
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  iframeEmbed(RouteFeature.LinkGuestRegistration),
                );
              }}
              reactiveContent={<div className="w-[5.82rem]">Code Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Code</div>
                <CodeIcon />
              </div>
            </ReactiveButton>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">Kiosk</div>
          <div className="flex flex-wrap gap-2">
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  createAbsoluteClubLink(
                    RouteLayout.Kiosk,
                    RouteFeature.KioskCheckIn,
                  ),
                );
              }}
              reactiveContent={<div className="w-[5.28rem]">Link Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Link</div>
                <LinkIcon />
              </div>
            </ReactiveButton>
            <ReactiveButton
              className="invisible"
              size="small"
              reactiveContent={<div className="w-[5.82rem]">Code Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Code</div>
                <CodeIcon />
              </div>
            </ReactiveButton>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-gray-500">
            Account Activation
          </div>
          <div className="flex flex-wrap gap-2">
            <ReactiveButton
              size="small"
              onClick={async () => {
                await copyToClipboard(
                  createAbsoluteClubLink(
                    RouteLayout.Migration,
                    RouteFeatureMigration.CheckExisting,
                  ),
                );
              }}
              reactiveContent={<div className="w-[5.28rem]">Link Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Link</div>
                <LinkIcon />
              </div>
            </ReactiveButton>
            <ReactiveButton
              className="invisible"
              size="small"
              reactiveContent={<div className="w-[5.82rem]">Code Copied</div>}
            >
              <div className="flex items-center gap-1">
                <div>Copy Code</div>
                <CodeIcon />
              </div>
            </ReactiveButton>
          </div>
        </div>
        <div>
          <div className="text-base font-semibold">Custom Links</div>
          <div className="flex items-center justify-between">
            <div className="text-sm font-normal text-gray-500">
              Build customised links or embeds that only show the options you
              choose to display.
            </div>
            <div>
              <Button
                intent="secondary"
                size="small"
                onClick={() => {
                  setAlert(<LinkGeneratorWizard onClose={hide} />);
                }}
              >
                <div className="flex items-center gap-1">
                  <PlusCircleIcon pathClassName="stroke-white" />
                  <div>New Link</div>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function transformIntoFormData({
  file,
  fields,
}: {
  file: {
    name: string;
    contents?: File;
  };
  fields: {
    name: string;
    content?: any;
  };
}) {
  const formData = new FormData();
  if (file && file.contents) {
    formData.append(file.name, file.contents, file.contents.name);
  }
  if (fields && fields.content) {
    formData.append(
      fields.name,
      new Blob([JSON.stringify(fields.content)], {
        type: "application/json",
      }),
    );
  }
  return formData;
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { noteQueryKeys } from "./noteQueryKeys";

export function useMutationNoteDeleteAttachment({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const removeAttachmentMutation = useMutation({
    mutationFn: ({ noteId }: { noteId: number }) =>
      api.noteApi.removeAttachment(noteId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: noteQueryKeys.all() });
    },
  });

  return removeAttachmentMutation;
}

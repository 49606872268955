import { activityCategoryListQueryFn } from "@gymflow/api";
import { ReactNode, useContext } from "react";

import { ModalContext } from "../../providers";
import useGymflowModels from "../../store";
import { ActivityCategoryModal } from "../Settings/Activity/ActivityCategoryModal";
import { PaginatedSelect } from ".";

export interface ActivityCategorySelectOption {
  label: string;
  value: number;
}

interface ActivityCategorySelectProps {
  className?: string;
  value: ActivityCategorySelectOption[];
  onChange: (
    newValue: (
      | ActivityCategorySelectOption
      | { value: "create"; label: ReactNode }
    )[],
  ) => void;
  allowMultipleSelection?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  showManageOption?: boolean;
}

export function ActivityCategorySelect({
  className,
  value,
  onChange,
  allowMultipleSelection,
  placeholder,
  isClearable,
  isSearchable,
  showManageOption,
}: ActivityCategorySelectProps) {
  const { api } = useGymflowModels();
  const { stackModal, popModal } = useContext(ModalContext);
  return (
    <PaginatedSelect
      className={className}
      isMulti={allowMultipleSelection}
      isSearchable={isSearchable}
      placeholder={placeholder}
      loadOptions={async (term, _, { page }) => {
        const categoryList = await activityCategoryListQueryFn({
          api,
          filter: {
            page: page,
            size: 100,
            statusList: ["ACTIVE"],
          },
        });
        const options: { label: ReactNode; value: number | "create" }[] =
          categoryList.content
            .map((category) => ({
              label: category.name,
              value: category.id,
            }))
            .filter((category) =>
              category.label.toLowerCase().includes(term.toLowerCase()),
            );
        return {
          options,
          hasMore: !categoryList.last,
          additional: {
            page: page + 1,
          },
        };
      }}
      value={value}
      onChange={(newValue) => {
        onChange(allowMultipleSelection ? newValue : [newValue]);
      }}
      isClearable={isClearable}
      refetchOnMenuOpen={showManageOption}
      showMenuFooterButton={showManageOption}
      menuFooterClick={
        showManageOption
          ? async () => {
              stackModal(<ActivityCategoryModal onCancel={() => popModal()} />);
            }
          : undefined
      }
    />
  );
}

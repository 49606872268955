import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { activityQueryKeys } from "./activityQueryKeys";

export function useMutationActivityEdit({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      activityId,
      patchedFields,
    }: {
      activityId: Parameters<ApiType["activityApi"]["update"]>[0];
      patchedFields: Parameters<ApiType["activityApi"]["update"]>[1];
    }) => {
      await api.activityApi.update(activityId, patchedFields);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: activityQueryKeys.all() });
    },
  });
  return mutation;
}

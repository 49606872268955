import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import {
  ApiTokenListResponse,
  CalendarEventOccurrenceAsMember,
} from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";

import { calendarAsMemberQueryKeys } from "./calendarAsMemberQueryKeys";

export function useInfiniteQueryCalendarEventOccurrencesAsMember({
  api,
  tz,
  filters,
}: {
  api: ApiType;
  tz: string;
  filters: Parameters<
    ApiType["customerOccurrenceApi"]["calendarEventOccurrences"]
  >[0]["filters"];
}) {
  return useInfiniteQuery({
    queryKey: calendarAsMemberQueryKeys.eventOccurrencesOld({ tz, filters }),
    queryFn: async ({ pageParam }) => {
      const result = await api.customerOccurrenceApi.calendarEventOccurrences({
        filters: createApiParams({
          filters: {
            ...filters,
            nextPageToken: pageParam,
          },
          tz,
        }),
      });
      return result.data;
    },
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    select: (data) => calendarEventOccurrencesAsMemberSelectFn(data, tz),
    enabled: !!tz && (!!filters?.["dateFrom"] || !!filters?.["dateTo"]),
  });
}

const createApiParams = ({
  filters,
  tz,
}: {
  filters: Parameters<
    ApiType["customerOccurrenceApi"]["calendarEventOccurrences"]
  >[0]["filters"];
  tz: string;
}) => {
  const parsed: { dateFrom?: string; dateTo?: string } = {};

  if (filters?.["dateFrom"]) {
    parsed["dateFrom"] = zonedTimeToUtc(filters["dateFrom"], tz);
  }

  if (filters?.["dateTo"]) {
    parsed["dateTo"] = zonedTimeToUtc(filters["dateTo"], tz);
  }

  return {
    ...filters,
    ...parsed,
  };
};

export function calendarEventOccurrencesAsMemberSelectFn(
  data: {
    pages: ApiTokenListResponse<CalendarEventOccurrenceAsMember>[];
    pageParams: unknown[];
  },
  tz: string,
) {
  return {
    ...data,
    pages: data.pages.map((page) =>
      page.content.map((eventOccurrence) => ({
        ...eventOccurrence,
        startDate: utcToZonedTime(eventOccurrence.startDate, tz),
        endDate: utcToZonedTime(eventOccurrence.endDate, tz),
      })),
    ),
  };
}

import { useMutationSendMigrationEmail } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { useFormik } from "formik";
import { useState } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { usePortalRoutes } from "../../../hooks";
import { RouteFeatureMigration, RouteLayout } from "../../../routes";
import useGymflowModels from "../../../store";
import { Button, DangerIcon, MailIcon, TextInput } from "../../atoms";

export function MigrationCheckEmail() {
  const { api } = useGymflowModels();
  const { mutateAsync: sendEmail } = useMutationSendMigrationEmail({ api });
  const [page, setPage] = useState<"FORM" | "SUCCESS">("FORM");

  const { createClubLink } = usePortalRoutes();

  const formik = useFormik<{ email: string }>({
    initialValues: { email: "" },
    onSubmit: async (values) => {
      try {
        await sendEmail({
          email: values.email,
          invitationLinkPath: createClubLink(
            RouteLayout.Migration,
            RouteFeatureMigration.MigrationForm,
          ),
        });
        setPage("SUCCESS");
      } catch (e: any) {
        if (e?.response?.status === 404) {
          formik.setFieldError(
            "email",
            "This email isn’t registered or the account is already active.",
          );
        } else if (!!e?.response?.data?.error_message) {
          formik.setFieldError(
            "email",
            "Please wait 10 minutes before requesting another activation email.",
          );
        }
      }
    },
    validationSchema: toFormikValidationSchema(schema),
  });

  const showErrors = formik.submitCount > 0 && formik.errors.email;

  if (page === "SUCCESS") {
    return (
      <div className="mt-16 flex justify-center ">
        <div className="flex flex-col items-center gap-4">
          <div className="border-secondary-25 flex h-14 w-14 items-center justify-center rounded-[32rem] border-8 bg-[#e5d8fb] dark:border-[#053321] dark:bg-[#074D31]">
            <MailIcon
              className="h-7 w-7"
              pathClassName="stroke-secondary-600 dark:stroke-[#17B26A]"
            />
          </div>
          <div className="flex flex-col items-center gap-2">
            <div className="dark:text-gray-25 text-center text-xl font-bold text-gray-950">
              Verification Email Sent
            </div>
            <div className="text-base font-medium text-gray-500 dark:text-gray-400">
              Please verify your email address by clicking the link in your
              email to proceed.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-16 flex justify-center">
      <div className="flex w-80 flex-col  items-stretch gap-6">
        <div className="dark:text-gray-25 text-center text-xl font-bold text-gray-950">
          <div>Enter the email</div>
          <div> you registered with</div>
        </div>
        <div>
          <div className="relative mt-2 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MailIcon className="h-5 w-5" pathClassName=" stroke-gray-500" />
            </div>
            <TextInput
              state={showErrors ? "error" : "default"}
              onChange={({ currentTarget: { value } }) => {
                formik.setFieldValue("email", value);
              }}
              value={formik.values.email}
              className={cn("pl-10", {
                "pr-10": formik.values.email,
              })}
            />
            {showErrors && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <DangerIcon
                  className="h-5 w-5"
                  pathClassName="stroke-error-600"
                />
              </div>
            )}
          </div>
          {showErrors && (
            <div className="text-error-600 mt-1.5 text-sm font-normal">
              {formik.errors.email}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-3">
          <Button
            intent="secondary"
            className="mt-2 flex-1"
            onClick={async () => {
              formik.submitForm();
            }}
            showSpinner={formik.isSubmitting}
          >
            Submit
          </Button>
          <div className="text-center text-sm font-medium text-gray-500 dark:text-gray-400">
            After clicking &quot;Submit&quot; you will receive an email to
            verify your email address.
          </div>
        </div>
      </div>
    </div>
  );
}

const schema = z.object({
  email: z.string().email(),
});

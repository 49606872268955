import { subject } from "@casl/ability";
import { useAbility } from "@casl/react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { activityQueryFn, facilityQueryFn, staffQueryFn } from "@gymflow/api";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  useRecordForm,
} from "@gymflow/common";
import { EventOccurrenceDTO } from "@gymflow/types";
import classNames from "classnames";
import { Formik, useFormikContext } from "formik";
import range from "lodash/range";
import moment, { Moment } from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";

import { AbilityContext, anyRecord, Subject, Verb } from "../../../permissions";
import { useAuthenticatedUser } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  ActivitySelect,
  Button,
  DateSelect,
  FacilitySelect,
  PaginatedSelect,
  SlideSideBar,
  StaffSelect,
  Switch,
  TimeSelect,
} from "../../atoms";
import { CollapsibleSections } from "../CollapsibleSections";
import { EventFormMapper } from "./OccurrenceFormMapper";
import {
  ACTIVITY_ID,
  CAPACITY,
  createBookingDetailsSchemaFields,
  createClassDetailsSchemaFields,
  createDateAndTimeSchemaFields,
  createEventSchemaWithDate,
  createRecurrenceSchemaFields,
  CREDIT_COST,
  END_DATE,
  END_TIME,
  FACILITY_ID,
  HOST_MEMBER_ID,
  IS_BOOKABLE,
  IS_PUBLIC,
  IS_RECURRING,
  RECURRING_END_DATE,
  RECURRING_INTERVAL,
  RECURRING_TYPE,
  RECURRING_WEEKDAYS,
  START_DATE,
  START_TIME,
  WAITING_LIST_CAPACITY,
} from "./OccurrenceSchema";

interface SideBarOccurrenceFormProps {
  onClose: () => void;
  isOpen: boolean;
  occurrenceId?: number | null;
  defaultStartDate: string | null;
  defaultHostId: string | null;
  defaultFacilityId: number | null;
  value: EventOccurrenceDTO | null;
  isLoading: boolean;
  onChange: (newValue: Record<string, any>) => Promise<void>;
  blankTime: boolean;
}

const mapper = new EventFormMapper();
/*** @deprecated - need to refactor or create a new solution;
 * for the first - change type for newValue: Record<string, any>;
 * second is change logic for onChange (it's submit);
 * additionally - fix errors like eslint(react-hooks/exhaustive-deps);
 * and rebuild logic with formik;
 */
export function SideBarOccurrenceForm({
  onClose,
  isOpen,
  defaultStartDate = null,
  defaultHostId = null,
  defaultFacilityId = null,
  blankTime = false,
  value = null,
  isLoading,
  onChange,
}: SideBarOccurrenceFormProps) {
  const referenceDate = defaultStartDate
    ? moment(defaultStartDate, DATE_TIME_FORMAT)
    : moment();
  const ability = useAbility(AbilityContext);
  const canViewAllEvents = ability.can(
    Verb.View,
    subject(Subject.StaffCalendar, anyRecord()),
  );
  const { id: loggedInId } = useAuthenticatedUser();
  const schema = createEventSchemaWithDate(
    referenceDate.format(DATE_TIME_FORMAT),
    blankTime,
    defaultHostId || (canViewAllEvents ? undefined : loggedInId),
    defaultFacilityId ? defaultFacilityId : undefined,
  );

  const { initialValues, getValues } = useRecordForm({
    fields: schema.getDefault(),
    record: value,
    mapper,
  });
  const [isLoadingDefaultValues, setIsLoadingDefaultValues] = useState(true);

  return (
    <SlideSideBar
      isOpen={isOpen}
      hide={onClose}
      className="!w-[32rem]"
      isLoading={isLoadingDefaultValues || isLoading}
    >
      <div className="flex h-full max-h-full flex-col overflow-hidden">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            onChange(getValues(values));
          }}
          validationSchema={schema}
        >
          {(formik) => (
            <>
              <div className="flex h-full max-h-full flex-col overflow-hidden">
                <div className="flex flex-col justify-between border-b border-gray-200 p-8">
                  <div className="mb-1 flex flex-row items-center justify-between">
                    <div className="text-xl font-semibold text-gray-900">
                      {value ? "Update Class" : "Add Class"}
                    </div>

                    <FontAwesomeIcon
                      onClick={() => {
                        onClose();
                      }}
                      className="cursor-pointer text-xl text-gray-600"
                      icon={faClose}
                    />
                  </div>
                  <div className="text-sm font-medium text-gray-600">
                    {value
                      ? "Update existing class details."
                      : "Add a class to your club's calendar."}
                  </div>
                </div>

                {isOpen && (
                  <OccurrenceForm
                    referenceDate={referenceDate}
                    setIsLoadingDefaultValues={setIsLoadingDefaultValues}
                    disableHost={!canViewAllEvents}
                  />
                )}
              </div>

              <div className="flex h-20 flex-row items-center justify-end gap-2 border-t border-gray-200 px-6">
                <Button onClick={() => onClose()} className="w-full">
                  Cancel
                </Button>

                <Button
                  intent="primary"
                  className="w-full"
                  onClick={async () => {
                    await formik.submitForm();
                  }}
                >
                  Apply
                </Button>
              </div>
            </>
          )}
        </Formik>
      </div>
    </SlideSideBar>
  );
}

function formToRecurringInterval(intervalType: string) {
  if (intervalType === "DAILY") {
    return "day";
  } else if (intervalType === "WEEKLY") {
    return "week";
  } else if (intervalType === "MONTHLY") {
    return "month";
  } else if (intervalType === "YEARLY") {
    return "year";
  }
  return "day";
}

const recurringTypeOptions = [
  {
    label: "Day",
    value: "DAILY",
  },
  {
    label: "Week",
    value: "WEEKLY",
  },
  {
    label: "Month",
    value: "MONTHLY",
  },
  {
    label: "Year",
    value: "YEARLY",
  },
];
const weekdaysOptions = [
  {
    label: "Mon",
    value: "MONDAY",
  },
  {
    label: "Tue",
    value: "TUESDAY",
  },
  {
    label: "Wed",
    value: "WEDNESDAY",
  },
  {
    label: "Thu",
    value: "THURSDAY",
  },
  {
    label: "Fri",
    value: "FRIDAY",
  },
  {
    label: "Sat",
    value: "SATURDAY",
  },
  {
    label: "Sun",
    value: "SUNDAY",
  },
];

function OccurrenceForm({
  referenceDate,
  setIsLoadingDefaultValues,
  disableHost,
}: {
  referenceDate: Moment;
  setIsLoadingDefaultValues: React.Dispatch<React.SetStateAction<boolean>>;
  disableHost: boolean;
}) {
  const formikProps = useFormikContext();
  const { setFieldValue } = formikProps;
  const values = formikProps.values as Record<string, any>;
  const errors = formikProps.errors as Record<string, any>;
  const initialValues = formikProps.initialValues as Record<string, any>;
  const [activityName, setActivityName] = useState("");
  const [facilityName, setFacilityName] = useState("");
  const [hostName, setHostName] = useState("");
  const [openSectionIdx, setOpenSectionIdx] = useState(0);

  const classDetailsTabIndex = 0;
  const dateAndTimeTabIndex = 1;
  const bookingDetailsTabIndex = 2;
  const recurrenceTabIndex = 3;

  const { api } = useGymflowModels();
  useEffect(() => {
    async function fetchDefaultLabels() {
      setIsLoadingDefaultValues(true);
      if (initialValues[ACTIVITY_ID]) {
        const activity = await activityQueryFn({
          api,
          activityId: values[ACTIVITY_ID],
        });
        setActivityName(activity.name);
      }
      if (initialValues[FACILITY_ID]) {
        const facility = await facilityQueryFn({
          api,
          facilityId: values[FACILITY_ID],
        });
        setFacilityName(facility.name);
      }
      if (initialValues[HOST_MEMBER_ID]) {
        const host = await staffQueryFn({
          api,
          staffId: initialValues[HOST_MEMBER_ID],
        });
        setHostName(`${host.firstName} ${host.lastName}`);
      }

      setIsLoadingDefaultValues(false);
    }
    fetchDefaultLabels();
  }, [initialValues]);

  useEffect(() => {
    const validateAndOpenInvalidSections = () => {
      const classDetailsSchema = Yup.object().shape(
        createClassDetailsSchemaFields(),
      );
      try {
        classDetailsSchema.validateSync(values);
      } catch {
        setOpenSectionIdx(classDetailsTabIndex);
        return;
      }

      const dateAndTimeSchema = Yup.object().shape(
        createDateAndTimeSchemaFields(),
      );
      try {
        dateAndTimeSchema.validateSync(values);
      } catch {
        setOpenSectionIdx(dateAndTimeTabIndex);
        return;
      }

      const bookingDetailsSchema = Yup.object().shape(
        createBookingDetailsSchemaFields(),
      );
      try {
        bookingDetailsSchema.validateSync(values);
      } catch {
        setOpenSectionIdx(bookingDetailsTabIndex);
        return;
      }

      const recurrenceSchema = Yup.object().shape(
        createRecurrenceSchemaFields(),
      );
      try {
        recurrenceSchema.validateSync(values);
      } catch {
        setOpenSectionIdx(recurrenceTabIndex);
        return;
      }
    };

    validateAndOpenInvalidSections();
  }, [formikProps.submitCount]);

  const setStartAndEndValues = useCallback(
    (
      startDate: any,
      startTime: any,
      endTime: any,
      updateEndTimeToHalfAnHourAfterStartTime = false,
    ) => {
      const startMoment = moment(
        `${startDate} ${startTime}`,
        `${DATE_FORMAT} ${TIME_FORMAT} a`,
      );
      let endMoment = moment(
        `${startDate} ${endTime}`,
        `${DATE_FORMAT} ${TIME_FORMAT}`,
      );

      if (startMoment.isValid() && updateEndTimeToHalfAnHourAfterStartTime) {
        endMoment = startMoment.add(30, "minutes");
      }

      setFieldValue(START_DATE, startDate);
      setFieldValue(START_TIME, startTime);
      setFieldValue(END_TIME, endMoment.format("hh:mm a"));
      if (startMoment.isAfter(endMoment)) {
        const nextDay = moment(startDate, DATE_FORMAT)
          .startOf("day")
          .add(1, "day");
        setFieldValue(END_DATE, nextDay.format(DATE_FORMAT));
      } else {
        setFieldValue(END_DATE, endMoment.format(DATE_FORMAT));
      }
    },
    [setFieldValue],
  );

  const sections = [
    {
      title: "Class Details",
      body: (
        <>
          <div className="flex flex-col">
            <label
              htmlFor={ACTIVITY_ID}
              className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
            >
              Class
            </label>
            <div>
              <ActivitySelect
                value={
                  values[ACTIVITY_ID] && [
                    {
                      value: values[ACTIVITY_ID],
                      label: activityName,
                    },
                  ]
                }
                onChange={(activityOption) => {
                  setFieldValue(ACTIVITY_ID, activityOption[0].value);
                  setActivityName(activityOption[0].label);
                }}
                placeholder="Select class"
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2", {
                hidden: !errors[ACTIVITY_ID],
              })}
            >
              {errors[ACTIVITY_ID]}
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor={FACILITY_ID}
              className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
            >
              Facility
            </label>
            <div>
              <FacilitySelect
                value={
                  values[FACILITY_ID] && [
                    {
                      value: values[FACILITY_ID],
                      label: facilityName,
                    },
                  ]
                }
                onChange={(facilityOption) => {
                  setFieldValue(FACILITY_ID, facilityOption[0].value);
                  setFacilityName(facilityOption[0].label);
                }}
                placeholder="Select facility"
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2", {
                hidden: !errors[FACILITY_ID],
              })}
            >
              {errors[FACILITY_ID]}
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor={HOST_MEMBER_ID}
              className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
            >
              Host
            </label>
            <div>
              <StaffSelect
                value={
                  values[HOST_MEMBER_ID] && [
                    {
                      value: values[HOST_MEMBER_ID],
                      label: hostName,
                    },
                  ]
                }
                onChange={(hostOption) => {
                  setFieldValue(HOST_MEMBER_ID, hostOption[0].value);
                  setHostName(hostOption[0].label);
                }}
                placeholder="Select host"
                isDisabled={disableHost}
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2", {
                hidden: !errors[HOST_MEMBER_ID],
              })}
            >
              {errors[HOST_MEMBER_ID]}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Date & Time",
      body: (
        <>
          <div className="flex flex-col">
            <label
              htmlFor={START_DATE}
              className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
            >
              Date
            </label>
            <div>
              <DateSelect
                value={values[START_DATE]}
                onChange={(newDate) => {
                  setStartAndEndValues(
                    newDate,
                    values[START_TIME],
                    values[END_TIME],
                  );
                }}
                dateFormat={DATE_FORMAT}
                dateToStartFrom={referenceDate
                  .clone()
                  .subtract(3, "days")
                  .format(DATE_FORMAT)}
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2", {
                hidden: !errors[START_DATE],
              })}
            >
              {errors[START_DATE]}
            </div>
          </div>

          <div className="flex flex-col">
            <label
              htmlFor={START_TIME}
              className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
            >
              Start Time
            </label>
            <div className="flex">
              <TimeSelect
                value={values[START_TIME]}
                onChange={(newTime) => {
                  setStartAndEndValues(
                    values[START_DATE],
                    newTime,
                    values[END_TIME],
                    true,
                  );
                }}
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2", {
                hidden: !errors[START_TIME],
              })}
            >
              {errors[START_TIME]}
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor={END_TIME}
              className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
            >
              End Time
            </label>
            <div className="flex">
              <TimeSelect
                value={values[END_TIME]}
                onChange={(newTime) => {
                  setStartAndEndValues(
                    values[START_DATE],
                    values[START_TIME],
                    newTime,
                  );
                }}
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2", {
                hidden: !errors[END_DATE],
              })}
            >
              {errors[END_DATE]}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Booking Details",
      body: (
        <>
          <div className="flex flex-col">
            <div
              className={classNames(
                "flex flex-row items-center justify-between",
              )}
            >
              <label
                htmlFor={IS_PUBLIC}
                className="mb-0 flex flex-row items-center text-sm font-medium text-gray-700"
              >
                Available Online
              </label>
              <Switch
                value={values[IS_PUBLIC]}
                label="Is public"
                onChange={(newValue) => {
                  setFieldValue(IS_PUBLIC, newValue);
                }}
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2", {
                hidden: !errors[IS_PUBLIC],
              })}
            >
              {errors[IS_PUBLIC]}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <label
                htmlFor={IS_BOOKABLE}
                className="mb-0 flex flex-row items-center text-sm font-medium text-gray-700"
              >
                Accept Bookings
              </label>
              <Switch
                value={values[IS_BOOKABLE]}
                label="Is bookable"
                onChange={(newValue) => {
                  setFieldValue(IS_BOOKABLE, newValue);
                }}
              />
            </div>
            <div
              className={classNames("text-error-700 pt-2", {
                hidden: !errors[IS_BOOKABLE],
              })}
            >
              {errors[IS_BOOKABLE]}
            </div>
          </div>

          {values[IS_BOOKABLE] && (
            <div className="flex flex-col">
              <label
                htmlFor={CREDIT_COST}
                className={classNames("flex text-sm font-medium text-gray-700")}
              >
                Credit Cost
              </label>
              <PaginatedSelect
                value={{
                  label: values[CREDIT_COST],
                  value: values[CREDIT_COST],
                }}
                onChange={(newCost) => {
                  setFieldValue(CREDIT_COST, newCost.value);
                }}
                loadOptions={async function () {
                  return {
                    options: range(0, 101).map((cost) => ({
                      label: cost,
                      value: cost,
                    })),
                    hasMore: false,
                  };
                }}
              />
              <div
                className={classNames("text-error-700 pt-2", {
                  hidden: !errors[CREDIT_COST],
                })}
              >
                {errors[CREDIT_COST]}
              </div>
            </div>
          )}
          {values[IS_BOOKABLE] && (
            <div className="flex flex-col">
              <label
                htmlFor={CAPACITY}
                className={classNames("flex text-sm font-medium text-gray-700")}
              >
                Capacity
              </label>
              <PaginatedSelect
                value={{ label: values[CAPACITY], value: values[CAPACITY] }}
                onChange={(newCapacity) => {
                  setFieldValue(CAPACITY, newCapacity.value);
                }}
                loadOptions={async function () {
                  return {
                    options: range(0, 101).map((capacity) => ({
                      label: capacity,
                      value: capacity,
                    })),
                    hasMore: false,
                  };
                }}
              />
              <div
                className={classNames("text-error-700 pt-2", {
                  hidden: !errors[CAPACITY],
                })}
              >
                {errors[CAPACITY]}
              </div>
            </div>
          )}
          {values[IS_BOOKABLE] && (
            <div className="flex flex-col">
              <label
                htmlFor={WAITING_LIST_CAPACITY}
                className={classNames("flex text-sm font-medium text-gray-700")}
              >
                Waitlist
              </label>
              <PaginatedSelect
                value={{
                  label: values[WAITING_LIST_CAPACITY],
                  value: values[WAITING_LIST_CAPACITY],
                }}
                onChange={(newWaitlistCapacity) => {
                  setFieldValue(
                    WAITING_LIST_CAPACITY,
                    newWaitlistCapacity.value,
                  );
                }}
                loadOptions={async function () {
                  return {
                    options: range(0, 101).map((waitlistCapacity) => ({
                      label: waitlistCapacity,
                      value: waitlistCapacity,
                    })),
                    hasMore: false,
                  };
                }}
              />
              <div
                className={classNames("text-error-700 pt-2", {
                  hidden: !errors[WAITING_LIST_CAPACITY],
                })}
              >
                {errors[WAITING_LIST_CAPACITY]}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Recurrence",
      body: (
        <>
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <label
                htmlFor={IS_RECURRING}
                className="mb-0 flex flex-row items-center text-sm font-medium text-gray-700"
              >
                Repeat
              </label>
              <div>
                <Switch
                  value={values[IS_RECURRING]}
                  label="Is recurring"
                  onChange={(newValue) => {
                    setFieldValue(IS_RECURRING, newValue);
                  }}
                />
              </div>
            </div>
            <div
              className={classNames("text-error-700 pt-2", {
                hidden: !errors[IS_RECURRING],
              })}
            >
              {errors[IS_RECURRING]}
            </div>
          </div>
          {values[IS_RECURRING] && (
            <div className="flex flex-col">
              <label
                htmlFor={RECURRING_INTERVAL}
                className={classNames("flex text-sm font-medium text-gray-700")}
              >
                Every
              </label>
              <PaginatedSelect
                value={{
                  label: values[RECURRING_INTERVAL],
                  value: values[RECURRING_INTERVAL],
                }}
                onChange={(newInterval) => {
                  setFieldValue(RECURRING_INTERVAL, newInterval.value);
                }}
                loadOptions={async function () {
                  return {
                    options: range(1, 5).map((interval) => ({
                      label: interval,
                      value: interval,
                    })),
                    hasMore: false,
                  };
                }}
              />
              <div
                className={classNames("text-error-700 pt-2", {
                  hidden: !errors[RECURRING_INTERVAL],
                })}
              >
                {errors[RECURRING_INTERVAL]}
              </div>
            </div>
          )}
          {values[IS_RECURRING] && (
            <div className="flex flex-col">
              <PaginatedSelect
                value={
                  values[RECURRING_TYPE]
                    ? recurringTypeOptions.find(
                        (v) => v.value === values[RECURRING_TYPE],
                      )
                    : null
                }
                onChange={(newType) => {
                  setFieldValue(RECURRING_TYPE, newType.value);
                }}
                loadOptions={async function () {
                  return {
                    options: recurringTypeOptions,
                    hasMore: false,
                  };
                }}
              />
              <div
                className={classNames("text-error-700 pt-2", {
                  hidden: !errors[RECURRING_TYPE],
                })}
              >
                {errors[RECURRING_TYPE]}
              </div>
            </div>
          )}
          {values[IS_RECURRING] && values[RECURRING_TYPE] === "WEEKLY" && (
            <div className="flex flex-col">
              <div
                className={classNames(
                  "flex flex-row items-center justify-between",
                )}
              >
                {weekdaysOptions.map((weekday) => {
                  return (
                    <div
                      className={classNames(
                        "flex h-11 w-14 min-w-[56px] cursor-pointer items-center justify-center rounded-sm border border-gray-300 font-medium",
                        {
                          "border-primary-600 text-primary-600 bg-gray-200":
                            values[RECURRING_WEEKDAYS].includes(weekday.value),
                        },
                      )}
                      key={weekday.value}
                      onClick={() => {
                        if (
                          values[RECURRING_WEEKDAYS].includes(weekday.value)
                        ) {
                          setFieldValue(
                            RECURRING_WEEKDAYS,
                            values[RECURRING_WEEKDAYS].filter(
                              (value: string) => value !== weekday.value,
                            ),
                          );
                        } else {
                          setFieldValue(RECURRING_WEEKDAYS, [
                            ...values[RECURRING_WEEKDAYS],
                            weekday.value,
                          ]);
                        }
                      }}
                    >
                      {weekday.label}
                    </div>
                  );
                })}
              </div>
              <div
                className={classNames("text-error-700 pt-2", {
                  hidden: !errors[RECURRING_WEEKDAYS],
                })}
              >
                {errors[RECURRING_WEEKDAYS]}
              </div>
            </div>
          )}
          {values[IS_RECURRING] && (
            <div className="flex flex-col">
              <label
                htmlFor={RECURRING_END_DATE}
                className={classNames("flex text-sm font-medium text-gray-700")}
              >
                Until
              </label>
              <DateSelect
                value={values[RECURRING_END_DATE]}
                onChange={(newDate) => {
                  setFieldValue(RECURRING_END_DATE, newDate);
                }}
                dateFormat={DATE_FORMAT}
                dateToStartFrom={referenceDate.format(DATE_FORMAT)}
                interval={{
                  type: formToRecurringInterval(values[RECURRING_TYPE]),
                  value: values[RECURRING_INTERVAL] ?? 1,
                }}
              />
              <div
                className={classNames("text-error-700 pt-2", {
                  hidden: !errors[RECURRING_END_DATE],
                })}
              >
                {errors[RECURRING_END_DATE]}
              </div>
            </div>
          )}
        </>
      ),
    },
  ];
  return (
    <div className="flex h-full max-h-full flex-col overflow-y-auto">
      <CollapsibleSections
        sections={sections}
        onChangeSection={(_, sectionIdx) => {
          setOpenSectionIdx(sectionIdx);
        }}
        openSectionIndex={openSectionIdx}
      />
    </div>
  );
}

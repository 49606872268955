import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { ActivityDTO, ApiListResponse } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityAsMemberQueryKeys } from "./activityAsMemberQueryKeys";

export async function activityListAsMemberQueryFn({
  filter,
  api,
}: {
  filter?: Parameters<ApiType["customerActivityApi"]["find"]>[0];
  api: ApiType;
}) {
  const result = await api.customerActivityApi.find({ ...filter });
  return result.data as ApiListResponse<ActivityDTO>;
}

export function useQueryActivityListAsMember({
  api,
  opts,
}: {
  api: ApiType;
  opts?: Parameters<ApiType["customerActivityApi"]["find"]>[0];
}) {
  const result = useQuery({
    queryKey: activityAsMemberQueryKeys.list(opts),
    queryFn: () => activityListAsMemberQueryFn({ api, filter: opts }),
    initialData: defaultPage<ActivityDTO>,
  });
  return result;
}

import { theme } from "@gymflow/common";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createBrowserHistory } from "history";
import { useMemo } from "react";
import {
  Redirect,
  Route,
  Router,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Toaster } from "sonner";
import { ThemeProvider } from "styled-components";

import environment from "./environment";
import { createQueryClient } from "./helpers/queryClient";
import { useEmbedLoadingStyleStart } from "./hooks/useEmbedLoadingStyle";
import { PortalRoutesContext } from "./hooks/usePortalRoutes";
import { AuthenticationLayout } from "./layouts/Authentication/Authentication";
import EmbedLayout from "./layouts/Embed/Embed";
import KioskLayout from "./layouts/Kiosk/Kiosk";
import { LinkLayout } from "./layouts/Link/Link";
import MemberLayout from "./layouts/Member/Member";
import { Migration as MigrationLayout } from "./layouts/Migration/Migration";
import SiteLayout from "./layouts/Site/Site";
import StaffLayout from "./layouts/Staff/Staff";
import { ToastProvider } from "./providers/ToastProvider";
import { RouteFeature } from "./routes/feature";
import { RouteLayout } from "./routes/layout";
import { GenericSecurePayment } from "./views/GenericSecurePayment";
import { StripeRedirect } from "./views/StripeRedirect";
import { Welcome } from "./views/Welcome";

const hist = createBrowserHistory();

function PortalRoutes() {
  const match = useRouteMatch();
  useEmbedLoadingStyleStart();

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path={`${match.path}${RouteLayout.Authentication}`}>
          {(props) => <AuthenticationLayout {...props} />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Staff}`}>
          {() => <StaffLayout />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Member}`}>
          {() => <MemberLayout />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Kiosk}`}>
          {(props) => <KioskLayout {...props} />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Site}`}>
          {(props) => <SiteLayout {...props} />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Migration}`}>
          <MigrationLayout />
        </Route>
        <Route path={`${match.path}${RouteLayout.Embed}`}>
          {(props) => <EmbedLayout {...props} />}
        </Route>
        <Route path={`${match.path}${RouteLayout.Link}`}>
          {(props) => <LinkLayout {...props} />}
        </Route>
        <Route>
          <Redirect
            exact
            from={match.path}
            to={`${match.url}${RouteLayout.Staff}`}
          />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

function PortalBootstrap() {
  const { brand, company, location } = useParams<{
    brand: string;
    company: string;
    location: string;
  }>();

  const routesConfig = useMemo(
    () => ({
      routeId: `${brand}/${company}/${location}`,
      brand,
      company,
      location,
    }),
    [brand, company, location],
  );

  return (
    <PortalRoutesContext.Provider value={routesConfig}>
      <PortalRoutes />
    </PortalRoutesContext.Provider>
  );
}

const queryClient = createQueryClient();

function PortalRoot() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster richColors />
      <ToastProvider>
        <Router history={hist}>
          <Switch>
            <Route path={`/stripe-redirect`}>
              <StripeRedirect />
            </Route>
            <Route path="/:brand/:company/:location">
              <PortalBootstrap />
            </Route>
            <Route path={RouteFeature.SiteSecurePayment}>
              {(props) => <GenericSecurePayment {...props} />}
            </Route>
            <Route path="">{() => <Welcome />}</Route>
          </Switch>
        </Router>
      </ToastProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default environment.get("SENTRY_ENABLED")
  ? Sentry.withProfiler(PortalRoot)
  : PortalRoot;

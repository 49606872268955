import { useStatistic } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import moment from "moment-timezone";
import QueryString from "qs";
import { useContext } from "react";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../../../hooks/usePortalRoutes";
import { useClubSettings } from "../../../../providers";
import { RouteFeature } from "../../../../routes/feature";
import { RouteLayout } from "../../../../routes/layout";
import useGymflowModels from "../../../../store";
import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportMembersPendingWidget = ({
  parentRoute,
}: {
  parentRoute: string;
}) => {
  const { createClubLink } = usePortalRoutes();
  const history = useHistory();

  const context = useContext(ReportContext);

  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { data, isLoading } = useStatistic({
    api,
    clubId,
    trendPeriod: "MONTH",
    categories: ["membership"],
    membershipFields: ["total", "active", "overdue", "paused", "pending"],
    start:
      context.endDate &&
      moment(context.endDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    end:
      context.endDate &&
      moment(context.endDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    membershipIncluded: context?.membershipIncluded?.map(({ id }) => id),
    membershipExcluded: context?.membershipExcluded?.map(({ id }) => id),
  });

  return (
    <ReportWidgetCard
      title="Pending Memberships"
      value={data?.membership?.pending?.total?.toString() || "0"}
      isLoading={isLoading}
      explanation="Sum of all recurring and pre-paid membership in a Pending status."
      variation={data?.membership?.pending?.var}
      positiveIsBad
      onClick={() =>
        history.push({
          pathname: createClubLink(
            RouteLayout.Staff,
            RouteFeature.PendingMemberships,
          ),
          search: QueryString.stringify({
            startDate: context.startDate,
            endDate: context.endDate,
            backRoute: parentRoute,
          }),
        })
      }
    />
  );
};

import { cn } from "@gymflow/helpers";
import { Field, FieldProps } from "formik";
import { Ref } from "react";

import { EmailEditor } from "../base";
import { EmailEditorProps, EmailEditorRef } from "../base/EmailEditor/types";
import { ErrorMessage } from "../ErrorMessage";

type FormEmailEditorProps<T> = {
  name: keyof T;
  innerRef?: Ref<EmailEditorRef>;
} & Pick<EmailEditorProps, "placeholders" | "className">;

// This is email editor component for forms (formik only);
// "formik.setFieldValue" - doesn't work for "update text/visual" in editor component because this is hard logic component with ref;
// For updating value (like formik.setFieldValue) - use methods from EmailEditorRef;
// "value" - works only for init (first value/first show) and has last updates of field;
// So, we can get value in submit and simple get value but can't update by setFieldValue;
// Check EmailEditorRef type for using custom ref;
export const FormEmailEditor = <T,>({
  name,
  innerRef,
  className,
  ...props
}: FormEmailEditorProps<T>) => (
  <Field name={name}>
    {({
      field,
      form: { setFieldValue },
      meta: { touched, error },
    }: FieldProps) => {
      const isError = touched && error;
      return (
        <div className="flex flex-col gap-1.5">
          <EmailEditor
            {...props}
            {...field}
            ref={innerRef}
            className={cn(className, { "border-error-200": isError })}
            onChange={(value) => setFieldValue(String(name), value)}
          />
          {isError && <ErrorMessage text={error} />}
        </div>
      );
    }}
  </Field>
);

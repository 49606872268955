import { faClock, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ordinal } from "@gymflow/helpers";
import { CalendarEventOccurrenceAsMember } from "@gymflow/types";
import { useAuthenticatedUser } from "apps/portal/src/providers";
import { LoginOverlayContext } from "apps/portal/src/providers/authentication/loginOverlay/context";
import { DateTime, Interval } from "luxon";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Button, CreditsIcon } from "../../atoms";
import CustomTooltip from "../../atoms/CustomTooltip";
import { EmptyDotBadge } from "../../atoms/EmptyDotBadge";
import { LocationMarkerIcon } from "../../atoms/icons/LocationMarkerIcon";
import { StaffAvatar } from "../../molecules/StaffAvatar";

export type HostedPagesClassCardProps = {
  calendarEventOccurrence: CalendarEventOccurrenceAsMember;
};

const HostedPagesClassCard = ({
  calendarEventOccurrence,
}: HostedPagesClassCardProps) => {
  const { t } = useTranslation();
  const { showSignUpOverlay } = useContext(LoginOverlayContext);
  const auth = useAuthenticatedUser();
  const slotsAvailable =
    (calendarEventOccurrence.capacity ?? 0) -
    (calendarEventOccurrence.bookedCount ?? 0);
  if (calendarEventOccurrence.waitingCount === 1) console.log(slotsAvailable);
  const waitlistSlotsAvailable =
    (calendarEventOccurrence.waitListCapacity ?? 0) -
    (calendarEventOccurrence.waitingCount ?? 0);
  if (
    !calendarEventOccurrence.eventRsvpId &&
    slotsAvailable === 0 &&
    waitlistSlotsAvailable === 0
  ) {
    return null;
  }
  return (
    <div className="flex flex-col rounded-2xl border border-gray-200 bg-[#ffffff] p-3 dark:border-gray-800 dark:bg-gray-950">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-row items-center gap-1">
            <div className="text-lg font-bold text-gray-950 dark:text-[#ffffff]">
              {DateTime.fromISO(
                calendarEventOccurrence.startDate,
              ).toLocaleString(DateTime.TIME_SIMPLE)}
            </div>
            <FontAwesomeIcon className="h-4 w-4 text-gray-500" icon={faClock} />
            <div className="text-xs font-medium text-gray-950 dark:text-[#ffffff]">
              {Interval.fromDateTimes(
                DateTime.fromISO(calendarEventOccurrence.startDate),
                DateTime.fromISO(calendarEventOccurrence.endDate),
              )
                .toDuration()
                .rescale()
                .toHuman({
                  unitDisplay: "narrow",
                  listStyle: "short",
                })}
            </div>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="font-bold text-gray-950 dark:text-[#ffffff]">
              {calendarEventOccurrence.activityName}
            </div>
            <CustomTooltip
              message={calendarEventOccurrence.activityDescription}
              hideIfEmpty
            >
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="h-4 w-4 text-gray-500"
              />
            </CustomTooltip>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-1">
            <div className="text-lg font-bold text-gray-950 dark:text-[#ffffff]">
              <StaffAvatar
                userStaff={{
                  firstName: calendarEventOccurrence.hostName.split(" ")[0],
                  lastName: calendarEventOccurrence.hostName.split(" ")[1],
                  picture: calendarEventOccurrence.hostPicture,
                }}
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-1">
            <LocationMarkerIcon pathClassName="stroke-gray-500 " />
            <div className="line-clamp-1 text-xs font-medium text-gray-950 dark:text-[#ffffff]">
              {calendarEventOccurrence.facilityName}
            </div>
          </div>
        </div>
      </div>
      <div className="my-2 flex w-full border-b border-gray-200 dark:border-gray-800" />
      <div className="flex flex-row items-center justify-between gap-2">
        <div className="flex flex-row items-center gap-1">
          <div className="text-lg font-bold text-gray-950 dark:text-[#ffffff]">
            {calendarEventOccurrence.sessionCost}
          </div>
          <CreditsIcon pathClassName="stroke-secondary-500" />
        </div>
        <div className="flex flex-row items-center gap-2">
          {!calendarEventOccurrence.eventRsvpId &&
            0 < slotsAvailable &&
            slotsAvailable < 4 && (
              <div className="text-warning-600 flex flex-col items-center justify-center text-xs font-semibold">
                <div>{slotsAvailable}</div>
                <div>{t("pages.hostedPagesClasses.slotsAvailable")}</div>
              </div>
            )}
          {!calendarEventOccurrence.eventRsvpId &&
            slotsAvailable === 0 &&
            (calendarEventOccurrence.waitingCount ?? 0) > 0 && (
              <div className="flex flex-col items-center justify-center text-xs font-semibold text-gray-600">
                <div>{calendarEventOccurrence.waitingCount}</div>
                <div>{t("pages.hostedPagesClasses.waiting")}</div>
              </div>
            )}
          {!auth.id ? (
            <Button
              onClick={async () => {
                const { isLoggedIn } = await showSignUpOverlay();

                if (isLoggedIn) {
                  // history.push(
                  //   createSiteOrEmbedLink(
                  //     RouteFeature.SiteBuySessionPackIdCheckout.replace(
                  //       ":id",
                  //       creditPack.id.toString(),
                  //     ),
                  //   ),
                  // );
                }
              }}
              intent="secondary"
            >
              {slotsAvailable === 0
                ? t("pages.hostedPagesClasses.joinWaitlist")
                : t("common.book")}
            </Button>
          ) : calendarEventOccurrence.eventRsvpStatus === "BOOKED" ? (
            <EmptyDotBadge intent="lightblue">
              {t("pages.hostedPagesClasses.booked")}
            </EmptyDotBadge>
          ) : calendarEventOccurrence.eventRsvpStatus === "WAITING" ? (
            <EmptyDotBadge intent="warning">
              {t("pages.hostedPagesClasses.positionOnWaitlist", {
                position: ordinal(
                  calendarEventOccurrence?.waitlistQueuePosition ?? 0,
                ),
              })}
            </EmptyDotBadge>
          ) : (
            <Button
              // link={createSiteOrEmbedLink(
              //   RouteFeature.SiteBuySessionPackIdCheckout.replace(
              //     ":id",
              //     creditPack.id.toString(),
              //   ),
              // )}
              intent="secondary"
            >
              {slotsAvailable === 0
                ? t("pages.hostedPagesClasses.joinWaitlist")
                : t("common.book")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default HostedPagesClassCard;

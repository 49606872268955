import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryHostAvailabilityCalculateMultipleDays(
  {
    api,
    params,
    tz,
  }: {
    api: ApiType;
    tz: string;
    params?: Parameters<
      ApiType["availabilityApi"]["hostCalculateAvailabilityMultipleDays"]
    >[0];
  },
  opts?: UseQueryOptions<
    | Awaited<
        ReturnType<
          ApiType["availabilityApi"]["hostCalculateAvailabilityMultipleDays"]
        >
      >["data"]
    | undefined
    | null
  >,
) {
  const result = useQuery({
    queryKey:
      availabilityQueryKeys.hostCalculateAvailabilityMultipleDays(params),
    queryFn: async () => {
      const utcDateFrom = zonedTimeToUtc(params!.dateFrom, tz);

      const result =
        await api.availabilityApi.hostCalculateAvailabilityMultipleDays({
          ...params!,
          dateFrom: utcDateFrom,
        });
      return result.data;
    },
    select: (data) =>
      hostCalculateAvailabilityMultipleDaysSelectFn({ data, tz: tz as string }),
    enabled:
      !!tz &&
      !!params,
    initialData: null,
    ...opts,
  });

  return result;
}

export function hostCalculateAvailabilityMultipleDaysSelectFn({
  data,
  tz,
}: {
  data?:
    | Awaited<
        ReturnType<
          ApiType["availabilityApi"]["hostCalculateAvailabilityMultipleDays"]
        >
      >["data"]
    | null;
  tz: string;
}) {
  if (!data) {
    return data;
  }
  return data.slice(0).map((availabilityLine) => ({
    ...availabilityLine,
    startTime: utcToZonedTime(availabilityLine.startTime, tz),
    endTime: utcToZonedTime(availabilityLine.endTime, tz),
  }));
}

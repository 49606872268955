import { useMutation } from "@tanstack/react-query";

export type SendSMSToMemberArgs = {
  body: string;
  userMemberId: string;
};

type Api = {
  smsApi: {
    sendSMSToMember: (args: SendSMSToMemberArgs) => Promise<void>;
  };
};

export const useMutationSendSMSToMember = ({ api }: { api: Api }) => {
  return useMutation({
    mutationFn: (payload: SendSMSToMemberArgs) =>
      api.smsApi.sendSMSToMember(payload),
  });
};

import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useQueryCalendarEventOccurrencesAsMember,
  useQueryCalendarEventOccurrencesAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { CalendarEventOccurrenceAsMember } from "@gymflow/types";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { DateTime } from "luxon";
import { useMemo } from "react";

import { Button, Spinner } from "../../atoms";
import { EmptyPage } from "../../atoms/EmptyPage";
import HostedPagesClassCard from "./HostedPagesClassCard";
import { HostedPagesClassesFilterPopoverProps } from "./HostedPagesClassesFilterPopover";

export type HostedPagesClassesDayCalendarProps = {
  selectedStartOfWeek: DateTime;
  filter: HostedPagesClassesFilterPopoverProps["filter"];
  setFilter: (filter: HostedPagesClassesFilterPopoverProps["filter"]) => void;
};

const HostedPagesClassesDayCalendar = ({
  filter,
  setFilter,
  selectedStartOfWeek,
}: HostedPagesClassesDayCalendarProps) => {
  const auth = useAuthenticatedUser();
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const publicQuery = useQueryCalendarEventOccurrencesAsPublic({
    api,
    filters: {
      dateFrom: DateTime.max(
        DateTime.now().startOf("hour"),
        selectedStartOfWeek,
      )
        .toUTC()
        .toISO({ suppressMilliseconds: true })!,
      dateTo: selectedStartOfWeek.toUTC().plus({ weeks: 1 }).toISO()!,
      includeWaitingCounts: true,
      includeBookedCounts: true,
      ...filter,
    },
    enabled: !auth.id,
  });
  const customerQuery = useQueryCalendarEventOccurrencesAsMember({
    api,
    filters: {
      dateFrom: DateTime.max(
        DateTime.now().startOf("hour"),
        selectedStartOfWeek,
      )
        .toUTC()
        .toISO({ suppressMilliseconds: true })!,
      dateTo: selectedStartOfWeek.toUTC().plus({ weeks: 1 }).toISO()!,
      includeWaitingCounts: true,
      includeBookedCounts: true,
      ...filter,
    },
    enabled: !!auth.id,
  });
  const { data: calendarClasses, isLoading } = auth.id
    ? customerQuery
    : publicQuery;
  const classesPerDay = useMemo(() => {
    const classesPerDay: CalendarEventOccurrenceAsMember[][] = new Array(7)
      .fill(0)
      .map((e) => []);
    calendarClasses
      ?.flatMap((e) => e.content)
      ?.forEach((e) => {
        const indexOfDayOfWeek =
          DateTime.fromISO(e.startDate).setZone(settings.timezone).weekday - 1;
        classesPerDay[indexOfDayOfWeek].push(e);
      });
    return classesPerDay;
  }, [calendarClasses, settings.timezone]);
  const hasFiltersApplied =
    filter.activityCategoryId.length > 0 ||
    filter.activityId.length > 0 ||
    filter.eventHostId.length > 0 ||
    filter.facilityId.length > 0;
  const hasClasses = classesPerDay.some((e) => e.length > 0);
  const { isEmbed } = usePortalRoutes();
  const [parent] = useAutoAnimate();
  return (
    <div
      className={cn(
        "track-height flex max-w-full flex-row gap-6 overflow-x-auto p-4 lg:px-28",
        {
          "items-center justify-center": !isLoading && !hasClasses,
          "h-full": !isEmbed,
        },
      )}
      ref={parent}
    >
      {!isLoading && !hasClasses && (
        <EmptyPage
          action={
            hasFiltersApplied ? (
              <Button
                onClick={() => {
                  setFilter({
                    activityCategoryId: [],
                    activityId: [],
                    eventHostId: [],
                    facilityId: [],
                  });
                }}
              >
                Clear Filters
              </Button>
            ) : undefined
          }
        />
      )}
      {(classesPerDay ?? new Array(7).fill(0).map(() => []))?.map(
        (eventOccurrences, i) => (
          <ClassLane
            eventOccurrences={eventOccurrences}
            isLoading={isLoading}
            currentDay={selectedStartOfWeek.plus({ days: i })}
            key={i}
          />
        ),
      )}
    </div>
  );
};

const ClassLane = ({
  eventOccurrences,
  isLoading,
  currentDay,
}: {
  eventOccurrences: CalendarEventOccurrenceAsMember[];
  isLoading: boolean;
  currentDay: DateTime;
}) => {
  const { isEmbed } = usePortalRoutes();
  const [parent] = useAutoAnimate();
  if (eventOccurrences.length === 0 && !isLoading) return null;
  return (
    <div
      className={cn(
        "flex h-full min-w-[min(calc(100vw-2rem),20rem)] flex-col gap-6 rounded-3xl border border-gray-200 bg-gray-100 py-4 dark:border-gray-800 dark:bg-gray-900",
        {
          "h-fit": isEmbed,
        },
      )}
    >
      <div className="flex flex-row items-center justify-between px-4">
        <div className="text-xl font-medium text-gray-950 dark:text-white">
          {currentDay.toLocaleString({ weekday: "long" })}
        </div>
        <div className="text-xl font-medium text-gray-500">
          {currentDay.toLocaleString({
            month: "short",
            day: "numeric",
          })}
        </div>
      </div>
      <div
        ref={parent}
        className="flex min-h-[4rem] flex-col gap-4 overflow-y-auto overflow-x-visible px-4"
      >
        {isLoading && <Spinner />}
        {!isLoading &&
          eventOccurrences.map((e, i) => (
            <HostedPagesClassCard
              key={e.eventOccurrenceId}
              calendarEventOccurrence={e}
            />
          ))}
      </div>
    </div>
  );
};

export default HostedPagesClassesDayCalendar;

import { useAbility } from "@casl/react";
import { hasStaffRole, useClubFeatureFlags, useStaff } from "@gymflow/api";
import { AlertProvider } from "@gymflow/common";
import classNames from "classnames";
import { useMemo } from "react";
import { Switch } from "react-router-dom";

import { NewUserSidebarProvider } from "../../components/molecules";
import { TopNavbar } from "../../components/Navbars/TopNavbar";
import {
  CreateEditTaskSidebarProvider,
  SendEmailSidebarProvider,
  SendSMSToLeadSidebarProvider,
  SendSMSToUserSidebarProvider,
} from "../../components/organisms";
import { ActivitySideBarFormProvider } from "../../components/Settings/Activity/ActivitySideBarForm";
import { FacilitySideBarFormProvider } from "../../components/Settings/Facility/FacilitySideBarForm";
import { PrepaidMembershipFormSidebarProvider } from "../../components/Settings/Membership/PrepaidMembershipFormSidebarProvider";
import { RecurringMembershipFormSidebarProvider } from "../../components/Settings/Membership/RecurringMembershipFormSidebarProvider";
import { TrialMembershipFormSidebarProvider } from "../../components/Settings/Membership/TrialMembershipFormSidebarProvider";
import { ProductFormSidebarProvider } from "../../components/Settings/Product/ProductFormSidebarProvider";
import { CreditPackFormSidebarProvider } from "../../components/Settings/Sessions/CreditPackFormSidebarProvider";
import { ShopSidebarSidebarProvider } from "../../components/Shop/ShopSidebar/ShopSidebarProvider";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { NoteFormSideBarProvider } from "../../components/UserMember/Notes/NoteFormSideBarProvider";
import { getVisibleRoutes } from "../../helpers/routes";
import { useIntercomReporting, usePageLayout } from "../../hooks";
import { AbilityContext } from "../../permissions";
import {
  ApiResolverProvider,
  AuthenticatedProvider,
  ClubSettingsProvider,
  ModalProvider,
  PageTitleProvider,
  PermissionsProvider,
  SidebarToggleContext,
  useAuthenticatedUser,
  useClubSettings,
  YupExtensions,
} from "../../providers";
import { generateStaffRoutes } from "../../routes";
import useGymflowModels from "../../store";
import MainStyleLayout from "../MainStyleLayout/MainStyleLayout";

function Staff() {
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });

  const staffRoutes = useMemo(
    () => generateStaffRoutes({ featureFlags }),
    [featureFlags],
  );
  const {
    isSidebarOpen,
    isNavbarVisible,
    getRoutes,
    toggleSidebar,
    closeSidebar,
    showSidebar,
  } = usePageLayout({ routes: staffRoutes });
  const { id: loggedInId } = useAuthenticatedUser();
  const { data: staff } = useStaff({ api, staffId: loggedInId });

  const ability = useAbility(AbilityContext);

  const visibleRoutes = getVisibleRoutes(staffRoutes, ability);

  useIntercomReporting();
  return (
    <AlertProvider>
      <NoteFormSideBarProvider>
        <CreateEditTaskSidebarProvider>
          <ModalProvider>
            <SidebarToggleContext.Provider
              value={{
                sidebarOpened: isSidebarOpen,
                toggleSidebar: toggleSidebar,
                hideSidebar: closeSidebar,
                showSidebar: showSidebar,
              }}
            >
              <NewUserSidebarProvider>
                <FacilitySideBarFormProvider>
                  <CreditPackFormSidebarProvider>
                    <ProductFormSidebarProvider>
                      <ActivitySideBarFormProvider>
                        <TrialMembershipFormSidebarProvider>
                          <RecurringMembershipFormSidebarProvider>
                            <PrepaidMembershipFormSidebarProvider>
                              <ShopSidebarSidebarProvider>
                                <SendSMSToLeadSidebarProvider>
                                  <SendSMSToUserSidebarProvider>
                                    <SendEmailSidebarProvider>
                                      <>
                                        <Sidebar
                                          routes={visibleRoutes}
                                          closeSidebar={closeSidebar}
                                          sidebarOpened={isSidebarOpen}
                                          userName={`${staff?.firstName} ${staff?.lastName}`}
                                          userEmail={staff?.email}
                                          avatar={staff?.picture}
                                        />
                                        <div
                                          className={classNames(
                                            " flex h-full max-h-full w-full flex-col lg:w-[calc(100vw-280px)]",
                                          )}
                                        >
                                          {isNavbarVisible && (
                                            <TopNavbar
                                              toggleSidebar={toggleSidebar}
                                            />
                                          )}
                                          <Switch>
                                            {getRoutes(staffRoutes)}
                                          </Switch>
                                        </div>
                                      </>
                                    </SendEmailSidebarProvider>
                                  </SendSMSToUserSidebarProvider>
                                </SendSMSToLeadSidebarProvider>
                              </ShopSidebarSidebarProvider>
                            </PrepaidMembershipFormSidebarProvider>
                          </RecurringMembershipFormSidebarProvider>
                        </TrialMembershipFormSidebarProvider>
                      </ActivitySideBarFormProvider>
                    </ProductFormSidebarProvider>
                  </CreditPackFormSidebarProvider>
                </FacilitySideBarFormProvider>
              </NewUserSidebarProvider>
            </SidebarToggleContext.Provider>
          </ModalProvider>
        </CreateEditTaskSidebarProvider>
      </NoteFormSideBarProvider>
    </AlertProvider>
  );
}

function StaffWithProviders() {
  return (
    <ApiResolverProvider userType="STAFF">
      {({ authController, clubId }) => (
        <AuthenticatedProvider authController={authController!}>
          {({ roles }) => {
            const isStaff = hasStaffRole(roles);
            return (
              <PageTitleProvider>
                <ClubSettingsProvider clubId={clubId}>
                  <PermissionsProvider allowedRoleCategory="STAFF">
                    <MainStyleLayout isStaff={isStaff}>
                      <YupExtensions>
                        <Staff />
                      </YupExtensions>
                    </MainStyleLayout>
                  </PermissionsProvider>
                </ClubSettingsProvider>
              </PageTitleProvider>
            );
          }}
        </AuthenticatedProvider>
      )}
    </ApiResolverProvider>
  );
}

export default StaffWithProviders;

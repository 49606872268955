import { SMSSendingResponse } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export type SendSMSToLeadArgs = {
  body: string;
  leadId: number;
};

type Api = {
  smsApi: {
    sendSMSToLead: (
      args: SendSMSToLeadArgs,
    ) => Promise<{ data: SMSSendingResponse }>;
  };
};

export const useMutationSendSMSToLead = ({ api }: { api: Api }) => {
  return useMutation({
    mutationFn: (payload: SendSMSToLeadArgs) =>
      api.smsApi.sendSMSToLead(payload),
  });
};

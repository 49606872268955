import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecordForm } from "@gymflow/common";
import { Formik, useFormikContext } from "formik";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import {
  useMutationActivityCreate,
  useMutationActivityEdit,
  useQueryActivity,
} from "../../../../../../libs/api/src";
import { getDefaultsFromZodSchema } from "../../../helpers/zod";
import { ToastContext } from "../../../providers/ToastProvider/context";
import useGymflowModels from "../../../store";
import {
  ActivityCategorySelect,
  Button,
  FormInput,
  FormTextarea,
  SlideSideBar,
} from "../../atoms";
import ActivityFormMapper from "./ActivityFormMapper";
import { ActivitySchema } from "./ActivitySchema";

interface ActivitySideBarContextType {
  showActivityForm: (params: {
    activityId?: number;
    onClose?: () => void;
  }) => void;
}

export const ActivitySideBarContext = createContext<ActivitySideBarContextType>(
  {
    showActivityForm: () => {
      throw new Error("function called outside of context");
    },
  },
);

type ActivityFormValues = z.infer<typeof ActivitySchema>;
const validationSchema = toFormikValidationSchema(ActivitySchema);
const mapper = new ActivityFormMapper();
const fields = getDefaultsFromZodSchema(ActivitySchema);
export function ActivitySideBarFormProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { api } = useGymflowModels();

  const [sidebarState, setSidebarState] = useState<{
    isOpen: boolean;
    editingId?: number;
    onClose?: () => void;
  }>({
    isOpen: false,
  });

  const { data: editing, isFetching: isFetchingActivity } = useQueryActivity({
    api,
    activityId: sidebarState.editingId,
  });
  const newActivityMutation = useMutationActivityCreate({ api });
  const editActivityMutation = useMutationActivityEdit({ api });

  const showActivityForm = useCallback(
    (params?: { activityId?: number; onClose?: () => void }) => {
      setSidebarState({
        isOpen: true,
        editingId: params?.activityId,
        onClose: params?.onClose,
      });
    },
    [],
  );

  const onClose = useCallback(() => {
    if (sidebarState?.onClose) {
      sidebarState.onClose();
    }
    setSidebarState({ isOpen: false });
  }, [sidebarState]);

  const { initialValues, getPatchedValues, getValues } = useRecordForm({
    record: !!sidebarState.editingId && editing ? editing : null,
    fields,
    mapper,
  });

  const { notifyDanger } = useContext(ToastContext);

  return (
    <ActivitySideBarContext.Provider value={{ showActivityForm }}>
      <SlideSideBar
        isOpen={sidebarState.isOpen}
        hide={onClose}
        isLoading={
          isFetchingActivity ||
          editActivityMutation.isLoading ||
          newActivityMutation.isLoading
        }
      >
        <div className="flex h-full max-h-full flex-col overflow-hidden">
          <Formik<ActivityFormValues>
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, formik) => {
              try {
                if (sidebarState.editingId) {
                  await editActivityMutation.mutateAsync({
                    activityId: sidebarState.editingId,
                    patchedFields: getPatchedValues(values),
                  });
                } else {
                  await newActivityMutation.mutateAsync(getValues(values));
                }
                onClose();
                formik.resetForm();
              } catch (e: any) {
                notifyDanger(e);
              }
            }}
          >
            <ActivityForm
              editingId={sidebarState.editingId}
              onClose={onClose}
            />
          </Formik>
        </div>
      </SlideSideBar>
      {children}
    </ActivitySideBarContext.Provider>
  );
}

function ActivityForm({
  editingId,
  onClose,
}: {
  editingId?: number;
  onClose: () => void;
}) {
  const { values, touched, errors, setFieldValue, submitForm } =
    useFormikContext<ActivityFormValues>();
  return (
    <>
      <div className="flex h-full max-h-full flex-col overflow-hidden">
        <div className="flex flex-col justify-between border-b border-gray-200 p-8">
          <div className="mb-1 flex flex-row items-center justify-between">
            <div className="text-xl font-semibold text-gray-900">
              {editingId ? "Edit" : "Create"} Class
            </div>

            <FontAwesomeIcon
              onClick={() => {
                onClose();
              }}
              className="cursor-pointer text-xl text-gray-600"
              icon={faClose}
            />
          </div>
          <div className="text-sm font-medium text-gray-600">
            {editingId ? "Edit" : "Create"} a class to schedule it on your
            calendar.
          </div>
        </div>

        <div className="flex h-full max-h-full flex-col gap-4 overflow-y-auto p-8">
          <FormInput<ActivityFormValues>
            name="name"
            label="Class Name"
            placeholder="Enter a class name"
          />

          <div className="flex flex-col gap-1.5">
            <div className="inline-flex items-center gap-x-2 text-sm font-medium text-gray-950 dark:text-white">
              Category
              <FontAwesomeIcon
                data-tooltip-id="category-tooltip"
                data-tooltip-content="Users will need to own credits with these categories to be able to book the class."
                className="cursor-pointer"
                icon={faQuestionCircle}
              />
              <Tooltip
                className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                id="category-tooltip"
              />
            </div>

            <ActivityCategorySelect
              className="shadow-xs outline-secondary-200 h-[2.875rem] w-full rounded-xl border border-gray-200 text-base font-medium text-gray-500"
              onChange={([newValue]) => {
                setFieldValue("activity-category-id", newValue);
              }}
              value={[values["activity-category-id"]]}
              showManageOption
              isSearchable
            />

            {touched["activity-category-id"] && (
              <label className="text-error-500">
                {errors["activity-category-id"] as any}
              </label>
            )}
          </div>

          <FormTextarea<ActivityFormValues>
            name="description"
            label="Description"
            placeholder="Enter a class description"
            rows={3}
          />
        </div>
      </div>
      <div className="flex h-20 flex-row items-center gap-2 border-t border-gray-200 px-6">
        <Button className="mt-2 flex-1" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="mt-2 flex-1"
          intent="primary"
          onClick={() => {
            submitForm();
          }}
        >
          {editingId ? "Edit" : "Create"}
        </Button>
      </div>
    </>
  );
}

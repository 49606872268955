import { ApiListResponse, TemplateDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { emailTemplateQueryKeys } from "./emailTemplateQueryKeys";

type Api = {
  emailTemplateApi: {
    find: (
      filter: EmailTemplateListFilter,
    ) => Promise<{ data: ApiListResponse<TemplateDTO> }>;
  };
};

export const emailTemplateListQueryFn = async ({
  api,
  opts,
}: {
  api: Api;
  opts: EmailTemplateListFilter;
}) => {
  const { data } = await api.emailTemplateApi.find({ ...opts });
  return data as ApiListResponse<TemplateDTO>;
};

export function useEmailTemplateList({
  api,
  opts,
}: {
  api: Api;
  opts: EmailTemplateListFilter;
}) {
  const result = useQuery({
    queryKey: emailTemplateQueryKeys.list(opts),
    queryFn: async () => await emailTemplateListQueryFn({ api, opts }),
    initialData: defaultPage<TemplateDTO>,
  });
  return result;
}

interface EmailTemplateListFilter {
  readonly extraParams?: {
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}

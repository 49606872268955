import {
  useInfiniteQueryMemberList,
  useQueryMemberListCount,
  UserMemberListFilter,
} from "@gymflow/api";
import { isMobile } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { UserMemberListItem } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { capitalize } from "lodash";
import { useRef, useState } from "react";
import { useLocalStorage, useWindowSize } from "usehooks-ts";

import { Badge, Checkbox, Spinner } from "../components/atoms";
import { UserActionsDropdown } from "../components/molecules";
import { UserMemberAvatar } from "../components/molecules/UserMemberAvatar";
import {
  ActionsSettingsContainer,
  PaginatedTable,
  SendSMSToUsersSidebar,
} from "../components/organisms";
import { UserMemberListFilterSidebar } from "../components/organisms/UserMemberList/UserMemberListFilterSidebar";
import { SettingsContainer } from "../components/Settings/SettingsContainer";
import { usePageSize, usePortalRoutes, useSelected } from "../hooks";
import { useAuthenticatedUser, useClubSettings } from "../providers";
import useGymflowModels from "../store";

export function UserMemberList() {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const [currentPage, setCurrentPage] = useState(0);
  const [filtersAreVisible, setFiltersAreVisible] = useState(false);
  const [isSendSMSToUsersSidebarVisible, setIsSendSMSToUsersSidebarVisible] =
    useState(false);
  const { id: loggedInId } = useAuthenticatedUser();
  const { routeId } = usePortalRoutes();
  const [filters, setFilters] = useLocalStorage<UserMemberListFilter>(
    buildUserListFilterLocalStorageKey(loggedInId as string, routeId),
    {},
  );
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const { data, isLoading, fetchNextPage } = useInfiniteQueryMemberList({
    api,
    pagination: {
      limit: pageSize,
    },
    filters,
    tz: settings.timezone,
  });

  const { data: count, isLoading: isLoadingCount } = useQueryMemberListCount({
    api,
    filters,
    tz: settings.timezone,
  });

  const [sort, setSort] = useState<SortingState>([]);

  const columnHelper = createColumnHelper<UserMemberListItem>();
  const {
    toggle,
    selectAll,
    selected,
    areAllSelected,
    excluded,
    isSelected,
    selectMultiple,
    reset,
  } = useSelected<string>();
  const actionColumn = columnHelper.accessor(
    (e) => ({
      memberId: e.userMemberId,
      firstName: e.firstName,
      lastName: e.lastName,
    }),
    {
      id: "actions",
      header: "Actions",
      cell: (cell) => {
        const { memberId, firstName, lastName } = cell.getValue();
        return (
          <UserActionsDropdown
            memberId={memberId}
            memberName={`${firstName} ${lastName}`}
          />
        );
      },
      enableSorting: false,
    },
  );
  const currentPageData = data?.pages[currentPage]?.content ?? [];
  const selectColumn = columnHelper.accessor("userMemberId", {
    id: "selected",
    enableSorting: false,
    header: () => (
      <Checkbox
        className="pr-4"
        value={areAllSelected}
        onChange={function (checked) {
          selectAll(checked);
        }}
      />
    ),
    cell: (cell) => {
      return (
        <Checkbox
          className="pr-4"
          value={isSelected(cell.getValue())}
          onChange={function (_, e) {
            if (e.shiftKey) {
              e.preventDefault();
              e.stopPropagation();
              const previousSelectedId = selected[selected.length - 1];
              if (previousSelectedId !== undefined) {
                const previousSelectedIdx = currentPageData.findIndex(
                  (row) => row.userMemberId === previousSelectedId,
                );
                if (previousSelectedIdx !== -1) {
                  const a =
                    previousSelectedIdx <= cell.row.index
                      ? previousSelectedIdx
                      : cell.row.index;
                  const b =
                    previousSelectedIdx > cell.row.index
                      ? previousSelectedIdx
                      : cell.row.index;

                  const slice = currentPageData
                    .slice(a, b + 1)
                    .map((r) => r.userMemberId);
                  selectMultiple(slice);
                  return;
                }
              }
            }
            toggle(cell.getValue());
          }}
        />
      );
    },
  });
  const desktopColumns = [
    selectColumn,
    columnHelper.accessor("userMemberId", {
      id: "firstName",
      header: "User",
      enableSorting: false,
      cell: (cell) => {
        return <UserMemberAvatar {...cell.row.original} id={cell.getValue()} />;
      },
    }),
    columnHelper.accessor(
      (e) => ({
        membershipName: e.membershipName,
        membershipPrice: e.membershipPrice,
      }),
      {
        id: "membershipName",
        header: "Current Membership",
        enableSorting: false,
        cell: (cell) => {
          if (!cell.getValue().membershipName) return <div>No Membership</div>;
          return (
            <div className="flex flex-col">
              <div className="text-sm font-medium">
                {cell.getValue().membershipName}
              </div>
              <div className="text-sm font-normal text-gray-600">
                {formatCurrency(
                  cell.getValue().membershipPrice,
                  settings.defaultCurrency,
                )}
              </div>
            </div>
          );
        },
      },
    ),
    columnHelper.accessor("membershipStatus", {
      id: "membershipStatus",
      header: "Membership Status",
      enableSorting: false,
      cell: (cell) => {
        if (!cell.getValue()) return "-";
        return (
          <Badge
            intent={
              (
                {
                  ACTIVE: "success",
                  PENDING: "default",
                  PAUSED: "warning",
                  CANCELLED: "default",
                  EXPIRED: "error",
                  DELETED: "error",
                  OVERDUE: "error",
                } as const
              )[cell.getValue()]
            }
          >
            {capitalize(cell.getValue())}
          </Badge>
        );
      },
    }),
    columnHelper.accessor(
      (e) => ({
        creditsRemaining: e.creditsRemaining,
        creditsUnlimited: e.creditsUnlimited,
      }),
      {
        header: "Credits",
        enableSorting: false,
        cell: (cell) => {
          if (!cell.getValue()) return "-";
          if (cell.getValue().creditsUnlimited) return <div>∞</div>;
          if (cell.getValue().creditsRemaining)
            return <div>{cell.getValue().creditsRemaining}</div>;
          return "-";
        },
      },
    ),
    actionColumn,
  ];
  const mobileColumns = [
    selectColumn,
    columnHelper.accessor("userMemberId", {
      id: "firstName",
      header: "User",
      enableSorting: false,
      cell: (cell) => {
        return (
          <UserMemberAvatar
            className="w-full"
            {...cell.row.original}
            id={cell.getValue()}
          />
        );
      },
    }),
    actionColumn,
  ];
  const windowSize = useWindowSize({ debounceDelay: 100 });
  const selectionCount = areAllSelected
    ? (count?.count ?? 0) - excluded.length
    : selected.length;

  return (
    <div className="flex h-full max-h-full w-full p-4 lg:p-8">
      <UserMemberListFilterSidebar
        isVisible={filtersAreVisible}
        onClose={() => setFiltersAreVisible(false)}
        value={filters}
        onChange={(newFilters) => {
          setCurrentPage(0);
          setFilters(newFilters);
        }}
      />
      <SendSMSToUsersSidebar
        isVisible={isSendSMSToUsersSidebarVisible}
        onClose={() => setIsSendSMSToUsersSidebarVisible(false)}
        recipients={selectionCount}
        selected={selected}
        excluded={excluded}
        areAllSelected={areAllSelected}
        filters={filters}
      />
      <SettingsContainer
        title={
          isLoadingCount ? (
            <Spinner />
          ) : (
            `${count?.count} Users ${
              selectionCount > 0 ? "(" + selectionCount + " selected)" : ""
            }`
          )
        }
        subTitle="All users that have accounts setup."
        actions={
          <ActionsSettingsContainer
            filters={filters}
            reset={reset}
            selected={selected}
            excluded={excluded}
            selectionCount={selectionCount}
            setFiltersAreVisible={setFiltersAreVisible}
            setIsSendSMSSidebarVisible={setIsSendSMSToUsersSidebarVisible}
            areAllSelected={areAllSelected}
          />
        }
      >
        <PaginatedTable
          tableProps={{
            data: currentPageData,
            columns: !isMobile(windowSize.width)
              ? desktopColumns
              : mobileColumns,
            onSortingChange: setSort,
            sort: sort,
            isFetching: isLoading,
            pageSize: pageSize,
            tableContainerRef,
          }}
          hasPreviousPage={!!data && currentPage > 0}
          hasNextPage={!!data && !!data.pages[currentPage]?.nextPageToken}
          goToNextPage={() => {
            setCurrentPage((e) => e + 1);
            if (!data?.pages[currentPage + 1]) {
              fetchNextPage();
            }
          }}
          goToPreviousPage={() => {
            setCurrentPage((e) => e - 1);
          }}
        />
      </SettingsContainer>
    </div>
  );
}

function buildUserListFilterLocalStorageKey(userId: string, routeId: string) {
  return `member-list-${userId}-${routeId}`;
}

import { useClubFeatureFlags, useEmailTemplateList } from "@gymflow/api";
import { AsyncButton, isMobile } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { EmailTemplateSidebar } from "../organisms";
import { ResourceTableCard } from "../ResourceTableCard";
import useTemplateAlert from "./useTemplateAlert";

export function Templates() {
  const [isEmailTemplateSidebarVisible, setIsEmailTemplateSidebarVisible] =
    useState(false);
  const [templateId, setTemplateId] = useState();

  const { t } = useTranslation();
  const { showAlert, editingTemplateId, setEditingTemplateId } =
    useTemplateAlert();
  const { clubId } = useClubSettings();
  const { api } = useGymflowModels();
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });

  const renderButtons = ({ resource: { id, isEditable }, beingEdited }) => (
    <AsyncButton
      className={classNames("btn-link", { "d-none": !isEditable })}
      data-testid="btn-edit"
      onClick={() => {
        if (featureFlags?.featureFlags?.PORTAL_NEW_EMAIL_TEMPLATE_SIDEBAR) {
          setTemplateId(id);
          setIsEmailTemplateSidebarVisible(true);
        } else {
          setEditingTemplateId(id);
        }
      }}
      size="sm"
      disabled={beingEdited}
    >
      Edit
    </AsyncButton>
  );

  return (
    <>
      <EmailTemplateSidebar
        isVisible={isEmailTemplateSidebarVisible}
        templateId={templateId}
        title={
          templateId
            ? t("page.marketing.templates.emailTemplateSidebar.title.edit")
            : t("page.marketing.templates.emailTemplateSidebar.title.add")
        }
        onClose={() => {
          setTemplateId(undefined);
          setIsEmailTemplateSidebarVisible(false);
        }}
      />
      <div className="relative mt-12 flex h-full max-h-full w-full flex-col">
        <Button
          color="primary"
          size="sm"
          className="absolute right-0 top-[-60px] w-fit"
          onClick={() => {
            if (featureFlags?.featureFlags?.PORTAL_NEW_EMAIL_TEMPLATE_SIDEBAR) {
              setIsEmailTemplateSidebarVisible(true);
            } else {
              showAlert();
            }
          }}
        >
          Create Template
        </Button>
        <ResourceTableCard
          columns={[
            {
              accessor: "templateName",
              Header: "Name",
            },
            {
              accessor: "templateType",
              Header: "Type",
              show: !isMobile(),
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false,
              minWidth: isMobile() ? 110 : 160,
            },
          ]}
          renderButtons={renderButtons}
          showEditButtons
          useQueryHook={useEmailTemplateList}
          onEditClick={setEditingTemplateId}
          selectedId={editingTemplateId}
          findParams={{ extraParams: { isAutomationTemplate: false } }}
        />
      </div>
    </>
  );
}

import {
  DATE_FORMAT,
  PARAMETER_DATE_ONLY_FORMAT,
  UserForm,
  UserFormField,
} from "@gymflow/common";
import moment from "moment";
import PropTypes from "prop-types";
import { CardBody } from "reactstrap";

import { useClubSettings } from "../../providers";

const {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  MOBILE_NUMBER,
  SMS_COMMUNICATION,
  EMAIL_COMMUNICATION,
  NATIONALITY,
  GENDER,
  DATE_OF_BIRTH,
  EMERGENCY_CONTACT,
  EMERGENCY_CONTACT_NAME,
  SOURCE,
  IS_CLUB_WAIVER_ACCEPTED,
  ADDRESS1,
  ADDRESS2,
  CITY,
  POST_CODE,
} = UserFormField;

function MemberUserDetailsUpdate({
  fetchLeadSources,
  onUserSelectClick,
  postCodeFormat,
  telephoneFormat,
  defaultNationality,
  dateFormat,
  findByEmail,
  initialValues,
  requiredFields,
}) {
  const settings = useClubSettings();
  const allowPhoneCountrySelection = settings.allow_phone_country_selection;
  return (
    <CardBody>
      <UserForm
        fetchLeadSources={fetchLeadSources}
        postCodeFormat={postCodeFormat}
        telephoneFormat={telephoneFormat}
        defaultNationality={defaultNationality}
        dateFormat={dateFormat}
        allowPhoneCountrySelection={allowPhoneCountrySelection}
        findByEmail={async (email) => {
          if (email === initialValues?.email) {
            return false;
          }
          return findByEmail(email);
        }}
        onSubmit={onUserSelectClick}
        value={
          initialValues
            ? {
                [EMAIL]: initialValues.email,
                [FIRST_NAME]: initialValues.firstName,
                [LAST_NAME]: initialValues.lastName,
                [POST_CODE]: initialValues.postCode,
                [GENDER]: initialValues.gender,
                [ADDRESS1]: initialValues.addressLine1,
                [ADDRESS2]: initialValues.addressLine2,
                [CITY]: initialValues.city,
                [MOBILE_NUMBER]: initialValues.mobileNumber,
                [DATE_OF_BIRTH]: initialValues.dateBirth
                  ? moment(
                      initialValues.dateBirth,
                      PARAMETER_DATE_ONLY_FORMAT,
                      true,
                    ).format(DATE_FORMAT)
                  : null,
                [EMERGENCY_CONTACT]: initialValues.emergencyContact,
                [EMERGENCY_CONTACT_NAME]: initialValues.emergencyContactName,
                [SMS_COMMUNICATION]: initialValues.smsCommunication,
                [EMAIL_COMMUNICATION]: initialValues.emailCommunication,
                [NATIONALITY]: defaultNationality,
                [SOURCE]: {
                  id: initialValues?.lead?.source.id,
                  name: initialValues?.lead?.source.name,
                },
                [IS_CLUB_WAIVER_ACCEPTED]: initialValues?.isClubWaiverAccepted,
              }
            : null
        }
        submitBtnText="Next"
        requiredFields={requiredFields}
        phone_number_country={settings.phone_number_country}
      />
    </CardBody>
  );
}

MemberUserDetailsUpdate.defaultProps = {
  initialValues: null,
};

MemberUserDetailsUpdate.propTypes = {
  fetchLeadSources: PropTypes.func.isRequired,
  onUserSelectClick: PropTypes.func.isRequired,
  postCodeFormat: PropTypes.string.isRequired,
  telephoneFormat: PropTypes.string.isRequired,
  defaultNationality: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
};

export default MemberUserDetailsUpdate;

export const AUTH_STORE_KEY = "auth";
export const CLUB_STORE_KEY = "club";
export const COMPANY_STORE_KEY = "company";
export const STAFF_STORE_KEY = "staff";
export const MEMBER_STORE_KEY = "member";
export const USER_MEMBER_STORE_KEY = "user-member";
export const PRODUCT_STORE_KEY = "product";
export const SERVICE_STORE_KEY = "service";
export const CREDIT_PACK_STORE_KEY = "credit-pack";
export const FACILITY_STORE_KEY = "facility";
export const ACTIVITY_STORE_KEY = "activity";
export const LEAD_SOURCE_STORE_KEY = "lead-source";
export const GLOBAL_NOTIFICATIONS_STORE_KEY = "notifications";
export const SETTINGS_STORE_KEY = "settings";
export const INVOICES_STORE_KEY = "invoices";
export const EVENT_STORE_KEY = "events";
export const TASK_STORE_KEY = "task";
export const NOTE_STORE_KEY = "note";
export const LEAD_STORE_KEY = "lead";
export const RULE_STORE_KEY = "rule";
export const STATISTICS_STORE_KEY = "statistics";
export const CUSTOMER_OCCURRENCE_STORE_KEY = "customer-occurrence";
export const CUSTOMER_CLUB_API_KEY = "customer-club";
export const PUBLIC_SERVICE_STORE_KEY = "public-service";
export const PUBLIC_CREDIT_PACK_STORE_KEY = "public-credit-pack";
export const HOSTED_CLUB_STORE_KEY = "hosted-club";
export const PUBLIC_RULE_STORE_KEY = "public-rule";
export const PUBLIC_HOSTED_CLUB_STORE_KEY = "public-hosted-club";
export const EMAIL_TEMPLATE_STORE_KEY = "email-template";
export const AUTOMATION_STORE_KEY = "automation";
export const REVENUE_STORE_KEY = "revenue";
export const PROMOTION_STORE_KEY = "promotion";

import {
  clubStaleTime,
  useAutomationPlaceholderLabels,
  useClub,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import {
  Badge,
  Button,
  CloseIcon,
  FormEmailEditor,
  FormInput,
  FormSelectInput,
  PlaceholderSelectInput,
} from "apps/portal/src/components/atoms";
import { EmailEditorRef } from "apps/portal/src/components/atoms/base/EmailEditor/types";
import { useSendEmailsNew } from "apps/portal/src/hooks";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { FormikProvider, useFormik } from "formik";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import { TemplateSelectInput } from "./components/TemplateSelectInput";
import {
  INITIAL_VALUES_SEND_EMAIL_FORM,
  MARKETING_TYPE_OPTIONS,
  sendEmailFormSchema,
} from "./constants";
import {
  MarketingType,
  SendEmailFormProps,
  SendEmailFormValues,
} from "./types";

export const SendEmailForm = ({
  onClose,
  recipient,
  allowMarketing,
  requestType,
  listOfIds,
  filter,
  onSuccess,
}: SendEmailFormProps) => {
  const emailEditorRef = useRef<EmailEditorRef | null>(null);

  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });
  const {
    sendEmailToLeads,
    sendEmailToLeadFilter,
    sendEmailToMembers,
    sendEmailToMemberFilter,
  } = useSendEmailsNew();

  const { data: placeholders } = useAutomationPlaceholderLabels({
    api,
    placeholderType: "GENERIC",
  });

  const formik = useFormik<SendEmailFormValues>({
    initialValues: INITIAL_VALUES_SEND_EMAIL_FORM,
    validationSchema: sendEmailFormSchema,
    onSubmit: async ({ body, subject, marketingType }: SendEmailFormValues) => {
      const emailPayload = {
        bccList: [],
        body,
        subject,
        marketing: marketingType[0].value === "MARKETING",
      };

      if (requestType === "LEAD") {
        if (filter) {
          await sendEmailToLeadFilter({
            emailPayload,
            ...filter,
          });
        } else if (listOfIds) {
          await sendEmailToLeads({
            emailPayload,
            leadIds: listOfIds as number[],
          });
        }
      }

      if (requestType === "USER") {
        if (filter) {
          await sendEmailToMemberFilter({
            emailPayload,
            ...filter,
          });
        } else if (listOfIds) {
          await sendEmailToMembers({
            emailPayload,
            userMemberIds: listOfIds as string[],
          });
        }
      }

      onClose();
      onSuccess?.();
    },
  });

  return (
    <FormikProvider value={formik}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col justify-between p-6">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-lg font-semibold text-gray-900">
                {t("components.sendEmailSidebarProvider.sendEmailForm.title")}
              </div>
              <div className="cursor-pointer" onClick={onClose}>
                <CloseIcon className="h-6 w-6" />
              </div>
            </div>
            <div className="pr-16 text-base text-gray-600">
              {t(
                "components.sendEmailSidebarProvider.sendEmailForm.description",
              )}
            </div>
          </div>
          <div className="flex flex-col px-5 pb-6">
            <div className="flex w-full flex-col gap-2 rounded-xl border border-gray-200 px-4 py-2 shadow-sm">
              <div className="flex items-center justify-between py-1.5">
                <div className="text-sm font-medium text-gray-950">
                  {t("components.sendEmailSidebarProvider.sendEmailForm.from")}
                </div>
                <div className="text-base font-medium text-gray-950">
                  {club?.email}
                </div>
              </div>
              <div className="h-px w-full bg-gray-300" />
              <div className="flex items-center justify-between py-1">
                <div className="text-sm font-medium text-gray-950">
                  {t(
                    "components.sendEmailSidebarProvider.sendEmailForm.recipients",
                  )}
                </div>
                <Badge className="rounded-lg border-gray-200 text-sm font-medium">
                  {recipient}
                </Badge>
              </div>
              <div className="h-px w-full bg-gray-300" />
              <FormInput<SendEmailFormValues>
                name="subject"
                variant="unstyled"
                className={cn("h-9 w-full", {
                  "font-semibold": formik.values.subject,
                })}
                placeholder={t(
                  "components.sendEmailSidebarProvider.sendEmailForm.subject.placeholder",
                )}
              />
            </div>
            <div className="flex flex-row gap-3 pb-3 pt-6">
              <Tooltip
                className="!w-44 rounded-lg !bg-black text-center !text-xs !font-semibold"
                id="marketingTypeTooltip"
              />
              <div
                className="flex-1"
                data-tooltip-id="marketingTypeTooltip"
                data-tooltip-content={t(
                  "components.sendEmailSidebarProvider.sendEmailForm.messageTypeFormSelectInput.tooltip",
                )}
                data-tooltip-hidden={allowMarketing}
                data-tooltip-place="top-end"
              >
                <FormSelectInput<SendEmailFormValues, MarketingType>
                  name="marketingType"
                  options={MARKETING_TYPE_OPTIONS}
                  placeholder={t(
                    "components.sendEmailSidebarProvider.sendEmailForm.messageTypeFormSelectInput.placeholder",
                  )}
                  label={t(
                    "components.sendEmailSidebarProvider.sendEmailForm.messageTypeFormSelectInput.label",
                  )}
                  variant="small"
                  isClearable={false}
                  isRequired
                  disabled={!allowMarketing}
                />
              </div>
              <TemplateSelectInput
                className="flex-1"
                onSelect={({ body, subject }) => {
                  emailEditorRef.current?.overrideHTML(body);
                  formik.setFieldValue("subject", subject);
                }}
              />
              <PlaceholderSelectInput
                className="flex-1"
                onSelect={(placeholder) =>
                  emailEditorRef.current?.insertText(placeholder)
                }
                placeholderType="GENERIC"
              />
            </div>
            <FormEmailEditor<SendEmailFormValues>
              innerRef={emailEditorRef}
              name="body"
              placeholders={placeholders}
            />
          </div>
        </div>
        <div className="flex items-center justify-between gap-3 border-t border-t-gray-300 p-6">
          <Button intent="default" className="w-full" onClick={onClose}>
            {t(
              "components.sendEmailSidebarProvider.sendEmailForm.button.cancel",
            )}
          </Button>
          <Button
            intent="secondary"
            className="w-full"
            onClick={async () => await formik.submitForm()}
          >
            {t("components.sendEmailSidebarProvider.sendEmailForm.button.send")}
          </Button>
        </div>
      </div>
    </FormikProvider>
  );
};

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useInfiniteQuery } from "@tanstack/react-query";

import { eventOccurrenceAsMemberQueryKeys } from "./eventOccurrenceAsMemberQueryKeys";

export function useInfiniteQueryEventOccurrencesAsMember({
  api,
  filters,
  enabled = true,
}: {
  api: ApiType;
  filters: Omit<
    Parameters<
      ApiType["customerOccurrenceApi"]["calendarEventOccurrences"]
    >[0]["filters"],
    "nextPageToken" | "limit"
  >;
  enabled?: boolean;
}) {
  return useInfiniteQuery({
    queryKey: eventOccurrenceAsMemberQueryKeys.eventOccurrencesInfiniteQuery({
      filters,
    }),
    queryFn: async ({ pageParam }) => {
      return (
        await api.customerOccurrenceApi.calendarEventOccurrences({
          filters: {
            ...filters,
            limit: 15,
            nextPageToken: pageParam,
            isPublicEvent: true,
          },
        })
      ).data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageToken;
    },
    enabled,
  });
}

import { cn } from "@gymflow/helpers";
import { useClubSettings } from "apps/portal/src/providers";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Card, Col, Row } from "reactstrap";

import { Spinner } from "../../atoms";
import EmailItem from "./EmailItem";
import LeadStatusItem from "./LeadStatusItem";
import MembershipStatusItem from "./MembershipStatusItem";
import TaskItem from "./TaskItem";
import TransactionItem from "./TransactionItem";

export const transactionTypeMap = {
  CREDIT: "Successful Payment",
  MANUAL_CREDIT: "Credit Applied",
  REFUND_DEBIT: "Refund",
  WRITE_OFF: "Debt Written off",
};

const Filter = ({ defaultValues, options = [], onChange }) => {
  const [checked, setChecked] = useState(defaultValues);

  return (
    <div>
      {options.map((item, idx) => {
        const isChecked =
          checked.some((i) => i === item.value) ||
          checked.length + 1 === options.length;
        const isLastOne = checked.length === 1 && checked[0] === item.value;
        return (
          <span
            key={idx}
            className="h3 mr-3"
            style={{
              cursor: isLastOne ? "not-allowed" : "pointer",
              borderBottom: isChecked ? "1px solid #6186db" : "none",
              color: isChecked ? "#6186db" : "#9d9ea2",
            }}
            onClick={() => {
              if (!onChange || isLastOne) {
                return;
              }
              if (item.value === null) {
                const newValue = options
                  .map((o) => o.value)
                  .filter((o) => o !== null);
                onChange(newValue);
                setChecked(newValue);
              } else {
                if (isChecked) {
                  const newValue = checked.filter((o) => o !== item.value);
                  onChange(newValue);
                  setChecked(newValue);
                } else {
                  const newValue = [...checked, item.value];
                  onChange(newValue);
                  setChecked(newValue);
                }
              }
            }}
          >
            {item.label}
          </span>
        );
      })}
    </div>
  );
};

const timelineNodeMap = {
  EMAIL: EmailItem,
  LEADSTATUS: LeadStatusItem,
  SUBSCRIPTION: MembershipStatusItem,
  TASK: TaskItem,
  TRANSACTION: TransactionItem,
};

const nodeTypeOptions = [
  { label: "ALL", value: null },
  { label: "EMAILS", value: "EMAIL" },
  { label: "LEAD STATUS", value: "LEADSTATUS" },
  { label: "MEMBERSHIP STATUS", value: "SUBSCRIPTION" },
  { label: "TASKS", value: "TASK" },
  { label: "TRANSACTIONS", value: "TRANSACTION" },
];

const defaultFilter = nodeTypeOptions
  .filter((op) => op.value)
  .map((op) => op.value);
const Timeline = ({ fetchNodes, refreshNodes, value, isLast, isLoading }) => {
  const settings = useClubSettings();
  const dateFormat = settings.date_format;

  const nodes = value
    .filter(
      (node) =>
        !(node.nodeType === "TRANSACTION" && !transactionTypeMap[node.type]),
    )
    .map((node) => {
      const Component = timelineNodeMap[node.nodeType];
      if (Component) {
        return (
          <li key={node.nodeType + "_" + node.id}>
            <Component
              {...node}
              dateFormat={dateFormat}
              currency={settings.defaultCurrency}
              refreshNodes={refreshNodes}
            />
          </li>
        );
      }

      return null;
    });

  return (
    <div className="relative flex h-full w-full">
      <div
        id="scrollableTimeline"
        className="absolute inset-0 overflow-x-hidden"
      >
        <Row>
          <Col>
            <Filter
              defaultValues={defaultFilter}
              options={nodeTypeOptions}
              onChange={(v) => {
                refreshNodes({ nodeType: v });
              }}
            />
          </Col>
        </Row>
        <Row className="mt-lg-20 mt-3">
          <Col>
            <Card className="card-timeline card-plain">
              <Spinner
                className={cn("text-gray-300", { hidden: !isLoading })}
              />
              <p className={cn("text-muted", { hidden: value.length > 0 })}>
                No activity yet.
              </p>
              <InfiniteScroll
                className={cn({ hidden: value.length === 0 })}
                dataLength={value.length}
                hasMore={!isLast}
                hasChildren
                next={() => {
                  fetchNodes();
                }}
                scrollableTarget="scrollableTimeline"
              >
                <ul className="timeline">{nodes}</ul>
              </InfiniteScroll>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Timeline.defaultProps = {
  fetchNodes: () => {},
};

Timeline.propTypes = {
  userMemberId: PropTypes.string,
  leadId: PropTypes.string,
  fetchNodes: PropTypes.func,
  refreshNodes: PropTypes.func.isRequired,
  value: PropTypes.array,
  isLast: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

export default Timeline;

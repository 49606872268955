import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { appointmentQueryKeys } from "../appointment";
import { noteQueryKeys } from "./noteQueryKeys";

export function useMutationNoteDelete({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  const removeNoteMutation = useMutation({
    mutationFn: async ({ noteId }: { noteId: number }) => {
      await api.noteApi.remove(noteId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: noteQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
    },
  });

  return removeNoteMutation;
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { appointmentQueryKeys } from "../appointment";
import { noteQueryKeys } from "./noteQueryKeys";

export function useMutationNoteEdit({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      noteId,
      patchedFields,
      file,
    }: { noteId: Parameters<ApiType["noteApi"]["update"]>[0] } & Parameters<
      ApiType["noteApi"]["update"]
    >[1]) => {
      await api.noteApi.update(noteId, { patchedFields, file });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: noteQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
    },
  });
  return mutation;
}

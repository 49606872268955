import { createContext, ReactNode, useCallback, useState } from "react";

import { useSendSMS } from "../../hooks";
import { SendSMSSidebar } from "./SendSMSSidebar/SendSMSSidebar";
import { SendSMSFormValues } from "./SendSMSSidebar/types";

interface SendSMSToLeadSidebarContextType {
  open: (arg: { leadId: number; leadName: string }) => void;
}

export const SendSMSToLeadSidebarContext =
  createContext<SendSMSToLeadSidebarContextType>({
    open: () => {
      throw new Error("Called outside of context");
    },
  });

export const SendSMSToLeadSidebarProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [leadDetails, setLeadDetails] = useState<{
    leadId: number;
    leadName: string;
  }>();
  const open = useCallback(
    ({ leadId, leadName }: { leadId: number; leadName: string }) => {
      setLeadDetails({ leadId, leadName });
      setIsVisible(true);
    },
    [],
  );

  return (
    <SendSMSToLeadSidebarContext.Provider
      value={{
        open,
      }}
    >
      <SendSMSToLeadSidebar
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(false);
        }}
        leadId={leadDetails?.leadId!}
        leadName={leadDetails?.leadName!}
      />
      {children}
    </SendSMSToLeadSidebarContext.Provider>
  );
};

export type SendSMSToLeadSidebarProps = {
  isVisible: boolean;
  onClose: () => void;
  leadId: number;
  leadName: string;
};

const SendSMSToLeadSidebar = ({
  onClose,
  leadId,
  leadName,
  isVisible,
}: SendSMSToLeadSidebarProps) => {
  const { sendSMSToLead } = useSendSMS();

  const onSubmit = useCallback(
    async (values: SendSMSFormValues) => {
      await sendSMSToLead({
        leadId,
        body: values.message,
      });

      onClose();
    },
    [leadId, onClose, sendSMSToLead],
  );

  return (
    <SendSMSSidebar
      onClose={onClose}
      onSubmit={onSubmit}
      recipient={leadName}
      description={`Send a SMS to ${leadName}.`}
      isVisible={isVisible}
      hideMarketingOption
      hideScheduleOption
    />
  );
};

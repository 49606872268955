import { subject } from "@casl/ability";
import { useInfiniteQueryMemberTimeline, useMember } from "@gymflow/api";
import { UserMemberBean } from "@gymflow/types";
import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useHistory, useRouteMatch } from "react-router-dom";

import usersIcon from "../../assets/img/users-icon.svg";
import { Button } from "../components/atoms";
import PillTabsLayout from "../components/molecules/PillTabsLayout";
import { ActionMenuButtons } from "../components/SearchGlobal/ActionMenuButtons";
import { ShopSidebarContext } from "../components/Shop/ShopSidebar/ShopSidebarContext";
import { UserMemberNotes } from "../components/UserMember/Notes/UserMemberNotes";
import Timeline from "../components/UserMember/Timeline/Timeline";
import { UserBookings } from "../components/UserMember/UserBookings";
import { UserMemberAccount } from "../components/UserMember/UserMemberAccount";
import { UserMemberOverview } from "../components/UserMember/UserMemberOverview";
import { UserMemberPayments } from "../components/UserMember/UserMemberPayments/UserMemberPayments";
import { UserMemberVisits } from "../components/UserMember/UserMemberVisits/UserMemberVisits";
import { UserSessions } from "../components/UserMember/UserSessions";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { Can, Subject, Verb } from "../permissions";
import { PageTitleProviderContext, useClubSettings } from "../providers";
import useGymflowModels from "../store";

export const UserMemberPage = {
  Profile: "/profile",
  Account: "/account",
  Sessions: "/sessions",
  Bookings: "/bookings",
  Notes: "/notes",
  Payments: "/payments",
  Timeline: "/timeline",
  AddBacsSuccess: "/account/bacs-payment-method-success",
  AddBacsError: "/account/bacs-payment-method-error",
  Visits: "/visits",
};

type NodeType =
  | "EMAIL"
  | "LEADSTATUS"
  | "SUBSCRIPTION"
  | "TASK"
  | "TRANSACTION"
  | null;
export function UserMember() {
  const history = useHistory();

  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { createMemberLink } = usePortalRoutes();
  const match = useRouteMatch<{ id: string }>();
  const { id } = match.params;

  const { data: userData, isFetching: isLoadingUser } = useMember({
    api,
    memberId: id,
    tz: settings.timezone,
  });
  const { setPageTitle } = useContext(PageTitleProviderContext);
  const editing = userData?.user;
  useEffect(() => {
    if (editing?.firstName && editing?.lastName) {
      setPageTitle({
        title: `${editing.firstName} ${editing.lastName}`,
        iconUrl: usersIcon,
      });
    }
  }, [setPageTitle, editing?.firstName, editing?.lastName]);
  const creditPacks = userData?.creditPacks;

  const [timelineOpts, setTimelineOpts] = useState<{ nodeType: NodeType[] }>({
    nodeType: ["EMAIL", "LEADSTATUS", "SUBSCRIPTION", "TASK", "TRANSACTION"],
  });
  const {
    data: timeline,
    isFetching: isTimelineLoading,
    hasNextPage: hasMoreNodes,
    fetchNextPage: fetchMoreNodes,
  } = useInfiniteQueryMemberTimeline({
    api,
    tz: settings.timezone,
    opts: {
      extraParams: { userMemberId: id, nodeType: timelineOpts?.nodeType },
    },
  });
  const nodes = timeline?.pages.flatMap((p) => p.content) ?? [];

  const refetchTimeline = useCallback(
    ({ nodeType }: { nodeType: NodeType[] }) => {
      setTimelineOpts({
        nodeType,
      });
    },
    [],
  );

  type LayoutConfigType<T extends (props: any) => ReactNode> = {
    text: string;
    path: string;
    component: T;
    props: Parameters<T>[0];
  };
  const layoutConfig = [
    {
      text: t("page.userMember.tabTitle.profile"),
      path: createMemberLink(id, UserMemberPage.Profile),
      component: UserMemberOverview,
      props: {
        user: editing as UserMemberBean,
      },
    } satisfies LayoutConfigType<typeof UserMemberOverview>,
    {
      text: t("page.userMember.tabTitle.membership"),
      path: createMemberLink(id, UserMemberPage.Account),
      component: UserMemberAccount,
      props: {
        user: editing as UserMemberBean,
        subscriptions:
          userData?.user.subscriptions.filter((e) => e.membershipBean) ?? [],
      },
    } satisfies LayoutConfigType<typeof UserMemberAccount>,
    {
      text: t("page.userMember.tabTitle.credits"),
      path: createMemberLink(id, UserMemberPage.Sessions),
      component: UserSessions,
      props: {
        isLoading: isLoadingUser,
        creditPacks: creditPacks ?? [],
        memberId: editing?.id ?? "",
      },
    } satisfies LayoutConfigType<typeof UserSessions>,
    {
      text: t("page.userMember.tabTitle.bookings"),
      path: createMemberLink(id, UserMemberPage.Bookings),
      component: UserBookings,
      props: {
        userMemberId: editing?.id,
      },
    } satisfies LayoutConfigType<typeof UserBookings>,
    {
      text: t("page.userMember.tabTitle.notes"),
      path: createMemberLink(id, UserMemberPage.Notes),
      component: UserMemberNotes,
      props: {
        userMemberId: editing?.id,
        name: `${editing?.firstName} ${editing?.lastName}`,
      },
    } satisfies LayoutConfigType<typeof UserMemberNotes>,
    {
      text: t("page.userMember.tabTitle.payments"),
      path: createMemberLink(id, UserMemberPage.Payments),
      component: UserMemberPayments,
      props: {
        user: editing as UserMemberBean,
      },
    } satisfies LayoutConfigType<typeof UserMemberPayments>,
    {
      text: t("page.userMember.tabTitle.timeline"),
      path: createMemberLink(id, UserMemberPage.Timeline),
      component: Timeline,
      props: {
        isLoading: isTimelineLoading,
        fetchNodes: fetchMoreNodes,
        value: nodes,
        isLast: !hasMoreNodes,
        refreshNodes: refetchTimeline,
      },
    },
    {
      text: t("page.userMember.tabTitle.visits"),
      path: createMemberLink(id, UserMemberPage.Visits),
      component: UserMemberVisits,
      props: {
        user: editing as UserMemberBean,
        userMemberId: editing?.id,
      },
    },
  ];
  const { showShop } = useContext(ShopSidebarContext);
  if (!editing) {
    return null;
  }

  return (
    <>
      <Can not I={Verb.View} a={subject(Subject.MemberProfile, { ...editing })}>
        <div className="content flex h-full max-h-full w-full flex-col p-8">
          You are not authorized to view this member&apos;s profile.
          <Button
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            Go back
          </Button>
        </div>
      </Can>
      <Can I={Verb.View} a={subject(Subject.MemberProfile, { ...editing })}>
        <Route path={createMemberLink(id)}>
          <div className="relative flex h-full w-full">
            <PillTabsLayout
              className="absolute inset-0 p-4 lg:p-8"
              tabs={layoutConfig as any}
              moreActions={
                <ActionMenuButtons userMemberId={id} showShop={showShop} />
              }
            />
          </div>
        </Route>
      </Can>
    </>
  );
}

import { cn } from "@gymflow/helpers";
import { UseInfiniteQueryOptions } from "@tanstack/react-query";
import { Field, FieldProps, FormikProps } from "formik";

import {
  SelectInput,
  SelectInputOptionsType,
  SelectInputProps,
} from "../base/SelectInput";
import { ErrorMessage } from "../ErrorMessage";

type FormSelectInputProps<T, V> = {
  name: keyof T;
} & (
  | (Omit<SelectInputProps<V>, "value" | "onChange" | "dataFetchingQuery"> & {
      options: SelectInputOptionsType<V>;
    })
  | (Omit<SelectInputProps<V>, "value" | "onChange" | "options"> & {
      dataFetchingQuery: () => UseInfiniteQueryOptions<
        unknown,
        unknown,
        SelectInputOptionsType<V>
      >;
    })
);

export const FormSelectInput = <T, V>({
  name,
  className,
  ...props
}: FormSelectInputProps<T, V>) => (
  <Field name={name}>
    {({
      field,
      form: { setFieldValue },
      meta: { touched, error },
    }: FieldProps<SelectInputOptionsType<V>, FormikProps<T>>) => {
      const isError = touched && error;
      return (
        <div className="flex flex-col gap-1.5">
          <SelectInput
            {...props}
            value={field.value}
            onChange={(selected: SelectInputOptionsType<V>) =>
              setFieldValue(String(name), selected)
            }
            className={cn(
              className,
              isError && "outline-error-300 border-error-200",
            )}
          />
          {isError && <ErrorMessage text={error} />}
        </div>
      );
    }}
  </Field>
);

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { AvailabilityByHostCalculationResponse } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryFacilityAvailabilityCalculateMappedByFacility(
  {
    api,
    tz,
    params,
  }: {
    api: ApiType;
    tz: string;
    params?: Parameters<
      ApiType["availabilityApi"]["facilityCalculateAvailabilityMappedByFacility"]
    >[0];
  },
  opts?: UseQueryOptions<
    AvailabilityByHostCalculationResponse | undefined | null
  >,
) {
  const result = useQuery({
    queryKey:
      availabilityQueryKeys.facilityCalculateAvailabilityMappedByFacility(
        params,
      ),
    queryFn: async () => {
      const utcDateFrom = zonedTimeToUtc(params!.dateFrom, tz);
      const utcDateTo = zonedTimeToUtc(params!.dateTo, tz);

      const result =
        await api.availabilityApi.facilityCalculateAvailabilityMappedByFacility(
          {
            ...params!,
            dateFrom: utcDateFrom,
            dateTo: utcDateTo,
          },
        );
      return result.data;
    },
    select: (data) =>
      facilityCalculateAvailabilityMappedByFacilitySelectFn({
        data,
        tz: tz as string,
      }),
    enabled: !!tz && !!params,
    initialData: null,
    ...opts,
  });

  return result;
}

export function facilityCalculateAvailabilityMappedByFacilitySelectFn({
  data,
  tz,
}: {
  data?:
    | Awaited<
        ReturnType<
          ApiType["availabilityApi"]["facilityCalculateAvailabilityMappedByFacility"]
        >
      >["data"]
    | null;
  tz: string;
}) {
  if (!data) {
    return data;
  }
  return Object.keys(data).reduce((acc, hostId) => {
    const timezoneParsedStaffAvailability = data[hostId]
      .slice(0)
      .map((availabilityLine) => ({
        ...availabilityLine,
        startTime: utcToZonedTime(availabilityLine.startTime, tz),
        endTime: utcToZonedTime(availabilityLine.endTime, tz),
      }));
    acc[hostId] = timezoneParsedStaffAvailability;
    return acc;
  }, {} as AvailabilityByHostCalculationResponse);
}

import { QueryKey } from "@tanstack/react-query";

export const calendarAsPublicQueryKeys = {
  all: () => ["calendarAsPublic"] as QueryKey,
  eventOccurrences: (filters?: Readonly<{ [k: string]: any }>) => {
    if (filters) {
      return [
        ...calendarAsPublicQueryKeys.all(),
        "eventOccurrences",
        { filters },
      ] as QueryKey;
    } else {
      return [
        ...calendarAsPublicQueryKeys.all(),
        "eventOccurrences",
      ] as QueryKey;
    }
  },
  eventOccurrencesInfiniteScroll: (
    filters?: Readonly<{ [k: string]: any }>,
  ) => {
    if (filters) {
      return [
        ...calendarAsPublicQueryKeys.all(),
        "eventOccurrencesInfiniteScroll",
        { filters },
      ] as QueryKey;
    } else {
      return [
        ...calendarAsPublicQueryKeys.all(),
        "eventOccurrencesInfiniteScroll",
      ] as QueryKey;
    }
  },
};

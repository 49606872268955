import "./CustomTooltip.css";

import * as Tooltip from "@radix-ui/react-tooltip";
import { ReactElement } from "react";

export type CustomTooltipProps = {
  message?: string;
  children: ReactElement;
  hideIfEmpty?: boolean;
};

const CustomTooltip = ({
  children,
  message,
  hideIfEmpty,
}: CustomTooltipProps) => {
  if (!message && hideIfEmpty) return undefined;
  if (!message) return children;
  return (
    <Tooltip.Provider delayDuration={100}>
      <Tooltip.Root>
        <Tooltip.Trigger className="!cursor-default bg-transparent">
          {children}
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="TooltipContent max-w-sm rounded-xl bg-black px-3 py-2 text-center text-sm font-semibold text-white">
            {message}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default CustomTooltip;

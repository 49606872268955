import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { EmailTemplateFormValues } from "./types";

export const emailTemplateFormSchema =
  toFormikValidationSchema<EmailTemplateFormValues>(
    z.object({
      templateName: z
        .string({ required_error: "Template Name is required" })
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 characters"),
      subject: z
        .string({ required_error: "Subject is required" })
        .min(3, "Minimum 3 characters")
        .max(100, "Maximum 100 characters"),
      body: z
        .string({ required_error: "Body is required" })
        .transform((value) => {
          // get ONLY text from HTML
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(value, "text/html");
          return htmlDoc.documentElement.textContent || "";
        })
        .refine((value) => value.length >= 3, {
          message: "Minimum 3 characters",
        }),
    }),
  );

export const INITIAL_VALUES_EMAIL_TEMPLATE_FORM: EmailTemplateFormValues = {
  templateName: "",
  subject: "",
  body: "",
};

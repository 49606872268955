import { UserMemberListFilter } from "@gymflow/api";
import { pluralize } from "@gymflow/helpers";
import { formattedScheduleDate } from "apps/portal/src/helpers/date";
import { useSendSMS } from "apps/portal/src/hooks";
import { useClubSettings } from "apps/portal/src/providers";
import { useCallback } from "react";

import { SCHEDULE_TIME_FORMAT } from "../SendSMSSidebar/constants";
import { SendSMSSidebar } from "../SendSMSSidebar/SendSMSSidebar";
import { SendSMSFormValues } from "../SendSMSSidebar/types";
import { dataFiltersPreparator } from "./utils/dataFiltersPreparator";

export type SendSMSToUsersSidebarProps = {
  isVisible: boolean;
  onClose: () => void;
  recipients: number;
  selected: string[];
  excluded: string[];
  areAllSelected: boolean;
  filters: UserMemberListFilter;
};

export const SendSMSToUsersSidebar = ({
  areAllSelected,
  filters,
  excluded,
  selected,
  onClose,
  recipients,
  ...props
}: SendSMSToUsersSidebarProps) => {
  const { timezone } = useClubSettings();
  const { sendSMSToMembers, sendSMSToMembersFilter } = useSendSMS();

  const onSubmit = useCallback(
    async ({
      marketing,
      message,
      isSchedule,
      date,
      time,
    }: SendSMSFormValues) => {
      const schedule =
        isSchedule && date && time && timezone
          ? formattedScheduleDate(date, time, timezone, SCHEDULE_TIME_FORMAT)
          : undefined;
      const smsPayload = {
        purpose: marketing,
        body: message,
        schedule,
      };

      if (areAllSelected) {
        await sendSMSToMembersFilter({
          ...dataFiltersPreparator(filters, excluded),
          smsPayload,
        });
      } else {
        await sendSMSToMembers({
          smsPayload,
          userMemberIds: selected,
        });
      }

      onClose();
    },
    [
      timezone,
      areAllSelected,
      onClose,
      sendSMSToMembersFilter,
      filters,
      excluded,
      sendSMSToMembers,
      selected,
    ],
  );

  return (
    <SendSMSSidebar
      {...props}
      onClose={onClose}
      onSubmit={onSubmit}
      recipient={`${recipients} ${pluralize("user", "users", recipients)}`}
      description="Send a SMS to the selected users."
    />
  );
};

import { automationPlaceholderLabelsQueryFn, useClubFeatureFlags } from "@gymflow/api";
import {
  AsyncButton,
  FormikInput,
  FormMapper,
  renderErrorRowOnTouch,
  RequiredFieldsLabel,
  useRecordForm,
} from "@gymflow/common";
import classNames from "classnames";
import { useFormik } from "formik";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from 'react-select';
import { AsyncPaginate } from "react-select-async-paginate";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { Editor as SlateEditor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import * as Yup from "yup";

import useTemplateLoadOptions from "../../../hooks/useTemplateLoadOptions";
import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { EmailEditor } from "../../atoms";
import Editor from "../../Marketing/Editor";
import useEditorPlugins from "../../Marketing/useEditorPlugins";

const MARKETING = "marketing";
const SUBJECT = "subject";
const BODY = "body";
const BCC = "bcc";

const EmailSchema = Yup.object().shape({
  [MARKETING]: Yup.boolean().required().default(false),
  [SUBJECT]: Yup.string().required().min(3),
  [BODY]: Yup.string()
    .transform((value) => {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(value, "text/html");
      return htmlDoc.documentElement.innerText;
    })
    .required()
    .min(3),
  [BCC]: Yup.string().min(3),
});

function SendEmailAlert({ onCancel, onSubmit, from, to, allowMarketing }) {
  const templateLoadOptions = useTemplateLoadOptions();
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const [placeholders, setPlaceholders] = useState([]);

  useEffect(() => {
    async function fetchPlaceholders() {
      const result = await automationPlaceholderLabelsQueryFn({
        api,
        placeholderType: "GENERIC",
      });
      setPlaceholders(result);
    }
    fetchPlaceholders();
  }, [api]);

  const [editorValue, setEditorValue] = useState(featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD ? "" : [
    {
      children: [{ text: "" }],
      type: "p",
    },
  ]);

  const { convertEditorNodesToHTML, convertHTMLToEditorNodes } =
    useEditorPlugins({ placeholders });

  const { initialValues, getValues } = useRecordForm({
    record: null,
    fields: EmailSchema.default(),
    mapper: new FormMapper(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: noop,
    validationSchema: EmailSchema,
  });
  const { touched, errors, setFieldValue, values, validateForm, submitForm } =
    formik;

  useEffect(() => {
    if (featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD) {
      return;
    }
    setEditorValue(convertHTMLToEditorNodes("<p></p>"));
  }, [convertHTMLToEditorNodes, featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD]);

  useEffect(() => {
    let html = featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD ? editorValue : convertEditorNodesToHTML(editorValue);

    setFieldValue(BODY, html);
  }, [editorValue, convertEditorNodesToHTML, setFieldValue, featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD]);
  const editorRef = useRef();
  const emailEditorRef = useRef();

  const renderButtons = () => (
    <>
      <button
        type="button"
        onClick={onCancel}
        className="btn btn-sm btn-outline-primary"
        style={{ marginRight: "8px" }}
      >
        Cancel
      </button>
      <AsyncButton
        className={classNames("btn btn-sm btn-primary")}
        onClick={async () => {
          await submitForm();
          const fieldErrors = await validateForm();
          const validated = Object.keys(fieldErrors).length === 0;
          if (validated) {
            await onSubmit(getValues(formik.values));
          }
        }}
        style={{
          marginRight: "8px",
          color: "white",
          borderColor: "rgb(40, 96, 144)",
          boxShadow:
            "rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgb(165, 202, 234) 0px 0px 8px",
        }}
      >
        Send
      </AsyncButton>
    </>
  );

  return (
    <ReactBSAlert
      title="Send Email"
      closeOnClickOutside={false}
      onConfirm={noop}
      customButtons={renderButtons()}
      style={{
        width: "80em",
      }}
    >
      <Form className="form-horizontal" role="form">
        <Row>
          <Col className="text-left">
            <Label tag="h3">To *</Label>
          </Col>
          <Col className="text-left">
            <Label tag="h3">From *</Label>
          </Col>
          <Col className="text-left">
            <Label tag="h3" htmlFor={BCC}>
              BCC
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input maxLength="128" type="text" readOnly value={to} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Input maxLength="128" type="text" readOnly value={from} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormikInput
                autoComplete="off"
                data-testid={BCC}
                maxLength="128"
                name={BCC}
                id={BCC}
                type="text"
                formikProps={formik}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            <Label tag="h3" htmlFor={SUBJECT}>
              Subject *
            </Label>
          </Col>
          <Col className="text-left">
            <Label tag="h3" htmlFor={SUBJECT}>
              Marketing? *
            </Label>
          </Col>
          <Col className="text-left">
            <Label tag="h3">Template</Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <FormikInput
                autoComplete="off"
                data-testid={SUBJECT}
                maxLength="128"
                name={SUBJECT}
                id={SUBJECT}
                type="text"
                formikProps={formik}
              />
            </FormGroup>
          </Col>
          <Col className="d-flex align-items-start">
            <FormGroup
              check
              inline
              className="form-check-radio"
              id="marketing-group"
            >
              <Label check>
                <Input
                  name={MARKETING}
                  type="radio"
                  checked={values[MARKETING] === true}
                  onChange={() => {
                    setFieldValue(MARKETING, true);
                  }}
                  readOnly={!allowMarketing}
                  disabled={!allowMarketing}
                />
                <span className="form-check-sign" />
                Yes
              </Label>
            </FormGroup>
            <UncontrolledTooltip
              target="marketing-group"
              className={{ "d-none": allowMarketing }}
            >
              Member does not allow marketing emails.
            </UncontrolledTooltip>
            <FormGroup check inline className="form-check-radio">
              <Label check>
                <Input
                  name={MARKETING}
                  type="radio"
                  checked={values[MARKETING] === false}
                  onChange={() => {
                    setFieldValue(MARKETING, false);
                  }}
                />
                <span className="form-check-sign" />
                No
              </Label>
            </FormGroup>
          </Col>
          <Col>
            <AsyncPaginate
              loadOptions={templateLoadOptions}
              additional={{
                page: 0,
              }}
              className="react-select info"
              classNamePrefix="react-select"
              onChange={({ value }) => {
                setFieldValue(SUBJECT, value.subject);
                if (featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD) {
                  emailEditorRef.current?.overrideHTML(value.body);
                } else {
                  const result = convertHTMLToEditorNodes(value.body);
                  const editor = editorRef.current;
                  if (!editor) return;
                  Transforms.select(editor, {
                    anchor: SlateEditor.start(editor, []),
                    focus: SlateEditor.end(editor, []),
                  });
                  Transforms.delete(editor);
                  Transforms.insertNodes(editor, result);
                  Transforms.select(editor, SlateEditor.start(editor, []));
                  editor.deleteForward();
                  Transforms.select(editor, SlateEditor.end(editor, []));
                  ReactEditor.focus(editor);
                }
              }}
              value={null}
              searchable={false}
            />
          </Col>
        </Row>
        {/* This is a temporary solution. The entire file will be removed after testing the new email composer */}
        {featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD && (
          <Row>
            <Col>
            <Select
              className="float-left w-52"
              options={Object.values(placeholders).map((p) => ({
                label: p,
                value: p,
              }))}
              onChange={(selected) => {
                const text = "${" + selected.value + "}";
                emailEditorRef.current?.insertText(text);
              }}
              value={null}
              placeholder="Placeholders"
              isSearchable={false}
            />
            </Col>
          </Row>
        )}
        <Row className="mt-2" style={{ padding: "10px" }}>
          {featureFlags?.featureFlags.PORTAL_NEW_EMAIL_EDITOR_FIELD ? (
            <>
              <EmailEditor
                ref={emailEditorRef}
                onChange={setEditorValue}
                value={editorValue}
                placeholders={placeholders}
              />
              <FormGroup>
                {renderErrorRowOnTouch(BODY, touched, errors)}
              </FormGroup>
            </>
          ) : (
            <Col
              className="text-left"
              style={{
                border: "1px solid rgba(58, 58, 63, 0.5)",
                borderRadius: "0.4285rem",
                minHeight: "250px",
              }}
            >
              <FormGroup>
                <Editor
                  editorRef={editorRef}
                  onChange={setEditorValue}
                  value={editorValue}
                  placeholders={placeholders}
                />
              </FormGroup>
              <FormGroup>
                {renderErrorRowOnTouch(BODY, touched, errors)}
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col className="text-left">
            <RequiredFieldsLabel />
          </Col>
        </Row>
      </Form>
    </ReactBSAlert>
  );
}

SendEmailAlert.defaultProps = {
  allowMarketing: false,
};

SendEmailAlert.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  allowMarketing: PropTypes.bool,
};

export default SendEmailAlert;

import { cn } from "@gymflow/helpers";
import React, {
  FocusEventHandler,
  forwardRef,
  ReactNode,
  useState,
} from "react";

type InputVariant = "default" | "unstyled" | "small";

export type InputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  label?: string;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  readOnly?: boolean;
  renderOverInput?: ReactNode;
  variant?: InputVariant;
  disabled?: boolean;
  isRequired?: boolean;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      label,
      value,
      startIcon,
      endIcon,
      renderOverInput,
      variant = "default",
      isRequired = false,
      disabled,
      ...rest
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <div className="relative flex flex-col gap-1.5">
        {label && (
          <div className="flex text-sm font-medium text-gray-950">
            {label}
            {isRequired && <div className="text-error-600 ml-0.5">*</div>}
          </div>
        )}
        <div className="relative">
          {startIcon && (
            <div className="absolute left-3.5 top-1/2 z-10 -translate-y-1/2">
              {startIcon}
            </div>
          )}
          <input
            {...rest}
            ref={ref}
            value={value}
            disabled={disabled}
            onFocus={(e) => {
              setIsFocused(true);
              rest.onFocus?.(e);
            }}
            onBlur={(e) => {
              setIsFocused(false);
              rest.onBlur?.(e);
            }}
            className={cn(
              "font-medium text-base",
              {
                "shadow-xs outline-secondary-200 w-full rounded-[0.625rem] border border-gray-200 px-3.5 py-2.5 disabled:bg-gray-50 h-10":
                  variant === "default",
                "shadow-xs outline-secondary-200 w-full rounded-[0.625rem] border border-gray-200 px-3.5 py-1.5 disabled:bg-gray-50 h-9":
                  variant === "small",
                "bg-transparent border-none p-0  outline-none":
                  variant === "unstyled",
                "text-gray-950": value,
                "text-gray-500": !value,
                "pl-10": startIcon,
                "pr-10": endIcon,
                "text-ellipsis overflow-hidden whitespace-nowrap": !isFocused,
              },
              className,
            )}
          />
          {endIcon && (
            <div className="absolute right-3.5 top-1/2 z-10 -translate-y-1/2">
              {endIcon}
            </div>
          )}
          {renderOverInput}
        </div>
      </div>
    );
  },
);

Input.displayName = "Input";

import {
  ActivityCategoryDTO,
  ActivityStatus,
  ApiListResponse,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

import { ApiPageableRequestParams } from "./types";

export type CustomerActivityApiType = ReturnType<typeof customerActivityApi>;

const customerActivityApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;

  return {
    find(params: ApiPageableRequestParams) {
      return axiosInstance.get(`${clubPrefix}customer/activity`, { params });
    },
    findCategories(
      params: ApiPageableRequestParams & { statusList?: ActivityStatus[] },
    ) {
      return axiosInstance.put<ApiListResponse<ActivityCategoryDTO>>(
        `${clubPrefix}customer/activity/category/search`,
        params,
      );
    },
    findCategoryById(id: number) {
      return axiosInstance.get(`${clubPrefix}customer/activity/category/${id}`);
    },
  };
};

export default customerActivityApi;

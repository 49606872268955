import { LeadSourceDTO } from "@gymflow/types";

import { createPublicAxiosInstance } from "./publicAxiosInstance";

export type PublicLeadApiType = ReturnType<typeof publicLeadApi>;

const publicLeadApi = (apiUrl: string, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  const axiosInstance = createPublicAxiosInstance({
    apiUrl,
  });

  return {
    create(newResource: any) {
      return axiosInstance.post(`${clubPrefix}hosted/lead`, newResource);
    },
    findLeadSources() {
      return axiosInstance.get<LeadSourceDTO[]>(
        `${clubPrefix}hosted/lead/source`,
      );
    },
  };
};

export default publicLeadApi;

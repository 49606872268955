import { cn } from "@gymflow/helpers";
import { Field, FieldProps, FormikProps } from "formik";

import { Input, InputProps } from "../base/Input";
import { ErrorMessage } from "../ErrorMessage";
import { AlertCircleIcon } from "../icons";

type FormInputProps<T> = {
  name: keyof T;
} & Pick<
  InputProps,
  "placeholder" | "label" | "className" | "variant" | "isRequired" | "disabled"
>;

export const FormInput = <T,>({
  name,
  className,
  ...props
}: FormInputProps<T>) => {
  return (
    <Field name={name}>
      {({
        field,
        meta: { touched, error },
      }: FieldProps<string, FormikProps<T>>) => {
        const isError = touched && error;
        return (
          <div className="flex flex-col gap-1.5">
            <Input
              {...field}
              {...props}
              className={cn(
                className,
                isError &&
                  props.variant !== "unstyled" &&
                  "outline-error-300 border-error-200",
              )}
              endIcon={isError && <AlertCircleIcon className="h-5 w-5" />}
            />
            {isError && <ErrorMessage text={error} />}
          </div>
        );
      }}
    </Field>
  );
};

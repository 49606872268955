import React from "react";

export interface StaffAvatarProps {
  userStaff: {
    firstName: string;
    lastName: string;
    picture?: string;
  };
}

export const StaffAvatar: React.FC<StaffAvatarProps> = ({ userStaff }) => {
  const name = `${userStaff.firstName} ${userStaff.lastName}`;
  return (
    <div className="flex flex-row gap-2">
      <div className="flex h-5 w-5 items-center justify-center overflow-hidden rounded-full border border-gray-100 dark:border-gray-900">
        {userStaff.picture ? (
          <img src={userStaff.picture} alt="avatar" />
        ) : (
          <div className="flex h-5 w-5 items-center justify-center text-xs font-semibold text-gray-500 ">
            {userStaff.firstName[0] + userStaff.lastName[0]}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center">
        <div className="line-clamp-1 text-sm text-gray-950 dark:text-[#ffffff]">{name}</div>
      </div>
    </div>
  );
};

import get from "lodash/get";
import intersection from "lodash/intersection";
import { useContext } from "react";

import { copyToClipboard } from "../../../../helpers/copyToClipboard";
import { useIframeEmbed } from "../../../../hooks/useIframeEmbed";
import { usePortalRoutes } from "../../../../hooks/usePortalRoutes";
import { ToastContext } from "../../../../providers/ToastProvider/context";
import { RouteLayout } from "../../../../routes/layout";
import LinkBox from "../../../forms/LinkBox";
import LandingPage from "./LandingPage";
import UrlType from "./UrlType";

function StepLast({ filters, type }) {
  const { createClubLink } = usePortalRoutes();
  const createIframeCode = useIframeEmbed();

  const separateGroupsFromOptions = ({
    value,
    groupIdField,
    optionGroupField,
  }) => {
    let options = value.filter(({ group }) => !group);
    const groups = value.filter(({ group }) => group);
    const groupIds = groups.map((group) => get(group, groupIdField));

    options = options.filter((option) => {
      const group = get(option, optionGroupField);
      if (group instanceof Array) {
        return intersection(groupIds, group).length === 0;
      }
      return !groupIds.includes(group);
    });

    return { options, groups };
  };

  const generateParams = () => {
    let params = [];
    let typeUrl = "";

    if (type === UrlType.Memberships) {
      const { options, groups } = separateGroupsFromOptions({
        value: filters.memberships,
        groupIdField: "id",
        optionGroupField: "type",
      });
      typeUrl = "/membership/any";
      groups.forEach((group) => {
        params.push(`type=${group.id}`);
      });

      options.forEach((option) => {
        params.push(`memberships=${option.id}`);
      });
    } else if (type === UrlType.SessionPacks) {
      const { options, groups } = separateGroupsFromOptions({
        value: filters.sessionPacks,
        groupIdField: "id",
        optionGroupField: "activityCategoryIdList",
      });

      typeUrl = "/session-pack";
      groups.forEach((group) => {
        params.push(`type=${group.id}`);
      });

      options.forEach((option) => {
        params.push(`sessionPacks=${option.id}`);
      });
    } else if (type === UrlType.Timetable) {
      const { options: activities, groups: activityCategories } =
        separateGroupsFromOptions({
          value: filters.activities,
          groupIdField: "id",
          optionGroupField: "activityCategory.id",
        });

      typeUrl = "/timetable";
      activityCategories.forEach((group) => {
        params.push(`activityCategory=${group.id}`);
      });

      activities.forEach((option) => {
        params.push(`activity=${option.id}`);
      });

      filters.hosts.forEach((host) => {
        params.push(`host=${host}`);
      });

      filters.facilities.forEach((facility) => {
        params.push(`facility=${facility}`);
      });

      params.push(`landingPage=${filters.landingPage}`);

      if (filters?.date) {
        params.push(`date=${filters.date}`);
      }

      if (filters.landingPage === LandingPage.Memberships) {
        const { options, groups } = separateGroupsFromOptions({
          value: filters.memberships,
          groupIdField: "id",
          optionGroupField: "type",
        });
        groups.forEach((group) => {
          params.push(`type=${group.id}`);
        });
        options.forEach((option) => {
          params.push(`memberships=${option.id}`);
        });
      } else if (filters.landingPage === LandingPage.SessionPacks) {
        const { options, groups } = separateGroupsFromOptions({
          value: filters.sessionPacks,
          groupIdField: "id",
          optionGroupField: "activityCategoryIdList",
        });

        groups.forEach((group) => {
          params.push(`type=${group.id}`);
        });

        options.forEach((option) => {
          params.push(`sessionPacks=${option.id}`);
        });
      }
    } else if (type === UrlType.GuestRegistration) {
      typeUrl = "/guest-registration";

      params.push(`source=${filters.leadSource.value.id}`);
    } else if (type === UrlType.Enquiry) {
      typeUrl = "/enquiry";

      params.push(`source=${filters.leadSource.value.id}`);
    } else {
      throw new Error("Unknown Url Type");
    }
    params = [...new Set(params)];

    return typeUrl + (params.length ? `?${params.join("&")}` : "");
  };

  const generatedParams = generateParams();
  const website = `${window.location.protocol}//${window.location.host}`;
  const generatedLink =
    website + createClubLink(RouteLayout.Link, generatedParams);
  const generatedIframeCode = createIframeCode(generatedParams);
  const { toast } = useContext(ToastContext);
  return (
    <div className="w-100 text-center">
      <b className="h2">Copy your custom links and code</b>
      <p className="text-muted mt-4">
        Custom links are not stored so ensure you save them or you will have to
        regenerate them.
      </p>
      <div className="m-5">
        <div>
          <LinkBox
            name="custom-link"
            actionText="Copy Link"
            link={generatedLink}
            onCopy={async () => {
              await copyToClipboard(generatedLink);
              toast({ message: "Link copied to clipboard" });
            }}
          />
        </div>
        <div className="mt-2">
          <LinkBox
            name="custom-code"
            actionText="Copy Code"
            link={createIframeCode(generatedParams)}
            onCopy={async () => {
              await copyToClipboard(generatedIframeCode);
              toast({ message: "Code copied to clipboard" });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default StepLast;

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationHostAvailabilityEdit({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      staffId,
      params,
    }: {
      staffId: Parameters<
        ApiType["availabilityApi"]["saveHostAvailability"]
      >[0];
      params: Parameters<ApiType["availabilityApi"]["saveHostAvailability"]>[1];
    }) => {
      const response = await api.availabilityApi.saveHostAvailability(
        staffId,
        params,
      );
      return response.data;
    },
    onSuccess: (_, { staffId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostAvailability(staffId),
      });
    },
  });

  return mutation;
}

/* eslint-disable react/prop-types */
import { membershipHelper } from "@gymflow/helpers";
import { UserMemberSubscriptionBean } from "@gymflow/types";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import { ReactNode, useState } from "react";

import { cn } from "../../../helpers/cn";
import { EmblaCarousel } from "../../molecules";
import { MembershipCard } from "./MembershipCard";
import { MembershipStatusHistory } from "./MembershipStatusHistory";

export type UserMemberMembershipProps = {
  subscriptions: UserMemberSubscriptionBean[];
  memberId: string;
};

export function UserMemberMembership({
  subscriptions = [],
  memberId,
}: UserMemberMembershipProps) {
  const currentMembership = subscriptions.find((sub) =>
    membershipHelper.isCurrentSubscription(sub),
  );
  const otherMemberships = subscriptions.filter(
    (sub) => sub.id !== currentMembership?.id,
  );
  return (
    <div className="absolute inset-0  inset-x-4 flex flex-col gap-x-8 gap-y-4 lg:inset-x-8 lg:flex-row">
      <div className="flex h-fit w-full lg:w-2/3">
        <Tabs
          currentTab={
            <MembershipCard
              memberId={memberId}
              membership={currentMembership}
            />
          }
          pastTab={
            <div className="flex h-fit w-full">
              <EmblaCarousel
                slides={otherMemberships
                  .sort((a, b) => {
                    return (
                      DateTime.fromISO(b.endDate).toMillis() -
                      DateTime.fromISO(a.endDate).toMillis()
                    );
                  })
                  .map((e) => (
                    <MembershipCard
                      key={e.id}
                      memberId={memberId}
                      membership={e}
                    />
                  ))}
              />
            </div>
          }
        />
      </div>
      <div className="flex h-fit w-full lg:w-1/3">
        <MembershipStatusHistory memberId={memberId} />
      </div>
    </div>
  );
}

const Tabs = ({
  currentTab,
  pastTab,
}: {
  currentTab: ReactNode;
  pastTab: ReactNode;
}) => {
  const [tab, setTab] = useState<"CURRENT" | "PAST">("CURRENT");
  return (
    <div className="flex min-h-max w-full flex-col overflow-x-hidden">
      <div className="flex w-full flex-row">
        {(["CURRENT", "PAST"] as const).map((e) => (
          <div
            key={e}
            className={cn(
              "flex h-9 w-1/2 cursor-pointer flex-row border-b border-b-gray-200 transition-all duration-100 ease-in-out",
              {
                "border-b-secondary-400 border-b-2": tab === e,
              },
            )}
            onClick={() => setTab(e)}
          >
            <div
              className={cn(
                "flex w-full items-center justify-center text-center text-sm font-semibold text-gray-500 transition-all duration-100 ease-in-out",
                {
                  "text-secondary-500 -mb-px": tab === e,
                },
              )}
            >
              {capitalize(e)}
            </div>
          </div>
        ))}
      </div>
      <div className="flex h-fit w-full overflow-x-hidden pt-6">
        <div
          className={cn(
            "flex h-fit min-w-full flex-row transition-all duration-500 ease-in-out",
            {
              "-ml-[100%] mr-[100%]": tab === "PAST",
            },
          )}
        >
          {currentTab}
        </div>
        <div
          className={cn(
            "flex h-fit min-w-full flex-row transition-all duration-500 ease-in-out",
            {
              "-ml-[100%] mr-[100%]": tab === "PAST",
              "-mr-[100%] ml-[100%]": tab === "CURRENT",
            },
          )}
        >
          {pastTab}
        </div>
      </div>
    </div>
  );
};

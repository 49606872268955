import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { calendarAsPublicQueryKeys } from "./calendarAsPublicQueryKeys";

export function useQueryCalendarEventOccurrencesAsPublic({
  api,
  filters,
  enabled = true,
}: {
  api: ApiType;
  filters: Omit<
    Parameters<
      ApiType["public"]["eventApi"]["calendarEventOccurrences"]
    >[0]["filters"],
    "nextPageToken" | "limit"
  >;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: calendarAsPublicQueryKeys.eventOccurrences({ filters }),
    queryFn: async ({}) => {
      const result = [
        (
          await api.public.eventApi.calendarEventOccurrences({
            filters: {
              ...filters,
              limit: 200,
              isPublicEvent: true,
            },
          })
        ).data,
      ];
      while (result[result.length - 1].nextPageToken !== undefined) {
        result.push(
          (
            await api.public.eventApi.calendarEventOccurrences({
              filters: {
                ...filters,
                limit: 200,
                isPublicEvent: true,
                nextPageToken: result[result.length - 1].nextPageToken,
              },
            })
          ).data,
        );
      }
      return result;
    },
    enabled,
  });
}

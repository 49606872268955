import { BrandingPatchDTO } from "@gymflow/types";
import { AxiosInstance } from "axios";

export type HostedClubApiType = ReturnType<typeof hostedClubApi>;

const hostedClubApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  return {
    update({ fields, logo }: { fields?: BrandingPatchDTO; logo?: any }) {
      const formData = new FormData();
      if (logo) {
        formData.append("file", logo.blob, logo.name);
      }

      if (fields) {
        formData.append(
          "club_hosted_page",
          new Blob([JSON.stringify({ settings: fields })], {
            type: "application/json",
          }),
        );
      }

      return axiosInstance.put(`${clubPrefix}settings`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  };
};

export default hostedClubApi;

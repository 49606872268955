import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { migrationQueryKeys } from "./migrationQueryKeys";

export function useQueryMigrationSetupIntent({
  api,
  invitationToken,
}: {
  api: ApiType;
  invitationToken: Parameters<
    ApiType["public"]["hostedClubApi"]["invitationCardPaymentMethod"]
  >[0]["invitationToken"];
}) {
  return useQuery({
    queryKey: migrationQueryKeys.setupIntent({ invitationToken }),
    queryFn: async () => {
      return await api.public.hostedClubApi.invitationPaymentMethodSetupIntent({
        invitationToken,
      });
    },
  });
}

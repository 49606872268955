// Since Quill is not a library specifically designed for creating email messages,
// some features are not built-in, and we added them ourselves
export const cleanHTMLForEmail = (html: string): string => {
  return (
    html
      // For [{ align: [] }] in "modules"
      .replace(/class="ql-align-center"/g, 'style="text-align: center;"')
      .replace(/class="ql-align-right"/g, 'style="text-align: right;"')
      .replace(/class="ql-align-justify"/g, 'style="text-align: justify;"')
  );
};

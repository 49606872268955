import {
  ApiTokenListResponse,
  CalendarEventOccurrenceAsMember,
  EventOccurrenceDTO,
  TokenPageableRequestParams,
} from "@gymflow/types";

import { createPublicAxiosInstance } from "./publicAxiosInstance";

export type PublicEventApiType = ReturnType<typeof publicEventApi>;

const publicEventApi = (apiUrl: string, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  const apiInstance = createPublicAxiosInstance({
    apiUrl,
    paramsSerializer: {
      indexes: null,
    },
  });
  return {
    findById(id: number) {
      return apiInstance.get<EventOccurrenceDTO>(
        `${clubPrefix}hosted/event/occurrence/${id}`,
      );
    },
    calendarEventOccurrences({
      filters,
    }: {
      filters: {
        dateFrom: string;
        dateTo: string;
        includeBookedCounts?: boolean;
        includeWaitingCounts?: boolean;
        isPublicEvent?: boolean;
        eventHostId?: string[];
        facilityId?: number[];
        activityId?: number[];
        activityCategoryId?: number[];
      } & TokenPageableRequestParams;
    }) {
      return apiInstance.get<
        ApiTokenListResponse<CalendarEventOccurrenceAsMember>
      >(`${clubPrefix}hosted/calendar/event-occurrences`, {
        params: filters,
      });
    },
  };
};

export default publicEventApi;

import { useTranslation } from "react-i18next";

import { CardIcon, LayeredCircles } from "../components/atoms";

export const StripeRedirect = () => {
  const { t } = useTranslation();
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-4 text-center">
      <LayeredCircles>
        <CardIcon pathClassName="stroke-secondary-600" />
      </LayeredCircles>
      <div className="flex flex-col items-center">
        <div className="text-xl font-bold text-gray-950">
          {t("page.stripeMobileRedirect.title")}
        </div>
        <div>{t("page.stripeMobileRedirect.description")}</div>
      </div>
    </div>
  );
};

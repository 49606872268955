import {
  ActivityCategoryDTO,
  ActivityCategoryPatchDTO,
  ActivityCategoryPostDTO,
  ActivityCategoryStatus,
  ActivityCreateDTO,
  ActivityDTO,
  ActivityStatus,
  ApiListResponse,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

import { trimFields } from "./features/trimFiels";
import { ApiPageableRequestParams } from "./types";

export type ActivityApiType = ReturnType<typeof activityApi>;

const activityApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  // @deprecated - fields should be trimmed by zod
  const fieldsToTrim = ["name", "description"];

  return {
    find(
      params: ApiPageableRequestParams & {
        status?: ActivityStatus;
        id?: number[];
      },
    ) {
      return axiosInstance.get(`${clubPrefix}activity`, { params });
    },
    findById(id: number) {
      return axiosInstance.get<ActivityDTO>(`${clubPrefix}activity/${id}`);
    },
    update(id: number, patchedFields: Partial<ActivityCreateDTO>) {
      return axiosInstance.patch(
        `${clubPrefix}activity/${id}`,
        trimFields(patchedFields, fieldsToTrim),
      );
    },
    deactivate(id: number) {
      return axiosInstance.patch(`${clubPrefix}activity/${id}/inactive`);
    },

    activate(id: number) {
      return axiosInstance.patch(`${clubPrefix}activity/${id}/active`);
    },
    create(newResource: ActivityCreateDTO) {
      return axiosInstance.post<ActivityDTO>(`${clubPrefix}activity`, {
        ...trimFields(newResource, fieldsToTrim),
        clubId,
      });
    },

    findCategories(
      params: ApiPageableRequestParams & {
        statusList?: ActivityCategoryStatus[];
      },
    ) {
      return axiosInstance.put<ApiListResponse<ActivityCategoryDTO>>(
        `${clubPrefix}activity/category/search`,
        params,
      );
    },

    findCategoryById(id: number) {
      return axiosInstance.get<ActivityCategoryDTO>(
        `${clubPrefix}activity/category/${id}`,
      );
    },

    createCategory(newCategory: ActivityCategoryPostDTO) {
      return axiosInstance.post<ActivityCategoryDTO>(
        `${clubPrefix}activity/category`,
        newCategory,
      );
    },

    updateCategory(id: number, patchedFields: ActivityCategoryPatchDTO) {
      return axiosInstance.patch<ActivityCategoryDTO>(
        `${clubPrefix}activity/category/${id}`,
        patchedFields,
      );
    },

    updateCategoryStatus(id: number, newStatus: ActivityCategoryStatus) {
      return axiosInstance.patch<ActivityCategoryDTO>(
        `${clubPrefix}activity/category/${id}/status`,
        {
          status: newStatus,
        },
      );
    },
    /**
     * @deprecated
     */ async fetchCategories() {
      const result = await axiosInstance.put(
        `${clubPrefix}activity/category/search`,
        {
          page: 0,
          size: 1000,
        },
      );
      result.data = result.data.content;
      return result;
    },
  };
};

export default activityApi;

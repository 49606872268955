import {
  InvitationProcessStatusDTO,
  PaymentMethodSetupIntent,
} from "@gymflow/types";

import endpoints from "./endpoints";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

export type PublicHostedClubApiType = ReturnType<typeof publicHostedClubApi>;
const publicHostedClubApi = (apiUrl: string, clubId: number) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.publicHostedClub}`,
    http: createPublicAxiosInstance({
      apiUrl,
    }),
  };

  return Object.assign(state, {
    findById(id: number) {
      return state.http.get(`${state.baseUrl}/settings`);
    },
    invitationRequest({
      email,
      invitationLinkPath,
    }: {
      email: string;
      invitationLinkPath: string;
    }) {
      return state.http.put(
        `${state.baseUrl}/invitation-request/${email}`,
        undefined,
        {
          params: {
            invitationLinkPath,
          },
        },
      );
    },
    invitationStatus({ invitationToken }: { invitationToken: string }) {
      return state.http.get<InvitationProcessStatusDTO>(
        `${state.baseUrl}/invitation-process/status`,
        {
          params: {
            invitationToken,
          },
        },
      );
    },
    invitationNewPassword({
      invitationToken,
      newPassword,
    }: {
      invitationToken: string;
      newPassword: string;
    }) {
      return state.http.put(
        `${state.baseUrl}/invitation-process/new-password`,
        {
          invitationToken,
          newPassword,
        },
      );
    },
    invitationCardPaymentMethod({
      invitationToken,
      paymentMethodId,
    }: {
      invitationToken: string;
      paymentMethodId: string;
    }) {
      return state.http.put(
        `${state.baseUrl}/invitation-process/card-payment-method`,
        {
          invitationToken,
          paymentMethodId,
        },
      );
    },
    async invitationPaymentMethodSetupIntent({
      invitationToken,
    }: {
      invitationToken: string;
    }) {
      return (
        await state.http.put<PaymentMethodSetupIntent>(
          `${state.baseUrl}/invitation-process/${invitationToken}/setup-intent`,
        )
      ).data;
    },
    invitationPaymentMethodSetupIntentAuthorized({
      invitationToken,
      setupIntentId,
    }: {
      setupIntentId: string;
      invitationToken: string;
    }) {
      return state.http.put(
        `${state.baseUrl}/invitation-process/${invitationToken}/setup-intent-authorized/${setupIntentId}`,
      );
    },
  });
};

export default publicHostedClubApi;

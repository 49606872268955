import {
  clubAsMemberQueryKeys,
  clubAsPublicQueryKeys,
  clubStaleTime,
  useClubPropertiesSettingsAsPublic,
} from "@gymflow/api";
import { ClubPropertiesSettings } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import { ReactNode, useEffect, useState } from "react";

import { LoadingPortal } from "../../components/pages";
import useGymflowModels from "../../store";
import { ClubSettingsContext } from "./ClubSettingsProvider";
import { resetTailwindColors } from "./resetTailwindColors";

export function ClubSettingsAsMemberProvider({
  clubId,
  children,
}: {
  clubId: number;
  children:
    | ((clubPropertiesSettings: ClubPropertiesSettings) => ReactNode)
    | ReactNode;
}) {
  const { api } = useGymflowModels();
  const { data: propertiesSettings } = useClubPropertiesSettingsAsPublic(
    { api, clubId },
    { staleTime: clubStaleTime },
  );

  const [isCacheReady, setIsCacheReady] = useState(false);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (propertiesSettings) {
      queryClient.setQueryData(
        clubAsMemberQueryKeys.details(clubId),
        propertiesSettings.club,
      );
      queryClient.setQueryData(clubAsPublicQueryKeys.featureFlags(clubId), {
        featureFlags: propertiesSettings.featureFlags,
      });
      queryClient.setQueryData(
        clubAsMemberQueryKeys.properties(clubId),
        propertiesSettings.properties,
      );
    }
    resetTailwindColors();
    setIsCacheReady(true);
  }, [clubId, propertiesSettings, queryClient]);

  if (!propertiesSettings) {
    return <LoadingPortal message="Processing club settings ..." />;
  }
  if (!isCacheReady) {
    return <LoadingPortal message="Caching club settings and properties..." />;
  }

  return (
    <ClubSettingsContext.Provider
      value={{
        clubId,

        stripe_account_type: propertiesSettings.club.stripeAccountType,

        default_nationality: propertiesSettings.properties.defaultNationality,
        phone_number_country: propertiesSettings.properties.phoneNumberCountry,
        postal_code_country: propertiesSettings.properties.postalCodeCountry,

        date_format: propertiesSettings.properties.dateFormat,
        short_date_format: propertiesSettings.properties.shortDateFormat,
        long_date_format: propertiesSettings.properties.longDateFormat,

        stripe_api_key: propertiesSettings.properties.stripeApiKey,
        minimum_charge: propertiesSettings.properties.minimumCharge,

        max_image_upload_width: propertiesSettings.properties.maxImgUploadWidth,
        max_image_upload_height:
          propertiesSettings.properties.maxImgUploadHeight,

        allow_phone_country_selection:
          propertiesSettings.properties.allowPhoneCountrySelection,
        allow_customer_pausing_memberships:
          propertiesSettings.properties.allowPausingMembershipsCustomer,
        allow_customer_cancel_memberships:
          propertiesSettings.properties.allowCustomerCancelMemberships,
        allow_customer_selecting_start_date:
          propertiesSettings.properties.allowCustomerSelectingStartDate,

        gym_opening_date: propertiesSettings.properties?.gymOpeningDate,

        defaultCurrency: propertiesSettings.club.defaultCurrency,
        timezone: propertiesSettings.club.timezone,
      }}
    >
      {typeof children === "function" ? children(propertiesSettings) : children}
    </ClubSettingsContext.Provider>
  );
}

import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationHostAvailabilityOverrideDelete({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      staffId,
      availabilityOverrideId,
    }: {
      staffId: Parameters<ApiType["availabilityApi"]["deleteHostOverride"]>[0];
      availabilityOverrideId: Parameters<
        ApiType["availabilityApi"]["deleteHostOverride"]
      >[1];
    }) => {
      await api.availabilityApi.deleteHostOverride(
        staffId,
        availabilityOverrideId,
      );
    },
    onSuccess: (_, { staffId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allHostOverrides(staffId),
      });
    },
  });

  return mutation;
}
